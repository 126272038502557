import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Chip,
  Container,
  Grid,
  ImageList,
  ImageListItem,
  Snackbar,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUniReward } from "../../../../actions/uniReward";
import { addUniRewardCart } from "../../../../actions/uniRewardCart";
import utils from "../../../../utils";
import TiptapDetail from "../../shared/reward/tiptapDetail";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const SnackbarResponse = ({
  configSnackbar: { open, message, severity },
  handleCloseSnackbar,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={handleCloseSnackbar} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

function UniRewardDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: reward } = useSelector((state) => state.uniRewardItem);

  const [select, setSelect] = useState({
    option1: null,
    option2: null,
  });

  const [configSnackbar, setConfigSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [selectItem, setSelectItem] = useState(null);
  const [selectImage, setImgae] = useState(null);

  const handleSelectItem = (optionIndex, x) => {
    // setSelect({ ...select, [`option${optionIndex}`]: x });
    const selectUpdated = { ...select, [`option${optionIndex}`]: x };
    const selectedItem = reward.items.find(
      (item) =>
        item.options[0]?.idVariationOption ===
          selectUpdated.option1?.idVariationOption &&
        item.options[1]?.idVariationOption ===
          selectUpdated.option2?.idVariationOption
    );
    handleChangeImage(selectedItem?.image);
    setSelect(selectUpdated);
    setSelectItem(selectedItem);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setConfigSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleChangeImage = (urlImage) => {
    setImgae(urlImage);
  };

  const handleAddToCart = async () => {
    const res = await dispatch(
      addUniRewardCart({
        idEmployees: userProfile.idEmployees,
        idUniRewardItem: selectItem.idUniRewardItem,
        quantity: 1,
      })
    );
    if (res) {
      setConfigSnackbar({
        open: true,
        severity: "success",
        message: "เพิ่มในตะกร้าเรียบร้อย",
      });
    }
  };

  useEffect(() => {
    dispatch(getUniReward(id));
  }, []);

  useEffect(() => {
    if (reward?.options?.length === 0) {
      setSelectItem(reward.items[0])
    }
  }, [reward]);

  return (
    <StyledRoot className={"page"}>
      {reward && (
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={5}>
              <Box
                sx={{ border: "solid", borderWidth: 1, borderColor: "#bdbdbd" }}
              >
                <img
                  width={"100%"}
                  height={"100%"}
                  alt={reward.name}
                  src={selectImage ? selectImage : reward.image}
                />
              </Box>
              <ImageList
                //   sx={{ width: 500, height: 450 }}
                cols={5}
                //   rowHeight={164}
              >
                {[{ image: reward.image }, ...reward.images].map((item) => (
                  <Box
                    sx={{
                      border: "solid",
                      borderWidth: 1,
                      borderColor: "#bdbdbd",
                    }}
                    onClick={() => handleChangeImage(item.image)}
                    key={item.image}
                  >
                    <ImageListItem key={item.idUniRewardImage}>
                      <img
                        src={item.image}
                        alt={item.idUniRewardImage}
                        loading="lazy"
                      />
                    </ImageListItem>
                  </Box>
                ))}
              </ImageList>
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <Typography variant="h4" marginBottom={8}>
                {reward.name}
              </Typography>
              {reward.options.map((item, index) => (
                <Stack
                  key={item.idVariation}
                  direction={"row"}
                  marginBottom={4}
                >
                  <Typography width={80}>{item.name}</Typography>
                  <Stack direction={"row"} spacing={2}>
                    {item.option.map((x) => (
                      <Chip
                        key={x.idVariationOption}
                        label={x.value}
                        onClick={() => handleSelectItem(index + 1, x)}
                        color={
                          x.idVariationOption ===
                          select[`option${index + 1}`]?.idVariationOption
                            ? "warning"
                            : "default"
                        }
                      />
                    ))}
                  </Stack>
                </Stack>
              ))}
              <Typography variant="h6" marginBottom={4}>
                {`คงเหลือ: ${
                  selectItem ? utils.numberWithCommas(selectItem.quantity) : ""
                }`}
              </Typography>
              <Typography variant="h4" marginBottom={4}>
                {`ราคา: ${
                  selectItem ? utils.numberWithCommas(selectItem.price) : ""
                }`}
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddShoppingCartIcon />}
                onClick={handleAddToCart}
                disabled={!selectItem}
              >
                เพิ่มไปยังตะกร้า
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TiptapDetail detail={reward.detail} />
            </Grid>
          </Grid>
          <SnackbarResponse
            handleCloseSnackbar={handleCloseSnackbar}
            configSnackbar={configSnackbar}
          />
        </Container>
      )}
    </StyledRoot>
  );
}

export default UniRewardDetail;
