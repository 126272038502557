import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SwitchInput from "../../shared/inputForm/switchInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import dayjs from "dayjs";
import DateInputField from "../../shared/inputForm/dateInputField";
import { DevTool } from "@hookform/devtools";
import { useUniReward, useUpdateUniRewardStatus } from "../../../../services/quries";
import TiptapDetail from "../../shared/reward/tiptapDetail";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const StyledBtnEdit = styled(IconButton)({
  marginLeft: 8,
  border: "1px solid #ffc107",
  backgroundColor: "rgba(25, 118, 210, 0.04)",
  color: "#ffc107",
  "&:hover": {
    color: "#FFF",
    backgroundColor: "#ffc107",
    borderColor: "#ffc107",
  },
});

const StyledBtnSave = styled(IconButton)({
  marginLeft: 8,
  border: "1px solid #66bb6a",
  backgroundColor: "rgba(25, 118, 210, 0.04)",
  color: "#66bb6a",
  "&:hover": {
    color: "#FFF",
    backgroundColor: "#66bb6a",
    borderColor: "#66bb6a",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const SnackbarResponse = ({
  configSnackbar: { open, message, severity },
  handleCloseSnackbar,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={handleCloseSnackbar} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

function DetailUniReward() {
  const { id: idUniReward } = useParams();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { data: reward, isPending } = useUniReward({ idUniReward })
  const { mutate } = useUpdateUniRewardStatus({ idUniReward })

  const [select, setSelect] = useState({
    option1: null,
    option2: null,
  });

  const [configSnackbar, setConfigSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [selectItem, setSelectItem] = useState(null);
  const [selectImage, setImgae] = useState(null);
  const [mode, setMode] = useState("read");

  const handleSelectItem = (optionIndex, x) => {
    // setSelect({ ...select, [`option${optionIndex}`]: x });
    const selectUpdated = { ...select, [`option${optionIndex}`]: x };
    const selectedItem = reward.items.find(
      (item) =>
        item.options[0]?.idVariationOption ===
          selectUpdated.option1?.idVariationOption &&
        item.options[1]?.idVariationOption ===
          selectUpdated.option2?.idVariationOption
    );
    handleChangeImage(selectedItem?.image);
    setSelect(selectUpdated);
    setSelectItem(selectedItem);
  };

  const defaultValues = {
    startDate: dayjs(),
    endDate: dayjs().add(1, "day"),
    reward: [],
  };

  const validationSchema = Yup.object().shape({
    startDate: Yup.date().min(
      dayjs().startOf("day"),
      "Start date must be later than current date"
    ),
    endDate: Yup.date().when("startDate", (startDate) => {
      return Yup.date().min(
        dayjs(startDate).add(1, "day"),
        "End date must be later than start date"
      );
    }),
    reward: Yup.array().of(
      Yup.object({
        isActive: Yup.boolean().required("Please enter coin amount"),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setConfigSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleChangeImage = (urlImage) => {
    setImgae(urlImage);
  };

  const handleChangeMode = (value) => {
    setMode(value);
  };

  const handleConfigReward = async (data) => {
    console.log(data);
    // const res = await dispatch(
    //   addUniRewardCart({
    //     idEmployees: currentUser.id,
    //     idUniRewardItem: selectItem.idUniRewardItem,
    //     quantity: 1,
    //   })
    // );
    // if (res) {
    //   setConfigSnackbar({
    //     open: true,
    //     severity: "success",
    //     message: "เพิ่มในตะกร้าเรียบร้อย",
    //   });
    // }
  };

  useEffect(() => {
    if (!isPending && reward) {
      const defaultValues = reward?.items?.map(item => ({ 
        idUniRewardItem: item.idUniRewardItem,
        isActive: item.isActive, 
      }))
      setValue("reward", defaultValues)
    }
  }, [])

  if (isPending) {
    return (
      <StyledRoot className={"page"}>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <CircularProgress color="secondary" size={80} />
        </Box>
      </StyledRoot>
    ) 
  }

  return (
    <StyledRoot className={"page"}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{ border: "solid", borderWidth: 1, borderColor: "#bdbdbd" }}
            >
              <img
                width={"100%"}
                height={"100%"}
                alt={reward.name}
                src={selectImage ? selectImage : reward.image}
              />
            </Box>
            <ImageList
              //   sx={{ width: 500, height: 450 }}
              cols={5}
              //   rowHeight={164}
            >
              {[{ image: reward.image }, ...reward.images].map((item) => (
                <Box
                  sx={{
                    border: "solid",
                    borderWidth: 1,
                    borderColor: "#bdbdbd",
                    display: 'flex',
                  }}
                  onClick={() => handleChangeImage(item.image)}
                  key={item.idUniRewardImage}
                >
                  <ImageListItem>
                    <img
                      src={item.image}
                      alt={item.idUniRewardImage}
                      loading="lazy"
                      style={{ objectFit: 'contain' }}
                    />
                  </ImageListItem>
                </Box>
              ))}
            </ImageList>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={7}>
            <Typography variant="h4" marginBottom={8}>
              {reward.name}
            </Typography>
            {reward.options.map((item, index) => (
              <Stack
                key={item.idVariation}
                direction={"row"}
                marginBottom={4}
              >
                <Typography width={80}>{item.name}</Typography>
                <Stack direction={"row"} spacing={2}>
                  {item.option.map((x) => (
                    <Chip
                      key={x.idVariationOption}
                      label={x.value}
                      onClick={() => handleSelectItem(index + 1, x)}
                      color={
                        x.idVariationOption ===
                        select[`option${index + 1}`]?.idVariationOption
                          ? "warning"
                          : "default"
                      }
                    />
                  ))}
                </Stack>
              </Stack>
            ))}
            <Typography variant="h6" marginBottom={4}>
              {`คงเหลือ: ${selectItem ? selectItem.quantity : ""}`}
            </Typography>
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <Button 
              component={Link} 
              to={`/uni-team/reward/edit/${idUniReward}`} 
              startIcon={<EditIcon />}
            >
              แก้ไขรายละเอียด
            </Button>
            <TiptapDetail detail={reward.detail} />
          </Grid>
          <Grid item xs={12}>
            <Card>
              <form onSubmit={handleSubmit(handleConfigReward)}>
                <CardContent>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginBottom={3}
                  >
                    <Stack direction={"row"} spacing={2}>
                      <DateInputField
                        control={control}
                        name={"startDate"}
                        label={"วันที่เริ่มต้น"}
                        minDate={dayjs()}
                      />
                      <DateInputField
                        control={control}
                        name={"endDate"}
                        label={"วันที่เริ่มต้น"}
                        minDate={dayjs(watch("startDate").add(1, "day"))}
                      />
                    </Stack>
                    {mode === "read" ? (
                      <StyledBtnEdit onClick={() => handleChangeMode("edit")}>
                        <EditIcon />
                      </StyledBtnEdit>
                    ) : (
                      <StyledBtnSave type="submit">
                        <SaveIcon />
                      </StyledBtnSave>
                    )}
                  </Stack>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">รูป</StyledTableCell>
                          <StyledTableCell align="center">SKU</StyledTableCell>
                          <StyledTableCell align="center">ตัวเลือก</StyledTableCell>
                          <StyledTableCell align="right">ราคา</StyledTableCell>
                          <StyledTableCell align="right">จำนวน</StyledTableCell>
                          <StyledTableCell align="center">สถานะ</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reward.items.map((item, index) => (
                          <TableRow key={item.idUniRewardItem}>
                            <StyledTableCell align="center">
                              <img 
                                src={item.image} 
                                alt="product" 
                                width={80} 
                                onClick={() => handleChangeImage(item.image)} 
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">{item.sku}</StyledTableCell>
                            <StyledTableCell align="center">
                              {item.options
                                .map((option) => option.value)
                                .join(", ")}
                            </StyledTableCell>
                            <StyledTableCell align="right">{item.price}</StyledTableCell>
                            <StyledTableCell align="right">
                              {item.quantity}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Stack alignItems={"center"}>
                                <SwitchInput
                                  control={control}
                                  name={`reward.${index}.isActive`}
                                  disable={mode === 'read' ? true : false}
                                />
                              </Stack>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
                <CardActions>
                  {/* <Button fullWidth type="submit">
                    Submit
                  </Button> */}
                </CardActions>
              </form>
            </Card>
          </Grid>
        </Grid>
        <SnackbarResponse
          handleCloseSnackbar={handleCloseSnackbar}
          configSnackbar={configSnackbar}
        />
      </Container>
      <DevTool control={control} />
    </StyledRoot>
  );
}

export default DetailUniReward;
