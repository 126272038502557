import React, { useState } from "react";
import {
  Autocomplete,
  Avatar,
  Button,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';

const StyledAutocomplete = styled(Autocomplete)({
  minWidth: "400px",
  backgroundColor: "#282828",
  "& .MuiOutlinedInput-root": {
    color: "#ffffff",
  },
  fieldset: {
    borderColor: "#ffffff",
  },
  "& .MuiFormLabel-root,& .MuiFormLabel-root.Mui-focused": {
    color: "#ffffff",
  },
});

const TableMoraleDaily = ({ filterData, exportFile }) => {
  const [filterEmployee, setFilterEmployee] = useState(null);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <PersonIcon sx={{ color: "#ffffff" }} />
          <Typography variant="h5" color={"#ffffff"}>
            รายชื่อพนักงาน
          </Typography>
        </div>
        <div style={{ display: "flex", gap: "8px" }}>
          <StyledAutocomplete
            options={filterData}
            value={filterEmployee}
            onChange={(event, newValue) => setFilterEmployee(newValue)}
            getOptionLabel={(option) =>
              `${option.firstname_TH} ${option.lastname_TH}`
            }
            isOptionEqualToValue={(option, value) =>
              option.idEmployees === value.idEmployees
            }
            renderInput={(params) => (
              <TextField {...params} label="เลือกพนักงาน" />
            )}
          />
          <Button 
            variant="contained" 
            onClick={exportFile} 
            disabled={filterData.length === 0}
          >
            Export
          </Button>
        </div>
      </div>
      <TableContainer 
        component={Paper} 
        sx={{ 
          maxHeight: 600, 
          backgroundColor: "#000000", 
          border: "1px solid #2e2e2e", 
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                colSpan={6}
                sx={{ backgroundColor: "#000000", color: "#ffffff", fontSize: "1rem", borderColor: "#2e2e2e", borderRight: "1px solid #2e2e2e" }}
              >
                Employee
              </TableCell>
              <TableCell
                align="center"
                colSpan={filterData[0].questionTopics.length + 1}
                sx={{ backgroundColor: "#000000", color: "#ffffff", fontSize: "1rem", borderColor: "#2e2e2e" }}
              >
                Details
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#000000", color: "#ffffff", fontSize: "1rem", borderColor: "#2e2e2e" }}>
                Name
              </TableCell>
              <TableCell 
                align="center" 
                sx={{ backgroundColor: "#000000", color: "#ffffff", fontSize: "1rem", borderColor: "#2e2e2e" }}
              >
                Position
              </TableCell>
              <TableCell 
                align="center" 
                sx={{ backgroundColor: "#000000", color: "#ffffff", fontSize: "1rem", borderColor: "#2e2e2e" }}
              >
                Section
              </TableCell>
              <TableCell 
                align="center" 
                sx={{ backgroundColor: "#000000", color: "#ffffff", fontSize: "1rem", borderColor: "#2e2e2e" }}
              >
                Department
              </TableCell>
              <TableCell 
                align="center" 
                sx={{ backgroundColor: "#000000", color: "#ffffff", fontSize: "1rem", borderColor: "#2e2e2e" }}
              >
                Division
              </TableCell>
              <TableCell
                align="center"
                sx={{ 
                  color: "#ffffff",
                  backgroundColor: "#000000",
                  fontSize: "1rem",
                  borderColor: "#2e2e2e",
                  borderRight: "1px solid #2e2e2e",
                }}
              >
                average Score
              </TableCell>
              {filterData[0].questionTopics.map((item) => (
                <TableCell 
                  key={item.idQuestionTopic}
                  align="center"
                  sx={{ backgroundColor: "#000000", color: "#ffffff", fontSize: "1rem", borderColor: "#2e2e2e" }}
                >
                  {item.questionTopic}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filterData
              .filter((item) =>
                filterEmployee
                  ? item.idEmployees === filterEmployee.idEmployees
                  : true
              )
              .map((row) => (
                <TableRow
                  key={row.idEmployees}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{ borderColor: "#2e2e2e" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Avatar src={row.imageURL} />
                      <Typography color={"#ffffff"}>
                        {`${row.firstname_TH} ${row.lastname_TH}`}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#ffffff", borderColor: "#2e2e2e" }}>
                    {row.positionName}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#ffffff", borderColor: "#2e2e2e" }}>
                    {row.sectionName}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#ffffff", borderColor: "#2e2e2e" }}>
                    {row.departmentName}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#ffffff", borderColor: "#2e2e2e" }}>
                    {row.DivisionName}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "#ffffff", borderColor: "#2e2e2e", borderRight: "1px solid #2e2e2e" }}
                  >
                    {row.averageScore}
                  </TableCell>
                  {row.questionTopics.map((item) => (
                    <TableCell key={item.idQuestionTopic} align="center" sx={{ color: "#ffffff", borderColor: "#2e2e2e" }}>
                      {item.averageScore}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableMoraleDaily;
