import { httpClient } from "./httpClient";

const getHeartLeaderboard = () => {
  return httpClient.get("heart-leaderboard");
};

const getCoinLeaderboard = () => {
  return httpClient.get("coin-leaderboard");
};

const getManagerHeartLeaderboard = () => {
  return httpClient.get("manager-heart-leaderboard");
};

export default {
  getHeartLeaderboard,
  getCoinLeaderboard,
  getManagerHeartLeaderboard,
};
