import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import HeaderPage from "../../shared/header/headerPage";
import { StyledDividerHeader } from "../../shared/dividerHeader";
import dayjs from "dayjs";
import utils from "../../../../utils";
import { useUniRewardOrder } from "../../../../quries/cart";
import CompanyBudget from "./companyBudget";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const OrderItem = ({ order }) => {
  const totalPrice = order.orderItem.reduce(
    (acc, curr) => acc + curr.quantity * curr.price,
    0
  );

  return (
    <Card>
      <CardHeader
        sx={{ bgcolor: "#071952" }}
        title={
          <>
            <Typography
              color={"white"}
            >{`รหัสคำสั่งซื้อ: ${order.idOrderReward}`}</Typography>
            <Typography
              color={"white"}
            >{`${order.firstname_TH} ${order.lastname_TH}`}</Typography>
          </>
        }
        titleTypographyProps={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "right",
        }}
        subheader={
          <Typography variant="body2" color={"text.secondary"}>
            {dayjs(order.createAt).format("DD/MM/YYYY HH:mm")}
          </Typography>
        }
      />
      <CardContent sx={{ pt: 0 }}>
        <List>
          {order.orderItem.map((item) => (
            <>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="reward" src={item.imageURL} />
                </ListItemAvatar>
                <ListItemText
                  primary={item.name}
                  secondary={
                    <>
                      {`ตัวเลือก: ${item.variation.map((item) => item.value).join(", ")}`}
                      <Typography variant="body2" color="text.primary">
                        {`จำนวน: ${item.quantity}`}
                      </Typography>
                      <Typography variant="body2" color="text.primary">
                        {`ราคา: ฿${utils.numberWithCommas(item.price)}`}
                      </Typography>
                    </>
                  }
                />
                <Typography variant="body2" color="text.primary">
                  {`฿${utils.numberWithCommas(item.price * item.quantity)}`}
                </Typography>
              </ListItem>
              <Divider component="li" />
            </>
          ))}
        </List>
        <Typography variant="h6" align="right">
          {`ราคารวม ฿${utils.numberWithCommas(totalPrice)}`}
        </Typography>
      </CardContent>
    </Card>
  );
};

function UniRewardOrder() {
  const { data, isPending} = useUniRewardOrder();

  if (isPending) {
    return <CircularProgress color="secondary" />;
  }

  return (
    <StyledRoot className={"page"}>
      <Container>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <HeaderPage textLabel={"ประวัติการซื้อ"} />
          <CompanyBudget />
        </div>
        <StyledDividerHeader />
        <Stack spacing={4}>
          {data?.map((order) => (
            <OrderItem order={order} />
          ))}
        </Stack>
      </Container>
    </StyledRoot>
  );
}

export default UniRewardOrder;
