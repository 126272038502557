import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import PlaceIcon from "@mui/icons-material/Place";
import NavigationIcon from "@mui/icons-material/Navigation";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { Divider, IconButton, Typography } from "@mui/material";
import { Alert, AlertTitle, Link as DownloadLink } from "@mui/material";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { MainCoin, SpecialIcon } from "../../shared/typeCoin";
import { useDispatch, useSelector } from "react-redux";
import { addActivityRegister } from "../../../../actions/activity";

const WrapDescItem = styled("div")({
  display: "flex",
  alignItems: "center",
  marginTop: 4,
  "& svg": {
    marginRight: 8,
  },
});

const WrapDescReward = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 16,
});

export default function DialogMyActivity({ open, handleClose, value }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"รายละเอียดกิจกรรม"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <img src={value.image} style={{ width: "100%" }} alt="activity" />
            {value.file && (
              <DownloadLink href={value.file} target="_blank" download>
                ไฟล์รายละเอียดกิจกรรม
              </DownloadLink>
            )}
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography variant="h5">{value.name}</Typography>
            <Typography variant="body2" color="textSecondary" paddingLeft={1}>
              {`รายละเอียด: ${value.detail}`}
            </Typography>
            <WrapDescItem>
              <PlaceIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ lineHeight: 1.8 }}
              >
                สถานที่จัดกิจกรรม
              </Typography>
            </WrapDescItem>
            <WrapDescItem>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ lineHeight: 1.8, paddingLeft: 32 }}
              >
                {value.location}
              </Typography>
              {value.lat && value.lng && (
                <IconButton
                  color="primary"
                  href={`https://www.google.com/maps/search/?api=1&query=${value.lat}%2C${value.lng}`}
                  target="_blank"
                >
                  <NavigationIcon />
                </IconButton>
              )}
            </WrapDescItem>
            <WrapDescItem>
              <EventIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ lineHeight: 1.8 }}
              >
                วัน-เวลา รับสมัคร
              </Typography>
            </WrapDescItem>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ lineHeight: 1.8, paddingLeft: 32 }}
            >
              {`${dayjs(value.openRegisterDate).format(
                "DD MMM YYYY HH:mm"
              )} - ${dayjs(value.closeRegisterDate).format(
                "DD MMM YYYY HH:mm"
              )}`}
            </Typography>
            <WrapDescItem>
              <AccessTimeIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ lineHeight: 1.8 }}
              >
                วัน-เวลา กิจกรรม
              </Typography>
            </WrapDescItem>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ lineHeight: 1.8, paddingLeft: 32 }}
            >
              {`${dayjs(value.startDate).format("DD MMM YYYY HH:mm")} - ${dayjs(
                value.endDate
              ).format("DD MMM YYYY HH:mm")}`}
            </Typography>
            <WrapDescItem>
              <ContactPhoneIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ lineHeight: 1.8 }}
              >
                ข้อมูลผู้ดูแลกิจกรรม
              </Typography>
            </WrapDescItem>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ lineHeight: 1.8, paddingLeft: 32 }}
            >
              {value.organizer}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ lineHeight: 1.8, paddingLeft: 32 }}
            >
              {value.contact}
            </Typography>
            <WrapDescReward>
              {value.coin > 0 && (
                <>
                  <MainCoin width={40} />
                  <Typography variant="h6" style={{ marginLeft: 5 }}>
                    {value.coin}
                  </Typography>
                </>
              )}
              {value.specialCoin > 0 && value.coin > 0 && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: "white", margin: "0 10px" }}
                />
              )}
              {value.specialCoin > 0 && (
                <>
                  <SpecialIcon idCoinType={value.idCoinType} width={40} />
                  <Typography variant="h6" style={{ marginLeft: 5 }}>
                    {`${value.specialCoin} ${value.specialCoinType}`}
                  </Typography>
                </>
              )}
            </WrapDescReward>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  );
}
