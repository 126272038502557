import React, { Fragment, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Typography,
  Paper,
  Button,
  Chip,
} from "@mui/material";

import { deleteAssessment, getAllAssessment } from "../../../../../actions/assessment";
import { useNavigate } from "react-router-dom";

const WrapCardQuestion = styled(Paper)({
  width: "100%",
  display: "flex",
  borderRadius: 15,
  marginBottom: 20,
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
})

const StyledBtnDescPublish = styled(Button)({
  border: 0,
  borderRadius: 50,
  padding: "4px 12px",
  fontSize: 14,
  backgroundColor: "#2A265F",
  color: "#FFFFFF",
  position: "absolute",
  bottom: 20,
  right: 20,
  "&:hover": {
    backgroundColor: "#2a265f",
    borderColor: "#2a265f",
    boxShadow: "none",
  },
  ["@media only screen and (max-width:600px)"]: {
    position: "initial",
    marginTop: 16,
    width: "100%"
  },
})

const StyledBtnDescDraft = styled(Button)({
  border: 0,
  borderRadius: 50,
  padding: "4px 12px",
  fontSize: 14,
  backgroundColor: "#f8de55",
  color: "#3C3C3C",
  position: "absolute",
  bottom: 20,
  right: 20,
  "&:hover": {
    backgroundColor: "#f8de55",
    borderColor: "#f8de55",
    boxShadow: "none",
  },
  ["@media only screen and (max-width:600px)"]: {
    position: "initial",
    marginTop: 16,
    width: "100%"
  },
})

const StyledPanelLeft = styled('div',
{ shouldForwardProp: (prop) => prop !== 'myBgColor' &&  prop !== 'myColor'})(
  ({ myBgColor, myColor }) => ({
    backgroundColor: myBgColor,
    width: "30%",
    padding: 20,
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .questionName": {
      fontSize: 40,
      color: myColor,
    },
    "& .subQuestionName": {
      fontSize: 24,
      color: myColor,
    },
    "& .wrapViewAnswer": {
      marginTop: 8,
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      color: myColor,
    },
    ["@media only screen and (max-width:600px)"]: {
      width: "100%",
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 15,
      "& .questionName": {
        fontSize: 24,
      },
      "& .subQuestionName": {
        fontSize: 16,
      },
    },
}))

const StyledPanelRight = styled('div')({
  width: "70%",
  padding: 20,
  color: "#2a265f",
  position: "relative",
  "& .inner-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .questionTopic":{
    display: "flex", alignItems: "baseline"
  },
  ["@media only screen and (max-width:600px)"]: {
    width: "100%",
    "& .inner-top": {
      flexDirection: "column",
      alignItems: "flex-end",
    },
    "& .questionTopic":{
      flexDirection: "column"
    }
  },
})

const StyledContentTopic = styled(Typography)({
  fontSize: 25,
  color: "#000000",
  display: "flex",
  alignItems: "baseline",
})

const StyledQuestionStatus = styled(Chip)({
  fontWeight: 500,
  borderRadius: 5,
  height: 20,
  textTransform: "uppercase",
})

const WrapQuestionStatusPublished = styled('div')({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 16,
})

const StyledQuestionNumber = styled(Typography,
  { shouldForwardProp: (prop) => prop !== 'myColor'})(
    ({ myColor }) => ({
      fontSize: 14, 
      fontStyle: "oblique",
      color: myColor,
}))

const AssessmentList = () => {
  // const history = useHistory()
  const navigate = useNavigate();

  const dispatch = useDispatch()
  const { result: assessmentList } = useSelector((state) => state.assessment);

  useEffect(() => {
    dispatch(getAllAssessment())
  }, []);

  const handleClickDescription = (id) => {
    navigate("/admin/manage/assessment360/" + id);
  };

  return (
    <Fragment>
      {assessmentList && assessmentList.map((value) => {
        return (
          <WrapCardQuestion key={value.idAssessment}>
            <StyledPanelLeft
              myBgColor={value.assessmentType === "open" ? "#f8de55" : "#2A265F"
              }
              myColor={value.assessmentType === "open" ? "#3C3C3C" : "#FFFFFF"}
            >
              <div>
                <StyledQuestionNumber 
                  myColor={value.assessmentType === "open" ? "#3C3C3C" : "#FFFFFF"}
                >
                  {`#${value.idAssessment}`}
                </StyledQuestionNumber>
                <Typography className={`questionName`}>
                  {value.assessmentType}
                </Typography>
                <Typography variant="h3" className={`subQuestionName`}>
                  {`ใช้ไปแล้ว: ${value.numberOfUse} ครั้ง`}
                </Typography>
              </div>
            </StyledPanelLeft>
            <StyledPanelRight>
              <div className={`inner-top`}>
                <WrapQuestionStatusPublished>
                  <StyledQuestionStatus
                    label={`Create: ${dayjs(value.createDate).format("YYYY-MM-DD HH:mm")}`}
                    style={{
                      backgroundColor: value.assessmentType === "open" 
                        ? "#f8de55" 
                        :  "#2A265F"
                      ,
                      color:
                        value.assessmentType === "open"
                          ? "#3C3C3C"
                          : "#FFFFFF",
                    }}
                  />
                </WrapQuestionStatusPublished>
              </div>
              <StyledContentTopic>
                {`ชื่อชุดคำถาม : ${value.assessmentName}`}
              </StyledContentTopic>
              <StyledContentTopic>
                {`จำนวนคำถาม : ${value.questionList.length} ข้อ`}
              </StyledContentTopic>
              {value.assessmentType === "close" && (
                <StyledBtnDescPublish
                  onClick={() => handleClickDescription(value.idAssessment)}
                >
                  รายละเอียด
                </StyledBtnDescPublish>
              )}
              {value.assessmentType === "open" && (
                <StyledBtnDescDraft
                  onClick={() => handleClickDescription(value.idAssessment)}
                >
                  รายละเอียด
                </StyledBtnDescDraft>
              )}
            </StyledPanelRight>
          </WrapCardQuestion>
        ) 
      })}
    </Fragment>
  );
};

export default AssessmentList;
