import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Typography, Paper, Tabs, Tab, Box } from "@mui/material";

import Monthly from "./monthly";
import Compare from "./compare";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTextHeader = styled(Typography)({
  color: "#e2e2e2",
  marginTop: 8,
  marginBottom: 24,
});

const WrapTabs = styled(Paper)({
  backgroundColor: "#424242",
  color: "#FFFFFF",
  margin: "auto",
  width: 350,
});

const StyledTabPanel = styled(TabPanel)({
  "& .MuiBox-root": {
    padding: 0,
    marginTop: 16,
  },
});

const MoraleKpi = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={`page dashboard-page`}>
      <StyledTextHeader variant="h4">Morale Dashboard</StyledTextHeader>
      <WrapTabs>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          centered
        >
          <Tab label="Monthly" {...a11yProps(0)} />
          <Tab label="5Q Comparison" {...a11yProps(1)} />
        </Tabs>
      </WrapTabs>
      <StyledTabPanel value={value} index={0}>
        <Monthly />
      </StyledTabPanel>
      <StyledTabPanel value={value} index={1}>
        <Compare />
      </StyledTabPanel>
    </div>
  );
};

export default MoraleKpi;
