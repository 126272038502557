import React from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  List,
  ListItem,
  IconButton,
  Paper,
  CircularProgress,
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import GaugeAnalytic from "./GaugeAnalytic";
import wordframe from "./assets/wordframe.png";
import { HeartCoin } from "../../shared/typeCoin";
import dayjs from "dayjs";
import { useHeartLeaderboardRank } from "../../../../quries/leaderBoard";

const StyledCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
});

const WrapPhone = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: 20,
});

const StyledCardMedia = styled(CardMedia)({
  width: 120,
});

const WrapWordCloud = styled(Paper)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "280px",
  width: "280px",
  backgroundImage: `url(${wordframe})`,
  backgroundSize: "100% 100%",
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const AnalyticDetail = (props) => {
  const { open, handleClose, scroll, detail } = props;
  const { isPending, data: ranking } = useHeartLeaderboardRank({ idEmployees: detail.idEmployees })

  // const wordCloud = [
  //   {
  //     id: 1,
  //     text: "Word Cloud 1",
  //   },
  //   {
  //     id: 2,
  //     text: "Word Cloud 2",
  //   },
  //   {
  //     id: 3,
  //     text: "Word Cloud 3",
  //   },
  //   {
  //     id: 4,
  //     text: "Word Cloud 4",
  //   },
  //   {
  //     id: 5,
  //     text: "Word Cloud 5",
  //   },
  //   {
  //     id: 6,
  //     text: "Word Cloud 6",
  //   },
  //   {
  //     id: 7,
  //     text: "Word Cloud 7",
  //   },
  //   {
  //     id: 8,
  //     text: "Word Cloud 8",
  //   },
  // ];

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <BootstrapDialogTitle onClose={handleClose}>
        Detail
      </BootstrapDialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <Grid container rowSpacing={8}>
          <Grid item xs={12}>
            <StyledCard elevation={10}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item sm={12} md={5}>
                  <Stack alignItems="center">
                    <StyledCardMedia
                      sx={{ borderRadius: "50%" }}
                      component="img"
                      image={detail.imageURL}
                      alt="employee"
                    />
                    <Typography variant="subtitle1" color="text.secondary">
                      {`${detail.firstname_TH} ${detail.lastname_TH}`}
                    </Typography>
                    <Typography>{detail.positionName}</Typography>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <WrapPhone>
                        <CallIcon color="success" />
                        <Typography marginLeft={1}>
                          {detail.telephoneMobile}
                        </Typography>
                      </WrapPhone>
                      <WrapPhone>
                        <EmailIcon color="error" />
                        <Typography marginLeft={1}>
                          {detail.email}
                        </Typography>
                      </WrapPhone>
                    </Stack>
                    <Button variant="outlined">view profile</Button>
                  </Stack>
                </Grid>
                <Grid item sm={12} md={7}>
                  <CardContent>
                    <Grid container rowSpacing={3}>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" color="text.secondary">
                          Employee Type
                        </Typography>
                        <Typography>{detail.employmentTypeName ? detail.employmentTypeName : "-"}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" color="text.secondary">
                          Company
                        </Typography>
                        <Typography>{detail.companyName}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" color="text.secondary">
                          BusinessUnit
                        </Typography>
                        <Typography>{detail.businessUnitName}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" color="text.secondary">
                          Division
                        </Typography>
                        <Typography>{detail.divisionName ? detail.divisionName : "-"}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" color="text.secondary">
                          Department
                        </Typography>
                        <Typography>{detail.departmentName ? detail.departmentName : "-"}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" color="text.secondary">
                          Section
                        </Typography>
                        <Typography>{detail.sectionName ? detail.sectionName : "-"}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" color="text.secondary">
                          Position
                        </Typography>
                        <Typography>{detail.positionName}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" color="text.secondary">
                          Job Group
                        </Typography>
                        <Typography>{detail.jobGroupName ? detail.jobGroupName : "-"}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" color="text.secondary">
                          Report to
                        </Typography>
                        <Typography>{`${detail.manager_firstname_TH} ${detail.manager_lastname_TH}`}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" color="text.secondary">
                          Personal Level
                        </Typography>
                        <Typography>{detail.levelName}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" color="text.secondary">
                          TIG
                        </Typography>
                        <Typography>
                          {`${dayjs().diff(detail.personnelLevelStart, "year")} / 
                            ${dayjs().diff(detail.personnelLevelStart, "month") % 12}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" color="text.secondary">
                          TIP
                        </Typography>
                        <Typography>
                          {`${dayjs().diff(detail.positionStart, "year")} / 
                            ${
                              dayjs().diff(detail.positionStart, "month") % 12
                            }`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" color="text.secondary">
                          ESY
                        </Typography>
                        <Typography>
                          {`${
                            dayjs().diff(detail.hiringDate, "year") +
                            detail.OESY_Y
                          } / 
                            ${
                              (dayjs().diff(detail.hiringDate, "month") % 12) +
                              detail.OESY_M
                            }`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" color="text.secondary">
                          Service Year
                        </Typography>
                        <Typography>
                          {`${dayjs().diff(detail.hiringDate, "year")} / 
                            ${dayjs().diff(detail.hiringDate, "month") % 12}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" color="text.secondary">
                          Age
                        </Typography>
                        <Typography>
                          {`${dayjs().diff(detail.birthday, "year")} / 
                            ${dayjs().diff(detail.birthday, "month") % 12}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
              </Grid>
            </StyledCard>
          </Grid>
          <Grid item xs={12}>
            <StyledCard elevation={10}>
              <CardContent>
                <Typography variant="h6">Morale Daily</Typography>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <div>
                    <GaugeAnalytic score={detail.moraleDailyAverageScore} />
                  </div>
                  <Typography variant="h1">{`${detail.moraleDailyAverageScore}%`}</Typography>
                </Stack>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12}>
            <StyledCard elevation={10}>
              <CardContent>
                <Typography variant="h6">Morale Quarter</Typography>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <div> 
                    <GaugeAnalytic score={detail.moraleAverageScore} />
                  </div>
                  <Typography variant="h1">{`${Math.round(detail.moraleAverageScore)}%`}</Typography>
                </Stack>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12}>
            <StyledCard elevation={10}>
              <CardContent>
                <Typography variant="h6">Morale Topic</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      variant="h5"
                      textAlign={"center"}
                      bgcolor="#66bb6a"
                    >
                      Happy
                    </Typography>
                    <Box borderColor="black" border={2}>
                      <List>
                        {detail.topicMoraleHappy.map((value, index) => (
                          <ListItem>
                            <Typography noWrap>
                              {`${index + 1} ${value.questionTopic}`}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h5"
                      textAlign={"center"}
                      bgcolor="#ffa726"
                    >
                      UnHappy
                    </Typography>
                    <Box borderColor="black" border={2}>
                      <List>
                        {detail.topicMoraleUnHappy.map((value, index) => (
                          <ListItem sx={{ display: "list-item" }}>
                            <Typography noWrap>
                              {`${index + 1} ${value.questionTopic}`}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12}>
            <StyledCard elevation={10}>
              <CardContent>
                <Typography variant="h6">Heart Rank</Typography>
                {isPending || !ranking ? (
                  <CircularProgress />
                ) : (
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <HeartCoin width={100} />
                    <Typography variant="h3">{`Rank ${ranking.rank}`}</Typography>
                  </Stack>
                )}
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12}>
            <StyledCard elevation={10}>
              <CardContent>
                <Typography variant="h6">Assessment 360</Typography>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <div>
                    <GaugeAnalytic score={detail.assessmentAverageScore} />
                  </div>
                  <Typography variant="h1">{`${detail.assessmentAverageScore}%`}</Typography>
                </Stack>
              </CardContent>
            </StyledCard>
          </Grid>
          {/* <Grid item xs={12}>
            <StyledCard elevation={10}>
              <CardContent>
                <Typography variant="h5">Word cloud</Typography>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  marginTop={2}
                >
                  {wordCloud.map((value) => (
                    <Grid item>
                      <WrapWordCloud elevation={0}>
                        <Typography
                          variant="h5"
                          width={"80%"}
                          textAlign="center"
                        >
                          {value.text}
                        </Typography>
                      </WrapWordCloud>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12}>
            <StyledCard elevation={10}>
              <CardContent>
                <Typography variant="h5">Other detail</Typography>
              </CardContent>
            </StyledCard>
          </Grid> */}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AnalyticDetail;
