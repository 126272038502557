import React, { Fragment, forwardRef, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ListItemButton } from "@mui/material";
import { useSelector } from "react-redux";

const StyledList = styled(List)({
  padding: 8,
});

const StyledListItem = styled(ListItemButton)({
  maxHeight: 48,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  borderRadius: 8,
  "&.activated": {
    color: "#ffffff",
    background: "linear-gradient(0.25turn, #000046, #1cb5e0)",
    fontWeight: "700 !important",
    "& *": {
      color: "inherit",
      fontWeight: "inherit",
    },
  },
});

const StyledListItemOpen = styled(ListItemButton)({
  borderRadius: 8,
  "&.activated": {
    color: "#ffffff",
    background: "linear-gradient(0.25turn, #000046, #1cb5e0)",
    fontWeight: "700 !important",
    "& *": {
      color: "inherit",
      fontWeight: "inherit",
    },
  },
  "& .MuiListItemIcon-root": {
    display: "flex",
    justifyContent: "center",
  },
});

const coachMenu = [
  "coach",
  "coachDashboard",
  "coach-booking",
  "coachee-list",
  "coach-history",
];

const CustomNavLink = forwardRef((props, ref) => {
  return (
    <NavLink
      end
      ref={ref}
      {...props}
      className={({ isActive }) =>
        isActive ? props.className + " activated" : props.className
      }
    />
  );
});

const ListMenu = (props) => {
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [dataListMenu] = useState(props.dataListMenu);
  const [openCollapse, setOpenCollapse] = React.useState(-1);

  const handleClickCollapse = (index) => {
    if (openCollapse === index) {
      setOpenCollapse(-1);
    } else {
      setOpenCollapse(index);
    }
  };

  return (
    <StyledList
      subheader={
        props.menuRole && <ListSubheader>{props.menuRole}</ListSubheader>
      }
    >
      {dataListMenu.map((value, index) => {
        return (
          <Fragment key={value.listKey}>
            {value.collapse ? (
              <>
                <StyledListItem
                  onClick={() => handleClickCollapse(index)}
                >
                  <ListItemIcon>{value.listItemIcon}</ListItemIcon>
                  <ListItemText primary={value.listItemText} />
                  {openCollapse === index ? <ExpandLess /> : <ExpandMore />}
                </StyledListItem>
                <Collapse
                  in={openCollapse === index}
                  timeout="auto"
                  unmountOnExit
                >
                  <List>
                    {value.collapse
                      .filter((collapse) =>
                        coachMenu.includes(collapse.listKey)
                          ? userProfile && userProfile.idCoach
                          : collapse
                      )
                      .map((collapse) => {
                        return (
                          <Fragment key={collapse.listKey}>
                            {collapse.listItemIcon === null ? (
                              <ListSubheader
                                style={{ padding: "0px 16px" }}
                              >
                                {collapse.listItemText}
                              </ListSubheader>
                            ) : (
                              <StyledListItemOpen
                                component={CustomNavLink}
                                to={collapse.listLink}
                                open={props.open}
                              >
                                <ListItemIcon>
                                  {collapse.listItemIcon}
                                </ListItemIcon>
                                <ListItemText primary={collapse.listItemText} />
                              </StyledListItemOpen>
                            )}
                          </Fragment>
                        );
                      })}
                  </List>
                </Collapse>
              </>
            ) : (
              <StyledListItem
                component={CustomNavLink}
                to={value.listLink}
                key={value.listKey}
              >
                <ListItemIcon>{value.listItemIcon}</ListItemIcon>
                <ListItemText primary={value.listItemText} />
              </StyledListItem>
            )}
          </Fragment>
        );
      })}
    </StyledList>
  );
};

export default ListMenu;