import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { httpClient } from "../services/httpClient";

export const useRedeemTransactions = ({ idCompany }) => {
  return useQuery({
    queryKey: ["redeemTransaction", "company"],
    queryFn: async () => (await httpClient.get(`redeem-transaction/company/${idCompany}`)).data,
    enabled: Boolean(idCompany),
  });
};

export const useRedeemTransactionEmployee = ({ idEmployees }) => {
  return useQuery({
    queryKey: ["redeemTransaction", "employee"],
    queryFn: async () => (await httpClient.get(`redeem-transaction/employee/${idEmployees}`)).data,
    enabled: Boolean(idEmployees),
  });
};

export const useRedeemTransaction = () => {
  return useQuery({
    queryKey: ["redeemTransaction"],
    queryFn: async () => (await httpClient.get("redeem-transaction")).data,
  });
};

export const useAddRedeemTransaction = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData) => httpClient.post("redeem-transaction", formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["redeemTransaction"] });
    },
  });
};

export const useUpdateRedeemTransaction = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData) => httpClient.patch(`redeem-transaction/${formData.idRedeem}`, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["redeemTransaction", "company"] });
    },
  });
};

export const useCancelRedeemTransaction= () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (idRedeem) => httpClient.patch(`redeem-transaction/${idRedeem}/cancel`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["redeemTransaction", "company"] });
    },
  });
};
