import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Card } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";

import { SpecialIcon } from "../typeCoin";
import dayjs from "dayjs";
import Utils from "../../../../utils";

import DialogDescriptionReward from "./dialogDescpritonReward";
import { useSelector } from "react-redux";
import DialogRedeemSuccess from "../../user/redeemReward/dialogRedeemSuccess";

// const WrapRootCard = styled("div")({
//   width: "100%",
//   position: "relative",
//   marginBottom: 24,
//   ["@media only screen and (min-width:576px)"]: {
//     // eslint-disable-line no-useless-computed-key
//     width: "calc((100% - 24px) / 2)",
//     "&:nth-child(n)": {
//       marginRight: 24,
//     },
//     "&:nth-child(2n)": {
//       marginRight: 0,
//     },
//   },
//   ["@media only screen and (min-width:768px)"]: {
//     // eslint-disable-line no-useless-computed-key
//     width: "calc((100% - 24px) / 2)",
//     "&:nth-child(n)": {
//       marginRight: 24,
//     },
//     "&:nth-child(2n)": {
//       marginRight: 0,
//     },
//   },
//   ["@media only screen and (min-width:992px)"]: {
//     // eslint-disable-line no-useless-computed-key
//     width: "calc((100% - 48px) / 3)",
//     "&:nth-child(n)": {
//       marginRight: 24,
//     },
//     "&:nth-child(3n)": {
//       marginRight: 0,
//     },
//   },
//   ["@media only screen and (min-width:1200px)"]: {
//     width: "calc((100% - 72px) / 4)",
//     "&:nth-child(n)": {
//       marginRight: 24,
//     },
//     "&:nth-child(4n)": {
//       marginRight: 0,
//     },
//   },
// });

const StyledCard = styled(Card)({
  borderRadius: 8,
  height: "100%",
});

// const StyledCardMedia = styled(CardMedia)({
//   height: "160px",
//   paddingTop: "56.25%", // 16:9
//   backgroundSize: "contain",
// });

const StyledCardContent = styled(CardContent)({
  padding: "16px !important",
  "& .rewardName": {
    display: "flex",
    alignItems: "end",
    "& img": {
      marginRight: 4,
    },
  },
  "& .price": {
    display: "flex",
    alignItems: "center",
    marginTop: 16,
    marginBottom: 8,
    justifyContent: "flex-end",
  },
  "& .price .MuiSvgIcon-root": {
    color: "rgba(0, 0, 0, 0.2)",
  },
});

const WrapCoin = styled("div", {
  shouldForwardProp: (prop) => prop !== "showAmount",
})(({ showAmount }) => ({
  display: "flex",
  alignItems: "center",
  opacity: showAmount,
}));

const CardReward = ({ data }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openRedeemSuccess, setOpenRedeemSuccess] = useState(false);

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const handleClickCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseRedeemSuccess = () => {
    setOpenRedeemSuccess(false);
  };

  const handleOpenRedeemSuccess = () => {
    setOpenRedeemSuccess(true);
  };

  return (
    <>
      {openDialog && (
        <DialogDescriptionReward
          data={data}
          open={openDialog}
          handleClose={handleClickCloseDialog}
          handleOpenRedeemSuccess={handleOpenRedeemSuccess}
        />
      )}
       {openRedeemSuccess && (
        <DialogRedeemSuccess
          open={openRedeemSuccess}
          handleClose={handleCloseRedeemSuccess}
          name={data.name}
          imageURL={data.imageURL}
        />
      )}
      {userProfile && (
        <StyledCard elevation={3}>
          <CardActionArea
            style={{ height: "100%" }}
            onClick={handleClickOpenDialog}
          >
            <StyledCardContent>
              <CardMedia
                component="img"
                height="140"
                alt={data.name}
                image={data.imageURL}
                sx={{ objectFit: "contain", marginBottom: "8px" }}
              />
              <div className={`rewardName`}>
                <Typography
                  variant="h6"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {data.name}
                </Typography>
              </div>
              <Typography variant="body2" color="textSecondary">
                {`คงเหลือ: ${data.quantity} / ${data.initialQuantity}`}
              </Typography>
              {data.endDate && (
                <Typography
                  color="textSecondary"
                  variant="body1"
                  style={{ fontSize: 14 }}
                >
                  {`หมดเขต: ${dayjs(data.endDate).format("D MMM YYYY")}`}
                </Typography>
              )}
              <div className={`price`}>
                {data?.coins.map((item, index) => (
                  <WrapCoin>
                    <SpecialIcon idCoinType={item.idCoinType} width={40} />
                    <Typography
                      variant="h6"
                      style={{ marginLeft: 8, fontSize: 24 }}
                    >
                      {Utils.numberWithCommas(item.amount)}
                    </Typography>
                    {data.coins.length > 1 &&
                      data.coins.length - 1 !== index && (
                        <CloseIcon color="textSecondary" />
                      )}
                  </WrapCoin>
                ))}
              </div>
            </StyledCardContent>
          </CardActionArea>
        </StyledCard>
      )}
    </>
  );
};

export default CardReward;
