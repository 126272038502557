import React, { useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Grid,
  Typography,
  Paper,
  LinearProgress,
  AccordionSummary,
  AccordionDetails,
  Chip,
  linearProgressClasses,
  Stack,
  CircularProgress,
} from "@mui/material";

import HowToRegIcon from "@mui/icons-material/HowToReg";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useSelector } from "react-redux";

import clsx from "clsx";
import { Bar } from "react-chartjs-2";
import FeelingIcon from "./../feelingIcon";
import unicornCry from "./assets/Unicorn-Mascot-Cry.png";
import unicornHeart from "./assets/Unicorn-Mascot-Heart.png";
import unicornIndifferently from "./assets/Unicorn-Mascot-Indifferently.png";
import { useMoraleKPI } from "../../../../quries/morale";

const BorderLinearProgress = styled(LinearProgress)({
  [`&.${linearProgressClasses.root}`]: {
    height: 10,
    borderRadius: 5,
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#333333",
  },
  [`&.${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
});

const BorderLinearProgressDetail = styled(LinearProgress)({
  [`&.${linearProgressClasses.root}`]: {
    height: 5,
    borderRadius: 5,
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#333333",
  },
  [`&.${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#f66817",
  },
});

const colorSet = [
  "#ff5100",
  "#ff9000",
  "#ebc400",
  "#9fc914",
  "#12b09b",
  "#127eb0",
  "#5053a3",
  "#6939a3",
  "#a3399e",
  "#a3395e",
];

const colorSelect = (score) => {
  if (score === 100) {
    return "#178867";
  } else if (score >= 80) {
    return "#649c18";
  } else if (score >= 60) {
    return "#caa401";
  } else if (score >= 40) {
    return "#cb6600";
  } else if (score >= 20) {
    return "#bc293a";
  } else {
    return "#cc3426";
  }
};

const optionsTopic = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        ticks: {
          fontColor: "#CCC", // this here
        },
        gridLines: {
          display: true,
          color: "rgb(40,40,40)",
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontColor: "#CCC", // this here
          suggestedMin: 0,
          suggestedMax: 100,
        },
        gridLines: {
          display: true,
          color: "rgb(40,40,40)",
        },
      },
    ],
  },
};

const StyledResultIcon = styled("div")({
  display: "flex",
  justifyContent: "center",
  // margin: "32px 0",
});

const WrapSummaryAnswerDepartment = styled(Paper)({
  backgroundColor: "#424242",
  padding: "12px 16px",
  minHeight: 48,
  "& .label": {
    display: "flex",
    justifyContent: "space-between",
    "& .label-left": {
      display: "flex",
      "& .MuiSvgIcon-root, .MuiTypography-root": {
        color: "#FFFFFF",
      },
    },
  },
});

const StyledAccordion = styled(Accordion)({
  backgroundColor: "#424242",
  "& .label": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .label-left": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      marginBottom: 8,
      "& .MuiSvgIcon-root, .MuiTypography-root": {
        color: "#FFFFFF",
      },
    },
  },
});

const WrapSummaryTopic = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  "& .cardTopicSummary": {
    opacity: 0.5,
    width: 256,
    padding: "8px 16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    "& .summary": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontSize: 45,
      },
      // "& .veryhappy": {
      //   color: "#447c20",
      // },
      // "& .happy": {
      //   color: "#6c9125",
      // },
      // "& .littlehappy": {
      //   color: "#6c9125",
      // },
      // "& .littlebad": {
      //   color: "#cc7f2e",
      // },
      // "& .bad": {
      //   color: "#cc7f2e",
      // },
      // "& .verybad": {
      //   color: "#a72523",
      // },
    },
    ["@media only screen and (max-width:600px)"]: {
      width: "100%",
    },
  },
  "& .active": {
    opacity: 1,
  },
  "& .veryhappy": {
    backgroundColor: "#178867",
  },
  "& .happy": {
    backgroundColor: "#649c18",
  },
  "& .littlehappy": {
    backgroundColor: "#caa401",
  },
  "& .littlebad": {
    backgroundColor: "#cb6600",
  },
  "& .bad": {
    backgroundColor: "#bc293a",
  },
  "& .verybad": {
    backgroundColor: "#cc3426",
  },
});

const MoraleDetail = ({ idMorale, filterSelected, role }) => {
  const [selectedTopic, setSelectedTopic] = useState(0);
  const [expandedComment, setExpandedComment] = React.useState("panel1");
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const {
    data: moraleKPI,
    isPending,
    isError,
  } = useMoraleKPI({
    idMorale,
    query: {
      gender: filterSelected["gender"].itemList
        ?.filter((item) => item.checked)
        .map((item) => item.value),
      generation: filterSelected["generation"].itemList
        ?.filter((item) => item.checked)
        .map((item) => item.value),
      jobLevel: filterSelected["jobLevel"].itemList
        ?.filter((item) => item.checked)
        .map((item) => item.value),
      jobType: filterSelected["jobType"].itemList
        ?.filter((item) => item.checked)
        .map((item) => item.value),
    },
  });

  if (isError) {
    return (
      <Typography variant="h6" color={"#ffffff"}>
        ไม่พบข้อมูล
      </Typography>
    );
  }

  const unicornImage = (x) => {
    if (x >= 80) {
      return unicornHeart;
    } else if (x >= 50) {
      return unicornIndifferently;
    } else {
      return unicornCry;
    }
  };

  const answerTopicGrade = (score) => {
    if (score === 100) {
      return "veryhappy";
    } else if (score >= 80) {
      return "happy";
    } else if (score >= 60) {
      return "littlehappy";
    } else if (score >= 40) {
      return "littlebad";
    } else if (score >= 20) {
      return "bad";
    } else {
      return "verybad";
    }
  };

  const dataChartQuestion = () => ({
    labels: moraleKPI.moraleTopicAvgAndBest[selectedTopic].moraleQuestion
      .filter((question) => question.questionType === "choice")
      .map((question) => question.idMoraleQuestion),
    datasets: [
      {
        data: [
          ...moraleKPI.moraleTopicAvgAndBest[selectedTopic].moraleQuestion
            .filter((question) => question.questionType === "choice")
            .map((question) => question.score.average),
        ],
        backgroundColor: [
          ...moraleKPI.moraleTopicAvgAndBest[selectedTopic].moraleQuestion
            .filter((question) => question.questionType === "choice")
            .map((question) => colorSelect(question.score.average)),
        ],
        barThickness: 100,
      },
    ],
  });

  const dataDepartment = () => ({
    labels: [...moraleKPI.moraleDepAvgAndBest.map((dep) => dep.departmentName)],
    datasets: [
      {
        type: "bar",
        barThickness: 100,
        label: "คะแนนประเมินผล",
        backgroundColor: "#00b81e",
        data: [
          ...moraleKPI.moraleDepAvgAndBest.map((dep) => dep.average.toFixed(2)),
        ],
      },
    ],
  });

  const dataTopic = () => ({
    labels: [
      ...moraleKPI.moraleTopicAvgAndBest.map((topic) => topic.questionTopic),
    ],
    datasets: [
      {
        data: [
          ...moraleKPI.moraleTopicAvgAndBest.map((topic) =>
            topic.average.toFixed(2)
          ),
        ],
        backgroundColor: colorSet,
        barThickness: 100,
      },
    ],
  });

  const handleChangeComment = (panel) => (event, newExpanded) => {
    setExpandedComment(newExpanded ? panel : false);
  };

  const totalParticipant = () => {
    return moraleKPI.moraleResponse.reduce(
      (acc, curr) => curr.participant + acc,
      0
    );
  };

  const totalResponse = () => {
    return moraleKPI.moraleResponse.reduce(
      (acc, curr) => parseInt(curr.response) + acc,
      0
    );
  };

  const avgScore = () => {
    const score =
      moraleKPI?.moraleTopicAvgAndBest.reduce(
        (prev, curr) => curr.average + prev,
        0
      ) / moraleKPI?.moraleTopicAvgAndBest.length;

    return Math.ceil(score);
  };

  if (isPending) {
    return <CircularProgress />;
  }

  return (
    <Fragment>
      <Typography variant="h4" textAlign={"center"} color="#e2e2e2">
        {moraleKPI.moraleName}
      </Typography>
      <Typography variant="h5" textAlign={"center"} color="#ffffff">
        {`Total score: ${avgScore()}%`}
      </Typography>
      <StyledResultIcon>
        <img
          src={unicornImage(avgScore())}
          alt="unicorn"
          width={720}
          height={405}
        />
      </StyledResultIcon>
      {moraleKPI?.moraleResponse.length > 0 && (
        <div>
          {userProfile.roles.includes("ROLE_ADMIN") ? (
            <div style={{ marginTop: 16, marginBottom: 16 }}>
              <StyledAccordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div style={{ width: "100%" }}>
                    <div className={`label`}>
                      <div className={`label-left`}>
                        <HowToRegIcon />
                        <Typography fontSize={"1.25rem"}>
                          {`ผู้เข้าร่วมตอบแบบประเมิน ( ${totalResponse()} / ${totalParticipant()} )`}
                        </Typography>
                      </div>
                      <Typography style={{ color: "white", marginBottom: 4 }}>
                        {`${Math.round((totalResponse() * 100) / totalParticipant())} %`}
                      </Typography>
                    </div>
                    <BorderLinearProgress
                      variant="determinate"
                      value={Math.round(
                        (totalResponse() * 100) / totalParticipant()
                      )}
                    />
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ color: "white", marginBottom: "1rem", fontSize: "1.25rem" }}>
                    แยกตามหน่วยงาน
                  </Typography>
                  <Stack spacing={1}>
                    {moraleKPI.moraleResponse.map((value) => {
                      return (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "0.5rem",
                            }}
                          >
                            <Typography color={"#ffffff"}>
                              {`${value.departmentName} (${value.response} / ${value.participant})`}
                            </Typography>
                            <Typography color={"#ffffff"}>
                              {`${Math.round((value.response * 100) / value.participant)} %`}
                            </Typography>
                          </div>
                          <BorderLinearProgressDetail
                            variant="determinate"
                            value={Math.round(
                              (value.response * 100) / value.participant
                            )}
                          />
                        </div>
                      );
                    })}
                  </Stack>
                </AccordionDetails>
              </StyledAccordion>

              {/* แสดงผล bar ของ admin จะเห็นของทุกหน่วยงาน */}
              <Typography
                style={{ color: "white", marginTop: 20 }}
                variant="h5"
              >
                ผลการประเมินแบ่งตามหน่วยงาน
              </Typography>
              <div style={{ height: 300 }}>
                <Bar
                  data={dataDepartment}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          stacked: true,
                          ticks: {
                            beginAtZero: true,
                            fontColor: "#CCC",
                          },
                          gridLines: {
                            display: true,
                            color: "rgb(40,40,40)",
                          },
                        },
                      ],
                      xAxes: [
                        {
                          stacked: true,
                          ticks: {
                            fontColor: "#CCC",
                          },
                          gridLines: {
                            display: true,
                            color: "rgb(40,40,40)",
                          },
                        },
                      ],
                    },
                    tooltips: {
                      enabled: true,
                      mode: "index",
                      intersect: false,
                    },
                  }}
                />
              </div>
            </div>
          ) : (
            <div style={{ marginTop: 16, marginBottom: 16 }}>
              <WrapSummaryAnswerDepartment>
                <div className={`inner`}>
                  <div className={`label`}>
                    <div className={`label-left`}>
                      <HowToRegIcon />
                      <Typography>
                        {`ผู้เข้าร่วมตอบแบบประเมิน (${totalResponse()} / ${totalParticipant()})`}
                      </Typography>
                    </div>
                    <Typography style={{ color: "white", marginBottom: 4 }}>
                      {`${Math.round(
                        (totalResponse() * 100) / totalParticipant()
                      )} %`}
                    </Typography>
                  </div>
                  <BorderLinearProgress
                    variant="determinate"
                    // value={dataQuestion[dataQuestionSelected].personAnswer}
                    value={
                      Math.round(totalResponse() * 100) / totalParticipant()
                    }
                  />
                </div>
              </WrapSummaryAnswerDepartment>

              {/* หน้า manager */}
              <Typography
                style={{ color: "white", marginTop: 20 }}
                variant="h5"
              >
                ผลการประเมินแบ่งตามหน่วยงาน
              </Typography>
              <div style={{ height: 300 }}>
                <Bar
                  // data={{
                  //   // แยกตาามหน่วยงาน แก้ไขบรรทัดล่างลงไป 2 บรรทัด
                  //   labels: department.map((o) => {
                  //     if(o.departmentShortName === "LLDPE"){
                  //       return o.departmentShortName
                  //     }
                  //     else{
                  //       return ""
                  //     }
                  //   }),
                  //   datasets: [
                  //     {
                  //       label: "คะแนนประเมินผล",
                  //       data: dataDepartment,
                  //       backgroundColor: "#00b81e",
                  //     },
                  //   ],
                  // }}
                  data={dataDepartment}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          stacked: true,
                          ticks: {
                            beginAtZero: true,
                            fontColor: "#CCC",
                          },
                          gridLines: {
                            display: true,
                            color: "rgb(40,40,40)",
                          },
                        },
                      ],
                      xAxes: [
                        {
                          stacked: true,
                          ticks: {
                            fontColor: "#CCC",
                          },
                          gridLines: {
                            display: true,
                            color: "rgb(40,40,40)",
                          },
                        },
                      ],
                    },
                    tooltips: {
                      enabled: true,
                      mode: "index",
                      intersect: false,
                    },
                  }}
                />
              </div>
            </div>
          )}

          <div style={{ marginTop: 16 }}>
            <Typography style={{ color: "white" }} variant="h5">
              ผลประเมินประจำปี
            </Typography>
            <div style={{ height: 350 }}>
              <Bar data={dataTopic()} options={optionsTopic} />
            </div>
          </div>
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 8,
              }}
            >
              <Typography style={{ color: "white" }} variant="h5">
                ผลสรุปตามหัวข้อ
              </Typography>
            </div>
            <WrapSummaryTopic>
              {moraleKPI.moraleTopicAvgAndBest.map((value, index) => {
                return (
                  <div
                    onClick={() => setSelectedTopic(index)}
                    key={index}
                    className={clsx(
                      `cardTopicSummary`,
                      answerTopicGrade(value.average),
                      {
                        [`active`]: selectedTopic === index,
                      }
                    )}
                  >
                    <div className={`summary`}>
                      <div style={{ width: 60, height: 60 }}>
                        <FeelingIcon
                          feeling={answerTopicGrade(value.average)}
                        />
                      </div>
                      <Typography
                        variant="h2"
                        className={answerTopicGrade(value.average)}
                        color="#ffffff"
                      >
                        {value.average.toFixed(2)}%
                      </Typography>
                    </div>
                    <Typography style={{ color: "white", textAlign: "end" }}>
                      {value.questionTopic}
                    </Typography>
                  </div>
                );
              })}
            </WrapSummaryTopic>
            <div style={{ marginTop: 32 }}>
              <Typography
                variant="h4"
                align="center"
                style={{ color: "white", marginBottom: 16 }}
              ></Typography>
              {/* {moraleKpi[dataQuestionSelected].moraleTopicAvgAndBest[ */}
              {moraleKPI.moraleTopicAvgAndBest[selectedTopic]
                .moraleQuestion && (
                <Grid container>
                  <Grid item xs={12} sm={7}>
                    <div style={{ height: 300 }}>
                      <Bar
                        data={dataChartQuestion()}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          legend: {
                            display: false,
                          },
                          scales: {
                            yAxes: [
                              {
                                // stacked: true,
                                ticks: {
                                  beginAtZero: true,
                                },
                                gridLines: {
                                  display: true,
                                  color: "rgb(40,40,40)",
                                },
                                min: 0,
                                max: 100,
                              },
                            ],
                            xAxes: [
                              {
                                // stacked: true,
                                gridLines: {
                                  display: true,
                                  color: "rgb(40,40,40)",
                                },
                              },
                            ],
                          },
                          tooltips: {
                            enabled: true,
                            mode: "index",
                            intersect: false,
                          },
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <div style={{ padding: 20 }}>
                      {moraleKPI.moraleTopicAvgAndBest[
                        selectedTopic
                      ].moraleQuestion.map((question, index) => {
                        return (
                          question.questionType === "choice" && (
                            <Fragment>
                              <Typography
                                style={{
                                  color: "white",
                                  fontSize: 14,
                                  paddingLeft: 4,
                                }}
                              >
                                {question.idMoraleQuestion}. {question.question}
                              </Typography>
                              <Divider
                                style={{
                                  margin: "5px 0",
                                  backgroundColor: "#ffffff38",
                                }}
                              />
                            </Fragment>
                          )
                        );
                      })}
                    </div>
                  </Grid>
                </Grid>
              )}
              {moraleKPI.moraleTopicAvgAndBest[selectedTopic]
                .moraleQuestion && (
                <div>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    style={{ color: "#fff", marginTop: 16, fontSize: 22 }}
                  >
                    Answer
                  </Typography>
                  <div>
                    {moraleKPI.moraleTopicAvgAndBest[
                      selectedTopic
                    ].moraleQuestion.map((question, index) => {
                      return (
                        <Accordion
                          square
                          expanded={expandedComment === "panel" + index}
                          onChange={handleChangeComment("panel" + index)}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              width={"100%"}
                            >
                              <Typography>
                                {question.idMoraleQuestion}. {question.question}
                              </Typography>
                              <Chip
                                label={question.questionType}
                                color={
                                  question.questionType === "choice"
                                    ? "primary"
                                    : "secondary"
                                }
                                variant="outlined"
                              />
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails style={{ display: "inline-block" }}>
                            {question.questionType === "choice" ? (
                              <Typography>Feedback</Typography>
                            ) : (
                              <Typography>Answer</Typography>
                            )}
                            {question.questionType === "text" ? (
                              question.questionAnswerText.map((item) => (
                                <Typography>
                                  {"- "}
                                  {item.answer}
                                </Typography>
                              ))
                            ) : question.questionFeedback.length > 0 ? (
                              question.questionFeedback.map((item) => (
                                <Typography>
                                  {"- "}
                                  {item.feedback}
                                </Typography>
                              ))
                            ) : (
                              <Typography>{"No feedback"}</Typography>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default MoraleDetail;
