import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  LinearProgress,
  Typography,
  Paper,
  Divider,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  IconButton,
  Drawer,
  Container,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddIcon from "@mui/icons-material/Add";

import MoraleQuestionIcon from "./assets/question.png";

import HeaderPage from "../../../shared/header/headerPage";

import { useDispatch, useSelector } from "react-redux";
import { addMorale, getAllMorale } from "../../../../../actions/moraleQuestion";
import * as dayjs from "dayjs";
import MoraleFilter from "../../../shared/moraleFilter";
import { useNavigate } from "react-router-dom";
import LoadingIcon from "../../../shared/general/loadingIcon";

const WrapCardQuestion = styled(Paper)({
  width: "100%",
  display: "flex",
  borderRadius: 15,
  marginBottom: 20,
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
});

const StyledBtnDescPublish = styled(Button)({
  border: 0,
  borderRadius: 50,
  padding: "4px 12px",
  fontSize: 14,
  backgroundColor: "#2A265F",
  color: "#FFFFFF",
  position: "absolute",
  bottom: 20,
  right: 20,
  "&:hover": {
    backgroundColor: "#2a265f",
    borderColor: "#2a265f",
    boxShadow: "none",
  },
  ["@media only screen and (max-width:600px)"]: {
    position: "initial",
    marginTop: 16,
    width: "100%",
  },
});

const StyledBtnDescDraft = styled(Button)({
  border: 0,
  borderRadius: 50,
  padding: "4px 12px",
  fontSize: 14,
  backgroundColor: "#f8de55",
  color: "#3C3C3C",
  position: "absolute",
  bottom: 20,
  right: 20,
  "&:hover": {
    backgroundColor: "#f8de55",
    borderColor: "#f8de55",
    boxShadow: "none",
  },
  ["@media only screen and (max-width:600px)"]: {
    position: "initial",
    marginTop: 16,
    width: "100%",
  },
});

const StyledBtnDescClosed = styled(Button)({
  border: 0,
  borderRadius: 50,
  padding: "4px 12px",
  fontSize: 14,
  backgroundColor: "#BBBBBB",
  color: "#FFFFFF",
  position: "absolute",
  bottom: 20,
  right: 20,
  "&:hover": {
    backgroundColor: "#BBBBBB",
    borderColor: "#BBBBBB",
    boxShadow: "none",
  },
  ["@media only screen and (max-width:600px)"]: {
    position: "initial",
    marginTop: 16,
    width: "100%",
  },
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const StyledPanelLeft = styled("div", {
  shouldForwardProp: (prop) => prop !== "myBgColor" && prop !== "myColor",
})(({ myBgColor, myColor }) => ({
  backgroundColor: myBgColor,
  width: "30%",
  padding: 20,
  borderTopLeftRadius: 15,
  borderBottomLeftRadius: 15,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "& .questionName": {
    fontSize: 32,
    color: myColor,
  },
  "& .wrapViewAnswer": {
    marginTop: 8,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    color: myColor,
  },
  ["@media only screen and (max-width:600px)"]: {
    width: "100%",
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 15,
    "& .questionName": {
      fontSize: 24,
    },
  },
}));

const StyledPanelRight = styled("div")({
  width: "70%",
  padding: 20,
  color: "#2a265f",
  position: "relative",
  "& .inner-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .questionTopic": {
    display: "flex",
    alignItems: "baseline",
  },
  ["@media only screen and (max-width:600px)"]: {
    width: "100%",
    "& .inner-top": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    "& .questionTopic": {
      flexDirection: "column",
    },
  },
});

const StyledHeaderTopic = styled(Typography)({
  fontSize: 14,
  opacity: 0.6,
  color: "#000000",
  marginRight: 5,
});

const StyledIconTopic = styled(ChevronRightIcon)({
  fontSize: 18,
  opacity: 0.6,
  color: "#000000",
});

const StyledContentTopic = styled(Typography)({
  fontSize: 20,
  color: "#000000",
  display: "flex",
  alignItems: "baseline",
});

const StyledQuestionStatus = styled(Chip)({
  fontWeight: 500,
  borderRadius: 5,
  height: 20,
  textTransform: "uppercase",
});

const WrapQuestionStatusPublished = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: 16,
});

const StyledQuestionStatusPublished = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "myColor",
})(({ myColor }) => ({
  fontSize: 12,
  textAlign: "right",
  paddingTop: 5,
  textTransform: "uppercase",
  color: myColor,
}));

const StyledSecondTextTopic = styled(Typography)({
  marginLeft: 5,
  textTransform: "uppercase",
  fontSize: 14,
  ["@media only screen and (max-width:600px)"]: {
    marginLeft: 22,
  },
});

const StyledQuestionNumber = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "myColor",
})(({ myColor }) => ({
  fontSize: 14,
  fontStyle: "oblique",
  color: myColor,
}));

const WrapFilter = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 40,
});

const FilterIconButton = styled(IconButton)({
  color: "#FFFFFF",
  backgroundColor: "#2A265F",
  "&:hover": {
    backgroundColor: "#353078",
  },
});

const AddQuestionButton = styled(Button)({
  marginRight: 8,
  fontSize: 20,
  backgroundColor: "#2A265F",
  "&:hover": {
    backgroundColor: "#353078",
  },
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const MoraleQuestion = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [moraleName, setMoraleName] = useState("");
  const [moraleType, setMoraleType] = useState("quarter");
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState({
    quarter: true,
    year: true,
    published: true,
    draft: true,
  });

  const dispatch = useDispatch();
  const { result: moraleQuestions, isFetching } = useSelector(
    (state) => state.moraleQuestions
  );

  useEffect(() => {
    dispatch(getAllMorale());
  }, []);

  const handleClickDescription = (id) => {
    navigate("/admin/manage/morale-question/" + id);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenFilter(!openFilter);
  };

  const handleChangeMoraleName = (event) => {
    setMoraleName(event.target.value);
  };

  const handleChangeMoraleType = (event) => {
    setMoraleType(event.target.value);
  };

  const handleAddQuestion = async () => {
    const res = await dispatch(
      addMorale({
        moraleName,
        moraleType,
      })
    );
    if (res) {
      dispatch(getAllMorale());
    }
    handleClose();
    setMoraleName("");
  };

  const numberOfResponse = (idMorale) => {
    return moraleQuestions
      .find((question) => question.idMorale === idMorale)
      .participantList.reduce(
        (prev, curr) => (curr.isComplete ? prev + 1 : prev),
        0
      );
  };

  return (
    <StyledRoot className={"page"}>
      <HeaderPage textLabel={"คำถาม Morale"} icon={MoraleQuestionIcon} />
      <StyledDivider />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>New Question</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            sx={{ marginBottom: 1 }}
            label="Morale name"
            variant="outlined"
            value={moraleName}
            onChange={handleChangeMoraleName}
          />
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Morale type
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={moraleType}
              onChange={handleChangeMoraleType}
            >
              <FormControlLabel
                value="quarter"
                control={<Radio />}
                label="quarter"
              />
              <FormControlLabel value="year" control={<Radio />} label="year" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={moraleName.length > 0 ? handleAddQuestion : null}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer anchor={"right"} open={openFilter} onClose={toggleDrawer}>
        <MoraleFilter setFilterMorale={setFilter} />
      </Drawer>
      <Container>
        {isFetching ? (
          <LoadingIcon />
        ) : (
          <>
            <WrapFilter>
              <AddQuestionButton
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleOpen}
              >
                New Question
              </AddQuestionButton>
              <FilterIconButton
                aria-label="filter"
                onClick={toggleDrawer}
                size="large"
              >
                <FilterListIcon fontSize="large" />
              </FilterIconButton>
            </WrapFilter>
            <Stack spacing={2}>
              {moraleQuestions &&
                moraleQuestions
                  .filter(
                    (value) =>
                      (filter.quarter && value.moraleType === "quarter") ||
                      (filter.year && value.moraleType === "year") ||
                      (filter.published && value.moraleStatus === "published") ||
                      (filter.draft && value.moraleStatus === "draft")
                  )
                  .map((value) => {
                    const numberOfQuestion = value.questionTopic.reduce(
                      (accumulator, topic) =>
                        accumulator + topic.questionList.length,
                      0
                    );
                    return (
                      <WrapCardQuestion elevation={4} key={value.idMorale}>
                        <StyledPanelLeft
                          myBgColor={
                            value.moraleStatus === "draft"
                              ? "#f8de55"
                              : value.moraleStatus === "published"
                              ? "#2A265F"
                              : "#BBBBBB"
                          }
                          myColor={
                            value.moraleStatus === "draft" ? "#3C3C3C" : "#FFFFFF"
                          }
                        >
                          <div>
                            <StyledQuestionNumber
                              myColor={
                                value.moraleStatus === "draft"
                                  ? "#3C3C3C"
                                  : "#FFFFFF"
                              }
                            >
                              {value.idMorale}
                            </StyledQuestionNumber>
                            <Typography className={`questionName`}>
                              {value.moraleName}
                            </Typography>
                            {value.moraleStatus === "published" && (
                              <div style={{ marginTop: 16 }}>
                                <LinearProgress
                                  style={{}}
                                  variant="determinate"
                                  value={
                                    (numberOfResponse(value.idMorale) * 100) /
                                    value.participantList.length
                                  }
                                />
                                <StyledQuestionStatusPublished
                                  myColor={
                                    value.moraleStatus === "draft"
                                      ? "#3C3C3C"
                                      : "#FFFFFF"
                                  }
                                >
                                  {`${numberOfResponse(value.idMorale)} จาก ${
                                    value.participantList.length
                                  } ผู้ตอบ`}
                                </StyledQuestionStatusPublished>
                              </div>
                            )}
                          </div>
                        </StyledPanelLeft>
                        <StyledPanelRight>
                          <div className={`inner-top`}>
                            <div style={{ display: "flex" }}>
                              <StyledHeaderTopic>
                                {`TOPIC: ${value.questionTopic.length}, QUESTION: ${numberOfQuestion}`}
                              </StyledHeaderTopic>
                            </div>
                            <WrapQuestionStatusPublished>
                              <Stack
                                spacing={1}
                                direction={{ xs: "column", sm: "row" }}
                                alignItems="center"
                              >
                                {value.moraleStatus === "draft" ? (
                                  <StyledQuestionStatus
                                    label={`${value.moraleStatus}`}
                                    style={{
                                      backgroundColor:
                                        value.moraleStatus === "draft"
                                          ? "#f8de55"
                                          : value.moraleStatus === "published"
                                          ? "#2A265F"
                                          : "#BBBBBB",
                                      color:
                                        value.moraleStatus === "draft"
                                          ? "#3C3C3C"
                                          : "#FFFFFF",
                                    }}
                                  />
                                ) : (
                                  <>
                                    <StyledQuestionStatus
                                      label={`Start: ${dayjs(
                                        value.startDate
                                      ).format("YYYY-MM-DD")}`}
                                      style={{
                                        backgroundColor:
                                          value.moraleStatus === "draft"
                                            ? "#f8de55"
                                            : value.moraleStatus === "published"
                                            ? "#2A265F"
                                            : "#BBBBBB",
                                        color:
                                          value.moraleStatus === "draft"
                                            ? "#3C3C3C"
                                            : "#FFFFFF",
                                      }}
                                    />
                                    <StyledQuestionStatus
                                      label={`End: ${dayjs(value.endDate).format(
                                        "YYYY-MM-DD"
                                      )}`}
                                      style={{
                                        backgroundColor:
                                          value.moraleStatus === "draft"
                                            ? "#f8de55"
                                            : value.moraleStatus === "published"
                                            ? "#2A265F"
                                            : "#BBBBBB",
                                        color:
                                          value.moraleStatus === "draft"
                                            ? "#3C3C3C"
                                            : "#FFFFFF",
                                      }}
                                    />
                                  </>
                                )}
                                <Chip
                                  variant="outlined"
                                  label={value.moraleType}
                                  color={
                                    value.moraleType === "year"
                                      ? "secondary"
                                      : "primary"
                                  }
                                />
                              </Stack>
                            </WrapQuestionStatusPublished>
                          </div>
                          {value.questionTopic &&
                            value.questionTopic.map((topic, indexTopic) => {
                              return (
                                <div className={`questionTopic`} key={indexTopic}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <StyledIconTopic />
                                    <StyledContentTopic>
                                      {" "}
                                      {topic.questionTopic === null
                                        ? "ไม่ระบุ"
                                        : topic.questionTopic}
                                    </StyledContentTopic>
                                  </div>
                                  <StyledSecondTextTopic color="textSecondary">
                                    {`(question : ${topic.questionList.length})`}
                                  </StyledSecondTextTopic>
                                </div>
                              );
                            })}
                          {value.moraleStatus === "published" && (
                            <StyledBtnDescPublish
                              onClick={() => handleClickDescription(value.idMorale)}
                            >
                              รายละเอียด
                            </StyledBtnDescPublish>
                          )}
                          {value.moraleStatus === "draft" && (
                            <StyledBtnDescDraft
                              onClick={() => handleClickDescription(value.idMorale)}
                            >
                              รายละเอียด
                            </StyledBtnDescDraft>
                          )}
                          {value.moraleStatus === "closed" && (
                            <StyledBtnDescClosed
                              onClick={() => handleClickDescription(value.idMorale)}
                            >
                              รายละเอียด
                            </StyledBtnDescClosed>
                          )}
                        </StyledPanelRight>
                      </WrapCardQuestion>
                    );
                  })}
            </Stack>
          </>
        )}
      </Container>
    </StyledRoot>
  );
};

export default MoraleQuestion;
