import React, {  useState } from "react";
import {
  Button,
  styled,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";

const WrapDrawerFilter = styled("div")({
  marginTop: 64,
  padding: 16,
  width: 300,
  "& .wrapBtnReset": {
    marginTop: 16,
    "& Button": {
      width: "100%",
      marginBottom: 8,
    },
  },
  "& .wrapListUsers": {
    marginTop: 16,
  },
});

const MoraleFilter = ({ setFilterMorale }) => {
  const [filter, setFilter] = useState({
    quarter: true,
    year: true,
    published: true,
    draft: true,
  });

  const handleSearch = () => {
    setFilterMorale(filter);
  };

  const handleReset = () => {
    setFilterMorale({
      quarter: true,
      year: true,
      published: true,
      draft: true,
    });
    setFilter({
      quarter: true,
      year: true,
      published: true,
      draft: true,
    });
  };

  const handleChange = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.checked });
  };

  return (
    <WrapDrawerFilter>
      <Stack spacing={2}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">ประเภทชุดคำถาม</FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.quarter}
                  onChange={handleChange}
                  name="quarter"
                  color="primary"
                />
              }
              label="quarter"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.year}
                  onChange={handleChange}
                  name="year"
                  color="primary"
                />
              }
              label="year"
            />
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">สถานะชุดคำถาม</FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.published}
                  onChange={handleChange}
                  name="published"
                  color="primary"
                />
              }
              label="published"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.draft}
                  onChange={handleChange}
                  name="draft"
                  color="primary"
                />
              }
              label="draft"
            />
          </FormGroup>
        </FormControl>
      </Stack>
      <div className={`wrapBtnReset`}>
        <Button variant="contained" color="primary" onClick={handleSearch}>
          search
        </Button>
        <Button variant="outlined" onClick={handleReset}>
          reset
        </Button>
      </div>
    </WrapDrawerFilter>
  );
};

export default MoraleFilter;
