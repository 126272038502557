import React from "react";
import { useController } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";

function DateInputField({ control, name, ...props }) {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <DatePicker
      {...field}
      {...props}
      slotProps={{
        textField: {
          error: !!error,
          helperText: error?.message,
        },
      }}
    />
  );
}

export default DateInputField;
