import React, { useEffect } from "react";
import { Container, Grid, Typography, styled } from "@mui/material";
import { CardWithInsert } from "../../shared/Cards";
import { useSelector, useDispatch } from "react-redux";
import { getCoacheeByIdCoach } from "../../../../actions/coach";
import { Link } from "react-router-dom";

const StyledRoot = styled("div")({
  marginTop: 8,
});

const Coachee = () => {
  const dispatch = useDispatch();
  const { result: coachee } = useSelector((state) => state.coachee);
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getCoacheeByIdCoach(currentUser.id));
  }, []);

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <Typography variant="h4">รายชื่อผู้เรียน</Typography>
          {coachee && coachee.length === 0 ? (
            <Typography variant="h5" align="center">No data</Typography>
          ) : (
            <Grid container spacing={1} direction="row">
              {coachee && coachee.map((val) => (
                <Grid item xs={12} sm={6} md={4} key={val.idEmployees}>
                  <CardWithInsert
                    value={val}
                    event={[
                      `Coaching-Time: ${val.coachTime}`,
                      `Tel: ${val.telephoneMobile}`,
                    ]}
                    to={`/coaching/profile/${val.idEmployees}`}
                  />
                </Grid>
              ))}
            </Grid>
          )}
      </Container>
    </StyledRoot>
  );
};

export default Coachee;
