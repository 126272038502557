import { httpClient } from "./httpClient";

const getRewards = () => {
  return httpClient.get("reward");
};

const getRewardsActive = (idCompany) => {
  return httpClient.get(`reward-active/${idCompany}`);
};

const updateReward = (formData) => {
  return httpClient.put("reward", formData);
};

// const getReward = (idReward) => {
//   return httpClient.get(`reward/${idReward}`);
// };

// const getRewardsBestSeller = (idCompany) => {
//   return httpClient.get(`reward/bestseller/${idCompany}`);
// };

export default {
  getRewards,
  getRewardsActive,
  updateReward,
  // getReward,
  // getRewardsBestSeller,
};
