import React, { useEffect, useState } from "react";
import {
  Divider,
  IconButton,
  Select,
  MenuItem,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  Stack,
  Container,
  FormHelperText,
  Typography,
  CircularProgress,
  styled,
  Grid,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BackspaceIcon from "@mui/icons-material/Backspace";
import SaveIcon from "@mui/icons-material/Save";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import ActivityIcon from "./assets/calendar.png";
import HeaderPage from "../../../shared/header/headerPage";

import { useDispatch, useSelector } from "react-redux";
import { addActivity } from "../../../../../actions/activity";
import { getAllEmployees } from "../../../../../actions/user";
import MapComponent from "./mapComponents/map";
import { Link, useNavigate } from "react-router-dom";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UploadFile from "../../../shared/uploadFile/uploadFile";
import SelectAffiliateCompany from "../../../shared/general/selectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../../actions/company";
import SelectEmployees from "../../../shared/general/selectEmployees";
import { useCoinBudget, useCoinType } from "../../../../../quries/coin";
import InputField from "../../../shared/inputForm/inputField";
import { toast } from "sonner";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

function CreateActivityAlt() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganization } = useSelector((state) => state.affiliateOrganization);
  const coinType = useCoinType()
  const coinBudget = useCoinBudget({ idCompany: userProfile?.idCompany });

  const initialValuesActivity = {
    name: "",
    detail: "",
    activityType: "onsite",
    location: "",
    link: "",
    startDate: dayjs().set("hour", 0).set("minute", 0).set("second", 0),
    endDate: dayjs()
      .add(1, "day")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0),
    openRegisterDate: dayjs().set("hour", 0).set("minute", 0).set("second", 0),
    closeRegisterDate: dayjs().set("hour", 0).set("minute", 0).set("second", 0),
    register: 0,
    coins: [{ idCoinType: "", amount: "" }],
    contact: "",
    organizer: "",
    coverImage: "",
    image: null,
    file: null,
    target: "",
    companyList: [
      {
        company: "",
        department: [],
      },
    ],
    selectedPerson: [],
    coordinate: {
      lat: null,
      lng: null,
    },
    selectedCompany: [],
  };

  const validationSchema = yup.object().shape({
    name: yup.string().max(100).required("Name is required"),
    detail: yup.string().max(200).required("Detail is required"),
    activityType: yup.string(),
    link: yup.string().when("activityType", {
      is: (activityType) => activityType === "online",
      then: yup.string().max(100).required("Link is require"),
    }),
    location: yup.string().when("activityType", {
      is: (activityType) => activityType === "onsite",
      then: yup.string().max(100).required("Location is require"),
    }),
    coordinate: yup.object().when("activityType", {
      is: (activityType) => activityType === "onsite",
      then: yup.object().shape({
        lat: yup.number().nullable().required("โปรดเลือกพิกัดในแผนที่"),
        lng: yup.number().nullable().required("โปรดเลือกพิกัดในแผนที่"),
      }),
    }),
    target: yup.string().required("โปรดเลือกผู้เข้าร่วมกิจกรรม"),
    register: yup.number().when("target", {
      is: (target) => target === "company" || target === "department",
      then: yup.number().min(1).integer().required("Register is require"),
    }),
    openRegisterDate: yup
      .date()
      .min(
        dayjs().startOf("day"),
        "Open register must be later than current date"
      ),
    closeRegisterDate: yup
      .date()
      .when("openRegisterDate", (openRegisterDate, schema) => {
        if (openRegisterDate) {
          const nextDay = new Date(openRegisterDate.getTime() + 86400000);
          return schema.min(nextDay, "Close date has to be after open date");
        }
        return schema;
      })
      .when("endDate", (endDate, schema) => {
        if (endDate) {
          return schema.max(endDate, "Close date has to be before end date");
        }
        return schema;
      }),
    startDate: yup
      .date()
      .when("openRegisterDate", (openRegisterDate, schema) => {
        if (openRegisterDate) {
          return schema.min(
            openRegisterDate,
            "Start date has to be after than open register date"
          );
        }
        return schema;
      }),
    endDate: yup.date().when("startDate", (startDate, schema) => {
      if (startDate) {
        return schema.min(
          startDate,
          "End date has to be after than start date"
        );
      }
      return schema;
    }),
    coins: yup.array().of(
      yup.object({
        idCoinType: yup.string().required("Please select coin type"),
        amount: yup.number()
          .typeError("Amount must be a number")
          .min(1)
          .integer()
          .required("Please enter coin amount"),
      })
    ).min(1),
    organizer: yup.string().max(100).required("Organizer is required"),
    contact: yup.string().max(100).required("Contact is required"),
    image: yup.mixed().required("Image is required"),
    file: yup.mixed(),
    companyList: yup.array().when("target", {
      is: (target) => target === "department",
      then: yup.array().of(
        yup.object().shape({
          company: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("โปรดเลือกบริษัท"),
          department: yup.array().of(yup.object()).min(1, "โปรดเลือกหน่วยงาน"),
        })
      ),
    }),
    selectedCompany: yup.array().when("target", {
      is: (val) => val === "company",
      then: (schema) => schema.min(1, "โปรดเลือกบริษัท"),
    }),
    selectedPerson: yup.array().when("target", {
      is: (target) => target === "person",
      then: (schema) => schema.min(1, "โปรดเลือกพนักงาน"),
    }),
  });

  const handleAddActivity = async (values) => {
    values.selectedCompany = [...values.selectedCompany].map(
      (item) => item.idCompany
    );

    values.selectedPerson = [...values.selectedPerson].map(
      (item) => item.idEmployees
    );

    values.selectedDepartment = values.companyList
      .map((company) => company.department.map((dep) => dep.idDepartment))
      .flat();

    delete values.companyList
    
    const formData = new FormData()
    for (let key in values) {
      if (Array.isArray(values[key]) || key === "coordinate") {
        formData.append(key, JSON.stringify(values[key]));
      } else if (values[key] !== null && values[key] !== "") {
        formData.append(key, values[key]);
      }
    }

    const res = await dispatch(addActivity(formData));
    if (res.status === 201) {
      toast.success("เพิ่มกิจกรรมสำเร็จ")
      navigate("/admin/manage/activity");
    } else {
      toast.error("เกิดข้อผิดพลาด")
    }
  };

  const handleCheckEnoughCoin = (values) => {
    let enoughCoin = true
    for (const coin of values.coins) {
      const selectCoin = coinBudget.data.find(item => item.idCoinType === parseInt(coin.idCoinType))
      if (selectCoin.totalCoin - selectCoin.coinInSystem < coin.amount) {
        enoughCoin = false
      }
    }

    if (!enoughCoin) {
      toast.error("เหรียญรางวัลไม่เพียงพอ")
    } else {
      handleAddActivity(values)
    }
  }

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: initialValuesActivity,
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "companyList",
  });

  const coins = useFieldArray({
    control,
    name: "coins",
  });

  const watchTarget = watch("target");
  const selectdCompanyList = watch("companyList").map((item) => item.company);

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getAffiliateOrganization());
  }, []);

  if (coinType.isPending || coinBudget.isPending) {
    return <CircularProgress />
  }

  return (
    <StyledRoot className={"page"}>
      <HeaderPage textLabel={"สร้างกิจกรรม"} icon={ActivityIcon} />
      <StyledDivider />
      <Container maxWidth="lg">
        <form onSubmit={handleSubmit(handleCheckEnoughCoin)}>
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12} sm={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ชื่อกิจกรรม"
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="detail"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="รายละเอียดกิจกรรม"
                    fullWidth
                    multiline
                    rows={2}
                    error={!!errors.detail}
                    helperText={errors.detail?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Controller
                name="activityType"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>ประเภทกิจกรรม</FormLabel>
                    <RadioGroup
                      {...field}
                      row
                      onChange={(event, value) => {
                        if (value === "onsite") {
                          setValue("link", "");
                        } else {
                          setValue("location", "");
                        }
                        setValue("activityType", value);
                      }}
                    >
                      <FormControlLabel
                        value="onsite"
                        control={<Radio />}
                        label="Onsite"
                      />
                      <FormControlLabel
                        value="online"
                        control={<Radio />}
                        label="Online"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            {watch("activityType") === "onsite" ? (
              <>
                <Grid item xs={12} sm={7} display="flex" alignItems="flex-end">
                  <Controller
                    name="location"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="สถานที่จัดกิจกรรม"
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} marginBottom={2}>
                  <Controller
                    name="coordinate"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <MapComponent
                        setFieldValue={onChange}
                        error={errors?.coordinate}
                      />
                    )}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={7} display="flex" alignItems="flex-end">
                <Controller
                  name="link"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Link กิจกรรม"
                      fullWidth
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={5}>
              <Controller
                name="organizer"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ชื่อผู้จัดกิจกรรม"
                    fullWidth
                    error={!!errors.organizer}
                    helperText={errors.organizer?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Controller
                name="contact"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="การติดต่อ"
                    fullWidth
                    error={!!errors.contact}
                    helperText={errors.contact?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controller
                name="register"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="จำนวนที่เปิดรับสมัคร"
                    type="number"
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="target"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl error={Boolean(fieldState.error)}>
                    <FormLabel>ผู้เข้าร่วมกิจกรรม</FormLabel>
                    <RadioGroup {...field} row>
                      <FormControlLabel
                        value="company"
                        control={<Radio />}
                        label="เลือกบริษัท"
                      />
                      <FormControlLabel
                        value="department"
                        control={<Radio />}
                        label="เลือกกลุ่มงาน"
                      />
                      <FormControlLabel
                        value="person"
                        control={<Radio />}
                        label="เลือกพนักงาน"
                      />
                    </RadioGroup>
                    {fieldState.error && (
                      <FormHelperText>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            {watchTarget === "company" && (
              <Grid item xs={12}>
                <Controller
                  name={`selectedCompany`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Typography fontWeight={600} fontSize={14} marginBottom={1} color="text.third">
                        ค้นหาบริษัท
                      </Typography>
                      <SelectAffiliateCompany
                        {...field}
                        onChange={(_, value) => {
                          field.onChange(value);
                        }}
                        multiple={true}
                      />
                      {fieldState.error && (
                        <Typography color="error" marginTop={1}>
                          {fieldState.error.message}
                        </Typography>
                      )}
                    </>
                  )}
                />
              </Grid>
            )}
            {watchTarget === "department" && (
              <Grid item xs={12} sm={12}>
                <Stack spacing={1} alignItems={"flex-end"}>
                  {fields.map((field, index) => (
                    <Stack
                      key={field.id}
                      width={"100%"}
                      alignItems="flex-end"
                      spacing={1}
                    >
                      {/* <Controller
                        name={`companyList.${index}.company`}
                        control={control}
                        render={({ field: { onChange } }) => (
                          <Autocomplete
                            fullWidth
                            options={company}
                            getOptionDisabled={(option) =>
                              watch("companyList").some(
                                (item) =>
                                  item?.company?.idCompany === option.idCompany
                              )
                            }
                            getOptionLabel={(option) => `${option.companyName}`}
                            isOptionEqualToValue={(option, value) =>
                              option.idCompany === value.idCompany
                            }
                            onChange={(event, value, reason) => {
                              if (reason === "clear") {
                                setValue(`companyList.${index}.department`, []);
                              } 
                              onChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="เลือกบริษัท"
                                error={!!errors.companyList?.[index]?.company}
                                helperText={errors.companyList?.[index]?.company.message}
                              />
                            )}
                          />
                        )}
                      /> */}
                      <Controller
                        name={`companyList.${index}.company`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl
                            fullWidth
                            error={Boolean(fieldState.error)}
                          >
                            <InputLabel>เลือกบริษัท</InputLabel>
                            <Select {...field} label="เลือกบริษัท">
                              {affiliateOrganization.map((option) => (
                                <MenuItem
                                  key={option.idCompany}
                                  value={option.idCompany}
                                  disabled={selectdCompanyList.includes(
                                    option.idCompany
                                  )}
                                >
                                  {option.companyName}
                                </MenuItem>
                              ))}
                            </Select>
                            {fieldState.error && (
                              <FormHelperText>
                                {fieldState.error.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                      <Controller
                        name={`companyList.${index}.department`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <Autocomplete
                            {...field}
                            fullWidth
                            multiple
                            options={
                              watch(`companyList.${index}.company`)
                                ? affiliateOrganization.find(
                                    (item) =>
                                      item.idCompany ===
                                      watch(`companyList.${index}.company`)
                                  )?.department
                                : []
                            }
                            disabled={!watch(`companyList.${index}.company`)}
                            getOptionLabel={(option) => option.departmentName}
                            isOptionEqualToValue={(option, value) =>
                              option.idDepartment === value.idDepartment
                            }
                            onChange={(event, value) => {
                              field.onChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="เลือหน่วยงาน"
                                error={Boolean(fieldState.error)}
                                helperText={
                                  fieldState.error && fieldState.error.message
                                }
                              />
                            )}
                          />
                        )}
                      />
                      {/* <Controller
                        name={`companyList.${index}.department`}
                        control={control}
                        render={({ fieldState, field }) => (
                          <FormControl fullWidth error={Boolean(fieldState.error)}>
                            <InputLabel>หน่วยงาน</InputLabel>
                            <Select {...field} multiple label="หน่วยงาน">
                              {affiliateOrganization
                                .find((item) => item.idCompany === watch(`companyList.${index}.company`))?.department
                                .map((option) => (
                                  <MenuItem
                                    key={option.idDepartment}
                                    value={option.idDepartment}
                                  >
                                    {option.departmentName}
                                  </MenuItem>
                                ))}
                            </Select>
                            {fieldState.error && (
                              <FormHelperText>
                                {fieldState.error.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      /> */}
                      <IconButton color="error" onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  ))}
                  <Button
                    onClick={() =>
                      append({
                        company: null,
                        department: [],
                      })
                    }
                    startIcon={<AddIcon />}
                  >
                    เพิ่มหน่วยงาน
                  </Button>
                </Stack>
              </Grid>
            )}
            {watchTarget === "person" && (
              <Grid item xs={12} sm={12}>
                <Controller
                  name="selectedPerson"
                  control={control}
                  render={({ fieldState, field: { onChange } }) => (
                    <>
                      <SelectEmployees
                        handleChange={onChange}
                        multiple={true}
                      />
                      {fieldState.error && (
                        <Typography color="error" marginTop={1}>
                          {fieldState.error.message}
                        </Typography>
                      )}
                    </>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="openRegisterDate"
                control={control}
                render={({ field, fieldState }) => (
                  <DatePicker
                    {...field}
                    label="วันที่เปิดรับสมัคร"
                    minDate={dayjs()}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: Boolean(fieldState.error),
                        helperText: fieldState.error?.message,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="closeRegisterDate"
                control={control}
                render={({ field, fieldState }) => (
                  <DatePicker
                    {...field}
                    label="วันที่ปิดรับสมัคร"
                    minDate={dayjs(watch("openRegisterDate")).add(1, "day")}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: Boolean(fieldState.error),
                        helperText: fieldState.error?.message,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="coin.amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="number"
                    label="เหรียญที่ได้รับ"
                    error={!!errors.coin?.amount}
                    helperText={errors.coin?.amount?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>ชนิดเหรียญพิเศษ</InputLabel>
                <Controller
                  name="specialCoin.idCoinType"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Select
                      label="ชนิดเหรียญพิเศษ"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === 0) {
                          setValue("specialCoin.amount", 0, true);
                        }
                        // onChange(e)
                        setValue("specialCoin.idCoinType", value, true);
                      }}
                      error={!!errors.specialCoin?.idCoinType}
                      defaultValue={0}
                    >
                      <MenuItem value={0}>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={3}>Pony</MenuItem>
                      <MenuItem value={4}>Unicorn</MenuItem>
                      <MenuItem value={5}>Centaur</MenuItem>
                      <MenuItem value={6}>CSR</MenuItem>
                      <MenuItem value={7}>Caring</MenuItem>
                      <MenuItem value={8}>Do It Professionally</MenuItem>
                      <MenuItem value={9}>Obsession</MenuItem>
                      <MenuItem value={10}>Fast Move</MenuItem>
                      <MenuItem value={11}>Outperform</MenuItem>
                    </Select>
                  )}
                />
                <FormHelperText>
                  {errors.specialCoin?.idCoinType?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="specialCoin.amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="number"
                    label="จำนวนเหรียญ(พิเศษ)"
                    error={!!errors.specialCoin?.amount}
                    helperText={errors.specialCoin?.amount?.message}
                    disabled={watch("specialCoin.idCoinType") === 0}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="startDate"
                control={control}
                render={({ field, fieldState }) => (
                  <DateTimePicker
                    {...field}
                    label="วันที่เริมกิจกรรม"
                    minDateTime={dayjs(watch("openRegisterDate"))}
                    format="DD/MM/YYYY HH:mm"
                    ampm={false}
                    slotProps={{
                      textField: {
                        error: Boolean(fieldState.error),
                        helperText: fieldState.error && fieldState.error.message,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="endDate"
                control={control}
                render={({ field, fieldState }) => (
                  <DateTimePicker
                    {...field}
                    label="วันที่สิ้นสุดกิจกรรม"
                    minDateTime={dayjs(watch("startDate"))}
                    format="DD/MM/YYYY HH:mm"
                    ampm={false}
                    slotProps={{
                      textField: {
                        error: Boolean(fieldState.error),
                        helperText:
                          fieldState.error && fieldState.error.message,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography>เหรียญรางวัล</Typography>
              <Stack spacing={1} marginBottom={1} marginTop={1}>
                {coins.fields.map((field, index) => (
                  <div
                    key={field.id}
                    style={{ display: "flex", gap: "0.5rem" }}
                  >
                    <FormControl fullWidth error={Boolean(errors?.coins?.[index]?.idCoinType)}>
                      <InputLabel>{"เหรียญ"}</InputLabel>
                      <Controller
                        control={control}
                        name={`coins.${index}.idCoinType`}
                        render={({ field }) => (
                          <Select {...field} label={"เหรียญ"}>
                            {coinType.data
                              .filter((item) => item.coinType !== "heart")
                              .map((item, index) => (
                                <MenuItem
                                  key={index}
                                  value={item.idCoinType}
                                  disabled={watch("coins")?.some((coin) => coin.idCoinType === item.idCoinType)}
                                >
                                  {item.coinName}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      />
                      <FormHelperText>
                        {errors?.coins?.[index]?.idCoinType?.message}
                      </FormHelperText>
                    </FormControl>
                    <InputField
                      control={control}
                      name={`coins.${index}.amount`}
                      type={"number"}
                      label={"จำนวน"}
                    />
                    <IconButton
                      type="button"
                      color="error"
                      onClick={() => coins.remove(index)}
                      disabled={coins.fields.length === 1}
                    >
                      <BackspaceIcon />
                    </IconButton>
                  </div>
                ))}
              </Stack>
              {coins.fields.length < 2 && (
                <Button
                  type="button"
                  variant="outlined"
                  startIcon={<AddCircleIcon />}
                  onClick={() => coins.append({ idCoinType: "", amount: "" })}
                  disabled={coins.fields.length === 2}
                >
                  เพิ่ม
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="image"
                control={control}
                render={({ field }) => (
                  <UploadFile
                    {...field}
                    error={errors.image}
                    acceptFileType={"image/jpeg, image/png"}
                    acceptFileTypeLabel={"*.jpeg and *.png images"}
                    name={"รูปภาพหน้าปกกิจกรรม"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <UploadFile
                    {...field}
                    error={errors.file}
                    acceptFileType={"image/jpeg, image/png, application/pdf"}
                    acceptFileTypeLabel={"*.jpeg and *.png images, *.pdf"}
                    name={"ไฟล์รายละเอียดกิจกรรม"}
                  />
                )}
              />
            </Grid>
            {errors.coordinate && (
              <Grid item xs={12}>
                <Typography color="error" textAlign="end">
                  {errors.coordinate.lat.message}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button
                  variant="outlined"
                  color="error"
                  component={Link}
                  to={"/admin/manage/activity"}
                >
                  ยกเลิก
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={isSubmitting}
                >
                  สร้างกิจกรรม
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Container>
    </StyledRoot>
  );
}

export default CreateActivityAlt;
