import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "date-fns";
import { styled } from "@mui/material/styles";
// Components
import { Typography, Divider, Alert, IconButton, AlertTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// Icon
import TableChartIcon from "@mui/icons-material/TableChart";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import DeleteIcon from "@mui/icons-material/Delete";
import HeaderPage from "../../../shared/header/headerPage";
import RewardIcon from "./assets/gifts.png";
import RewardCards from "./rewardCards";
import RewardTable from "./rewardTable";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyRewards } from "../../../../../actions/companyReward";
import { useCompanyRewardByCompany, useCompanyRewards, useUniRewardByCompany } from "../../../../../services/quries";

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
})

const StyledRoot = styled('div')({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
})

const StyledImageDropZone = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  borderWidth: "2px",
  borderRadius: "2px",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
})

export default function Reward() {
  const [alignment, setAlignment] = useState("card");
  const [openAlert, setOpenAlert] = useState(false);
  const [alert, setAlert] = useState({
    color: "info",
    title: "",
    detail: "",
  });
  
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  
  return (
    <StyledRoot className={'page'}>
      <div>
        <HeaderPage textLabel={"จัดการของรางวัล"} icon={RewardIcon} />
        <StyledDivider />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <Typography style={{ paddingRight: 5 }}>มุมมอง</Typography>
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleAlignment}
          size="small"
          aria-label="text alignment"
        >
          <ToggleButton value="card" aria-label="left aligned">
            <ViewModuleIcon />
          </ToggleButton>
          <ToggleButton value="table" aria-label="centered">
            <TableChartIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <RewardCards />
      <Dialog open={openAlert} onClose={handleCloseAlert}>
        <DialogContent>
          <Alert severity={alert.color} onClose={handleCloseAlert}>
            <AlertTitle>{alert.title}</AlertTitle>
            {alert.detail}
          </Alert>
        </DialogContent>
      </Dialog>
    </StyledRoot>
  );
}
