import React, { useEffect, useState, useMemo, useCallback } from "react";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import HeaderPage from "../../../shared/header/headerPage";
import iconHeader from "./assets/teamwork.svg";
import ActivityRegisterList from "./activityRegisterList";
import "./index.css";
import {
  Button,
  Divider,
  Autocomplete,
  TextField,
  Grid,
  Container,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../../actions/user";
import {
  getActivity,
  getActivityParticipant,
} from "../../../../../actions/activity";
import { useParams } from "react-router-dom";
// import FilterEmployeeName from "./filterEmployeeName";
import { useOrganization } from "../../../../../quries/company";
import { utils, writeFileXLSX } from "xlsx";
import DialogAddParticipant from "./dialogAddParticipant";

const WrapHeader = styled("div")({
  marginTop: 16,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  ["@media only screen and (max-width: 600px)"]: {
    "& .MuiTypography-root": {
      fontSize: 26,
    },
  },
  ["@media only screen and (min-width:600px)"]: {
    "& .MuiTypography-root": {
      fontSize: 26,
    },
  },
  ["@media only screen and (min-width:768px)"]: {
    "& .MuiTypography-root": {
      fontSize: 34,
    },
  },
  ["@media only screen and (min-width:992px)"]: {},
});

const WrapBtnAddNew = styled("div")({
  ["@media only screen and (max-width: 600px)"]: {
    display: "none",
  },
  ["@media only screen and (min-width:600px)"]: {},
  ["@media only screen and (min-width:768px)"]: {},
  ["@media only screen and (min-width:992px)"]: {},
});

const WrapBtnIconAddNew = styled("div")({
  ["@media only screen and (max-width: 600px)"]: {},
  ["@media only screen and (min-width:600px)"]: {
    display: "none",
  },
  ["@media only screen and (min-width:768px)"]: {},
  ["@media only screen and (min-width:992px)"]: {},
});

const StyledDivider = styled(Divider)({
  margin: "10px 0",
});

const ActivityRegister = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { result: activityParticipant } = useSelector((state) => state.activityParticipant);
  const { result: activity } = useSelector((state) => state.activityItem);

  const organization = useOrganization();

  const [openAddParticipant, setOpenAddParticipant] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [filterEmployee, setFilterEmployee] = useState("");
  const [companySelected, setCompanySelected] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState({
    departmentName: "All Department",
    idDepartment: 0,
  });
  const [sectionSelected, setSectionSelected] = useState({
    sectionName: "All Section",
    idSection: 0,
  });
  const [mode, setMode] = useState("list");

  useEffect(() => {
    dispatch(getActivity(id));
    dispatch(getActivityParticipant(id));
    dispatch(getAllEmployees());
  }, []);

  const idActivityParticipantList = useMemo(() => {
    if (activityParticipant) {
      return activityParticipant.map((person) => person.idEmployees);
    }
  }, [activityParticipant]);

  const handleChangeFilterEmployee = (value) => {
    setFilterEmployee(value);
  };

  const handleClickOpenAddNewEmployee = () => {
    setOpenAddParticipant(true);
  };

  const handleCloseAddParticipant = () => {
    setOpenAddParticipant(false);
  };

  const exportFile = useCallback(() => {
    let participant = [...activityParticipant]
    if (companySelected) {
      participant = activityParticipant.filter(emp => emp.idCompany === companySelected.idCompany)
    }
    
    const data = participant.map(emp => ({
      employeeID: emp.employeeID, 
      firstname_TH: emp.firstname_TH, 
      lastname_TH: emp.lastname_TH,
      positionName: emp.positionName,
      sectionName: emp.sectionName,
      departmentName: emp.departmentName,
      divisionName: emp.divisionName,
      companyName: emp.companyName,
      telephoneMobile: emp.telephoneMobile,
      participantStatus: emp.participantStatus ? "เข้าร่วม" : "ไม่เข้าร่วม"
    }))

    const ws = utils.json_to_sheet(data);
    utils.sheet_add_aoa(ws, [["รหัสพนักงาน", "ชื่อ", "นามสกุล", "ตำแหน่ง", "ฝ่าย", "ส่วน", "แผนก", "เบอร์โทร", "สถานะ"]], { origin: "A1" });
    ws["!cols"] = [{ wch: 10 }, { wch: 25 }, { wch: 25 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 10 }];

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws);
    writeFileXLSX(wb, "SheetJSReactHTML.xlsx");
  }, [companySelected]);

  const participantData = activityParticipant?.filter((item) => {
    let isInclude = true;
    if (departmentSelected.idDepartment) {
      isInclude = departmentSelected.idDepartment === item.idDepartment;
    }
    if (sectionSelected.idSection) {
      isInclude = sectionSelected.idSection === item.idSection;
    }
    return isInclude;
  })

  return (
    <div className="page">
      <WrapHeader>
        {mode === "list" && (
          <Fragment>
            <HeaderPage textLabel={"รายชื่อลงทะเบียน"} icon={iconHeader} />
            <WrapBtnAddNew>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => handleClickOpenAddNewEmployee()}
              >
                เพิ่มผู้เข้าร่วมกิจกรรม
              </Button>
            </WrapBtnAddNew>
          </Fragment>
        )}
      </WrapHeader>
      <StyledDivider />
      <Container>
        <Grid container alignItems={"center"} spacing={1} marginBottom={4}>
          <Grid item xs={12} sm={12} md={3}>
            <Autocomplete
              disableClearable
              value={companySelected}
              options={organization.data}
              onChange={(event, newValue) => {
                setCompanySelected(newValue);
                setDepartmentSelected({
                  departmentName: "All Department",
                  idDepartment: 0,
                });
                setSectionSelected({ sectionName: "All Section", idSection: 0 });
              }}
              getOptionLabel={(option) => option.companyName}
              isOptionEqualToValue={(option, value) =>
                option.idCompany === value.idCompany
              }
              renderInput={(params) => <TextField {...params} label="Company" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disableClearable
              value={departmentSelected}
              options={
                companySelected
                  ? [
                      { departmentName: "All Department", idDepartment: 0 },
                      ...companySelected.department,
                    ]
                  : []
              }
              onChange={(event, newValue) => setDepartmentSelected(newValue)}
              getOptionLabel={(option) => option.departmentName}
              isOptionEqualToValue={(option, value) =>
                option.idDepartment === value.idDepartment
              }
              renderInput={(params) => <TextField {...params} label="Department" />}
            />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disableClearable
              value={sectionSelected}
              options={
                companySelected
                  ? [
                      { sectionName: "All Section", idSection: 0 },
                      ...companySelected.section,
                    ]
                  : []
              }
              onChange={(event, newValue) => setSectionSelected(newValue)}
              getOptionLabel={(option) => option.sectionName}
              isOptionEqualToValue={(option, value) =>
                option.idSection === value.idSection
              }
              renderInput={(params) => <TextField {...params} label="Section" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              sx={{ minWidth: "fit-content" }}
              onClick={exportFile}
              startIcon={<FileDownloadIcon />}
            >
              Export
            </Button>
          </Grid>
        </Grid>
        {mode === "list" && activity && participantData && (
          <ActivityRegisterList
            activity={activity}
            activityParticipant={participantData}
            idActivity={id}
            filterEmployee={filterEmployee}
            companySelected={companySelected}
          />
        )}
        {openAddParticipant && (
          <DialogAddParticipant
            open={openAddParticipant}
            handleClose={handleCloseAddParticipant}
            idActivity={id}
          />
        )}
      </Container>
    </div>
  );
};

export default ActivityRegister;
