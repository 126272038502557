import React from "react";
import { styled } from "@mui/material/styles";
import { Divider, Button, Stack, Container } from "@mui/material";
import RedeemIcon from "./assets/buy.png";
import HeaderPage from "../../shared/header/headerPage";
import RedeemTransactionGrid from "./redeemTransactionGrid";
import { Link } from "react-router-dom";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 400,
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

export default function RedeemTransaction() {
  return (
    <StyledRoot className={"page"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <HeaderPage textLabel={"รายการแลกของรางวัล"} icon={RedeemIcon} />
      </Stack>
      <StyledDivider />
      <Container maxWidth="xl">
        <RedeemTransactionGrid />
      </Container>
    </StyledRoot>
  );
}
