import * as React from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { ListItemButton } from "@mui/material";

DialogSelectSample.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function DialogSelectSample(props) {
  const { open, onClose, appraiseeList, selectSampleSize } = props;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select sample answer</DialogTitle>
      <List sx={{ pt: 0 }}>
        {appraiseeList.map((item) => (
          <ListItemButton
            onClick={() => handleListItemClick(item.idAppraisee_SampleSize)}
            key={item.idAppraisee_SampleSize}
            selected={item.idAppraisee_SampleSize === selectSampleSize.idAppraisee_SampleSize}
          >
            <ListItemText primary={`${item.firstname_TH} ${item.lastname_TH}`} />
          </ListItemButton>
        ))}
      </List>
    </Dialog>
  );
}
