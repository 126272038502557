import { useQuery } from "@tanstack/react-query";
import { httpClient } from "../services/httpClient";

export const useHeartLeaderboard = ({ idCompany }) => {
  return useQuery({
    queryKey: ["heartLeaderboard"],
    queryFn: async () => (await httpClient.get("heart-leaderboard")).data,
    enabled: !!idCompany,
  });
};

export const useHeartLeaderboardRank = ({ idEmployees }) => {
  return useQuery({
    queryKey: ["heartLeaderboard", "rank", idEmployees],
    queryFn: async () => (await httpClient.get(`heart-leaderboard/rank/${idEmployees}`)).data,
    enabled: !!idEmployees,
    refetchOnWindowFocus: false,
  });
};

export const useCoinLeaderboard = ({ idCompany }) => {
  return useQuery({
    queryKey: ["coinLeaderboard"],
    queryFn: async () => (await httpClient.get("coin-leaderboard")).data,
    enabled: !!idCompany,
  });
};

export const useManagerHeartLeaderboardd = ({ idCompany }) => {
  return useQuery({
    queryKey: ["managerHeartLeaderboard"],
    queryFn: async () => (await httpClient.get("manager-heart-leaderboard")).data,
    enabled: !!idCompany,
  });
};
