import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  styled,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import HeaderPage from "../../../../shared/header/headerPage";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RowConfig from "./rowConfig";
import DialogAddRewardCost from "./addRewardCost";
import TiptapDetail from "../../../../shared/reward/tiptapDetail";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

function DetailReward() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [selectImage, setImgae] = useState(null);
  const [open, setOpen] = useState(false);

  const handleChangeImage = (urlImage) => {
    setImgae(urlImage);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!state?.value) {
      return navigate("/admin/manage/reward", { replace: true })
    }
  }, [])
 
  return (
    <StyledRoot className={"page"}>
      {state?.value && (
        <Container>
          <HeaderPage textLabel={"รายละเอียดของรางวัล"} />
          <StyledDivider />
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{ border: "solid", borderWidth: 1, borderColor: "#bdbdbd" }}
              >
                <img
                  width={"100%"}
                  height={"100%"}
                  alt={state.value.name}
                  src={selectImage ? selectImage : state.value.imageURL}
                />
              </Box>
              <ImageList
                //   sx={{ width: 500, height: 450 }}
                cols={5}
                //   rowHeight={164}
              >
                {[{ imageURL: state.value.imageURL }, ...state.value.images].map((item, index) => (
                  <Box
                    sx={{
                      border: "solid",
                      borderWidth: 1,
                      borderColor: "#bdbdbd",
                    }}
                    onClick={() => handleChangeImage(item)}
                    key={index}
                  >
                    <ImageListItem>
                      <img
                        src={item.imageURL}
                        alt={"reward"}
                        loading="lazy"
                      />
                    </ImageListItem>
                  </Box>
                ))}
              </ImageList>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                component={Link} 
                // to={`/admin/manage/reward/edit/${value.idCompanyReward}`} 
                startIcon={<EditIcon />}
              >
                แก้ไขรายละเอียด
              </Button>
              <TiptapDetail detail={state.value.detail} />
            </Grid>
            {state.value.options.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h6" marginBottom={1}>ตัวเลือก</Typography>
                {state.value.options.map((item, index) => (
                  <>
                    <Typography>{item.name}</Typography>
                    {/* <ToggleButtonGroup
                      value={selectReward[`option${index + 1}`]}
                      onChange={(event, newAlignment) => {
                        setSelectReward((prev) => ({
                          ...prev,
                          [`option${index + 1}`]: newAlignment,
                        }));
                      }}
                      exclusive
                      color="info"
                    >
                      {item.option.map((op) => (
                        <ToggleButton
                          key={op.idVariationOption}
                          value={op.idVariationOption}
                        >
                          {op.value}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup> */}
                  </>
                ))}
            </Grid>
            )}
          </Grid>
          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "16px" }}>
            <Button onClick={handleOpen} variant="outlined">
              เพิ่มของรางวัล
            </Button>
          </div>
          {open && (
            <DialogAddRewardCost 
              open={open} 
              handleClose={handleClose} 
              idCompanyReward={state?.value?.idCompanyReward}  
              idUniReward={state?.value?.idUniReward}
            />
          )}
          <RowConfig 
            idCompanyReward={state?.value?.idCompanyReward} 
            idUniReward={state?.value?.idUniReward}
          />
        </Container>
      )}
    </StyledRoot>
  );
}

export default DetailReward;
