import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import dayjs from "dayjs";

import { addAssessment } from "../../../../actions/assessment";

const DialogResultUpload = (props) => {
  const { open, handleClose, data } = props;
  const dispatch = useDispatch();

  const handleAddAssessment = async () => {

    const response = await dispatch(addAssessment(data));
    console.log(response);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Review Assessment"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {JSON.stringify(data)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={handleAddAssessment} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogResultUpload;
