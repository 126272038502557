import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getCoinType,
  getCoinInReward,
  getCoinOutActivity,
  getTokenomic,
} from "./specialCoin.service";
import { httpClient } from "./httpClient";
import { toast } from "sonner";

export const useUniRewards = () => {
  return useQuery({
    queryKey: ["uniRewards"],
    queryFn: async () => (await httpClient.get("uni-reward")).data,
  });
};

export const useUniReward = ({ idUniReward }) => {
  return useQuery({
    queryKey: ["uniRewards", idUniReward],
    queryFn: async () => (await httpClient.get(`uni-reward/${idUniReward}`)).data,
  });
};

export const useAddUniReward = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (formData) => httpClient.post("uni-reward", formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["uniRewards"] })
    },
  });
};

export const useUpdateUniRewards = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (formData) => httpClient.put("uni-reward", formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["uniRewards"] })
    },
  });
};

export const useUpdateUniRewardStatus = ({ idUniReward }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (formData) => httpClient.put("uni-reward", formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["uniRewards", idUniReward] })
    },
  });
};

// export const useCompanyBudget = () => {
//   return useQuery({
//     queryKey: ["companyBudget"],
//     queryFn: async () => (await httpClient.get('company-budget')).data,
//   });
// };

// export const useUniRewardCart = () => {
//   return useQuery({
//     queryKey: ["rewardCart"],
//     queryFn: async () => (await httpClient.get('uni-reward-cart')).data,
//   });
// };

// export const useUpdateUniRewardCart = () => {
//   const queryClient = useQueryClient()
//   return useMutation({
//     mutationFn: async (formData) => httpClient.put("uni-reward-cart", formData),
//     onSuccess: () => {
//       // Invalidate and refetch
//       queryClient.invalidateQueries({ queryKey: ['rewardCart'] })
//     },
//   });
// };

// export const useDeleteUniRewardCart = () => {
//   const queryClient = useQueryClient()
//   return useMutation({
//     mutationFn: async (idCartItem) => httpClient.delete(`uni-reward-cart/${idCartItem}`),
//     onSuccess: () => {
//       // Invalidate and refetch
//       queryClient.invalidateQueries({ queryKey: ['rewardCart'] })
//     },
//   });
// };

// export const useUniRewardOrder = () => {
//   return useQuery({
//     queryKey: ["rewardOrder"],
//     queryFn: async (id) => (await httpClient.get(`uni-reward-order/${id}`)).data,
//   });
// };

// export const useAddUniRewardOrder = () => {
//   const queryClient = useQueryClient()
//   return useMutation({
//     mutationFn: async (formData) => httpClient.post("uni-reward-order", formData),
//     onSuccess: () => {
//       // Invalidate and refetch
//       queryClient.invalidateQueries({ queryKey: ['rewardCart'] })
//     },
//     onError: () => {
//       toast.error("เกิดข้อผิดพลาด")
//     },
//   });
// };

export const useCompanyRewards = () => {
  return useQuery({
    queryKey: ["companyRewards"],
    queryFn: async () => (await httpClient.get("company-reward")).data,
  });
};

export const useCompanyReward = ({ idCompanyReward }) => {
  return useQuery({
    queryKey: ["companyRewards", idCompanyReward],
    queryFn: async () => (await httpClient.get(`company-reward/${idCompanyReward}`)).data,
    enabled: idCompanyReward,
  });
};

export const useAddCompanyReward = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (reward) => httpClient.post("company-reward", reward),
    onSuccess: () => {
      toast.success("สำเร็จ")
      queryClient.invalidateQueries({ queryKey: ["companyRewards"] })
    },
    onError: () => {
      toast.error("เกิดข้อผิดพลาด")
    }
  });
};

export const useUniRewardByCompany = (select) => {
  return useQuery({
    queryKey: ["rewards", "uni-rewards"],
    queryFn: async () => (await httpClient.get(`rewards/uni-rewards`)).data,
    select,
    refetchOnWindowFocus: false,
  });
};

export const useCompanyRewardByCompany = (select) => {
  return useQuery({
    queryKey: ["rewards", "company-rewards"],
    queryFn: async () => (await httpClient.get(`rewards/company-rewards`)).data,
    select,
    refetchOnWindowFocus: false,
  });
};

export const useCoinInReward = ({ idCoinType }) => {
  return useQuery({
    queryKey: ['coinInReward', idCoinType],
    queryFn: () => getCoinInReward(idCoinType),
    enabled: Boolean(idCoinType)
  });
};

export const useCoinOutActivity = ({ idCoinType }) => {
  return useQuery({
    queryKey: ['coinOutActivity', idCoinType],
    queryFn: () => getCoinOutActivity(idCoinType),
    enabled: Boolean(idCoinType)
  });
};

export const useTokenomic = ({ select }) => {
  return useQuery({
    queryKey: ["getTokenomic"],
    queryFn: getTokenomic,
    select,
  });
};
