import { httpClient } from "./httpClient";

const getUserProfile = () => {
  return httpClient.get("profile");
};

const getAllEmployees = (filter, search) => {
  if (filter) {
    return httpClient.get(`employees?filter=${filter}`);
  } else if (search) {
    return httpClient.get(`employees?search=${search}`);
  } else {
    return httpClient.get("employees");
  }
};

const getAllUserCoin = (query) => {
  return httpClient.get("user-coin", { params: query });
};

const getAllCoinQuota = () => {
  return httpClient.get("coin-quota");
};

const updateSendHeartQuota = (type, formData) => {
  return httpClient.put(`send-heart-quota/${type}`, formData);
};

const updateMissionCoinQuota = (type, formData) => {
  return httpClient.put(`mission-coin-quota/${type}`, formData);
};

export default {
  getUserProfile,
  getAllEmployees,
  getAllUserCoin,
  getAllCoinQuota,
  updateSendHeartQuota,
  updateMissionCoinQuota,
};
