import {
  COACH_FETCHING,
  COACH_FAILED,
  COACH_SUCCESS,
  COACH_ITEM_FETCHING,
  COACH_ITEM_FAILED,
  COACH_ITEM_SUCCESS,
  COACH_POPULAR_FETCHING,
  COACH_POPULAR_FAILED,
  COACH_POPULAR_SUCCESS,
  COACHEE_FETCHING,
  COACHEE_FAILED,
  COACHEE_SUCCESS,
} from "./types";

import coachService from "../services/coach.service";

export const getAllCoach = () => async (dispatch) => {
  try {
    dispatch({
      type: COACH_FETCHING,
    });
    const res = await coachService.getAllCoach();
    if (res) {
      dispatch({
        type: COACH_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: COACH_FAILED,
    });
    console.log(err);
  }
};

export const getCoach = (idCoach) => async (dispatch) => {
  try {
    dispatch({
      type: COACH_ITEM_FETCHING,
    });
    const res = await coachService.getCoach(idCoach);
    if (res) {
      dispatch({
        type: COACH_ITEM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: COACH_ITEM_FAILED,
    });
    console.log(err);
  }
};

export const getCoachPopular = () => async (dispatch) => {
  try {
    dispatch({
      type: COACH_POPULAR_FETCHING,
    });
    const res = await coachService.getCoachPopular();
    if (res) {
      dispatch({
        type: COACH_POPULAR_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: COACH_POPULAR_FAILED,
    });
    console.log(err);
  }
};

export const addCoach = (formData) => async (dispatch) => {
  try {
    const res = await coachService.addCoach(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: COACH_FAILED,
    });
    console.log(err);
  }
};

export const updateCoach = (formData) => async (dispatch) => {
  try {
    const res = await coachService.updateCoach(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: COACH_FAILED,
    });
    console.log(err);
  }
};

export const getCoacheeByIdCoach = (idCoach) => async (dispatch) => {
  try {
    dispatch({
      type: COACHEE_FETCHING,
    });
    const res = await coachService.getCoacheeByIdCoach(idCoach);
    if (res) {
      dispatch({
        type: COACHEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: COACHEE_FAILED,
    });
    console.log(err);
  }
};
