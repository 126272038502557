import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  ImageList,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";
import HeaderPage from "../../../../shared/header/headerPage";
import { StyledDividerHeader } from "../../../../shared/dividerHeader";
import VariationField from "../../../../shared/formReward/variationField";
import SwitchInput from "../../../../shared/inputForm/switchInput";
import TipTap from "../../../../shared/tiptapEditor";
import { useNavigate } from "react-router-dom";
import InputField from "../../../../shared/inputForm/inputField";
import UploadRewardImage from "../../../../shared/uploadReward/uploadRewardImage";
import { useAddCompanyReward } from "../../../../../../services/quries";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const StyledCard = styled(Card)({
  borderRadius: 8,
});

function CreateReward() {
  const navigate = useNavigate();
  const { mutate } = useAddCompanyReward();

  const defaultValues = {
    name: "",
    detail: "",
    idRewardType: "",
    price: 0,
    quantity: 0,
    sku: "",
    rewardManager: "",
    contact: "",
    images: [null, null, null, null],
    mainImage: { image: '' },
    openVariation: false,
    addOptionImage: false,
    variation: [{ name: "", options: [{ name: "", image: null }] }],
    attributes: [{ option1: "", option2: "", price: "", quantity: "", sku: "" }],
  };

  const validationSchema = yup.object().shape({
    name: yup.string().max(100).required('required'),
    idRewardType: yup.string().required('required'),
    price: yup.number().when("openVariation", {
      is: false,
      then: (schema) => schema.min(1).max(100000).integer().required('required'),
    }),
    quantity: yup.number().when("openVariation", {
      is: false,
      then: (schema) => schema.min(1).max(1000).integer().required('required'),
    }),
    sku: yup.string().when("openVariation", {
      is: false,
      then: (schema) => schema.max(10).required('required'),
    }),
    rewardManager: yup.string().max(100).required('required'),
    contact: yup.string().max(100).required('required'),
    mainImage: yup
      .mixed()
      .test(
        "required",
        "โปรดเพิ่มรูปภาพหลัก",
        (value) => value && (value.path || value.image)
    ),
    images: yup.array().max(4),
    openVariation: yup.boolean(),
    variation: yup.array().when("openVariation", {
      is: true,
      then: (schema) =>
        schema.of(
          yup.object().shape({
            name: yup.string().max(15).required('required'),
            options: yup.array().of(
              yup.object().shape({
                name: yup.string().max(20).required('required'),
                image: yup.mixed(),
              })
            ),
          })
        ),
    }),
    attributes: yup.array().when("openVariation", {
      is: true,
      then: (schema) => schema.of(
        yup.object().shape({
          price: yup
            .number()
            .transform((value) => (isNaN(value) ? null : value))
            .nullable()
            .min(1, "must be greater than or equal to 1")
            .integer()
            .required('required'),
          quantity: yup
            .number()
            .transform((value) => (isNaN(value) ? null : value))
            .nullable()
            .min(1, "must be greater than or equal to 1")
            .integer()
            .required('required'),
          sku: yup.string().max(15, "must be at most 15").required('required'),
        })
      )
    }),
    addOptionImage: yup.boolean(),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });
  console.log(errors)

  const images = getValues("images");

  const handleAddCompanyReward = async (data) => {
    const formData = new FormData();
    for (const property in data) {
      if (property === "images") {
        data[property]
          .filter((item) => item instanceof File)
          .forEach((file) => formData.append(property, file));
      } else if (Array.isArray(data[property])) {
        formData.append(property, JSON.stringify(data[property]));
      } else {
        formData.append(property, data[property]);
      }
    }

    data["variation"][0].options
      .forEach((file) => formData.append("variationImages", file.image));


    mutate(formData)
    navigate("/admin/manage/reward");
  };

  return (
    <StyledRoot className={"page"}>
      <DevTool control={control} />
      <HeaderPage textLabel={"เพิ่มรางวัล"} />
      <StyledDividerHeader />
      <Container maxWidth="lg">
        <form onSubmit={handleSubmit(handleAddCompanyReward)}>
          <Stack spacing={2}>
            <StyledCard elevation={4}>
              <CardHeader title="ข้อมูลรางวัล" />
              <CardContent>
                <Grid container spacing={2} marginTop={0}>
                  <Grid item xs={12}>
                    <InputField 
                      name="name"
                      control={control}
                      label="ชื่อรางวัล"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>รายละเอียด</InputLabel>
                    <Controller
                      name="detail"
                      control={control}
                      render={({ field }) => (
                        <TipTap
                          detail={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <InputLabel>ประเภทของรางวัล</InputLabel>
                      <Controller
                        name="idRewardType"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="ประเภทของรางวัล"
                            error={!!errors.idRewardType}
                          >
                            <MenuItem value={"1"}>Food</MenuItem>
                            <MenuItem value={"2"}>Shopping</MenuItem>
                            <MenuItem value={"3"}>Entertainment</MenuItem>
                            <MenuItem value={"4"}>Lifestyle</MenuItem>
                            <MenuItem value={"5"}>Travel</MenuItem>
                            <MenuItem value={"6"}>Health</MenuItem>
                          </Select>
                        )}
                      />
                      <FormHelperText error>
                        {errors.idRewardType?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <InputField 
                      name="price"
                      control={control}
                      label="ราคา"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <InputField 
                      name="quantity"
                      control={control}
                      label="จำนวน"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputField 
                      name="sku"
                      control={control}
                      label="SKU"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputField 
                      name="rewardManager"
                      control={control}
                      label="ผู้ดูแลรางวัล"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InputField 
                      name="contact"
                      control={control}
                      label="การติดต่อ"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>

            <StyledCard elevation={4}>
              <CardHeader title={"รูปภาพรางวัล"} />
              <CardContent>
                <Typography>{"ขนาดไฟล์ภาพต้องไม่เกิน 500 KB"}</Typography>
                <ImageList cols={5}>
                  <Grid item xs={2}>
                    <Controller
                      name={'mainImage'}
                      control={control}
                      render={({ field }) => (
                        <UploadRewardImage
                          {...field}
                          acceptFileType={"image/jpeg, image/png, image/webp"}
                          error={errors.mainImage}
                          title={"รูปภาพหลัก"}
                        />
                      )}
                    />
                  </Grid>
                  {images.map((value, index) => (
                    <Grid item xs={2} key={index}>
                      <Controller
                        name={`images.${index}`}
                        control={control}
                        render={({ field }) => (
                          <UploadRewardImage
                            {...field}
                            acceptFileType={"image/jpeg, image/png, image/webp"}
                            error={errors.images}
                            title={"รูปภาพเสริม"}
                          />
                        )}
                      />
                    </Grid>
                  ))}
                </ImageList>
              </CardContent>
            </StyledCard>
            <StyledCard>
              <CardHeader
                title={"ข้อมูลการขาย"}
                action={
                  <SwitchInput
                    name="openVariation"
                    control={control}
                    label={"เพิ่มตัวเลือกรางวัล"}
                  />
                }
              />
              {watch("openVariation") && (
                <CardContent>
                  <VariationField {...{ control, defaultValues, errors }} />
                </CardContent>
              )}
            </StyledCard>
            <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
              <Button type="button" color="error" onClick={() => navigate("/admin/manage/reward")}>
                ยกเลิก
              </Button>
              <Button type="submit" variant="contained">
                สร้างของรางวัล
              </Button>
            </Stack>
          </Stack>
        </form>
      </Container>
    </StyledRoot>
  );
}

export default CreateReward;
