import {
  UNI_REWARD_CART_FETCHING,
  UNI_REWARD_CART_FAILED,
  UNI_REWARD_CART_SUCCESS,
} from "./types";
import uniRewardService from "../services/uniReward.service";

export const getUniRewardCart = () => async (dispatch) => {
  try {
    dispatch({
      type: UNI_REWARD_CART_FETCHING,
    });
    const res = await uniRewardService.getUniRewardCart();
    if (res) {
      dispatch({
        type: UNI_REWARD_CART_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: UNI_REWARD_CART_FAILED,
    });
    console.log(error);
  }
};

export const addUniRewardCart = (formData) => async (dispatch) => {
  try {
    const res = await uniRewardService.addUniRewardCart(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: UNI_REWARD_CART_FAILED,
    });
    console.log(error);
  }
};

export const updateUniRewardCart = (formData) => async (dispatch) => {
  try {
    const res = await uniRewardService.updateUniRewardCart(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: UNI_REWARD_CART_FAILED,
    });
    console.log(error);
  }
};

export const deleteUniRewardCart = (idCartItem) => async (dispatch) => {
  try {
    const res = await uniRewardService.deleteUniRewardCart(idCartItem);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: UNI_REWARD_CART_FAILED,
    });
    console.log(error);
  }
};