import React, { useState, useEffect } from "react";
import {
  Avatar,
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  FormControl,
  Button,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { Rating } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

import { useSelector, useDispatch } from "react-redux";
import { addBooking, getBookingByIdCoach } from "../../../../actions/book";
import { getUserProfile } from "../../../../actions/user";
import { useNavigate, Navigate, useParams } from "react-router-dom";

import Calendar from "react-awesome-calendar";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import { getCoach } from "../../../../actions/coach";
import { MainCoin } from "../../shared/typeCoin";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";

dayjs.extend(isBetween);
dayjs.extend(utc);

const StyledAvatar = styled(Avatar)({
  height: 200,
  width: 200,
  borderBlockColor: "black",
});

const StyledCalendar = styled(Calendar)({
  "& .calendarWrapper": {
    display: "none",
  },
});

const WrapMain = styled(Paper)(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(4),
  border: "1px solid #DFDFDF",
  padding: 20,
}));

const WrapAvatar = styled("div")(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  padding: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(4),
  },
}));

const Booking = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: bookCoach } = useSelector((state) => state.bookCoach);
  const { result: coachItem } = useSelector((state) => state.coachItem);

  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [displaySnackbar, setDisplaySnackbar] = useState({ value: "", mode: "" });
  const dataBooking = {
    idCoach: id,
    bookDate: dayjs().add(1, 'day'),
    startTime: dayjs().add(1, 'day').startOf('hour'),
    endTime: dayjs().add(1, 'day').startOf('hour'),
    note: "",
    type: "online",
    place: "",
  };

  const validationSchema = yup.object().shape({
    bookDate: yup.date().min(dayjs().startOf('day').add(1, 'day')).required(),
    startTime: yup.date().required(),
    endTime: yup.date().when("startTime", (startTime, schema) => {
      if(startTime) {
        return schema.min(dayjs(startTime).add(1, 'hour'), 'end time must be after than start time').required()
      }
    }),
    note: yup.string().max(100),
    type: yup.string().oneOf(["online", "onsite"]).required(),
    place: yup.string().max(100).required(),
  });

  const { control, watch, getValues, setValue, handleSubmit, formState: { errors } } = useForm({
    defaultValues: dataBooking,
    resolver: yupResolver(validationSchema),
  });

  const bookDate = watch("bookDate");
  const startTime = watch("startTime");
  const endTime = watch("endTime");

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getUserProfile(currentUser.id));
      dispatch(getBookingByIdCoach(id));
      dispatch(getCoach(id));
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const timeDiffColorMark = (startTime, endTime) => {
    const timeDiff = dayjs(endTime).diff(dayjs(startTime), "hour");
    switch (timeDiff) {
      case 1:
        return "#1CCB9E";
      case 2:
        return "#E5D400";
      case 3:
        return "#E58B00";
      default:
        return "#E10000";
    }
  };

  const getEvents = () => {
    const bookCoachFuture = bookCoach.filter((item) =>
      dayjs().isBefore(item.startTime, "day")
    );

    const date = [];
    for (let i = 0; i < bookCoachFuture.length; i++) {
      const tmpEvent = {
        id: bookCoachFuture[i].idBooking,
        color: timeDiffColorMark(bookCoachFuture[i].startTime, bookCoachFuture[i].endTime),
        from: `${dayjs(bookCoachFuture[i].startTime).utc(true).format()}`,
        to: `${dayjs(bookCoachFuture[i].endTime).utc(true).format()}`,
        title: bookCoachFuture[i].note || 'title',
      };
      date.push(tmpEvent);
    }
    return date;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOpenSnackbar = (value, mode) => {
    setDisplaySnackbar({ value, mode });
    setOpenSnackbar(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckDateBook = () => {
    const bookingInDatePick = bookCoach.filter((item) =>
      dayjs(item.startTime).isSame(bookDate, "day")
    );

    const checkBetweenStart = bookingInDatePick.some((item) =>
      dayjs(startTime).isBetween(dayjs(item.startTime), dayjs(item.endTime), "hour")
    );

    const checkBetweenEnd = bookingInDatePick.some((item) =>
      dayjs(endTime).isBetween(dayjs(item.startTime), dayjs(item.endTime), "hour")
    );

    const checkBookStart = bookingInDatePick.some((item) =>
      dayjs(item.startTime).isBetween(dayjs(startTime), dayjs(endTime), "hour")
    );

    const checkBookEnd = bookingInDatePick.some((item) =>
      dayjs(item.endTime).isBetween(dayjs(startTime), dayjs(endTime), "hour")
    );

    const checkSameStart = bookingInDatePick.some((item) => 
      dayjs(item.startTime).isSame(dayjs(startTime), "hour")
    );

    const checkSameEnd = bookingInDatePick.some((item) => 
      dayjs(item.endTime).isSame(dayjs(endTime), "hour")
    );

    if (
      !checkBetweenStart &&
      !checkBetweenEnd &&
      !checkBookStart &&
      !checkBookEnd &&
      !checkSameStart &&
      !checkSameEnd
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async () => {
    const checkTime = handleCheckDateBook();
    if (checkTime) {
      handleClickOpen();
    } else {
      handleOpenSnackbar("โปรดเลือกเวลาที่ยังว่าง", "error");
    }
  };

  const handleConfirm = async () => {
    const values = getValues();
    const formData = {
      ...values,
      value: coachItem[0].price * dayjs(dataBooking.endTime).diff(dayjs(dataBooking.startTime), "hour"),
      idEmployees: currentUser.id,
    };
    const res = await dispatch(addBooking(formData));
    if (res) {
      handleClose();
      handleOpenSnackbar("จองเวลาสำเร็จ", "success");
      setTimeout(() => {
        navigate("/coaching/coachee-booking");
      }, 3000)
    }
  };

  return (
    <div className={`page`}>
      <DevTool control={control}/>
      {parseInt(currentUser.id) === parseInt(id) ? (
        <Navigate to={`/coaching/profile/${id}`} replace />
      ) : (
        coachItem && userProfile && (
          <Container maxWidth="lg">
            <WrapMain>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={3} md={3}>
                  <WrapAvatar>
                    <StyledAvatar
                      // src={`${process.env.REACT_APP_API_URL}image/profile/${userProfile.image}`}
                      src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                    />
                  </WrapAvatar>
                </Grid>
                <Grid item xs={12} md={9} style={{ marginTop: 10 }}>
                  <div>
                    <Typography variant="h4" gutterBottom>
                      {`${coachItem[0].firstname_TH} ${coachItem[0].lastname_TH}`}
                    </Typography>
                  </div>
                  <Grid container>
                    <Grid item xs={12} md={5}>
                      <Typography variant="subtitle1">
                        {`${coachItem[0].positionName}`}
                      </Typography>
                      <br />
                      <div style={{ display: "flex" }}>
                        <Typography variant="subtitle1">Rating : </Typography>
                        <Rating
                          value={coachItem[0].rating}
                          readOnly
                          style={{ fontSize: 24 }}
                          precision={0.5}
                        />
                      </div>
                      <Stack direction={"row"} spacing={1}>
                        <Typography variant="subtitle1">
                          {`price/hours: ${coachItem[0].price}`}
                        </Typography>
                        <MainCoin width={24} />
                      </Stack>
                      <br />
                      <Typography variant="subtitle1" gutterBottom>
                        Contact
                      </Typography>
                      <Typography variant="subtitle1">
                        {`Tel: ${coachItem[0].telephoneMobile}`}
                      </Typography>
                      <Typography variant="subtitle1">
                        {`Email: ${coachItem[0].emailAddressBusiness}`}
                      </Typography>
                      <br />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Typography variant="subtitle1">{`Profile: ${coachItem[0].coachProfile}`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </WrapMain>
            {bookCoach && (
              <div stlye={{ paddinig: 20 }}>
                <StyledCalendar events={getEvents()} />
              </div>
            )}
            <WrapMain>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4} direction="row">
                  <Grid item xs={12}>
                    <Typography component="h2" variant="h5" gutterBottom>
                      จองเวลานัดหมาย
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={5} md={3}>
                    <Controller
                      name="bookDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          onChange={(newValue) => {
                            setValue("bookDate", newValue);
                            setValue("startTime",
                              dayjs(newValue)
                                .set("hour", startTime.get("hour"))
                                .set("minute", 0)
                                .set("second", 0)
                            );
                            setValue("endTime",
                              dayjs(newValue)
                                .set("hour", endTime.get("hour"))
                                .set("minute", 0)
                                .set("second", 0)
                            );
                          }}
                          label="วันที่"          
                          minDate={dayjs().add(1, "day")}
                          slotProps={{
                            textField: {
                              readOnly: true,
                              error: !!errors.bookDate,
                              helperText: errors.bookDate?.message,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3.5} md={3}>
                    <Controller
                      name="startTime"
                      control={control}
                      render={({ field }) => (
                        <TimePicker
                          {...field}
                          onChange={(newValue) =>
                            setValue(
                              "startTime",
                              dayjs(bookDate)
                                .set("hour", newValue.get("hour"))
                                .set("minute", 0)
                                .set('second', 0)
                            )
                          }
                          label="เวลาเริ่ม"          
                          views={["hours"]}
                          format="HH:mm"
                          slotProps={{
                            textField: {
                              readOnly: true,
                              error: !!errors.startTime,
                              helperText: errors.startTime?.message,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3.5} md={3}>
                    <Controller
                      name="endTime"
                      control={control}
                      render={({ field }) => (
                        <TimePicker
                          {...field}
                          onChange={(newValue) =>
                            setValue("endTime",
                              dayjs(bookDate)
                                .set("hour", newValue.get("hour"))
                                .set('minute', 0)
                                .set('second', 0)
                            )
                          }
                          label="เวลาสิ้นสุด"          
                          minTime={dayjs(startTime).add(1, "hour")}
                          views={["hours"]}
                          format="HH:mm"
                          slotProps={{
                            textField: {
                              readOnly: true,
                              error: !!errors.endTime,
                              helperText: errors.endTime?.message,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        รูปแบบ
                      </InputLabel>
                      <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="รูปแบบ"
                            error={!!errors.type}
                            placeholder="Type"
                          >
                            <MenuItem value={"online"}>Online</MenuItem>
                            <MenuItem value={"onsite"}>Onsite</MenuItem>
                          </Select>
                        )}
                      />
                    <FormHelperText>{errors.type && errors.type?.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={9} md={12}>
                    <Controller
                      name="place"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="สถานที่ / ลิงค์ประชุม"
                          variant="outlined"
                          error={!!errors.place}
                          helperText={errors.place?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="note"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          rows={4}
                          multiline
                          label="Note"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      style={{
                        color: "#fff",
                        backgroundColor: "#4caf50",
                      }}
                    >
                      ยืนยัน
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </WrapMain>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={handleCloseSnackbar}
            >
              <Alert severity={displaySnackbar.mode} onClose={handleCloseSnackbar}>
                {displaySnackbar.value}
              </Alert>
            </Snackbar>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle id="alert-dialog-title">
                {"จำนวนเหรียญที่ต้องจ่าย"}
              </DialogTitle>
              <DialogContent>
                <Typography>
                  {`ราคา ${coachItem[0].price} x ${endTime.diff(startTime, "hour")} ชั่วโมง`}
                </Typography>
                <Typography>
                  {`ราคารวม: ${coachItem[0].price * endTime.diff(startTime, "hour")}`}
                </Typography>
                <Typography>
                  {`จำนวนเหรียญที่มี: ${userProfile.coin}`}
                </Typography>
                {coachItem[0].price * endTime.diff(startTime, "hour") >
                  userProfile.coin && (
                  <Typography color={"error"}>จำนวนเหรียญไม่เพียงพอ</Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>ยกเลิก</Button>
                <Button
                  variant="contained"
                  onClick={handleConfirm}
                  disabled={
                    coachItem[0].price * endTime.diff(startTime, "hour") >
                    userProfile.coin
                  }
                >
                  ยืนยัน
                </Button>
              </DialogActions>
            </Dialog>
          </Container>
        )
      )}
    </div>
  );
};

export default Booking;
