import {
  Container,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../../../editCss/editor.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { addCoachRecord } from "../../../../actions/coachRecord";

const StyledButton = styled(Button)({
  "& .MuiButtonBase-root ": {
    backgroundColor: "#4caf50",
  },
  margin: 10,
  fontWeight: 600,
  backgroundColor: "#39f",
  color: "#ffffff",
});

const DisplayEditor = styled("div")({
  cursor: "pointer",
  border: "0.5px solid #b8b8b8",
  borderRadius: "5px",
  height: 150,
});

const DisplayEditor2 = styled("div")({
  cursor: "pointer",
  border: "0.5px solid #b8b8b8",
  borderRadius: "5px",
  height: 80,
});

const Meeting = () => {
  // const history = useHistory();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: book } = useSelector((state) => state.book);

  const [editorStateGap, setEditorStateGap] = useState(
    EditorState.createEmpty()
  );
  const [editorStateNote, setEditorStateNote] = useState(
    EditorState.createEmpty()
  );
  const [editorStateNext, setEditorStateNext] = useState(
    EditorState.createEmpty()
  );
  const [showGap, setShowGap] = useState(null);
  const [showNote, setShowNote] = useState(null);
  const [showNext, setShowNext] = useState(null);
  const [openGap, setOpenGap] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [openNext, setOpenNext] = useState(false);
  const [mixData, setMixData] = useState({
    goal: "",
    reality: "",
    opportunity: "",
    wrapUp: "",
  });

  const submitGap = () => {
    setShowGap(editorStateGap);
    setOpenGap(false);
  };
  const submitNote = () => {
    setShowNote(editorStateNote);
    setOpenNote(false);
  };
  const submitNext = () => {
    setShowNext(editorStateNext);
    setOpenNext(false);
  };

  const onEditorStateChangeGap = (editorState) => {
    setEditorStateGap(editorState);
  };
  const onEditorStateChangeNote = (editorState) => {
    setEditorStateNote(editorState);
  };
  const onEditorStateChangeNext = (editorState) => {
    setEditorStateNext(editorState);
  };

  const handleOpenGap = () => {
    setOpenGap(!openGap);
    setEditorStateGap(showGap);
  };
  const handleOpenNote = () => {
    setOpenNote(!openNote);
    setEditorStateNote(showNote);
  };
  const handleOpenNext = () => {
    setOpenNext(!openNext);
    setEditorStateNext(showNext);
  };

  const onChangeMixData = (event) => {
    let newMixData = { ...mixData };
    const data = event.target.value;
    const name = event.target.name;
    newMixData[name] = data;
    setMixData(newMixData);
  };

  const handleSubmit = async () => {
    const res = await dispatch(
      addCoachRecord({
        ...mixData,
        idCoach: currentUser.id,
        value: book[0].totalPrice,
        idBooking: book[0].idBooking,
      })
    );
    if (res) {
      navigate("/coaching/coach-history");
    }
  };

  useEffect(() => {
    if(book[0].idCoach !== currentUser.id) {
      navigate("/coaching", { replace: true })
    }
  }, []);

  return (
    <>
      {book && (
        <div className={`page`}>
          <Container maxWidth="lg">
            <Paper style={{ padding: 20 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Interference / Issue / Gap
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <DisplayEditor onClick={() => handleOpenGap()}>
                    <Editor
                      readOnly={true}
                      editorState={showGap}
                      toolbarHidden
                    />
                  </DisplayEditor>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 50 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="goal"
                        name="goal"
                        variant="outlined"
                        label="Goal"
                        fullWidth
                        multiline
                        rows={15}
                        onChange={onChangeMixData}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="reality"
                        name="reality"
                        variant="outlined"
                        label="Reality"
                        fullWidth
                        multiline
                        rows={15}
                        onChange={onChangeMixData}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="opportunity"
                        name="opportunity"
                        variant="outlined"
                        label="Opportunity"
                        fullWidth
                        multiline
                        rows={15}
                        onChange={onChangeMixData}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="wrapUp"
                        name="wrapUp"
                        variant="outlined"
                        label="Wrap up"
                        fullWidth
                        multiline
                        rows={15}
                        onChange={onChangeMixData}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginTop: 40 }}
                  >
                    Note
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <DisplayEditor2 onClick={() => handleOpenNote()}>
                    <Editor
                      readOnly={true}
                      editorState={showNote}
                      toolbarHidden
                    />
                  </DisplayEditor2>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginTop: 40 }}
                  >
                    What next / Homework
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <DisplayEditor2 onClick={() => handleOpenNext()}>
                    <Editor
                      // wrapperClassName={classes.note}
                      readOnly={true}
                      editorState={showNext}
                      toolbarHidden
                    />
                  </DisplayEditor2>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <div style={{ whiteSpace: "nowrap", float: "right" }}>
                    {/* <StyledButton
                        variant="contained"
                        style={{
                          margin: 10,
                          fontWeight: 600,
                          backgroundColor: "#39f",
                          color: "#ffffff",
                        }}
                      >
                        Save as draft
                      </StyledButton> */}
                    <Button
                      variant="contained"
                      style={{
                        margin: 10,
                        fontWeight: 600,
                        backgroundColor: "#4caf50",
                        color: "#ffffff",
                      }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Container>
          {/* Dialog */}
          <Dialog
            open={openGap}
            onClose={handleOpenGap}
            aria-labelledby="interference-form"
          >
            <DialogTitle id="interference-form">
              Interference / Issue / Gap
            </DialogTitle>
            <DialogContent>
              <Editor
                editorState={editorStateGap}
                onEditorStateChange={onEditorStateChangeGap}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleOpenGap}
                style={{
                  fontWeight: 600,
                  backgroundColor: "#f50057",
                  color: "#ffffff",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={submitGap}
                style={{
                  fontWeight: 600,
                  backgroundColor: "#4caf50",
                  color: "#ffffff",
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>{" "}
          <Dialog
            open={openNote}
            onClose={handleOpenNote}
            aria-labelledby="Note-form"
          >
            <DialogTitle id="Note-form">Note</DialogTitle>
            <DialogContent>
              <Editor
                editorState={editorStateNote}
                onEditorStateChange={onEditorStateChangeNote}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleOpenNote}
                style={{
                  fontWeight: 600,
                  backgroundColor: "#f50057",
                  color: "#ffffff",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={submitNote}
                style={{
                  fontWeight: 600,
                  backgroundColor: "#4caf50",
                  color: "#ffffff",
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>{" "}
          <Dialog
            open={openNext}
            onClose={handleOpenNext}
            aria-labelledby="interference-form"
          >
            <DialogTitle id="interference-form">
              What next / Homework
            </DialogTitle>
            <DialogContent>
              <Editor
                editorState={editorStateNext}
                onEditorStateChange={onEditorStateChangeNext}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleOpenNext}
                style={{
                  fontWeight: 600,
                  backgroundColor: "#f50057",
                  color: "#ffffff",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={submitNext}
                style={{
                  fontWeight: 600,
                  backgroundColor: "#4caf50",
                  color: "#ffffff",
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default Meeting;
