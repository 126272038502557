import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { styled } from '@mui/material/styles';
import * as dayjs from 'dayjs'

import {
  Divider,
  Grid,
  Typography,
  Card,
  TextField,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Fab,
  ListItem,
  List,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListSubheader,
  Container,
  Alert,
  Snackbar,
  Box,
  Stack,
} from "@mui/material";
import AvatarGroup from "@mui/material/AvatarGroup";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveIcon from "@mui/icons-material/Remove";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Fragment } from "react";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import SaveIcon from "@mui/icons-material/Save";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import bgCrate from "../assets/bgCrate.svg";
import Me from "../assets/me.gif";
import { getAllEmployees } from "../../../../../actions/user";
import { addAppraisee } from "../../../../../actions/appraisee";
import { getAssessment } from "../../../../../actions/assessment";

const StyleDivider = styled(Divider)({
  margin: "10px 0",
})

const StyleListSubheader = styled(ListSubheader)(({ type }) => {
  const listMaster = {
    borderRadius: 6,
    textTransform: "uppercase",
    color: "#FFFFFF",
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 4,
  }
 
  switch(type) {
    case "boss":
      return { backgroundColor: "#ff3034", ...listMaster }
    case "peer":
      return { backgroundColor: "#ff8d00", ...listMaster }
    case "project":
      return { backgroundColor: "#ffc700", ...listMaster }
    case "subordinate":
      return { backgroundColor: "#00bdb1", ...listMaster }
    case "customer":
      return { backgroundColor: "#008dd1", ...listMaster }
    case "crossfunction":
      return { backgroundColor: "#7d55c2", ...listMaster }
    default :
      return listMaster
  }
})

const ItemQuestion = styled('div')({
    display: "flex",
    alignItems: "center",
    padding: 8,
})

const Section = styled('div')({
  marginTop: 16,
})

const WrapButtonAddQuestion = styled('div')({
  display: "flex",
  justifyContent: "flex-end",
})

const WrapCenterLeftRightSeletedPerson = styled('div')({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
})

const WrapPerson = styled('div')({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
})

const WrapCalculation = styled('div')({
  display: "flex",
  justifyContent: "space-around",
  border: "1px solid #CDCDCD",
  borderRadius: 4,
  padding: 16,
})

const WrapSelectedPersonList = styled(List)({
  backgroundColor: "#FFFFFF",
  position: "relative",
  overflow: "auto",
  maxHeight: 540,
})

const StyleAccordionSummary = styled(AccordionSummary)({
  backgroundImage: `url(${bgCrate})`,
  "& .MuiAccordionSummary-content": {
    margin: "16px 0",
  },
})

const accordionSummaryIcon = {  
  color: "#272727",
  position: "absolute",
  fontSize: 60,
  top: -15,
  left: -35,
}
const StyleLooksOneIcon = styled(LooksOneIcon)(accordionSummaryIcon)
const StyleLooksTwoIcon = styled(LooksTwoIcon)(accordionSummaryIcon)
const StyleLooks3Icon = styled(Looks3Icon)(accordionSummaryIcon)

const AccordionSummaryText = styled(Typography)({
  marginLeft: 40,
  color: "#FFFFFF",
})

const StyleAccordionDetails = styled(AccordionDetails)({
  width: "100%",
  padding: 16,
})

const ListSection = styled('li')({
  backgroundColor: "inherit",
})

const Ul = styled('ul')({
  backgroundColor: "inherit",
  padding: 0,
})

const BtnSave = styled('div')({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: 16,
})

const StyledRoot = styled('div')({
  display: "flex",
  flexDirection: "column",
  padding: 0,
  marginTop: 40,
  minWidth: 400,
})

const AvatarNull = styled(Card)({
  backgroundColor: "transparent",
  color: "transparent",
  borderColor: "transparent",
})

const InputCalculation = styled(Input)({
    width: 75,
    marginBottom: 8,
    fontSize: 24,
    fontWeight: 500,
    "& .MuiInputBase-input": {
      padding: "3px 0",
      textAlign: "center",
    },
})

const StyleBtnMaster = styled(Fab)(({ group }) => {
    const btnMaster = {
      minWidth: 100,
      color: "#FFFFFF",
      height: 40,
      fontSize: 16,
    }

    switch(group) {
      case "boss":
        return { backgroundColor: "#ff3034", "&:hover": { backgroundColor: "#c8292c" }, ...btnMaster }
      case "peer":
        return { backgroundColor: "#ff8d00", "&:hover": { backgroundColor: "#d38e39" }, ...btnMaster }
      case "crossfunction":
        return { backgroundColor: "#7d55c2", "&:hover": { backgroundColor: "#483170" }, ...btnMaster }
      case "customer":
        return { backgroundColor: "#008dd1", "&:hover": { backgroundColor: "#025780" }, ...btnMaster }
      case "subordinate":
        return { backgroundColor: "#00bdb1", "&:hover": { backgroundColor: "#027c74" }, ...btnMaster }
      case "project":
        return { backgroundColor: "#ffc700", "&:hover": { backgroundColor: "#c59b02" }, ...btnMaster }
      default :
        return  btnMaster
    }
})

const TextPercent = styled(InputAdornment)({
  "& .MuiTypography-colorTextSecondary": {
    color: "#000000DE",
  },
})

const StyledAvatar = styled(Avatar)({
  width: 48, 
  height: 48, 
  marginRight: 24
})

const DivRoot = styled('div')({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
})

function summarySamplesizeCalculation(calculationAll) {
  var summary = 0;
  for (let calculation of Object.values(calculationAll)) {
    summary += calculation;
  }
  return summary;
}

function checkNull(obj) {
  for (var key in obj) {
    if (obj[key] !== null) return false;
  }
  return true;
}

const Calculation = ({
  group,
  sampleSizeCalculation,
  personList,
  handleClick,
  handleBlur,
  handleChangeCalculation,
}) => {
  return (
    <DivRoot>
      <div style={{ display: "flex" }}>
        <InputCalculation
          value={sampleSizeCalculation.value}
          onChange={handleChangeCalculation}
          onBlur={() => handleBlur(group)}
          name={group}
          startAdornment={
            <TextPercent position="start">
              %
            </TextPercent>
          }
          disabled={
            sampleSizeCalculation.value === 0 &&
            sampleSizeCalculation.summary >= 100
          }
          inputProps={{
            step: 5,
            min: 0,
            max: 100,
            type: "number",
            "aria-labelledby": "input-slider",
          }}
        />
      </div>

      <StyleBtnMaster
        variant="extended"
        aria-label="add"
        onClick={() => handleClick(group)}
        disabled={sampleSizeCalculation.value === 0}
        group={group}
      >
        {group === "crossfunction" ? "cross function" : group}
      </StyleBtnMaster>
      <div style={{ marginTop: 8 }}>
        <AvatarGroup max={4}>
          {personList ? (
            personList.map((value, index) => {
              return (
                <Avatar
                  key={index}
                  alt={value.username}
                  src={`${process.env.REACT_APP_URL}image/profile/${value.image}`}
                />
              );
            })
          ) : (
            <AvatarNull>
              <RemoveIcon />
            </AvatarNull>
          )}
        </AvatarGroup>
      </div>
    </DivRoot>
  );
};
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));

export default function EditAssessment360() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [assessment, setAssessment] = React.useState({
    appraisee: null,
    startDate: dayjs(),
    endDate: dayjs().add(1, 'day'),
    sampleSize: {
      boss: 0,
      peer: 0,
      project: 0,
      subordinate: 0,
      customer: 0,
      crossfunction: 0,
    },
    selected: {
      boss: null,
      peer: null,
      project: null,
      subordinate: null,
      customer: null,
      crossfunction: null,
    },
  });
  const [selectGroup, setSelectGroup] = React.useState(null);
  const [openSelectAppraisee, setOpenSelectAppraisee] = React.useState(false);
  const [openSelectPerson, setOpenSelectPerson] = React.useState(false);
  const [selectedPersonList, setSelectedPersonList] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [displaySnackbar, setDisplaySnackbar] = useState("");

  const dispatch = useDispatch();
  const { result: assessmentItem } = useSelector((state) => state.assessmentItem);
  const { result: persons } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getAssessment(parseInt(id)));
    dispatch(getAllEmployees());
  }, []); 

  const [checked, setChecked] = React.useState([]);

  const handleOpenSnackbar = (value) => {
    setDisplaySnackbar(value)
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOpenSelectAppraisee = () => {
    setOpenSelectAppraisee(true);
  };

  const handleCloseSelectAppraisee = () => {
    setOpenSelectAppraisee(false);
  };

  const handleChangeCalculation = (event) => {
    let newValue = { ...assessment };
    newValue.sampleSize[event.target.name] =
      event.target.value === "" ? 0 : Number(event.target.value);
    let summary = summarySamplesizeCalculation(newValue.sampleSize);
    if (summary > 100) {
      newValue.sampleSize[event.target.name] = 0;
    }

    setAssessment(newValue);
  };

  const handleBlur = (name) => {
    let newValue = { ...assessment };
    if (newValue.sampleSize[name] < 0) {
      newValue.sampleSize[name] = 0;
    } else if (newValue.sampleSize[name] > 100) {
      newValue.sampleSize[name] = 100;
    }

    setAssessment(newValue);
  };

  const handleChangeDateSample = (name, date) => {
    const newValue = { ...assessment, [name]: date };
    setAssessment(newValue);
  };

  const handleChangeSelectedAppraisee = (event, value) => {
    let newValue = { ...assessment };
    newValue.appraisee = value
    //set new select
    newValue.selected['boss'] = null
    newValue.selected['crossfunction'] = null
    newValue.selected['customer'] = null
    newValue.selected['peer'] = null
    newValue.selected['project'] = null
    newValue.selected['subordinate'] = null

    setSelectedPersonList([value.idEmployees])
    setAssessment(newValue);
    handleCloseSelectAppraisee();
  };

  const handleSaveChangeSelectedPerson = () => {
    let newValue = { ...assessment };

    if (newValue.selected[selectGroup] == null) {
      newValue.selected[selectGroup] = checked;
    } else {
      newValue.selected[selectGroup] = [
        ...newValue.selected[selectGroup],
        ...checked,
      ];
    }
  
    setSelectedPersonList([...selectedPersonList, ...checked.map(item => item.idEmployees)]);
    setAssessment(newValue);
    handleCloseSelectPerson();
  };

  const handleClickOpenSelectPerson = (group) => {
    setSelectGroup(group);
    setChecked([]);
    handleOpenSelectPerson();
  };

  const handleCloseSelectPerson = () => {
    setOpenSelectPerson(false);
  };

  const handleOpenSelectPerson = () => {
    setOpenSelectPerson(true);
  };

  const handleClickDeleteSelectedPerson = (index, group) => {
    let newValue = { ...assessment };
  
    const person = newValue.selected[group][index]
    newValue.selected[group].splice(index, 1);
    if (newValue.selected[group].length === 0) {
      newValue.selected[group] = null;
    }
    setAssessment(newValue);

    const filtered = selectedPersonList.filter(item => item !== person.idEmployees)
    setSelectedPersonList(filtered)
  };

  const handleSaveAssessment = async () => {
    //each selected field have
    const calculationAll = assessment.sampleSize
    const selectedAll = assessment.selected
    const datePass = assessment.startDate < assessment.endDate
    let appraiserPass = true
    for (let property in calculationAll) {
      if(calculationAll[property] > 0 && selectedAll[property] === null) {
        appraiserPass = false 
        break;
      }
    }
    //sumary100%
    const sumaryPass = summarySamplesizeCalculation(assessment.sampleSize) === 100
    const appraiseePass = assessment.appraisee !== null

    if(appraiseePass && 
      datePass && 
      appraiserPass && 
      sumaryPass) {
      const res = await dispatch(
        addAppraisee({
          ...assessment,
          appraisee: assessment.appraisee.idEmployees,
          idAssessment: parseInt(id),
        })
      )
     if(res) {
        navigate("/admin/assessment360")
      }
    } else {
      if(!appraiseePass) {
        handleOpenSnackbar('โปรดเลือกผู้ถูกประเมิน')
      } else if(!datePass) {
        handleOpenSnackbar('โปรดเลือกวันที่ให้ถูกต้อง')
      } else if(!sumaryPass) {
        handleOpenSnackbar('โปรดเพิ่มอัตราส่วนให้ครบ 100%')
      } else if(!appraiserPass) {
        handleOpenSnackbar('โปรดเลือกผู้ประเมินให้ครบ')
      }
    }
  }

  const randerSelectedPersonList = () => {
    const output = Object.keys(assessment.selected)
      .filter(
        (key) =>
          assessment.selected[key] !== null && assessment.selected[key].length > 0
      )
      .map((key, index) => (
        <ListSection key={`section-${key}`}>
          <Ul>
            <StyleListSubheader
              type={key}
            >
              <Fragment>
                <Typography color={"#FFFFFF"}>
                  {key === "crossfunction" ? "cross function" : key}
                  {` (${assessment.selected[key].length})`}
                </Typography>
              </Fragment>
            </StyleListSubheader>
            {assessment.selected[key].map((item, indexItem) => (
              <ListItem key={`item-${key}-${indexItem}`}>
                <ListItemAvatar>
                  <Avatar
                    alt={`${item.username}`}
                    src={`${process.env.REACT_APP_URL}image/profile/${item.image}`}
                  />
                </ListItemAvatar>
                <ListItemText primary={`${item.firstname_TH} ${item.lastname_TH}`} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    style={{ color: "#a2a2a2" }}
                    onClick={() => handleClickDeleteSelectedPerson(indexItem, key)}
                    size="large"
                  >
                    <CancelIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </Ul>
        </ListSection>
      ));
    return output;
  };

  return (
    <StyledRoot className={'page'}>
      <Typography variant="h4" align="center" marginBottom={4}>
        แบบประเมิน 360 องศา
      </Typography>
      <Container maxWidth={"lg"}>
        {assessmentItem &&
          <Typography variant="h6">
            {`ชื่อชุดคำถาม: ${assessmentItem[0].assessmentName}`}
          </Typography>
        }
        <StyleDivider />
        <>
          <Accordion style={{ marginBottom: 8 }}>
            <StyleAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div style={{ position: "relative" }}>
                <StyleLooksOneIcon />
                <AccordionSummaryText
                  variant="h6"
                >
                  Select Appraisee
                </AccordionSummaryText>
              </div>
            </StyleAccordionSummary>
            <StyleAccordionDetails>
              <div style={{ width: "100%" }}>
                <div style={{ marginBottom: 16 }}>
                  {assessment.appraisee &&
                    <div>
                      <ItemQuestion>
                        <Typography variant="h6">
                          {`${assessment.appraisee.firstname_TH} ${assessment.appraisee.lastname_TH}`}
                        </Typography>
                      </ItemQuestion>
                    </div>  
                  }
                </div>
                <WrapButtonAddQuestion>
                  <Button
                    variant="outlined"
                    size="medium"
                    color="primary"
                    startIcon={<AddIcon />}
                    style={{ backgroundColor: "#0161cd", color: "#FFFFFF" }}
                    onClick={handleOpenSelectAppraisee}
                  >
                    Select appraisee
                  </Button>
                </WrapButtonAddQuestion>
              </div>
            </StyleAccordionDetails>
          </Accordion>
          <Accordion style={{ marginBottom: 8 }}>
            <StyleAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div style={{ position: "relative" }}>
                <StyleLooksTwoIcon />
                <AccordionSummaryText
                  variant="h6"
                >
                  Select Appraisee
                </AccordionSummaryText>
              </div>
            </StyleAccordionSummary>
            <StyleAccordionDetails>
              <Stack direction="row" spacing={2}>
                <DatePicker
                  label="Start Date"
                  value={assessment.startDate}
                  // onChange={(e, ve) handleChangeDateSample("startDate")}
                  minDate={dayjs()}
                  onChange={(newValue) => handleChangeDateSample("startDate", newValue)}
                  // slotProps={{
                  //   textField: {
                  //     error: !!errors.openRegisterDate,
                  //     helperText: errors.openRegisterDate?.message,
                  //   },
                  // }}
                />
                <DatePicker
                  label="End Date"
                  value={assessment.endDate}
                  // onChange={handleChangeDateSample("endDate")}
                  onChange={(newValue) => handleChangeDateSample("endDate", newValue)}
                  minDate={dayjs(assessment.startDate).add(1, 'day')}
                  // slotProps={{
                  //   textField: {
                  //     error: assessment.startDate >= assessment.endDate,
                  //     helperText: assessment.startDate >= assessment.endDate && "End date has to be after start date",
                  //   },
                  // }}
                />
              </Stack>
            </StyleAccordionDetails>
          </Accordion>
          <Accordion style={{ marginBottom: 8 }}>
            <StyleAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div style={{ position: "relative" }}>
                <StyleLooks3Icon />
                <AccordionSummaryText
                  variant="h6"
                >
                  Sample Size
                </AccordionSummaryText>
              </div>
            </StyleAccordionSummary>
            <StyleAccordionDetails>
              <Section style={{ width: "100%", overflow: "auto" }}>
                <Grid container spacing={2} style={{}}>
                  <Grid
                    item
                    xs={12}
                    sm={checkNull(assessment.selected) ? 12 : 8}
                  >
                    <WrapCalculation>
                      <WrapCenterLeftRightSeletedPerson>
                        <Calculation
                          group="crossfunction"
                          sampleSizeCalculation={{
                            value:
                              assessment.sampleSize["crossfunction"],
                            summary: summarySamplesizeCalculation(
                              assessment.sampleSize
                            ),
                          }}
                          personList={assessment.selected["crossfunction"]}
                          handleClick={handleClickOpenSelectPerson}
                          handleBlur={handleBlur}
                          handleChangeCalculation={handleChangeCalculation}
                        />
                        <Calculation
                          group="customer"
                          sampleSizeCalculation={{
                            value:
                              assessment.sampleSize["customer"],
                            summary: summarySamplesizeCalculation(
                              assessment.sampleSize
                            ),
                          }}
                          personList={assessment.selected["customer"]}
                          handleClick={handleClickOpenSelectPerson}
                          handleBlur={handleBlur}
                          handleChangeCalculation={handleChangeCalculation}
                        />
                      </WrapCenterLeftRightSeletedPerson>
                      <div>
                        <Calculation
                          group="boss"
                          sampleSizeCalculation={{
                            value:
                              assessment.sampleSize["boss"],
                            summary: summarySamplesizeCalculation(
                              assessment.sampleSize
                            ),
                          }}
                          personList={assessment.selected["boss"]}
                          handleClick={handleClickOpenSelectPerson}
                          handleBlur={handleBlur}
                          handleChangeCalculation={handleChangeCalculation}
                        />
                        <WrapPerson>
                          <div style={{ width: "100%", margin: "8px 0" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                align="center"
                                color="primary"
                                style={{ fontWeight: 500 }}
                              >
                                {summarySamplesizeCalculation(
                                  assessment.sampleSize
                                )}
                              </Typography>
                              <Typography
                                align="center"
                                color="textSecondary"
                              >
                                {"/100%"}
                              </Typography>
                            </div>
                            <BorderLinearProgress
                              variant="determinate"
                              value={summarySamplesizeCalculation(
                                assessment.sampleSize
                              )}
                            />
                          </div>

                          <img width={150} src={Me} alt="me" />
                        </WrapPerson>
                        <div>
                          <Calculation
                            group="subordinate"
                            sampleSizeCalculation={{
                              value:
                                assessment.sampleSize["subordinate"],
                              summary: summarySamplesizeCalculation(
                                assessment.sampleSize
                              ),
                            }}
                            personList={assessment.selected["subordinate"]}
                            handleClick={handleClickOpenSelectPerson}
                            handleBlur={handleBlur}
                            handleChangeCalculation={
                              handleChangeCalculation
                            }
                          />
                        </div>
                      </div>
                      <WrapCenterLeftRightSeletedPerson>
                        <Calculation
                          group="peer"
                          sampleSizeCalculation={{
                            value:
                              assessment.sampleSize["peer"],
                            summary: summarySamplesizeCalculation(
                              assessment.sampleSize
                            ),
                          }}
                          personList={assessment.selected["peer"]}
                          handleClick={handleClickOpenSelectPerson}
                          handleBlur={handleBlur}
                          handleChangeCalculation={handleChangeCalculation}
                        />
                        <Calculation
                          group="project"
                          sampleSizeCalculation={{
                            value:
                              assessment.sampleSize["project"],
                            summary: summarySamplesizeCalculation(
                              assessment.sampleSize
                            ),
                          }}
                          personList={assessment.selected["project"]}
                          handleClick={handleClickOpenSelectPerson}
                          handleBlur={handleBlur}
                          handleChangeCalculation={handleChangeCalculation}
                        />
                      </WrapCenterLeftRightSeletedPerson>
                    </WrapCalculation>
                  </Grid>
                  {!checkNull(assessment.selected) && (
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        {checkNull(assessment.selected)}
                      </Typography>
                      <WrapSelectedPersonList
                        subheader={<li />}
                      >
                        {randerSelectedPersonList()}
                      </WrapSelectedPersonList>
                    </Grid>
                  )}
                </Grid>
              </Section>
            </StyleAccordionDetails>
          </Accordion>
          <BtnSave>
            <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<SaveIcon />}
                onClick={handleSaveAssessment}
              >
                save
            </Button>
          </BtnSave>
        </>
      </Container>

      <Dialog
        open={openSelectPerson}
        onClose={handleCloseSelectPerson}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { maxHeight: 400 } }}
      >
        <DialogTitle id="alert-dialog-title">{"เลือกผู้ประเมิน"}</DialogTitle>
        <DialogContent>
          <div>
            <Typography
              variant="h6"
              style={{ textTransform: "uppercase", fontWeight: 400 }}
              gutterBottom
            >
              {selectGroup === "crossfunction" ? "cross Function" : selectGroup}{" "}
              :
            </Typography>
            {persons && 
              <Autocomplete
                multiple
                options={persons.filter(item => !selectedPersonList.includes(item.idEmployees))}
                value={checked}
                onChange={(event, newValue) => {
                  setChecked(newValue)
                }}
                isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                disableCloseOnSelect
                getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                sx={{ minWidth: 400 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select" />
                )}
              />
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSelectPerson} color="primary">
            {" "}
            cancel{" "}
          </Button>
          <Button onClick={handleSaveChangeSelectedPerson} color="primary">
            {" "}
            save{" "}
          </Button>
        </DialogActions>
      </Dialog>
      {persons && 
        <Dialog
          open={openSelectAppraisee}
          onClose={handleCloseSelectAppraisee}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">{"เลือกผู้ถูกประเมิน"}</DialogTitle>
          <DialogContent>
            <div>
              <Typography
                variant="h6"
                style={{ textTransform: "uppercase", fontWeight: 400 }}
                gutterBottom
              >
              Select Appraisee :
              </Typography>
              <Autocomplete
                autoHighlight
                options={persons}
                value={assessment.appraisee}
                onChange={(event, newValue) => {
                  handleChangeSelectedAppraisee(event, newValue)
                }}
                isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                getOptionLabel={(option) =>
                  `${option.firstname_TH} ${option.lastname_TH}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="ค้นหาพนักงาน"
                    variant="outlined"
                  />
                )}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSelectAppraisee} color="primary">
              {" "}
              close{" "}
            </Button>
          </DialogActions>
        </Dialog>
      }
      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {displaySnackbar}
        </Alert>
      </Snackbar>
    </StyledRoot>
  );
}
