import {
  Container,
  Grid,
  Paper,
  Avatar,
  Typography,
  Fab,
  Rating,
  Button,
  Divider,
  Stack,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";

import dayjs from "dayjs";

import { MainCoin } from "../../shared/typeCoin";
import { NavLink, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCoachReviewByIdCoach } from "../../../../actions/coachReview";
import { getCoachProfile } from "../../../../actions/user";

const StyledAvatar = styled(Avatar)({
  height: 100,
  width: 100,
  borderBlockColor: "black",
});

const Main = styled(Paper)(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(4),
  border: "1px solid #DFDFDF",
}));

const MainCenter = styled("div")(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(4),
  },
}));

const SeconContent = styled("div")(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(4),
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const ViewProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: coachReview } = useSelector((state) => state.coachReview);
  const { result: coachProfile } = useSelector((state) => state.coachProfile);

  useEffect(() => {
    dispatch(getCoachReviewByIdCoach(id))
    dispatch(getCoachProfile(id))
  }, []);

  return (
    <div className={`page`}>
      {coachProfile && (
        <Container maxWidth="lg">
          <Main>
            <Grid
              container
              padding={1}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={3} md={2}>
                <Stack justifyContent={"center"} alignItems={"center"}>
                  <StyledAvatar
                    // src={`${process.env.REACT_APP_API_URL}image/profile/${profile.image}`}
                    src={coachProfile[0].image}
                  />
                  {coachProfile[0].rating && (
                    <Stack direction={"row"} spacing={1} marginTop={4}>
                      <Rating
                        style={{ alignItems: "center" }}
                        value={coachProfile[0].rating}
                        readOnly
                        size="small"
                        precision={0.1}
                      />
                      <Typography>{coachProfile[0].rating}</Typography>
                    </Stack>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={5} md={7}>
                <MainCenter>
                  <Typography variant="h4" component="h2">
                    {`${coachProfile[0].firstname_TH} ${coachProfile[0].lastname_TH}`}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: "18px",
                      color: "#3F3F3F",
                    }}
                  >
                    {coachProfile[0].positionName}
                  </Typography>
                  <Typography variant="h6" marginTop={3}>
                    ติดต่อ
                  </Typography>
                  <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                    <Chip
                      color="info"
                      label={coachProfile[0].telephoneMobile}
                      icon={<PhoneIcon />}
                    />
                    <Chip
                      color="info"
                      label={coachProfile[0].emailAddressBusiness}
                      icon={<MailOutlineIcon />}
                    />
                    {/* <Fab
                      variant="extended"
                      size="medium"
                      style={{
                        backgroundColor: "#3967A2",
                        textDecoration: "none",
                      }}
                      aria-label="tel"
                      value={userProfile.telephoneMobile}
                      disabled
                    >
                      <PhoneIcon style={{ color: "white", marginRight: 8 }} />
                      <Typography color={"white"} variant="body2">
                        {userProfile.telephoneMobile}
                      </Typography>
                    </Fab>
                    <Fab
                      variant="extended"
                      size="medium"
                      style={{
                        backgroundColor: "#3967A2",
                        textDecoration: "none",
                      }}
                      aria-label="email"
                      value={userProfile.emailAddressBusiness}
                      disabled
                    >
                      <MailOutlineIcon
                        style={{ color: "white", marginRight: 8 }}
                      />
                      <Typography color={"white"} variant={"body2"}>
                        {userProfile.emailAddressBusiness}
                      </Typography>
                    </Fab> */}
                  </Stack>
                </MainCenter>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                {coachProfile[0].price && (
                  <SeconContent style={{ justify: "center" }}>
                    <Typography
                      variant="h6"
                      component="p"
                      style={{ textAlign: "center" }}
                    >
                      ค่าสอน/ชั่วโมง
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#3967A2",
                        margin: "0px 40px 0px 40px",
                        borderRadius: 20,
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="p"
                        style={{ color: "#ffffff", marginRight: 8 }}
                      >
                        {coachProfile[0].price}
                      </Typography>
                      <MainCoin width={24} />
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: 20,
                      }}
                    >
                      {coachProfile[0].idCoach !== currentUser.id &&
                        coachProfile[0].idCoach != null && (
                          <Button
                            variant="contained"
                            style={{
                              color: "#fff",
                              backgroundColor: "#4caf50",
                            }}
                            to={`/coaching/coach-booking/${coachProfile[0].idCoach}`}
                            component={NavLink}
                          >
                            Book
                          </Button>
                        )}
                    </div>
                  </SeconContent>
                )}
              </Grid>
            </Grid>
          </Main>
          <Main style={{ marginTop: 50 }}>
            <MainCenter>
              <Typography variant="h5" component="h6" gutterBottom>
                Profile
              </Typography>
              <StyledDivider />
              <Typography variant="subtitle1" component="p">
                {coachProfile[0].coachProfile}
              </Typography>
            </MainCenter>
          </Main>
          <Main>
            <MainCenter>
              <Typography variant="h5" component="h6" gutterBottom>
                Experience
              </Typography>
              <StyledDivider />
              <Typography variant="subtitle1" component="p">
                {coachProfile[0].coachExperience}
              </Typography>
            </MainCenter>
          </Main>
          <Main style={{ marginTop: 50 }}>
            <MainCenter>
              <Typography variant="h5" component="h6" gutterBottom>
                Education
              </Typography>
              <StyledDivider />
              {coachProfile[0].education &&
                coachProfile[0].education.map((item, idy) => (
                  <div key={idy}>
                    <Grid container direction="row">
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        style={{ paddingBottom: "20px" }}
                      >
                        <Typography
                          variant="subtitle2"
                          component="h6"
                          style={{ color: "#4A4A4A" }}
                          gutterBottom
                        >
                          Education level
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          {item.educationLevel}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        style={{ paddingBottom: "20px" }}
                      >
                        <Typography
                          variant="subtitle2"
                          style={{ color: "#4A4A4A" }}
                          gutterBottom
                        >
                          Major
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          {item.major}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        style={{ paddingBottom: "20px" }}
                      >
                        <Typography
                          variant="subtitle2"
                          fontSize={16}
                          style={{ color: "#4A4A4A" }}
                          gutterBottom
                        >
                          Institute
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          {item.institute}
                        </Typography>
                      </Grid>
                    </Grid>
                    <StyledDivider />
                  </div>
                ))}
            </MainCenter>
          </Main>
          {coachReview.length > 0 && (
            <Main>
              <MainCenter>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography component="h6" variant="h5" gutterBottom>
                      Review
                    </Typography>
                    <StyledDivider />
                  </Grid>
                  <Grid item xs={12}>
                    {coachReview.map((val, idx) => {
                      return (
                        <div key={idx}>
                          {/* {val.article.map((reviewer, idy) => ( */}
                          {/* <div key={idy}> */}
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            style={{ marginBottom: 50 }}
                          >
                            <Grid item xs={12} sm={1}>
                              <Avatar
                                size="small"
                                // src={`${
                                //   process.env.REACT_APP_URL
                                // }image/profile/${allUser
                                //   .filter(
                                //     (item) => item.id === reviewer.fromId
                                //   )
                                //   .map((img) => {
                                //     return img.image;
                                //   })
                                //   .toString()}`}
                                src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                              />
                            </Grid>
                            <Grid item xs={12} sm={9}>
                              <Typography variant="h6" gutterBottom>
                                {`${val.firstname_TH} ${val.lastname_TH}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <Rating
                                // style={{ float: "right" }}
                                value={val.rating}
                                readOnly
                                size="small"
                                precision={0.5}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Typography gutterBottom marginLeft={"5%"}>
                                {`review: ${val.review}`}
                              </Typography>
                            </Grid>
                            {/* <Grid item xs={12} md={11}>
                                        <Grid container>
                                          <Grid item xs={12}>
                                            <Grid container>
                                              <Grid item xs={12} md={8}>
                                                <Typography
                                                  variant="h6"
                                                  gutterBottom
                                                  style={{ margin: 0 }}
                                                >
                                                  {allUser
                                                    .filter(
                                                      (item) =>
                                                        item.id == reviewer.fromId
                                                    )
                                                    .map((name) => {
                                                      return (
                                                        name.firstname_TH +
                                                        " " +
                                                        name.lastname_TH
                                                      );
                                                    })}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={12} md={4}>
                                                <Rating
                                                  style={{ float: "right" }}
                                                  value={reviewer.rating}
                                                  readOnly
                                                  size="small"
                                                  precision={0.5}
                                                />
                                              </Grid>
                                            </Grid>
                                            <Typography
                                              variant="subtitle2"
                                              gutterBottom
                                              style={{
                                                margin: 0,
                                                color: "#5B5B5B",
                                              }}
                                            >
                                              {dayjs(reviewer.date).format(
                                                "DD/MM/YYYY"
                                              )}
                                            </Typography>

                                            {reviewer.like ? (
                                              <Typography
                                                variant="subtitle1"
                                                gutterBottom
                                                style={{
                                                  margin: 0,
                                                  color: "#1EC600",
                                                }}
                                              >
                                                <ThumbUpAltIcon
                                                  size="small"
                                                  style={{ fontSize: 18 }}
                                                />{" "}
                                                I recommend the consectetur
                                              </Typography>
                                            ) : (
                                              <Typography
                                                variant="subtitle1"
                                                gutterBottom
                                                style={{
                                                  margin: 0,
                                                  color: "#DE0000",
                                                }}
                                              >
                                                {reviewer.dislike && (
                                                  <ThumbDownAltIcon
                                                    size="small"
                                                    style={{ fontSize: 18 }}
                                                  />
                                                )}
                                                {reviewer.dislike &&
                                                  " I not recommend the consectetur"}
                                              </Typography>
                                            )}
                                            <Typography
                                              variant="subtitle1"
                                              style={{
                                                marginTop: 15,
                                                fontSize: 14,
                                              }}
                                              gutterBottom
                                            >
                                              {reviewer.review}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={6} md={3}>
                                            <Typography
                                              variant="subtitle1"
                                              style={{
                                                color: "#0093FF",
                                                fontWeight: 550,
                                                fontSize: 14,
                                                textShadow: "0.2px 0.2px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <ReplyAllIcon
                                                style={{ fontSize: 16 }}
                                              />{" "}
                                              {"  "}
                                              Reply
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} md={9}>
                                            <Button
                                              variant="outlined"
                                              style={{
                                                float: "right",
                                                borderColor: "#DE0000",
                                              }}
                                              size="small"
                                            >
                                              <ThumbDownAltIcon
                                                style={{
                                                  fontSize: 14,
                                                  color: "#DE0000",
                                                  marginRight: 5,
                                                }}
                                              />{" "}
                                              {"  "} No
                                            </Button>
                                            <Button
                                              variant="outlined"
                                              style={{
                                                float: "right",
                                                borderColor: "#1EC600",
                                                marginRight: 10,
                                              }}
                                              size="small"
                                            >
                                              <ThumbUpAltIcon
                                                style={{
                                                  fontSize: 14,
                                                  color: "#1EC600",
                                                  marginRight: 5,
                                                }}
                                              />
                                              Yes
                                            </Button>
                                          </Grid>
                                          <Grid item xs={12}>
                                            {reviewer.commend.map((com, idz) => (
                                              <div key={idz}>
                                                <Grid container>
                                                  <Grid item xs={1}>
                                                    <Avatar
                                                      size="small"
                                                      src={`${
                                                        process.env.REACT_APP_URL
                                                      }image/profile/${allUser
                                                        .filter(
                                                          (item) =>
                                                            item.id === com.fromId
                                                        )
                                                        .map((img) => {
                                                          return img.image;
                                                        })}`}
                                                    />
                                                  </Grid>
                                                  <Grid item xs={11}>
                                                    <Typography
                                                      variant="h6"
                                                      gutterBottom
                                                      style={{
                                                        marginTop: 5,
                                                        fontSize: 16,
                                                      }}
                                                    >
                                                      {allUser
                                                        .filter(
                                                          (item) =>
                                                            item.id ==
                                                            reviewer.fromId
                                                        )
                                                        .map((name) => {
                                                          return (
                                                            name.firstname_TH +
                                                            " " +
                                                            name.lastname_TH
                                                          );
                                                        })}
                                                      <br />
                                                      <Typography
                                                        style={{
                                                          fontSize: 12,
                                                          color: "#5B5B5B",
                                                        }}
                                                      >
                                                        {dayjs(com.date).format(
                                                          "DD/MM/YYYY"
                                                        )}
                                                      </Typography>
                                                    </Typography>
                                                    <Typography
                                                      style={{
                                                        fontSize: 12,
                                                        marginTop: 15,
                                                        color: "#5B5B5B",
                                                      }}
                                                    >
                                                      {com.recommend}
                                                    </Typography>
                                                    <Typography
                                                      variant="subtitle2"
                                                      style={{
                                                        color: "#0093FF",
                                                        fontWeight: 600,
                                                        textShadow: "0.2px 0.2px",
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <ReplyAllIcon
                                                        style={{
                                                          fontSize: 14,
                                                        }}
                                                      />{" "}
                                                      {"  "}
                                                      Reply
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              </div>
                                            ))}
                                          </Grid>
                                        </Grid>
                                      </Grid> */}
                          </Grid>
                          <StyledDivider />
                          {/* </div> */}
                          {/* ))} */}
                        </div>
                      );
                    })}
                  </Grid>
                </Grid>
              </MainCenter>
            </Main>
          )}
        </Container>
      )}
    </div>
  );
};

export default ViewProfile;
