import { httpClient } from "./httpClient";

const addBooking = (formData) => {
  return httpClient.post("booking", formData);
};

const updateBooking = (formData) => {
  return httpClient.patch("booking", formData);
};

const getBookingByIdCoachee = (idCoachee) => {
  return httpClient.get("booking-coachee/" + idCoachee);
};

const getBookingByIdCoach = (idCoach) => {
  return httpClient.get("booking-coach/" + idCoach);
};

// const getAllBookingByIdCoach = (idCoach) => {
//   return httpClient.get("all-booking-coach/" + idCoach);
// };

// const getApproveBookingByIdCoach = (idCoach) => {
//   return httpClient.get("approve-booking-coach/" + idCoach);
// };

export default {
  addBooking,
  updateBooking,
  getBookingByIdCoachee,
  getBookingByIdCoach,
  // getAllBookingByIdCoach,
  // getApproveBookingByIdCoach,
};
