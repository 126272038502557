import { httpClient } from "./httpClient";

const getCoachRecord = (id) => {
  return httpClient.get("coach-record/" + id);
};

const addCoachRecord = (formData) => {
  return httpClient.post("coach-record", formData);
};

const updateCoachRecord = (formData) => {
  return httpClient.patch("coach-record", formData);
};

export default {
  getCoachRecord,
  addCoachRecord,
  updateCoachRecord,
};
