import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Typography, Avatar, Chip, Container } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getBookingByIdCoachee } from "../../../../actions/book";
import dayjs from "dayjs";
import CoachingStatus from "../../shared/coachingStatus/CoachingStatus";

const StyledRoot = styled("div")({
  paddingTop: 100,
  height: "100%",
});

const WrapDataGrid = styled("div")({
  height: 640,
  width: "100%",
});

const StyledDataGrid = styled(DataGrid)({
  "&.MuiDataGrid-root": {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      border: "none",
    },
    "& .MuiDataGrid-cell": {
      border: "none",
    },
  },
  "& .super-app-theme--header": {
    backgroundColor: "#f4f6f8",
    color: "#637381",
  },
});

const StyledChipTime = styled(Chip)(({ theme }) => ({
  justifyContent: "left",
  color: "#ffffff",
  backgroundColor: theme.palette.warning.main,
}));

const ApproveBooking = () => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: bookCoachee } = useSelector((state) => state.bookCoachee);

  useEffect(() => {
    dispatch(getBookingByIdCoachee(currentUser.id));
  }, []);

  const rowsData = () => {
    return bookCoachee
      // .filter((item) => item.bookingStatus === "approve")
      .map((item) => ({
        id: item.idBooking,
        name: `${item.firstname_TH} ${item.lastname_TH}`,
        bookDate: `${dayjs(item.bookDate).format("DD MMM YYYY HH:mm")}`,
        date: `${dayjs(item.startTime).format("DD MMM YYYY")}`,
        time: `${dayjs(item.startTime).format("HH:mm")} - ${dayjs(
          item.endTime
        ).format("HH:mm")}`,
        status: item.bookingStatus,
        image: "1.jpg",
      }));
  };

  const columns = [
    {
      field: "name",
      headerName: "ชื่อนาม-สกุล",
      flex: 3,
      minWidth: 280,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={`${process.env.REACT_APP_API_URL}image/profile/${params.row.image}`}
            />
            <Typography style={{ marginLeft: 8 }}>{params.row.name}</Typography>
          </div>
        );
      },
    },
    {
      field: "bookDate",
      headerName: "วันที่จอง",
      type: "date",
      flex: 1,
      minWidth: 160,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => new Date(params.row.bookDate),
      renderCell: (params) => {
        return (
          <div>
            <Typography style={{ fontWeight: 600, fontSize: 14 }}>
              {dayjs(params.row.bookDate).format("DD MMM YYYY ")}
            </Typography>
            <Typography style={{ fontSize: 14 }} color="text.third">
              {dayjs(params.row.bookDate).format("HH:mm")}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "วันที่เรียน",
      type: "date",
      flex: 1,
      minWidth: 160,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => new Date(params.row.date),
      renderCell: (params) => {
        return (
          <Typography>
            {dayjs(params.row.date).format("DD MMM YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "time",
      headerName: "เวลาเรียน",
      type: "date",
      flex: 1,
      minWidth: 160,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => new Date(params.row.startTime),
      renderCell: (params) => {
        return (
          <StyledChipTime
            label={`${dayjs(params.row.startTime).format("HH:mm")} - ${dayjs(
              params.row.endTime
            ).format("HH:mm")} `}
          />
        );
      },
      filterable: false,
    },
    {
      field: "status",
      headerName: "สถานะ",
      flex: 1,
      minWidth: 160,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return <CoachingStatus status={params.value} />;
      },
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <Typography variant="h4" marginBottom={2}>
          การโค้ชที่ได้รับอนุมัติ
        </Typography>
        {bookCoachee && (
          <WrapDataGrid>
            <StyledDataGrid
              rows={rowsData()}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 100]}
            />
          </WrapDataGrid>
        )}
      </Container>
    </StyledRoot>
  );
};

export default ApproveBooking;
