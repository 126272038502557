import React, { useState } from "react";
import {
  Badge,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  styled,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SearchIcon from "@mui/icons-material/Search";
import HeaderPage from "../../shared/header/headerPage";
import { StyledDividerHeader } from "../../shared/dividerHeader";
import RewardTypeFilter from "../../shared/rewardTypeFilter";
import CardReward from "./cardReward";
import { Link } from "react-router-dom";
import { useUniRewardCart } from "../../../../quries/cart";
import { useUniRewards } from "../../../../services/quries";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

function UniReward() {
  const { data: uniReward } = useUniRewards();
  const uniRewardCart = useUniRewardCart();

  const [valueSearch, setValueSearch] = useState("");
  const [filterName, setFilterName] = useState("");
  const [rewardType, setRewardType] = useState(0);
 
  const handleChangeSearch = (event) => {
    setValueSearch(event.target.value);
  };

  const handleClickSearch = () => {
    setFilterName(valueSearch);
  };

  const handleChangeRewardType = (value) => {
    setRewardType(value);
  };

  const handleMouseDownSearch = (event) => {
    event.preventDefault();
  };

  if (uniRewardCart.isPending) {
    return <CircularProgress />
  }

  return (
    <StyledRoot className={"page"}>
      {uniReward &&(
        <>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <HeaderPage textLabel={"ของรางวัล UNI"} />
            <IconButton component={Link} to={'cart'}>
              <Badge badgeContent={uniRewardCart?.data?.length} color="primary">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
          </Stack>
          <StyledDividerHeader />
          <Stack
            direction={{ xs: "column", lg: "row" }}
            alignItems={"flex-end"}
            marginTop={2}
            marginBottom={2}
            spacing={2}
          >
            <RewardTypeFilter
              rewardType={rewardType}
              handleChangeRewardType={handleChangeRewardType}
            />
            <FormControl>
              <OutlinedInput
                type="search"
                value={valueSearch}
                onChange={handleChangeSearch}
                placeholder="Search Reward"
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton
                      onClick={handleClickSearch}
                      onMouseDown={handleMouseDownSearch}
                      edge="start"
                      size="large"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleClickSearch();
                  }
                }}
              />
            </FormControl>
            {/* <IconButton onClick={handleOpen}>
              <Badge badgeContent={rewardCart.length} color="error">
                <ShoppingCartIcon fontSize="large" />
              </Badge>
            </IconButton> */}
          </Stack>
          <Grid container spacing={3}>
            {uniReward
              .filter((value) => {
                return value.name
                  .toLowerCase()
                  .includes(filterName.toLowerCase());
              })
              .filter(
                (item) => rewardType === 0 || item.idRewardType === rewardType
              )
              .map((value) => {
                return (
                  <Grid item xs={12} sm={6} md={4}>
                    <CardReward key={value.idReward} data={value} />
                  </Grid>
                );
              })}
          </Grid>
        </>
      )}
    </StyledRoot>
  );
}

export default UniReward;
