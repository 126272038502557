import { httpClient } from "./httpClient";

const getAllMoraleKPI = () => {
  return httpClient.get("morale-kpi");
};

const getMoraleKPI = ({ idMorale, ...query }) => {
  return httpClient.get(`morale-kpi/${idMorale}`, { params: query });
};

const getMoraleCompare = () => {
  return httpClient.get("morale-compare");
};

const getAllMoraleKPIYear = (idCompany) => {
  return httpClient.get("morale-kpi-year", { params: { idCompany } });
};

const getMoraleKPIYear = ({ idMorale, ...query }) => {
  return httpClient.get(`morale-kpi-year/${idMorale}`, { params: query });
};

export default {
  getAllMoraleKPI,
  getMoraleKPI,
  getMoraleCompare,
  getAllMoraleKPIYear,
  getMoraleKPIYear,
};
