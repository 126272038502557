import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  Typography,
  Avatar,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Container,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { getBookingByIdCoach, updateBooking } from "../../../../actions/book";
import dayjs from "dayjs";

const StyledRoot = styled("div")({
  paddingTop: 100,
  height: "100%",
});

const WrapDataGrid = styled("div")({
  height: 640,
  width: "100%",
});

const StyledDataGrid = styled(DataGrid)({
  "&.MuiDataGrid-root": {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      border: "none",
    },
    "& .MuiDataGrid-cell": {
      border: "none",
    },
  },
  "& .super-app-theme--header": {
    backgroundColor: "#f4f6f8",
    color: "#637381",
  },
});

const StyledChipTime = styled(Chip)(({ theme }) => ({
  justifyContent: "left",
  color: "#ffffff",
  backgroundColor: theme.palette.warning.main,
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: "left",
  "& .icon": {
    color: "inherit",
  },
  "&.book": {
    color: theme.palette.info.main,
    border: `1px solid ${theme.palette.info.main}`,
  },
  "&.approve": {
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
  },
  "&.reject": {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
  "&.success": {
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
  },
}));

const Status = (props) => {
  const { status } = props;
  let icon = null;

  if (status === "book") {
    icon = <InfoIcon className={"icon"} />;
  } else if (status === "approve") {
    icon = <DoneIcon className={"icon"} />;
  } else if (status === "reject") {
    icon = <CancelIcon className={"icon"} />;
  } else if (status === "success") {
    icon = <DoneAllIcon className={"icon"} />;
  }

  return (
    <StyledChip
      className={status}
      icon={icon}
      label={status}
      variant={"outlined"}
    />
  );
};

const DetailDialog = ({ open, handleClose, type, place, note, totalPrice }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle id="alert-dialog-title">{"Detail"}</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>{`Total price: ${totalPrice}`}</Typography>
        <Typography gutterBottom>{`Type: ${type}`}</Typography>
        <Typography gutterBottom>{`Place: ${place}`}</Typography>
        <Typography gutterBottom>{`Note: ${note}`}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

const BookingSummary = () => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: bookCoach } = useSelector((state) => state.bookCoach);

  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState({
    totalPrice: "",
    type: "",
    place: "",
    note: "",
  });
  const [openOverlap, setOpenOverlap] = useState(false);
  const [dataBook, setDataBook] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(null);

  const handleOpenOverlap = () => {
    setOpenOverlap(true);
  };

  const handleCloseOverlap = () => {
    setOpenOverlap(false);
  };

  const handleClickOpen = (value) => {
    setDetail(value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDetail({
      totalPrice: "",
      type: "",
      place: "",
      note: "",
    });
  };

  useEffect(() => {
    dispatch(getBookingByIdCoach(currentUser.id));
  }, []);

  const handleCheckDateBook = (dataBooking) => {
    const checkDataStart = bookCoach.some((item) =>
      dayjs(dataBooking.startTime).isBetween(
        dayjs(item.startTime),
        dayjs(item.endTime)
      )
    );

    const checkDataEnd = bookCoach.some((item) =>
      dayjs(dataBooking.endTime).isBetween(
        dayjs(item.startTime),
        dayjs(item.endTime)
      )
    );

    const checkBookStart = bookCoach.some((item) =>
      dayjs(item.startTime).isBetween(
        dayjs(dataBooking.startTime),
        dayjs(dataBooking.endTime)
      )
    );

    const checkBookEnd = bookCoach.some((item) =>
      dayjs(item.endTime).isBetween(
        dayjs(dataBooking.startTime),
        dayjs(dataBooking.endTime)
      )
    );

    const checkStart = bookCoach.some((item) =>
      dayjs(item.startTime).isSame(dayjs(dataBooking.startTime))
    );

    const checkEnd = bookCoach.some((item) =>
      dayjs(item.endTime).isSame(dayjs(dataBooking.endTime))
    );
    if (
      !checkDataStart &&
      !checkDataEnd &&
      !checkBookStart &&
      !checkBookEnd &&
      !checkStart &&
      !checkEnd
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleConfirm = async () => {
    const res = await dispatch(updateBooking(dataBook));
    if (res) {
      dispatch(getBookingByIdCoach(currentUser.id));
      handleCloseOverlap();
    }
  };

  const handleEditClick = async (idBooking, idBookingStatus) => {
    const res = await dispatch(updateBooking({ idBooking, idBookingStatus }));
    if (res) {
      dispatch(getBookingByIdCoach(currentUser.id));
    }
  };

  const handleApproveClick = async (
    idBooking,
    idBookingStatus,
    startTime,
    endTime
  ) => {
    const check = handleCheckDateBook({ startTime, endTime });
    if (check) {
      setDataBook({ idBooking, idBookingStatus });
      handleOpenOverlap();
    } else {
      const res = await dispatch(updateBooking({ idBooking, idBookingStatus }));
      if (res) {
        dispatch(getBookingByIdCoach(currentUser.id));
        handleCloseOverlap();
      }
    }
  };

  const handleReject = async () => {
    const res = await dispatch(updateBooking(dataBook));
    if (res) {
      dispatch(getBookingByIdCoach(currentUser.id));
    }
  };

  const handleRejectClick = async (
    idBooking,
    idBookingStatus,
    totalPrice,
    idEmployees
  ) => {
    const res = await dispatch(
      updateBooking({ idBooking, idBookingStatus, totalPrice, idEmployees })
    );
    if (res) {
      dispatch(getBookingByIdCoach(currentUser.id));
    }
  };

  // const rowsData = () => {
  //   return bookCoach.map((item) => ({
  //     id: item.idBooking,
  //     name: `${item.firstname_TH} ${item.lastname_TH}`,
  //     bookDate: `${dayjs(item.bookDate).format("DD MMM YYYY HH:mm")}`,
  //     date: `${dayjs(item.startTime).format("DD MMM YYYY")}`,
  //     time: `${dayjs(item.startTime).format("HH:mm")} ${dayjs(
  //       item.endTime
  //     ).format("HH:mm")}`,
  //     status: item.bookingStatus,
  //     note: item.note ? item.note : "no note",
  //     image: "1.jpg",
  //     totalPrice: item.totalPrice,
  //     idEmployees: item.idEmployees,
  //     startTime: item.startTime,
  //     endTime: item.endTime,
  //     type: item.type,
  //     place: item.place,
  //   }));
  // };

  const rowsData = () => {
    return bookCoach.map((item) => ({
      id: item.idBooking,
      name: `${item.firstname_TH} ${item.lastname_TH}`,
      bookDate: item.bookDate,
      startTime: item.startTime,
      endTime: item.endTime,
      date: item.startTime,
      status: item.bookingStatus,
      note: item.note ? item.note : "no note",
      totalPrice: item.totalPrice,
      idEmployees: item.idEmployees,
      type: item.type,
      place: item.place,
      image: "1.jpg",
    }));
  };

  const columns = [
    {
      field: "name",
      headerName: "ชื่อนาม-สกุล",
      flex: 4,
      minWidth: 320,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={`${process.env.REACT_APP_API_URL}image/profile/${params.row.image}`}
            />
            <Typography style={{ marginLeft: 8 }}>{params.row.name}</Typography>
          </div>
        );
      },
    },
    {
      field: "bookDate",
      headerName: "วันที่จอง",
      type: "date",
      flex: 1.5,
      minWidth: 120,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => new Date(params.row.bookDate),
      renderCell: (params) => {
        return (
          <div>
            <Typography style={{ fontWeight: 600, fontSize: 14 }}>
              {dayjs(params.row.bookDate).format("DD MMM YYYY ")}
            </Typography>
            <Typography style={{ fontSize: 14 }} color="text.third">
              {dayjs(params.row.bookDate).format("HH:mm")}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "วันที่สอน",
      type: "date",
      flex: 1.5,
      minWidth: 120,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => new Date(params.row.date),
      renderCell: (params) => {
        return (
          <Typography>
            {dayjs(params.row.date).format("DD MMM YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "startTime",
      headerName: "เวลาเรียน",
      type: "date",
      flex: 1.5,
      minWidth: 120,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <StyledChipTime
            label={`${dayjs(params.row.startTime).format("HH:mm")} ${dayjs(
              params.row.endTime
            ).format("HH:mm")} `}
          />
        );
      },
      valueGetter: (params) => new Date(params.row.startTime),
      filterable: false,
    },
    {
      field: "status",
      headerName: "สถานะ",
      flex: 1.5,
      minWidth: 130,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return <Status status={params.value} />;
      },
      type: "singleSelect",
      valueOptions: ["book", "approve", "reject"],
      sortable: false,
    },
    {
      field: "detail",
      headerName: "รายละเอียด",
      flex: 1,
      minWidth: 80,
      headerClassName: "super-app-theme--header",
      cellClassName: "actions",
      // getActions: (params) => {
      //   const isBookStatus = params.row.status === "book";
      //   if (isBookStatus) {
      //     return [
            
      //     ];
      //   } else {
      //     return [];
      //   }
      // },
      renderCell: (params) => {
        console.log(params)
        return (
          <Button
            // ref={buttonElement}
            // touchRippleRef={rippleRef}
            variant="contained"
            size="small"
            // Remove button from tab sequence when cell does not have focus
            // tabIndex={hasFocus ? 0 : -1}
            // onKeyDown={(event) => {
            //   if (event.key === ' ') {
            //     // Prevent key navigation when focus is on button
            //     event.stopPropagation();
            //   }
            // }}
            onClick={() =>
              handleClickOpen({
                totalPrice: params.row.totalPrice,
                type: params.row.type,
                place: params.row.place,
                note: params.row.note,
              })
            }
          >
            Detail
          </Button>
        )  
      }
    },
    {
      field: "actions",
      type: "actions",
      headerName: "ตัวเลือก",
      flex: 1,
      minWidth: 80,
      headerClassName: "super-app-theme--header",
      cellClassName: "actions",
      getActions: (params) => {
        const isBookStatus = params.row.status === "book";
        if (isBookStatus) {
          return [
            <GridActionsCellItem
              showInMenu
              label="Book"
              icon={<InfoIcon color="info" />}
              className="textPrimary"
              onClick={() => handleEditClick(params.row.id, 1)}
            />,
            <GridActionsCellItem
              showInMenu
              label="Approve"
              icon={<DoneIcon color="success" />}
              onClick={() =>
                handleApproveClick(
                  params.row.id,
                  2,
                  params.row.startTime,
                  params.row.endTime
                )
              }
            />,
            <GridActionsCellItem
              showInMenu
              label="Reject"
              icon={<CancelIcon color="error" />}
              onClick={() =>
                handleRejectClick(
                  params.row.id,
                  3,
                  params.row.totalPrice,
                  params.row.idEmployees
                )
              }
            />,
          ];
        } else {
          return [];
        }
      },
    },
  ];

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <Typography variant="h4" marginBottom={2}>
          คำขอผู้เรียน
        </Typography>
        {bookCoach && (
          <WrapDataGrid>
            <StyledDataGrid
              rows={rowsData()}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 100]}
            />
          </WrapDataGrid>
        )}
      </Container>
      <DetailDialog
        open={open}
        handleClose={handleClose}
        totalPrice={detail.totalPrice}
        type={detail.type}
        place={detail.place}
        note={detail.note}
      />
      <Dialog open={openOverlap} onClose={handleCloseOverlap} fullWidth>
        <DialogTitle id="alert-dialog-title">
          {"วัน-เวลา ตรงกับตารางสอนก่อนหน้า"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography color={"error"}>
              {`วัน-เวลาซ้ำกับที่ approve ก่อนหน้า ยืนยันที่จะ approve หรือไม่`}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={handleConfirm}>
            ยืนยัน
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseOverlap}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openOverlap} onClose={handleCloseOverlap} fullWidth>
        <DialogTitle id="alert-dialog-title">{"ปฏิเสธรับการสอน"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>{`ดำเนินการปฏิเสธคำขอการเรียน`}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={handleConfirm}>
            ปฏิเสธ
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseOverlap}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
    </StyledRoot>
  );
};

export default BookingSummary;
