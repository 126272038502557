import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { getDepartment } from "../../../../../actions/department";
import { addMoraleDaily } from "../../../../../actions/moraleDaily";
import dayjs from "dayjs";
import { toast } from "sonner";

const DialogPublish = ({ open, handleClose, idQuestion }) => {
  const dispatch = useDispatch();
  const { result: department } = useSelector((state) => state.department);

  const [date, setDate] = useState({
    startDate: dayjs(),
    endDate: dayjs().add(1, "day"),
  });
  const [valueTarget, setValueTarget] = React.useState("company");
  const [selectedDepartment, setSelectedDepartment] = React.useState([]);

  const handleChangeValueTarget = (event) => {
    setValueTarget(event.target.value);
  };

  const handlePublish = async () => {
    const targetPass = (valueTarget === "department" && selectedDepartment.length > 0) || valueTarget === "company";
    const department = selectedDepartment.map((item) => item.idDepartment);

    if (!targetPass) {
      toast.error("โปรดเลือกหน่วยงาน")
      return;
    }

    const res = await dispatch(
      addMoraleDaily({
        idMoraledailyQuestion: idQuestion,
        startDate: date.startDate,
        endDate: date.endDate,
        valueTarget,
        selectedDepartment: department,
      })
    );

    handleClose();
    if (res.status === 201) {
      toast.success("ส่งคำถามสำเร็จ")
    } else {
      toast.error("เกิดข้อผิดพลาด")
    }
  };

  useEffect(() => {
    dispatch(getDepartment());
  }, []);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"เลือกกลุ่มเป้าหมาย"}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} marginTop={1} paddingBottom={2}>
          <div style={{ display: "flex", gap: "8px" }}>
            <DateTimePicker
              label="วันที่เริ่มต้น"
              value={date.startDate}
              onChange={(value) => setDate((prev) => ({ ...prev, startDate: value }))}
              format="DD/MM/YYYY HH:mm"
              ampm={false}
              minDate={dayjs()}
              maxDate={date.endDate}
            />
            <DateTimePicker
              label="วันที่สิ้นสุด"
              value={date.endDate}
              onChange={(value) => setDate((prev) => ({ ...prev, endDate: value }))}
              format="DD/MM/YYYY HH:mm"
              ampm={false}
              minDate={date.startDate}
            />
          </div>
          <FormControl component="fieldset">
            <FormLabel component="legend">กลุ่มเป้าหมาย</FormLabel>
            <RadioGroup
              aria-label="valueTarget"
              name="valueTarget"
              value={valueTarget}
              onChange={handleChangeValueTarget}
              row
            >
              <FormControlLabel
                value="company"
                control={<Radio />}
                label="ทั้งบริษัท"
              />
              <FormControlLabel
                value="department"
                control={<Radio />}
                label="รายหน่วยงาน"
              />
            </RadioGroup>
          </FormControl>
          {valueTarget === "department" && department && (
            <Autocomplete
              multiple
              ListboxProps={{ style: { maxHeight: 320 } }}
              id="fixed-tags-demo-department"
              value={selectedDepartment}
              onChange={(event, newValue) => setSelectedDepartment(newValue)}
              options={department.filter((item) => item.idDepartment)}
              isOptionEqualToValue={(option, value) => option.idDepartment === value.idDepartment}
              getOptionLabel={(option) => option.departmentName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="หน่วยงาน"
                />
              )}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClose}>
          cancel
        </Button>
        <Button variant="contained" color="success" onClick={handlePublish}>
          publish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogPublish;
