import React, { Fragment, useEffect } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import InputField from "../../../shared/inputForm/inputField";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.secondary,
  },
}));

function TableField({ control }) {
  const { replace } = useFieldArray({
    control,
    name: "attributes",
  });

  const attributes = useWatch({
    control,
    name: "attributes",
  });

  const variation = useWatch({
    control,
    name: "variation",
  });

  // console.log(attributes);
  // console.log(variation);

  useEffect(() => {
    const temp = [];
    if (variation) {
      variation[0]?.option.forEach((option_1) => {
        console.log(option_1)
        if (variation[1]) {
          return variation[1]?.option.forEach((option_2) =>
            temp.push({
              existVariationOption: option_1.idVariationOption && option_2.idVariationOption ? true : false,
              option1: option_1.value,
              option2: option_2.value,
              price: "",
              quantity: "",
              sku: "",
            })
          );
        } else {
          temp.push({
            existVariationOption: option_1.idVariationOption ? true : false,
            option1: option_1.value,
            price: "",
            quantity: "",
            sku: "",
          });
        }
      });
      replace(temp);
    }
  }, [variation, replace]);

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          // "& .MuiTableCell-root": {
          //   border: "1px solid black",
          // },
        }}
      >
        <TableHead>
          <TableRow>
            {variation?.map((row) => (
              <StyledTableCell align="center" key={row.value}>
                {row.value}
              </StyledTableCell>
            ))}
            <StyledTableCell align="center">ราคา</StyledTableCell>
            <StyledTableCell align="center">จำนวน</StyledTableCell>
            <StyledTableCell align="center">รหัส SKU</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {variation[0].option.map((option_1, index_1) => (
            // {fields.option.map((option_1, index) => (
            <Fragment key={option_1.value}>
              <TableRow
              // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  align="center"
                  style={{ minWidth: 160 }}
                  rowSpan={variation[1] ? variation[1].option.length + 1 : 2}
                >
                  {option_1.value}
                </TableCell>
              </TableRow>
              {variation[1] ? (
                variation[1].option.map((option_2, index_2) => {
                  const index = index_1 * variation[1].option.length + index_2;
                  return (
                    <TableRow key={`${option_1.value},${option_2.value}`}>
                      <TableCell style={{ minWidth: 160 }} align="center">
                        {option_2.value}
                      </TableCell>
                      <TableCell style={{ minWidth: 160 }} align="center">
                        <InputField
                          control={control}
                          name={`attributes.${index}.price`}
                          type={"number"}
                          // disabled={attributes[index]?.existVariationOption}
                        />
                      </TableCell>
                      <TableCell style={{ minWidth: 160 }} align="center">
                        <InputField
                          control={control}
                          name={`attributes.${index}.quantity`}
                          type={"number"}
                          // disabled={attributes[index]?.existVariationOption}
                        />
                      </TableCell>
                      <TableCell style={{ minWidth: 160 }} align="center">
                        <InputField
                          control={control}
                          name={`attributes.${index}.sku`}
                          type={"text"}
                          // disabled={attributes[index]?.existVariationOption}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell style={{ minWidth: 160 }} align="center">
                    <InputField
                      control={control}
                      name={`attributes.${index_1}.price`}
                      type={"number"}
                      // disabled={attributes[index_1]?.existVariationOption}
                    />
                  </TableCell>
                  <TableCell style={{ minWidth: 160 }} align="center">
                    <InputField
                      control={control}
                      name={`attributes.${index_1}.quantity`}
                      type={"number"}
                      // disabled={attributes[index_1]?.existVariationOption}
                    />
                  </TableCell>
                  <TableCell style={{ minWidth: 160 }} align="center">
                    <InputField
                      control={control}
                      name={`attributes.${index_1}.sku`}
                      type={"text"}
                      // disabled={attributes[index_1]?.existVariationOption}
                    />
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableField;
