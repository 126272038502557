import {
  Button,
  Container,
  Grid,
  TextField,
  InputAdornment,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  IconButton,
  Typography,
  CardMedia,
  Checkbox,
  Card,
  CardActionArea,
  CardContent,
  FormControlLabel,
  Stack,
  ListItemButton,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { getUserProfile } from "../../../../actions/user";
import { useSelector, useDispatch } from "react-redux";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Rating } from "@mui/material";
import { getCategory } from "../../../../actions/category";
import { getAllCoach } from "../../../../actions/coach";
import {
  addFavoriteCoach,
  deleteFavoriteCoach,
} from "../../../../actions/favoriteCoach";
import { Link } from "react-router-dom";

const StyledDrawer = styled(Drawer)({
  width: 240,
  flexShrink: 0,
  // "& .MuiDrawer-paper": {
  //   width: 240,
  // },
});

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(0.5, 0),
}));

const StyledCard = styled(Card)({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
});

const StyledCardMedia = styled(CardMedia)({
  position: "relative",
  height: "100%",
  width: "100%",
  paddingTop: "100%",
});

const SearchCoach = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectCategory, setSelectCategory] = useState(null);
  const [search, setSearch] = useState("");
  const [favorite, setFavorite] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: coach } = useSelector((state) => state.coach);
  const { result: category } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(getAllCoach());
    dispatch(getCategory());
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value.toLocaleLowerCase());
  };

  const handleSelectCategory = (event, select, index) => {
    if (select === selectCategory) {
      setSelectCategory(null);
    } else {
      setSelectCategory(select);
    }
  };

  const handleChangeFavoriteCoach = async (event) => {
    let res = null;
    if (event.target.checked) {
      res = await dispatch(
        addFavoriteCoach({
          idEmployees: currentUser.id,
          idCoach: event.target.value,
        })
      );
    } else {
      res = await dispatch(
        deleteFavoriteCoach({
          idCoach: event.target.value,
          idEmployees: currentUser.id,
        })
      );
    }
    if (res) {
      dispatch(getUserProfile(currentUser.id));
    }
  };

  return (
    <div className={`page`}>
      {userProfile && (
        <Container maxWidth="lg">
          <Typography variant="h4">ค้นหาโค้ช</Typography>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction={"row"} spacing={2}>
              <Button
                aria-haspopup="true"
                variant="outlined"
                startIcon={<FilterAltIcon />}
                onClick={handleClick}
              >
                Job group
              </Button>
              <Button
                startIcon={<FavoriteIcon />}
                variant={favorite ? "contained" : "outlined"}
                color={"error"}
                onClick={() => setFavorite(!favorite)}
              >
                Favorite
              </Button>
            </Stack>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              style={{ margin: 10, float: "right" }}
              onChange={onChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fontSize: "16px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <StyledDivider />
          <Grid container spacing={5} marginTop={0}>
            {coach &&
              coach
                .filter((item) => item.idCoach !== userProfile.idEmployees)
                .filter((item) =>
                  selectCategory != null
                    ? item.categoryList.find(
                        (cate) => cate.idCategory === selectCategory
                      )
                    : {}
                )
                .filter((item) => {
                  return (
                    (item.firstname_TH + " " + item.lastname_TH)
                      .toLocaleLowerCase()
                      .search(search) != -1
                  );
                })
                .filter((item) => {
                  return favorite
                    ? userProfile.favoriteCoach.find(
                        (id) => id === item.idCoach
                      )
                    : item;
                })
                .map((val) => (
                  <Grid item xs={12} sm={6} md={4} xl={3} key={val.idEmployees}>
                    <StyledCard>
                      <CardActionArea component={Link} to={`/coaching/profile/${val.idEmployees}`}>
                        <div>
                          <StyledCardMedia
                            // image={`${process.env.REACT_APP_API_URL}image/profile/${val.image}`}
                            image={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                          >
                            <FormControlLabel
                              label=""
                              control={
                                <Checkbox
                                  checked={
                                    userProfile.favoriteCoach.find(
                                      (id) => id === val.idCoach
                                    )
                                      ? true
                                      : false
                                  }
                                  style={{
                                    position: "absolute",
                                    color: "#d32f2f",
                                    bottom: "88%",
                                    left: "88%",
                                    fontWeight: 20,
                                    backgroundColor: "white",
                                  }}
                                  icon={<FavoriteBorderIcon />}
                                  checkedIcon={<FavoriteIcon />}
                                  name="fovorite"
                                  value={val.idCoach}
                                  onChange={handleChangeFavoriteCoach}
                                />
                              }
                            />
                          </StyledCardMedia>
                        </div>
                        <CardContent>
                          <Typography component="h2" variant="h6">
                            {`${val.firstname_TH} ${val.lastname_TH}`}
                          </Typography>
                          <Typography
                            component="p"
                            variant="subtitle1"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {val.positionName}
                          </Typography>
                          <Rating
                            style={{ right: "2px" }}
                            value={val.rating}
                            readOnly
                            size="small"
                            precision={0.1}
                          />
                          <Typography component="p" variant="subtitle2">
                            {val.rating}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </StyledCard>
                  </Grid>
                ))}
          </Grid>
        </Container>
      )}
      <StyledDrawer variant="persistent" anchor="right" open={open}>
        <List>
          <ListItem style={{ marginTop: 60 }}>
            <ListItemText primary="Select position" />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="close"
                onClick={handleClick}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <br />
          <ListItemButton
            value={null}
            selected={selectCategory === null}
            onClick={() => setSelectCategory(null)}
          >
            <ListItemText primary={"All"} />
          </ListItemButton>
          <StyledDivider />
          {category &&
            category.map((item, idx) => (
              <div key={idx}>
                <ListItemButton
                  value={item.idCategory}
                  selected={selectCategory === item.idCategory}
                  onClick={(event) =>
                    handleSelectCategory(event, item.idCategory, idx)
                  }
                >
                  <ListItemText primary={item.category} />
                </ListItemButton>
                <StyledDivider />
              </div>
            ))}
        </List>
      </StyledDrawer>
    </div>
  );
};

export default SearchCoach;
