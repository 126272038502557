import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Alert,
  Avatar,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Snackbar,
  styled,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const StyledImageDropZone = styled("div")({
  "& .dropzone-announcement": {
    width: 140,
    height: 140,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },
});

const thumb = {
  display: "inline-flex",
  border: "1px solid #eaeaea",
  width: 140,
  height: 140,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const MAX_SIZE = 500000 //500KB

const UploadRewardImage = (props) => {
  const { onChange, acceptFileType, title, error } = props;
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    onChange(acceptedFiles[0]);
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    accept: acceptFileType,
    maxSize: MAX_SIZE,
    onDrop,
    multiple: false,
  });

  const checkFileSize = fileRejections.length > 0 && fileRejections[0].file.size > MAX_SIZE;

  const handleRemove = () => {
    setFiles([]);
    onChange(null);
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          alt={file.name}
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  return (
    <>
      <ImageListItem>
        <StyledImageDropZone className={!!error ? "error" : ""}>
          {thumbs.length === 0 ? (
            <div {...getRootProps({ className: "dropzone-announcement" })}>
              <input {...getInputProps({ onChange })} />
              <Avatar variant="rounded">
                <AddAPhotoIcon />
              </Avatar>
            </div>
          ) : (
            <aside onClick={open}>{thumbs}</aside>
          )}
        </StyledImageDropZone>
        <ImageListItemBar
          title={title}
          actionIcon={
            thumbs.length !== 0 ? (
              <IconButton
                sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                aria-label={`info about`}
                onClick={handleRemove}
              >
                <DeleteIcon />
              </IconButton>
            ) : null
          }
        />
        
      </ImageListItem>
      {checkFileSize && (<p style={{ color: "#d32f2f" }}>ไฟล์มีขนาดเกิน 500 KB</p>)}
      {error && (
        <Snackbar
          open={Boolean(error)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {error?.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default UploadRewardImage;