import {
  QUESTION_TOPIC_FETCHING,
  QUESTION_TOPIC_FAILED,
  QUESTION_TOPIC_SUCCESS,
  QUESTION_FETCHING,
  QUESTION_FAILED,
  QUESTION_SUCCESS,
} from "./types";
import questionTopicService from "../services/questionTopic.service";

export const getAllQuestionTopic = (idCompany) => async (dispatch) => {
  try {
    dispatch({ type: QUESTION_TOPIC_FETCHING });
    const res = await questionTopicService.getAllQuestionTopic(idCompany);
    if (res) {
      dispatch({
        type: QUESTION_TOPIC_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({ type: QUESTION_TOPIC_FAILED });
  }
};

export const addQuestionTopic = (formData) => async (dispatch) => {
  try {
    const res = await questionTopicService.addQuestionTopic(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({ type: QUESTION_TOPIC_FAILED });
  }
};

export const updateQuestionTopic = (formData) => async (dispatch) => {
  try {
    const res = await questionTopicService.updateQuestionTopic(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({ type: QUESTION_TOPIC_FAILED });
    console.log(error);
  }
};

export const deleteQuestionTopic = (id) => async (dispatch) => {
  try {
    const res = await questionTopicService.deleteQuestionTopic(id);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({ type: QUESTION_TOPIC_FAILED });
    console.log(error);
  }
};

export const getAllQuestion = () => async (dispatch) => {
  try {
    dispatch({ type: QUESTION_FETCHING });
    const res = await questionTopicService.getAllQuestion();
    if (res) {
      dispatch({
        type: QUESTION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({ type: QUESTION_FAILED });
    console.log(error);
  }
};

export const addQuestion = (formData) => async (dispatch) => {
  try {
    const res = await questionTopicService.addQuestion(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({ type: QUESTION_FAILED });
    console.log(error);
  }
};