import React, { useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  ImageList,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import HeaderPage from "../../../shared/header/headerPage";
import { StyledDividerHeader } from "../../../shared/dividerHeader";
import TipTap from "../../../shared/tiptapEditor";
import SwitchInput from "../../../shared/inputForm/switchInput";
import UpdateRewardImage from "../../../shared/uploadReward/updateRewardImage";
import { useDispatch, useSelector } from "react-redux";
import {
  getUniReward,
  updateUniReward,
} from "../../../../../actions/uniReward";
import { useNavigate, useParams } from "react-router-dom";
import VariationField from "./variationFiled";

import { DevTool } from "@hookform/devtools";
import TableExistVariation from "./tableExistVariation";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const StyledCard = styled(Card)({
  borderRadius: 8,
});

function EditUniReward() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { result: uniRewardItem } = useSelector((state) => state.uniRewardItem);

  yup.addMethod(yup.array, 'unique', function (message, mapper = a => a) {
    return this.test('unique', message, function (list) {
      return list.length === new Set(list.map(mapper)).size;
    });
  });

  const validationSchema = yup.object().shape({
    name: yup.string().max(100).required(),
    idRewardType: yup.number().required(),
    price: yup.number().when("openVariation", {
      is: false,
      then: (schema) => schema.min(1).max(100000).integer().required(),
    }),
    quantity: yup.number().when("openVariation", {
      is: false,
      then: (schema) => schema.min(1).max(1000).integer().required(),
    }),
    sku: yup.string().when("openVariation", {
      is: false,
      then: (schema) => schema.max(10).required(),
    }),
    mainImage: yup
      .mixed()
      .test(
        "required",
        "โปรดเพิ่มรูปภาพหลัก",
        (value) => value && (value.path || value.image)
      ),
    images: yup.array(),
    openVariation: yup.boolean(),
    variation: yup.array().when("openVariation", {
      is: true,
      then: (schema) =>
        schema.of(
          yup.object().shape({
            name: yup.string().max(15).required("required"),
            option: yup.array().of(
              yup.object().shape({
                value: yup.string().max(20).required("required"),
                image: yup.mixed(),
              })
            ).unique('ข้อมูลตัวเลือกซ้ำ', a => a.value),
          })
        ),
    }),
    attributes: yup.array().when("openVariation", {
      is: true,
      then: (schema) =>
        schema.of(
          yup.object().shape({
            price: yup
              .number()
              .transform((value) => (isNaN(value) ? null : value))
              .nullable()
              .min(1, "must be greater than or equal to 1")
              .integer(),
            quantity: yup
              .number()
              .transform((value) => (isNaN(value) ? null : value))
              .nullable()
              .min(1, "must be greater than or equal to 1")
              .integer(),
            sku: yup.string().max(15, "must be at most 15"),
          })
        ),
    }),
    addOptionImage: yup.boolean(),
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const images = getValues("images");

  const handleEditUniReward = async (data) => {
    console.log(data)
    delete data.items
    const formData = new FormData();
    for (const property in data) {
      if (property === "images") {
        data[property]
          .filter((item) => item instanceof File)
          .forEach((file) => formData.append(property, file));
        formData.append('imagesReward', JSON.stringify(data[property]));
      } else if (Array.isArray(data[property])) {
        formData.append(property, JSON.stringify(data[property]));
      } else {
        formData.append(property, data[property]);
      }
    }

    data["variation"][0].option
      .filter((item) => item.image instanceof File)
      .forEach((file) => formData.append("variationImages", file.image));

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    const res = await dispatch(updateUniReward(formData));
    if (res) {
      navigate(`/uni-team/reward/detail/${id}`);
    }
  };

  useEffect(() => {
    if (uniRewardItem) {
      reset({
        ...uniRewardItem,
        mainImage: { image: uniRewardItem.image },
        images: [
          ...uniRewardItem.images,
          ...Array(4 - uniRewardItem.images.length).fill(''),
        ],
        openVariation: uniRewardItem.options ? true : false,
        variation: uniRewardItem.options,
        attributes: uniRewardItem.items,
      });
    }
  }, [uniRewardItem]);

  useEffect(() => {
    dispatch(getUniReward(id));
  }, []);

  return (
    <StyledRoot className={"page"}>
      <DevTool control={control} />
      <HeaderPage textLabel={"แก้ไขรายละเอียดสินค้า"} />
      <StyledDividerHeader />
      {uniRewardItem && (
        <Container maxWidth="lg">
          <Alert severity="warning" style={{ marginBottom: 16 }}>
            <AlertTitle>คำเตือน</AlertTitle>
            การแก้ไขข้อมูลสินค้าจะส่งผลเปลี่ยนแปลงต่อข้อมูลสินค้าใน
            <strong>ประวัติการซื้อ</strong>
          </Alert>
          <form onSubmit={handleSubmit(handleEditUniReward)}>
            <Stack spacing={2}>
              <StyledCard elevation={4}>
                <CardHeader title="ข้อมูลสินค้า" />
                <CardContent>
                  <Grid container spacing={2} marginTop={0}>
                    <Grid item xs={12}>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="ชื่อสินค้า"
                            fullWidth
                            error={!!errors.name}
                            helperText={errors.name?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>รายละเอียด</InputLabel>
                      <Controller
                        name="detail"
                        control={control}
                        render={({ field }) => (
                          <TipTap
                            detail={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel>ประเภทของรางวัล</InputLabel>
                        <Controller
                          name={"idRewardType"}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              label="ประเภทของรางวัล"
                              error={!!errors.idRewardType}
                            >
                              <MenuItem value={1}>Food</MenuItem>
                              <MenuItem value={2}>Shopping</MenuItem>
                              <MenuItem value={3}>Entertainment</MenuItem>
                              <MenuItem value={4}>Lifestyle</MenuItem>
                              <MenuItem value={5}>Travel</MenuItem>
                              <MenuItem value={6}>Health</MenuItem>
                            </Select>
                          )}
                        />
                        <FormHelperText error>
                          {errors.idRewardType?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="price"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="ราคา"
                            type="number"
                            fullWidth
                            error={!!errors.price}
                            helperText={errors.price?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="quantity"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="จำนวน"
                            type="number"
                            fullWidth
                            error={!!errors.quantity}
                            helperText={errors.quantity?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="sku"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="SKU"
                            fullWidth
                            error={!!errors.sku}
                            helperText={errors.sku?.message}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledCard>
              <StyledCard elevation={4}>
                <CardHeader title={"รูปภาพสินค้า"} />
                <CardContent>
                  <Typography>{"ขนาดไฟล์ภาพต้องไม่เกิน 500 KB"}</Typography>
                  <ImageList cols={5}>
                  <Grid item xs={2}>
                    <Controller
                      name={'mainImage'}
                      control={control}
                      render={({ field }) => (
                        <UpdateRewardImage
                          {...field}
                          acceptFileType={"image/jpeg, image/png, image/webp"}
                          error={errors.mainImage}
                          title={"รูปภาพหลัก"}
                        />
                      )}
                    />
                  </Grid>
                    {images &&
                      images.map((field, index) => (
                        <Grid item xs={2} key={index}>
                          <Controller
                            name={`images.${index}`}
                            control={control}
                            render={({ field }) => (
                              <UpdateRewardImage
                                {...field}
                                acceptFileType={"image/jpeg, image/png, image/webp"}
                                error={errors.images}
                                title={ "รูปภาพเสริม"}
                              />
                            )}
                          />
                        </Grid>
                      ))}
                  </ImageList>
                </CardContent>
              </StyledCard>
              <StyledCard>
                <CardHeader
                  title={"ข้อมูลการขาย *(หากปิดจะทำการลบข้อมูลตัวเลือกของสินค้า)"}
                  action={
                    <SwitchInput
                      name="openVariation"
                      control={control}
                      label={"เพิ่มตัวเลือกสินค้า"}
                    />
                  }
                />
                {watch("openVariation") && (
                  <CardContent>
                    <Typography gutterBottom>
                      ตัวเลือกสินค้าที่มีอยู่แล้ว
                    </Typography>
                    <TableExistVariation items={uniRewardItem.items} />
                    <VariationField {...{ control, errors, items: uniRewardItem.items }} />
                  </CardContent>
                )}
              </StyledCard>
              <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
                <Button type="button" variant="outlined">
                  ยกเลิก
                </Button>
                <Button type="submit" variant="contained">
                  บันทึกการแก้ไข
                </Button>
              </Stack>
            </Stack>
          </form>
        </Container>
      )}
    </StyledRoot>
  );
}

export default EditUniReward;
