import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Button,
  Stack,
} from "@mui/material";

import accounting from "./assets/accounting.jpg";
import admin from "./assets/admin.jpg";
import business from "./assets/business.jpg";
import construction from "./assets/construction.jpg";
import csr from "./assets/csr.jpg";
import digital from "./assets/digital.jpg";
import energy from "./assets/energy.jpg";
import finance from "./assets/finance.jpg";
import ga from "./assets/ga.jpg";
import hr from "./assets/hr.jpg";
import legal from "./assets/legal.jpg";
import logistic from "./assets/logistic.jpg";
import maintenance from "./assets/maintenance.jpg";
import marketing from "./assets/marketing.jpg";
import procurement from "./assets/procurement.jpg";
import production from "./assets/production.jpg";
import qa from "./assets/qa.jpg";
import research from "./assets/research.jpg";
import safety from "./assets/safety.jpg";
import secretary from "./assets/secretary.jpg";
import startup from "./assets/startup.jpg";
import supply from "./assets/supply.jpg";
import technology from "./assets/technology.jpg";

import { getDepartment } from "../../../../actions/department";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import SlideArrow from "../../shared/slideArrow";
import { NormalCard, AbsoluteCard } from "../../shared/Cards";
import { Link } from "react-router-dom";
import { getAllCoach, getCoachPopular } from "../../../../actions/coach";

const StyledRoot = styled("div")({
  marginTop: 8,
});

const StyledGrid = styled(Grid)({
  textAlign: "center",
});

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(8, 0),
}));

const Home = () => {
  const dispatch = useDispatch();
  const { result: coach } = useSelector((state) => state.coach);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: coachPopular } = useSelector((state) => state.coachPopular);

  useEffect(() => {
    dispatch(getAllCoach());
    dispatch(getDepartment());
    dispatch(getCoachPopular());
  }, []);

  const fixCoachMock = [
    {
      id: 4,
      email: "mandeemc@domain.com",
      image: "4.jpg",
      firstName: "Mandeep",
      lastName: "Mccray",
      idDepartment: 5,
      department: "Catalyst & Pilot Plant Production Department",
      position: "Catalyst Production Operator",
      mobileNumber: "095-557-3152",
      workingLocation: "Rayong Site#3",
      rating: 3.9,
      price: 4,
    },
    {
      id: 5,
      email: "vendor@domain.com",
      image: "5.jpg",
      firstName: "Alayna",
      lastName: "Wolfe",
      idDepartment: 2,
      department: "Quality Assurance & Quality Control Department",
      position: "Quality Control Technician - PAL",
      mobileNumber: "085-558-4021",
      workingLocation: "Rayong Site#3",
      rating: 4.9,
      price: 5,
    },
    {
      id: 6,
      email: "anishava@domain.com",
      image: "6.jpg",
      firstName: "Anisha",
      lastName: "Valdez",
      idDepartment: 12,
      department: "Safety, Occupational Health & Environment Department",
      position: "Occupational Health & Safety System Engineer",
      mobileNumber: "085-555-6279",
      workingLocation: "Rayong Site#3",
      rating: 4.0,
      price: 3,
    },
    {
      id: 7,
      email: "aedankn@domain.com",
      image: "7.jpg",
      firstName: "Aedan",
      lastName: "Knights",
      idDepartment: 6,
      department: "HDPE 4 Production",
      position: "HDPE 4 Production Operator",
      mobileNumber: "095-554-4538",
      workingLocation: "Rayong Site#3",
      rating: 4.5,
      price: 7,
    },
    {
      id: 8,
      email: "kaitlabr@domain.com",
      image: "8.jpg",
      firstName: "Kaitlan",
      lastName: "Braun",
      idDepartment: 6,
      department: "HDPE 4 Production",
      position: "HDPE 4 Production Operator",
      mobileNumber: "085-559-9269",
      workingLocation: "Rayong Site#3",
      rating: 4.1,
      price: 5,
    },
  ];

  const popCoachMock = [
    {
      id: 1,
      email: "admin@scg.com",
      image: "1.jpg",
      firstName: "Tapmid",
      lastName: "Humphries",
      idDepartment: 13,
      department: "HR Business Partner",
      position: "Assistant Manager-HR Business Partner",
      mobileNumber: "065-556-3915",
      workingLocation: "Rayong Site#1",
      rating: 3.9,
      price: 4,
    },
    {
      id: 2,
      email: "manager@scg.com",
      image: "2.jpg",
      firstName: "Theodor",
      lastName: "O'Moore",
      idDepartment: 13,
      department: "HR Business Partner",
      position: "Manager-HR Business Partner",
      mobileNumber: "085-550-0949",
      workingLocation: "Rayong Site#1",
      rating: 3.9,
      price: 6,
    },
    {
      id: 3,
      email: "user@scg.com",
      image: "3.jpg",
      firstName: "Shaunna",
      lastName: "Richard",
      idDepartment: 6,
      department: "HDPE 4 Production",
      position: "HDPE 4 Production Operator",
      mobileNumber: "085-558-9673",
      workingLocation: "Rayong Site#3",
      rating: 4.5,
      price: 9,
    },
    {
      id: 4,
      email: "mandeemc@domain.com",
      image: "4.jpg",
      firstName: "Mandeep",
      lastName: "Mccray",
      idDepartment: 5,
      department: "Catalyst & Pilot Plant Production Department",
      position: "Catalyst Production Operator",
      mobileNumber: "095-557-3152",
      workingLocation: "Rayong Site#3",
      rating: 3.9,
      price: 4,
    },
    {
      id: 5,
      email: "vendor@domain.com",
      image: "5.jpg",
      firstName: "Alayna",
      lastName: "Wolfe",
      idDepartment: 2,
      department: "Quality Assurance & Quality Control Department",
      position: "Quality Control Technician - PAL",
      mobileNumber: "085-558-4021",
      workingLocation: "Rayong Site#3",
      rating: 4.9,
      price: 5,
    },
    {
      id: 6,
      email: "anishava@domain.com",
      image: "6.jpg",
      firstName: "Anisha",
      lastName: "Valdez",
      idDepartment: 12,
      department: "Safety, Occupational Health & Environment Department",
      position: "Occupational Health & Safety System Engineer",
      mobileNumber: "085-555-6279",
      workingLocation: "Rayong Site#3",
      rating: 4.0,
      price: 3,
    },
    {
      id: 7,
      email: "aedankn@domain.com",
      image: "7.jpg",
      firstName: "Aedan",
      lastName: "Knights",
      idDepartment: 6,
      department: "HDPE 4 Production",
      position: "HDPE 4 Production Operator",
      mobileNumber: "095-554-4538",
      workingLocation: "Rayong Site#3",
      rating: 4.5,
      price: 7,
    },
    {
      id: 8,
      email: "kaitlabr@domain.com",
      image: "8.jpg",
      firstName: "Kaitlan",
      lastName: "Braun",
      idDepartment: 6,
      department: "HDPE 4 Production",
      position: "HDPE 4 Production Operator",
      mobileNumber: "085-559-9269",
      workingLocation: "Rayong Site#3",
      rating: 4.1,
      price: 5,
    },
  ];

  const jobGroup = [
    { id: 1, name: "Production", image: production },
    { id: 2, name: "Technology & Product Development", image: technology },
    { id: 3, name: "Maintenance", image: maintenance },
    { id: 4, name: "Engineering & Construction", image: construction },
    { id: 5, name: "QA/QC/Lab", image: qa },
    { id: 6, name: "Supply chain", image: supply },
    { id: 7, name: "Logistics", image: logistic },
    { id: 8, name: "Procurement", image: procurement },
    { id: 9, name: "HR", image: hr },
    { id: 10, name: "GA & Facility Management", image: ga },
    { id: 11, name: "Admin & Office Support", image: admin },
    { id: 12, name: "Finance", image: finance },
    { id: 13, name: "Accounting", image: accounting },
    { id: 14, name: "Sales & Marketing", image: marketing },
    {
      id: 15,
      name: "Business Development/ Analyst/ Planning",
      image: business,
    },
    { id: 16, name: "Start up & New Business", image: startup },
    { id: 17, name: "Safety & ESG", image: safety },
    { id: 18, name: "Research", image: research },
    { id: 19, name: "Digital & IT", image: digital },
    { id: 20, name: "Secretary", image: secretary },
    { id: 21, name: "Brand & CSR", image: csr },
    { id: 22, name: "Legal & Compliance", image: legal },
    { id: 23, name: "Energy & EV", image: energy },
  ];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 1,
    nextArrow: <SlideArrow icon={<KeyboardArrowRightIcon />} />,
    prevArrow: <SlideArrow icon={<KeyboardArrowLeftIcon />} />,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <StyledRoot className={"page"}>
      {userProfile && (
        <Container maxWidth="lg">
          {userProfile.idCoach === null && (
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <Button
                component={Link}
                to={'/coaching/register-coach'}
                color="secondary"
                variant="contained"
              >
                Coach Register
              </Button>
            </Stack>
          )}
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <StyledGrid item xs={12}>
              <Typography variant="h4">Fix Coach</Typography>
            </StyledGrid>
            <StyledGrid item xs={12}>
              <Typography variant="subtitle1">
                This is your line of coaches when you join us.
              </Typography>
            </StyledGrid>
            <Grid item xs={12}>
              <Swiper>
                {userProfile &&
                  coach &&
                  fixCoachMock.map((val) => (
                    <SwiperSlide>
                      <NormalCard
                        value={val}
                        tagType={"fix-coach"}
                        key={val.idEmployees}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <StyledDivider />
            </Grid>
            <StyledGrid item xs={12} style={{ marginTop: 30 }}>
              <Typography variant="h4">Popular Coach</Typography>
            </StyledGrid>
            <StyledGrid item xs={12}>
              <Typography variant="subtitle1">
                Do you want to move on next step? Choose your most popular
                leaning mentors, it will help you to achieve your professional
                goals.
              </Typography>
            </StyledGrid>
            <Grid item xs={12}>
              <Swiper>
                {coachPopular &&
                  popCoachMock.map((val, idx) => (
                    <SwiperSlide>
                      <NormalCard
                        key={idx}
                        value={val}
                        tagType={"popular-coach"}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <StyledDivider />
            </Grid>
            <StyledGrid item xs={12} style={{ marginTop: 30 }}>
              <Typography variant="h4">เลือกตามกลุ่มงาน</Typography>
            </StyledGrid>
            <StyledGrid item xs={12}>
              <Typography variant="subtitle1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt
              </Typography>
            </StyledGrid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={5}
                direction="row"
                justifyContent="center"
              >
                {jobGroup.map((val) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={val.id}>
                    <AbsoluteCard value={val} tagType={"position-card"} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </StyledRoot>
  );
};

export default Home;
