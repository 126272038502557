import React from "react";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useController } from "react-hook-form";

function SwitchInput({ control, name, label, disable }) {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <FormGroup>
      <FormControlLabel
        {...field}
        checked={field.value}
        control={<Switch />}
        label={label ?? ""}
        disabled={disable}
      />
    </FormGroup>
  );
}

export default SwitchInput;
