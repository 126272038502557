import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import InputField from "../../../../shared/inputForm/inputField";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BackspaceIcon from "@mui/icons-material/Backspace";
import "./tiptap.css";
import { useCoinType } from "../../../../../../quries/coin";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import DateInputField from "../../../../shared/inputForm/dateInputField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { httpClient } from "../../../../../../services/httpClient";
import { useLocation } from "react-router-dom";
import SwitchInput from "../../../../shared/inputForm/switchInput";

function DialogConfig({ open, handleClose, selectReward }) {
  const { state } = useLocation()
  const queryClient = useQueryClient()
  const { data: coinType, isPending } = useCoinType();

  const { mutate } = useMutation({
    mutationFn: async (formData) =>
      await httpClient.patch(`reward/${selectReward.idReward}/coin`, formData),
    onSuccess: () => {
      if (state?.value?.idCompanyReward) {
        queryClient.invalidateQueries({
          queryKey: ["rewards", "company-rewards", state?.value?.idCompanyReward, "cost"],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ["rewards", "company-rewards", state?.value?.idUniReward, "cost"],
        });
      }
      toast.success("สำเร็จ")
    },
    onError: () => toast.error("เกิดข้อผิดพลาด"),
    onSettled: () => handleClose()
  });

  const validationSchema = yup.object().shape({
    startDate: yup.date(),
    endDate: yup.date().when("startDate", (startDate) => {
      return yup.date().min(
        dayjs(startDate).add(1, "day"),
        "End date must be later than start date"
      );
    }),
    // active: yup.boolean(),
    coins: yup.array().when("active", {
      is: true,
      then: yup.array()
        .of(
          yup.object({
            idCoinType: yup.string().min(1).required("Please select coin type"),
            amount: yup.number()
              .typeError("Amount must be a number")
              .min(1)
              .integer()
              .required("Please enter coin amount"),
          })
        )
        .min(1),
    }),
    // quantity: yup.number()
    //   .typeError("Quantity must be a number")
    //   .min(0)
    //   .integer()
    //   .required(),
  });

  const defaultValues = {
    coins: selectReward.coins.map((item) => ({
      idCoinType: item.idCoinType,
      amount: item.amount,
    })),
    startDate: selectReward.startDate ? dayjs(selectReward.startDate) : dayjs(),
    endDate: selectReward.endDate ? dayjs(selectReward.endDate) : dayjs().add(1, 'day'),
    isSetDate: selectReward.startDate || selectReward.endDate ? true : false,
  };

  const {
    control,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "coins",
  });

  const handleConfigReward = (values) => {
    const formData = {
      coins: values.coins,
      startDate: values.isSetDate ? values.startDate : null,
      endDate: values.isSetDate ? values.endDate : null,
    }
    mutate(formData)
  }

  if (isPending) {
    return <span>Loading...</span>
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>รายละเอียดเหรียญรางวัล</DialogTitle>
      <form onSubmit={handleSubmit(handleConfigReward)}>
        <DialogContent>
          <DialogContentText>
            เพิ่มเงื่อนไขเหรียญให้กับการแลกรางวัลและกำหนดวันที่แลกของรางวัล
          </DialogContentText>
          <Stack marginTop={2} spacing={2}>
            <SwitchInput
              control={control}
              name={"isSetDate"}
              label={"กำหนดวันที่"}
            />
            {watch("isSetDate") && (
              <Stack direction={"row"} spacing={2}>
                <DateInputField
                  control={control}
                  name={"startDate"}
                  label={"วันที่เริ่ม"}
                />
                <DateInputField
                  control={control}
                  name={"endDate"}
                  label={"วันที่สิ้นสุด"}
                  minDate={dayjs(watch("startDate"))}
                />
              </Stack>
            )}
            {fields.map((field, index) => (
              <Stack
                key={field.id}
                direction={"row"}
                alignItems={"flex-start"}
                spacing={1}
              >
                <FormControl fullWidth>
                  <InputLabel>{"เหรียญ"}</InputLabel>
                  <Controller
                    control={control}
                    name={`coins.${index}.idCoinType`}
                    render={({ field }) => (
                      <Select {...field} label={"เหรียญ"}>
                        {coinType.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item.idCoinType}
                            disabled={watch("coins")?.some((coin) => coin.idCoinType === item.idCoinType)}
                          >
                            {item.coinName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <InputField
                  control={control}
                  name={`coins.${index}.amount`}
                  type={"number"}
                  label={"จำนวน"}
                />
                <IconButton
                  type="button"
                  color="error"
                  onClick={() => remove(index)}
                  disabled={fields.length === 1}
                >
                  <BackspaceIcon />
                </IconButton>
              </Stack>
            ))}
          </Stack>
          <Button
            type="button"
            startIcon={<AddCircleIcon />}
            onClick={() => append({ idCoinType: "", amount: "" })}
            disabled={fields.length === coinType.length}
          >
            เพิ่ม
          </Button>
        </DialogContent>
        <DialogActions>
          <Button type="button" color="error" onClick={handleClose}>
            ยกเลิก
          </Button>
          <Button type="submit">
            ยืนยัน
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default DialogConfig;
