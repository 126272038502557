import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import HeaderPage from "../../../shared/header/headerPage";
import { DataGrid } from "@mui/x-data-grid";
import { getAllUserCoin } from "../../../../../actions/user";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import LoadingIcon from "../../../shared/general/loadingIcon";
import { utils, writeFileXLSX } from "xlsx";
import { useOrganization } from "../../../../../quries/company";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 320,
});

const WrapHeader = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const StyledCard = styled(Card)({
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
});

const ManageCoin = () => {
  const dispatch = useDispatch();
  const [companySelected, setCompanySelected] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState({
    departmentName: "All Department",
    idDepartment: 0,
  });
  const [sectionSelected, setSectionSelected] = useState({
    sectionName: "All Section",
    idSection: 0,
  });
  const [employeeSelected, setEmployeeSelected] = useState(null)
  const { result: userCoin, isFetching } = useSelector((state) => state.userCoin);
  const organization = useOrganization();

  const handleChangeEmployee = (value) => {
    setEmployeeSelected(value)
  }

  const exportFile = useCallback(() => {
    const data = userCoin?.reduce((acc, cur) => {
      const employee = {
        employeeID: cur.employeeID, 
        firstname_TH: cur.firstname_TH, 
        lastname_TH: cur.lastname_TH,
        positionName: cur.positionName,
        sectionName: cur.sectionName,
        departmentName: cur.departmentName,
        divisionName: cur.divisionName,
      }

      const temp = Object.assign(employee, ...cur.coins.map(item => ({ [item.coinName]: item.amount })))
      acc.push(temp)
      return acc
    }, [])

    const ws = utils.json_to_sheet(data);
    utils.sheet_add_aoa(ws, [[
      "รหัสพนักงาน",
      "ชื่อ",
      "นามสกุล",
      "ตำแหน่ง",
      "ฝ่าย",
      "ส่วน",
      "แผนก",
      ...userCoin[0].coins.map((item) => item.coinName)
    ]], { origin: "A1" });

    ws["!cols"] = [
      { wch: 10 },
      { wch: 25 },
      { wch: 25 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      ...userCoin[0].coins.map((item) => ({ wch: 10 }))
    ];

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws);
    writeFileXLSX(wb, `เหรียญรางวัลพนักงาน ${companySelected.companyName} ${dayjs().format("DD-MM-YYYY")}.xlsx`);
  }, [companySelected]);

  const coinType = userCoin?.[0]?.coins.map((item, index) => ({
    field: item.coinName,
    headerName: item.coinName,
    headerClassName: 'theme-header',
    type: "number",
    flex: 1,
    filterable: false,
  }));

  let columns = [];
  if (coinType) {
    columns = [
      {
        field: "fullname",
        headerName: "ชื่อ-นามสกุล",
        headerClassName: 'theme-header',
        type: "string",
        flex: 3,
        sortable: false,
        disableExport: true,
        renderCell: (params) => (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Avatar
              alt={"userImage"}
              src={params.row.imageURL}
            />
            <Typography>{params.value}</Typography>
          </div>
        ),
      },
      { 
        field: "positionName",
        headerName: "Position",
        sortable: false,
        headerClassName: 'theme-header'
      },
      { 
        field: "sectionName",
        headerName: "Section",
        sortable: false,
        headerClassName: 'theme-header'
      },
      { 
        field: "departmentName",
        headerName: "Department",
        sortable: false,
        headerClassName: 'theme-header'
      },
      { 
        field: "divisionName", 
        headerName: "Division", 
        sortable: false, 
        headerClassName: 'theme-header' 
      },
      ...coinType,
    ];
  }

  const rows = userCoin
    ?.filter((item) => {
      let isInclude = true;
      if (companySelected) {
        isInclude = companySelected.idDepartment === item.idCompany;
      }
      if (departmentSelected.idDepartment) {
        isInclude = departmentSelected.idDepartment === item.idDepartment;
      }
      if (sectionSelected.idSection) {
        isInclude = sectionSelected.idSection === item.idSection;
      }
      return isInclude;
    })
    ?.map((item) => {
      const temp = item.coins.map((e) => ({ [e.coinName]: e.amount }));
      return Object.assign(
        {
          id: item.idEmployees,
          fullname: `${item.firstname_TH} ${item.lastname_TH}`,
          firstname: item.firstname_TH,
          lastname: item.lastname_TH,
          imageURL: item.imageURL,
          positionName: item.positionName,
          sectionName: item.sectionName,
          departmentName: item.departmentName,
          divisionName: item.divisionName,
        },
        ...temp
      );
    });

  useEffect(() => {
    if (organization.isSuccess) {
      setCompanySelected(organization.data[0])
    }
  }, [organization.isSuccess])

  useEffect(() => {
    if (companySelected) {
      dispatch(getAllUserCoin({ idCompany: companySelected.idCompany }));
    }
  }, [companySelected]);

  return (
    <StyledRoot className={"page"}>
      <WrapHeader>
        <HeaderPage textLabel={"จัดการเหรียญรางวัล"} />
      </WrapHeader>
      <StyledDivider />
      <Container>
        <Grid container alignItems={"center"} spacing={1} marginBottom={4}>
          <Grid item xs={12} sm={12} md={3}>
            <Autocomplete
              disableClearable
              value={companySelected}
              options={organization.data}
              onChange={(event, newValue) => {
                setCompanySelected(newValue);
                setDepartmentSelected({
                  departmentName: "All Department",
                  idDepartment: 0,
                });
                setSectionSelected({ sectionName: "All Section", idSection: 0 });
              }}
              getOptionLabel={(option) => option.companyName}
              isOptionEqualToValue={(option, value) =>
                option.idCompany === value.idCompany
              }
              renderInput={(params) => <TextField {...params} label="Company" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disableClearable
              value={departmentSelected}
              options={
                companySelected
                  ? [
                      { departmentName: "All Department", idDepartment: 0 },
                      ...companySelected.department,
                    ]
                  : []
              }
              onChange={(event, newValue) => setDepartmentSelected(newValue)}
              getOptionLabel={(option) => option.departmentName}
              isOptionEqualToValue={(option, value) =>
                option.idDepartment === value.idDepartment
              }
              renderInput={(params) => <TextField {...params} label="Department" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disableClearable
              value={sectionSelected}
              options={
                companySelected
                  ? [
                      { sectionName: "All Section", idSection: 0 },
                      ...companySelected.section,
                    ]
                  : []
              }
              onChange={(event, newValue) => setSectionSelected(newValue)}
              getOptionLabel={(option) => option.sectionName}
              isOptionEqualToValue={(option, value) =>
                option.idSection === value.idSection
              }
              renderInput={(params) => <TextField {...params} label="Section" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              sx={{ minWidth: "fit-content" }}
              onClick={exportFile}
              startIcon={<FileDownloadIcon />}
            >
              Export
            </Button>
          </Grid>
        </Grid>
        {isFetching ? (
          <LoadingIcon />
        ) : (
          <StyledCard>
            <DataGrid
              rows={rows || []}
              columns={columns}
              rowHeight={80}
              sx={{
                "& .theme-header": {
                  color: "#637381",
                  backgroundColor: "#f4f6f8",
                },
                ".MuiDataGrid-cell": {
                  borderButtom: "0.1px solid #f2f2f2",
                },
                borderRadius: "20px",
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              // slots={{ toolbar: CustomToolbar }}
              // slotProps={{ toolbar: { showQuickFilter: true } }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    firstname: false,
                    lastname: false,
                    positionName: false,
                  },
                },
              }}
            />
          </StyledCard>
        )}
      </Container>
    </StyledRoot>
  );
};

export default ManageCoin;
