import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { styled } from '@mui/material/styles';

import {
  Divider,
  Grid,
  Typography,
  FormControl,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Container,
  Alert,
  Snackbar,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from '@mui/icons-material/Looks4';
import SaveIcon from "@mui/icons-material/Save";

import bgCrate from "../assets/bgCrate.svg";
import questionClose from "../assets/questionClose.png";
import questionOpen from "../assets/questionOpen.png";
import { getAllEmployees } from "../../../../../actions/user";
import { addAssessment } from "../../../../../actions/assessment";

const StyleDivider = styled(Divider)({
  margin: "10px 0",
})

const StyleCard = styled(Card)({
  backgroundColor: "#5FBCED",
  margin: 16,
})

const StyleCardMedia = styled(CardMedia)({
  height: 150,
})

const StyleCardContent = styled(CardContent)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: 0,
})

const CardContentText = styled(Typography)(({ assessmenttype }) => ({
    fontSize: 46,
    color: assessmenttype ? "#FFFFFF" : "#ffffff6b",
}))

const ItemQuestion = styled('div')({
    display: "flex",
    alignItems: "center",
    padding: 8,
})

const TextFieldQuestion = styled(Input)({
  marginLeft: 16,
  width: "100%",
})

const WrapButtonAddQuestion = styled('div')({
  display: "flex",
  justifyContent: "flex-end",
})

const StyleAccordionSummary = styled(AccordionSummary)({
  backgroundImage: `url(${bgCrate})`,
  "& .MuiAccordionSummary-content": {
    margin: "16px 0",
  },
})

const accordionSummaryIcon = {  
  color: "#272727",
  position: "absolute",
  fontSize: 60,
  top: -15,
  left: -35,
}
const StyleLooksOneIcon = styled(LooksOneIcon)(accordionSummaryIcon)
const StyleLooksTwoIcon = styled(LooksTwoIcon)(accordionSummaryIcon)
const StyleLooks3Icon = styled(Looks3Icon)(accordionSummaryIcon)
const StyleLooks4Icon = styled(Looks4Icon)(accordionSummaryIcon)

const AccordionSummaryText = styled(Typography)({
  marginLeft: 40,
  color: "#FFFFFF",
})

const StyleAccordionDetails = styled(AccordionDetails)({
  width: "100%",
  padding: 16,
})


const BtnSave = styled('div')({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: 16,
})

const StyledRoot = styled('div')({
  display: "flex",
    flexDirection: "column",
    padding: 0,
    marginTop: 60,
    minWidth: 400,
})

export default function CreateAssessment360() {
  const dispatch = useDispatch();
  // const history = useHistory();
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  const [assessment, setAssessment] = React.useState({
    assessmentType: null,
    // appraisee: [""],
    assessmentName: "",
    assessmentDescription: "",
    questionAssessment: null,
    scale: 6,
    createBy: userProfile.idEmployees,
    // sampleSize: {
    //   number: 0,
    //   lunchDate: new Date(),
    //   endDate: new Date(),
    //   calculation: {
    //     boss: 0,
    //     peer: 0,
    //     project: 0,
    //     customer: 0,
    //     crossfunction: 0,
    //     subordinate: 0,
    //   },
    //   description: "",
    // },
    // selected: {
    //   boss: null,
    //   peer: null,
    //   project: null,
    //   customer: null,
    //   crossfunction: null,
    //   subordinate: null,
    // },
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleClickSelectType = (value) => {
    let newValue = { ...assessment };
    newValue.assessmentType = value;
    if (newValue.questionAssessment == null) {
      newValue.questionAssessment = [""];
    }
    if(value === 'open') {
      newValue.scale = null;
    }
    else if(value === 'close') {
      newValue.scale = 6;
    }

    setAssessment(newValue);
  };

  const handleChangeAssessmentName = (event) => {
    let newValue = { ...assessment };
    newValue.assessmentName = event.target.value;

    setAssessment(newValue);
  };

  const handleChangeAssessmentDesc = (event) => {
    let newValue = { ...assessment };
    newValue.assessmentDescription = event.target.value;

    setAssessment(newValue);
  };

  const handleChangeQuestion = (index) => (event) => {
    let newValue = { ...assessment };
    newValue.questionAssessment[index] = event.target.value;

    setAssessment(newValue);
  };

  const handleAddNewQuestion = () => {
    let newValue = { ...assessment };
    newValue.questionAssessment.push("");
    setAssessment(newValue);
  };

  const handleClickDeleteQuestion = (index) => {
    let newValue = { ...assessment };
    newValue.questionAssessment.splice(index, 1);
    setAssessment(newValue);
  };

  const handleChangeScale = (event) => {
    let newValue = { ...assessment };
    newValue.scale = parseInt(event.target.value);
    setAssessment(newValue);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOpenConfirm  = () => {
    const passAssessmentName = assessment.assessmentName.trim().length !== 0
    const passAssessmentDesc = assessment.assessmentDescription.trim().length !== 0
    let passQuestion = true
    for(let i=0; i < assessment.questionAssessment.length; i++) {
      if(assessment.questionAssessment[i].trim().length === 0) {
        passQuestion = false
        break;
      }
    }

    if(passAssessmentName && 
      passAssessmentDesc && 
      passQuestion) {
      setOpenConfirm(true);
    } else {
      setOpenSnackbar(true)
    }
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };


  const handleSaveAssessment = async () => {
    const res = await dispatch(addAssessment(assessment));
    if(res) {
      navigate("/admin/manage/assessment360")
    }
  }

  return (
    <StyledRoot className={'page'}>
      <Container maxWidth={"lg"}>
        <div>
          <Typography variant="h4" align="center">
            สร้างชุดคำถาม 360 องศา
          </Typography>
        </div>
        <Grid
          container
          style={{
            margin: "auto",
            marginTop: 30,
            marginBottom: 30,
            maxWidth: 800,
          }}
        >
          <Grid item xs={12} sm={6}>
            <StyleCard>
              <CardActionArea onClick={() => handleClickSelectType("close")}>
                <StyleCardMedia
                  image={questionClose}
                  title="Contemplative Reptile"
                />
                <StyleCardContent>
                  <CardContentText
                    variant="h5"
                    align="center"
                    assessmenttype={assessment.assessmentType === "close" ? "true" : undefined}
                  >
                    คำถามปลายปิด
                  </CardContentText>
                </StyleCardContent>
              </CardActionArea>
            </StyleCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyleCard>
              <CardActionArea onClick={() => handleClickSelectType("open")}>
                <StyleCardMedia
                  style={{
                    maxWidth: 180,
                    margin: "auto",
                  }}
                  image={questionOpen}
                  title="Contemplative Reptile"
                />
                <StyleCardContent>
                  <CardContentText
                    variant="h5"
                    align="center"                 
                    assessmenttype={assessment.assessmentType === "open" ? "true" : undefined}
                  >
                    คำถามปลายเปิด
                  </CardContentText>
                </StyleCardContent>
              </CardActionArea>
            </StyleCard>
          </Grid>
        </Grid>
        <StyleDivider />
        {assessment.questionAssessment && (
          <div style={{ margin: "0 16px" }}>
            <Accordion style={{ marginBottom: 8 }}>
              <StyleAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={{ position: "relative" }}>
                  <StyleLooksOneIcon />
                  <AccordionSummaryText
                    variant="h6"
                  >
                    Assessment Name
                  </AccordionSummaryText>
                </div>
              </StyleAccordionSummary>
              <StyleAccordionDetails>
                <div style={{ width: "100%" }}>
                  <div style={{ marginBottom: 16 }}>
                    <ItemQuestion>
                      <FormControl style={{ width: "100%" }}>
                        <TextFieldQuestion
                          value={assessment.assessmentName}
                          onChange={handleChangeAssessmentName}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                style={{ color: "#a2a2a2" }}
                                aria-label="delete question"
                                onMouseDown={handleMouseDownPassword}
                                size="large"
                              >
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </ItemQuestion>
                  </div>
                </div>
              </StyleAccordionDetails>
            </Accordion>
            <Accordion style={{ marginBottom: 8 }}>
              <StyleAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={{ position: "relative" }}>
                  <StyleLooksTwoIcon />
                  <AccordionSummaryText
                    variant="h6"
                  >
                    Assessment Description
                  </AccordionSummaryText>
                </div>
              </StyleAccordionSummary>
              <StyleAccordionDetails>
                <div style={{ width: "100%" }}>
                  <div style={{ marginBottom: 16 }}>
                    <ItemQuestion>
                      <FormControl style={{ width: "100%" }}>
                        <TextFieldQuestion
                          value={assessment.assessmentDescription}
                          onChange={handleChangeAssessmentDesc}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                style={{ color: "#a2a2a2" }}
                                aria-label="delete question"
                                onMouseDown={handleMouseDownPassword}
                                size="large"
                              >
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </ItemQuestion>
                  </div>
                </div>
              </StyleAccordionDetails>
            </Accordion>
            <Accordion style={{ marginBottom: 8 }}>
              <StyleAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={{ position: "relative" }}>
                  <StyleLooks3Icon />
                  <AccordionSummaryText
                    variant="h6"
                  >
                    Create Questions
                  </AccordionSummaryText>
                </div>
              </StyleAccordionSummary>
              <StyleAccordionDetails>
                <div style={{ width: "100%" }}>
                  <div style={{ marginBottom: 16 }}>
                    {assessment.questionAssessment &&
                      assessment.questionAssessment.map((value, index) => {
                        return (
                          <ItemQuestion key={index}>
                            <Typography>{index + 1}</Typography>
                            <FormControl style={{ width: "100%" }}>
                              <TextFieldQuestion
                                value={value}
                                onChange={handleChangeQuestion(index)}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      style={{ color: "#a2a2a2" }}
                                      aria-label="delete question"
                                      onClick={() =>
                                        handleClickDeleteQuestion(index)
                                      }
                                      onMouseDown={handleMouseDownPassword}
                                      size="large"
                                    >
                                      {index > 0 && <CloseIcon />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </ItemQuestion>
                        );
                      })}
                  </div>
                  <WrapButtonAddQuestion>
                    <Button
                      variant="outlined"
                      size="medium"
                      color="primary"
                      startIcon={<AddIcon />}
                      style={{ backgroundColor: "#0161cd", color: "#FFFFFF" }}
                      onClick={handleAddNewQuestion}
                    >
                      เพิ่มคำถาม
                    </Button>
                  </WrapButtonAddQuestion>
                </div>
              </StyleAccordionDetails>
            </Accordion>
            <BtnSave>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<SaveIcon />}
                onClick={handleOpenConfirm}
              >
                Save
              </Button>
            </BtnSave>
          </div>
        )}
      </Container>
      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          โปรดตั้งชื่อหัวข้อแบบประเมินและเพิ่มคำถาม!
        </Alert>
      </Snackbar>

      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"ยืนยันสร้างชุดคำถาม"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleSaveAssessment}>ยืนยัน</Button>
          <Button onClick={handleCloseConfirm}>ยกเลิก</Button>
        </DialogActions>
      </Dialog>
    </StyledRoot>
  );
}
