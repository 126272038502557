import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import utils from "../../../../utils";
import DialogSuccess from "./dialogSuccess";
import { useNavigate } from "react-router-dom";
import { toast } from 'sonner'
import { useCompanyBudget } from "../../../../quries/company";
import {
  useAddUniRewardOrder,
  useDeleteUniRewardCart,
  useUniRewardCart,
  useUpdateUniRewardCart,
} from "../../../../quries/cart";
import CompanyBudget from "./companyBudget";
import HeaderPage from "../../shared/header/headerPage";
import { useSelector } from "react-redux";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const StyledCard = styled(Card)({
  marginTop: 24,
  border: "1px solid rgba(224, 224, 224, 1)",
});

const ProductImage = styled(Avatar)({
  width: 80,
  height: 80,
  borderRadius: 8,
});

const StyledButton = styled(Button)({
  "& .MuiButton-startIcon": {
    margin: 0,
  },
});

const columns = [
  { id: "name", label: "สินค้า", minWidth: 220 },
  { id: "price", label: "ราคาต่อหน่วย", minWidth: 120, align: "right" },
  { id: "amount", label: "จำนวน", minWidth: 100, align: "center" },
  { id: "totalPrice", label: "ราคารวม", minWidth: 100, align: "right" },
  { id: "action", label: "ลบรายการ", minWidth: 100, align: "center" },
];

function UniRewardCart() {
  const navigate = useNavigate();
  const { result: userProfile } = useSelector(state => state.userProfile);
  const companyBudget = useCompanyBudget({ idCompany: userProfile?.idCompany });
  const uniRewardCart = useUniRewardCart();
  const updateUniRewardCart = useUpdateUniRewardCart();
  const deleteUniRewardCart = useDeleteUniRewardCart();
  const addOrderReward = useAddUniRewardOrder();

  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);

  const handleOpenDialogConfirm = () => {
    setOpenDialogConfirm(true);
  };

  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
  };

  const totalPrice = uniRewardCart?.data?.reduce(
    (prev, curr) => prev + curr.price * curr.quantity,
    0
  );

  const rewardAvailable = uniRewardCart?.data?.some((item) => item.quantity > item.quantityInStock);

  const handleConfirm = async () => {
    const formData =  uniRewardCart.data.map(item => ({
      idUniRewardItem: item.idUniRewardItem,
      quantity: item.quantity,
      price: item.price
    }))
    
    addOrderReward.mutate(
      formData, 
      {
        onSuccess: () => { 
          navigate("/admin/manage/reward")
          toast.success("สำเร็จ")
        },
        onError: () => toast.error("เกิดข้อผิดพลาด"),
        onSettled: () => handleCloseDialogConfirm
      }
    )
  };

  if (companyBudget.isPending || uniRewardCart.isPending) {
    return <CircularProgress color="secondary" />
  }

  return (
    <StyledRoot className={"page"}>
      {uniRewardCart.data && (
        <Container maxWidth="lg">
          <div 
            style={{ 
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
              padding: "16px",
              borderRadius: "16px",
              backgroundColor: "#000000" 
            }}
          >
            <HeaderPage textLabel={"ตะกร้าสินค้า"} color={"#ffffff"} />
            <CompanyBudget />
          </div>
          {uniRewardCart.data.length === 0 ? (
            <Typography variant="h5" textAlign="center">
              No item
            </Typography>
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 720 }}>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uniRewardCart.data.map((row) => (
                      <TableRow key={row.idUniRewardItem}>
                        <TableCell>
                          <Stack direction={"row"} spacing={2}>
                            <ProductImage src={row.image} alt={row.name} />
                            <Stack>
                              <Typography variant="subtitle2">{`รหัสสินค้า: ${row.sku}`}</Typography>
                              <Typography>{row.name}</Typography>
                              <Typography variant="body2" gutterBottom>
                                {row?.options?.map((item) => item.value).join(" ")}
                              </Typography>
                              <Typography
                                variant="body2"
                                color={"text.secondary"}
                              >
                                {`คงเหลือในคลัง ${row.quantityInStock}`}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell align="right" style={{ minWidth: 80 }}>
                          {utils.numberWithCommas(row.price)}
                        </TableCell>
                        <TableCell align="right" style={{ minWidth: 80 }}>
                          <ButtonGroup aria-label="outlined primary button group">
                            <StyledButton
                              startIcon={<RemoveIcon fontSize="inherit" />}
                              size="small"
                              disabled={row.quantity === 1 || updateUniRewardCart.isPending}
                              onClick={() =>
                                updateUniRewardCart.mutate({
                                  operator: "decrease",
                                  idUniRewardItem: row.idUniRewardItem,
                                })
                              }
                            ></StyledButton>
                            <Button disableRipple>{row.quantity}</Button>
                            <StyledButton
                              startIcon={<AddIcon fontSize="inherit" />}
                              size="small"
                              disabled={row.quantity >= row.quantityInStock || updateUniRewardCart.isPending}
                              onClick={() =>
                                updateUniRewardCart.mutate({
                                  operator: "increase",
                                  idUniRewardItem: row.idUniRewardItem,
                                })
                              }
                            ></StyledButton>
                          </ButtonGroup>
                        </TableCell>
                        <TableCell align="right" style={{ minWidth: 80 }}>
                          {utils.numberWithCommas(row.price * row.quantity)}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => deleteUniRewardCart.mutate(row.idUniRewardItem)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <StyledCard elevation={0}>
                <CardContent>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6" fontWeight={300}>
                        รวมทั้งหมด
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} align="right">
                      <Typography variant="h6" fontWeight={300}>
                        {utils.numberWithCommas(totalPrice)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6" fontWeight={300}>
                        ส่วนลด
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} align="right">
                      <Typography variant="h6" fontWeight={300}>
                        {utils.numberWithCommas(500000)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6" fontWeight={300}>
                        เครดิตคงเหลือ
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} align="right">
                      <Typography variant="h6" fontWeight={300}>
                        {utils.numberWithCommas(companyBudget.data.uniCultureCredit - totalPrice)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h5">ราคาสุทธิ</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} align="right">
                      <Typography variant="h5">
                        {utils.numberWithCommas(totalPrice)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} align="right">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={handleOpenDialogConfirm}
                        disabled={
                          rewardAvailable ||
                          totalPrice > companyBudget.data.uniCultureCredit ||
                          addOrderReward.isPending
                        }
                      >
                        Check out
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledCard>
            </>
          )}
          <DialogSuccess
            open={openDialogConfirm}
            handleClose={handleCloseDialogConfirm}
            handleConfirm={handleConfirm}
            title={"ยืนยันการซื้อสิ้นค้า"}
            content={`ราคาสินค้า: ${totalPrice}`}
          />
        </Container>
      )}
    </StyledRoot>
  );
}

export default UniRewardCart;
