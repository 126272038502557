import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Popover,
  Button,
  Typography,
  Link,
  Breadcrumbs,
  Chip,
  Divider,
  Stack,
  IconButton,
} from "@mui/material";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from '@mui/icons-material/Close';

import MainTab from "./mainTab";
import FilterTab from "./filterTab";

const StyledPopover = styled(Popover)({
  "& .MuiPopover-paper": {
    padding: 16,
    width: "100%",
    maxWidth: 350,
  },
  "& .btn-action":{
    marginTop: "0.5rem",
    display: "flex",
    justifyContent: "flex-end",
    gap: "0.5rem"
  }
});

const StyledChipLabel = styled(Chip)({
  marginTop: 16,
  marginRight: 8,
  backgroundColor: "#FFFFFF",
});

const RenderSelectedFilter = ({ selectedFilter, handleToggle }) => {
  return (
    <div>
      <Typography variant="caption" className="label" color="text.secondary">
        Selected Filter
      </Typography>
      {Object.keys(selectedFilter)
        .filter((key) => selectedFilter[key].itemList.findIndex((el) => el.checked) > -1)
        .map((element) => {
          return (
            <div key={element}>
              <StyledChipLabel icon={<FilterAltIcon />} label={selectedFilter[element].label} />
              {selectedFilter[element].itemList
                .filter((item) => {
                  return item.checked;
                })
                .map((item) => {
                  return (
                    <StyledChipLabel
                      variant="outlined"
                      label={item.text}
                      onDelete={() => handleToggle(element, item.text)}
                    />
                  );
                })}
            </div>
          );
        })}
    </div>
  );
};

const Filter = ({ filter, handleClickApply, handleClearFilter, CustomButton }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterTab, setFilterTab] = useState("main");
  const [selectedFilter, setSelectedFilter] = useState({ ...filter });

  const [breadcrumbs, setBreadcrumbs] = useState([
    <Link
      underline="hover"
      key="1"
      color="inherit"
      onClick={() => handleClickToMain()}
    >
      Filter by
    </Link>,
  ]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClickToMain = () => {
    setBreadcrumbs([
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => handleClickToMain()}
      >
        Filter by
      </Link>,
    ]);
    setFilterTab("main");
  };

  const handleClickMenu = (label, key) => {
    const tempBreadcrumbs = [...breadcrumbs];
    tempBreadcrumbs.push(
      <Typography color="text.primary">{label}</Typography>
    );
    setBreadcrumbs(tempBreadcrumbs);
    setFilterTab(key);
  };

  const handleCheckFilter = (keyFilter, itemValue) => {
    const tempSelectedFilter = {...selectedFilter};
    const indexChecked = tempSelectedFilter[keyFilter].itemList.findIndex(
      (element) => element.value === itemValue
    );

    tempSelectedFilter[keyFilter].itemList[indexChecked].checked =
      !tempSelectedFilter[keyFilter].itemList[indexChecked].checked;
    
    setSelectedFilter(tempSelectedFilter);
  };

  useEffect(() => {
    setSelectedFilter(filter)
  }, [filter])

  return (
    <div className="filter">
      <CustomButton variant="contained" onClick={handleClick}>
        Filter
      </CustomButton>
      {/* <Button variant="contained" onClick={handleClick}>
        Filter
      </Button> */}
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div>
          {filterTab === "main" ? (
            <MainTab
              handleClick={handleClickMenu}
              selectedFilter={selectedFilter}
            />
          ) : (
            <FilterTab
              keyFilter={filterTab}
              selectedFilter={selectedFilter}
              handleCheckFilter={handleCheckFilter}
            />
          )}
        </div>
        <Divider />
        {/* {tempSelectedFilter && (
          <RenderSelectedFilter
            selectedFilter={tempSelectedFilter} 
            handleToggle={handleToggle}
          />
        )} */}
        <div className="btn-action">
          <Button variant="outlined" color="error" onClick={handleClearFilter}>
            Clear
          </Button>
          <Button onClick={() => handleClickApply(selectedFilter)} variant="contained">Apply</Button>
        </div>
      </StyledPopover>
    </div>
  );
};

export default Filter;
