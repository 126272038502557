import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../../../shared/inputForm/inputField";
import "./tiptap.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { httpClient } from "../../../../../../services/httpClient";
import { useLocation } from "react-router-dom";
import { useCompanyRewardCost, useUniRewardCost } from "../../../../../../quries/reward";
import SwitchInput from "../../../../shared/inputForm/switchInput";
import DateInputField from "../../../../shared/inputForm/dateInputField";

function DialogAddRewardCost({ open, handleClose, idCompanyReward }) {
  const { state } = useLocation();
  const queryClient = useQueryClient();

  const { data: companyRewardCost } = useCompanyRewardCost({ idCompanyReward });

  const { mutate } = useMutation({
    mutationFn: async (formData) => await httpClient.post("company-reward-cost", formData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["rewards", "company-rewards", state?.value?.idCompanyReward, "cost"],
      });
      toast.success("สำเร็จ");
    },
    onError: () => toast.error("เกิดข้อผิดพลาด"),
    onSettled: () => handleClose(),
  });

  const validationSchema = yup.object().shape({
    idCompanyRewardItem: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required("required"),
    price: yup
      .number()
      .transform((value) => (isNaN(value) ? null : value))
      .integer()
      .nullable()
      .min(1, "must be greater than or equal to 1")
      .required('required'),
    quantity: yup
      .number()
      .transform((value) => (isNaN(value) ? null : value))
      .integer()
      .nullable()
      .min(1, "must be greater than or equal to 1")
      .required('required'),
    isSetDate: yup.boolean(),
    startDate: yup.date().when("isSetDate", {
      is: (val) => val,
      then: (schema) => schema.required('required'),
    }),
    endDate: yup
      .date()
      .when(["isSetDate", "startDate"], (isSetDate, startDate, schema) => {
        return isSetDate
          ? schema.min(dayjs(startDate).add(1, "day"),"End date must be later than start date").required("required")
          : schema;
      }),
  });

  const { control, handleSubmit, watch, formState: { isSubmitting, errors } } = useForm({
    defaultValues: {
      quantity: "",
      price: "",
      isSetDate: false,
      idCompanyRewardItem: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const handleConfigReward = (values) => {
    mutate(values);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>เพิ่มของรางวัล</DialogTitle>
      <form onSubmit={handleSubmit(handleConfigReward)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"idCompanyRewardItem"}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={Boolean(fieldState.error)}>
                    <InputLabel>{"เลือกของรางวัล"}</InputLabel>
                    <Select {...field} label={"เลือกของรางวัล"}>
                      {companyRewardCost
                      ?.filter((obj1, i, arr) => 
                        arr.findIndex(obj2 => (obj2.idCompanyRewardItem === obj1.idCompanyRewardItem)) === i
                      )
                      .map((item) => (
                        <MenuItem key={item.idCompanyRewardItem} value={item.idCompanyRewardItem}>
                          {`${item.sku} ${item?.options.map((option) => option.value).join(", ")}`}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{fieldState?.error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                control={control}
                name={"price"}
                type={"number"}
                label={"ราคา"}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                control={control}
                name={"quantity"}
                type={"number"}
                label={"จำนวน"}
              />
            </Grid>
            <Grid item xs={12}>
              <SwitchInput
                control={control}
                name={"isSetDate"}
                label={"กำหนดวันที่"}
              />
            </Grid>

            {watch("isSetDate") && (
              <>
                <Grid item xs={6}>
                  <DateInputField
                    control={control}
                    name={"startDate"}
                    label={"วันที่เริ่ม"}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateInputField
                    control={control}
                    name={"endDate"}
                    label={"วันที่สิ้นสุด"}
                    minDate={dayjs(watch("startDate"))}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" color="error" onClick={handleClose}>
            ยกเลิก
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            ยืนยัน
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default DialogAddRewardCost;
