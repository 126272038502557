import React, { useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { Alert, Card, Divider } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import PlaceIcon from "@mui/icons-material/Place";
import EventIcon from "@mui/icons-material/Event";
import LabelIcon from "@mui/icons-material/Label";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";

import { SpecialIcon } from "../../shared/typeCoin";
import DialogDescriptionActivity from "./dialogDescriptionActivity";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import utils from "../../../../utils";

const StyledCard = styled(Card)({
  width: "100%",
  // marginBottom: 24,
  borderRadius: 8,
  // ["@media only screen and (min-width:576px)"]: {
  //   // eslint-disable-line no-useless-computed-key
  //   width: "calc((100% - 24px) / 2)",
  //   "&:nth-child(n)": {
  //     marginRight: 24,
  //   },
  //   "&:nth-child(2n)": {
  //     marginRight: 0,
  //   },
  // },
  // ["@media only screen and (min-width:768px)"]: {
  //   // eslint-disable-line no-useless-computed-key
  //   width: "calc((100% - 24px) / 2)",
  //   "&:nth-child(n)": {
  //     marginRight: 24,
  //   },
  //   "&:nth-child(2n)": {
  //     marginRight: 0,
  //   },
  // },
  // ["@media only screen and (min-width:992px)"]: {
  //   // eslint-disable-line no-useless-computed-key
  //   width: "calc((100% - 48px) / 3)",
  //   "&:nth-child(n)": {
  //     marginRight: 24,
  //   },
  //   "&:nth-child(3n)": {
  //     marginRight: 0,
  //   },
  // },
  // ["@media only screen and (min-width:1200px)"]: {
  //   width: "calc((100% - 48px) / 4)",
  //   "&:nth-child(n)": {
  //     marginRight: 24,
  //   },
  //   "&:nth-child(4n)": {
  //     marginRight: 0,
  //   },
  // },
});

const StyledCardMedia = styled(CardMedia)({
  height: 0,
  paddingTop: "56.25%", // 16:9
  backgroundSize: "contain",
});

const WrapBtnCard = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 25,
  padding: 6,
  boxShadow:
    "0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  backgroundColor: "#2a3555",
  // backgroundImage: "linear-gradient(112.1deg, rgba(32, 38, 57, 1) 11.4%, rgba(63, 76, 119, 1) 70.2%)"
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#2a3555ed",
  },
});

const StyledBtnCardText = styled(Typography)({
  marginRight: 5,
  fontSize: 18,
  fontWeight: 500,
  color: "rgba(255, 255, 255, 0.87)",
});

const WrapStatus = styled("div")({
  display: "flex",
  alignContent: "center",
});

const StyledTextStatus = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "myColor",
})(({ myColor }) => ({
  color: myColor,
}));

const StyledLabelIcon = styled(LabelIcon, {
  shouldForwardProp: (prop) => prop !== "myColor",
})(({ myColor }) => ({
  color: myColor,
}));

const WrapLabel = styled("div")({
  display: "flex",
  marginBottom: 8,
  "& .labelDesc": {
    fontSize: 14,
    marginRight: "auto",
  },
  "& .labelIcon": {
    marginRight: 4,
    fontSize: 20,
    color: "rgba(0, 0, 0, 0.54)",
  },
  "& .enrollDateTo": {
    marginLeft: "auto",
  },
});

const tagStatusText = ["เปิดรับลงทะเบียน", "จำนวนใกล้เต็ม", "ปิดรับลงทะเบียน"];

const CardActivity = (props) => {
  const navigate = useNavigate();
  const { value } = props;
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const changeToMyActivity = () => {
    navigate(`/my-activity`);
  };

  return (
    <Fragment>
      {openDialog && (
        <DialogDescriptionActivity
          open={openDialog}
          handleClose={handleClickCloseDialog}
          changeToMyActivity={changeToMyActivity}
          value={value}
        />
      )}
      <StyledCard>
        <StyledCardMedia image={value.image} />
        <CardContent style={{ padding: 16 }}>
          <Typography gutterBottom variant="h6">
            {" "}
            {value.name}{" "}
          </Typography>
          <WrapLabel>
            <PlaceIcon className={`labelIcon`} />
            <Typography color="textSecondary" className={`labelDesc`}>
              สถานที่:{" "}
            </Typography>
            <Typography variant="body2" color="textSecondary" noWrap>
              {value.location}
            </Typography>
          </WrapLabel>
          <WrapLabel>
            <EventIcon className={`labelIcon`} />
            <Typography color="textSecondary" className={`labelDesc`}>
              วันที่เวลา:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {dayjs(value.startDate).isSame(value.endDate, "day")
                ? `${dayjs(value.startDate).format("DD MMM YYYY")}`
                : `${dayjs(value.startDate).format("DD MMM YYYY")} 
                  - ${dayjs(value.endDate).format("DD MMM YYYY")}`}
            </Typography>
          </WrapLabel>
          <WrapLabel>
            <ScheduleOutlinedIcon className={`labelIcon`} />
            <Typography color="textSecondary" className={`labelDesc`}>
              รับสมัคร:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {`${dayjs(value.openRegisterDate).format(
                "DD MMM YYYY"
              )} - ${dayjs(value.closeRegisterDate).format(
                "DD MMM YYYY"
              )}`}{" "}
            </Typography>
          </WrapLabel>
          <WrapLabel>
            <StyledLabelIcon
              myColor={
                value.status === "rewarded" || value.status === "close"
                  ? "#ff001d"
                  : value.numberOfParticipant &&
                    value.register &&
                    Math.floor(value.numberOfParticipant / value.register) * 100 >= 50
                  ? "#ffc400"
                  : "#00c853"
              }
            />
            <StyledTextStatus
              variant="body2"
              myColor={
                value.status === "rewarded" || value.status === "close"
                  ? "#ff001d"
                  : value.numberOfParticipant &&
                    value.register &&
                    Math.floor(value.numberOfParticipant / value.register) * 100 >= 50
                  ? "#ffc400"
                  : "#00c853"
              }
            >
              {value.status === "rewarded" || value.status === "close"
                ? tagStatusText[2]
                : value.numberOfParticipant &&
                  value.register &&
                  Math.floor(value.numberOfParticipant / value.register) * 100 >= 50
                ? tagStatusText[1]
                : tagStatusText[0]}
            </StyledTextStatus>
          </WrapLabel>
          {
            // value.participantList.find(participant => participant.idEmployees === currentUser.id)
            //   ? (
            //     <WrapBtnCard>
            //       <StyledBtnCardText variant="h6">ลงทะเบียนแล้ว</StyledBtnCardText>
            //     </WrapBtnCard>
            //   ) : (
            // (value.status === "open" || value.status === "update") &&
            value.register > value.numberOfParticipant ? (
              <WrapBtnCard onClick={handleClickOpenDialog}>
                <StyledBtnCardText variant="h6">
                  ลงทะเบียนรับ
                </StyledBtnCardText>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {value?.coins.map((item, index) => (
                    <>
                      <SpecialIcon idCoinType={item.idCoinType} width={32} />
                      <Typography 
                        variant="h6" 
                        color={"#ffffff"}
                        style={{ marginLeft: 8, fontSize: 20 }}
                      >
                        {utils.numberWithCommas(item.amount)}
                      </Typography>
                      {value.coins.length > 1 &&
                        value.coins.length - 1 !== index && (
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ backgroundColor: "white", margin: "0 10px" }}
                          />
                        )}
                    </>
                  ))}
                </div>
              </WrapBtnCard>
            ) : (
              <WrapBtnCard>
                <StyledBtnCardText variant="h6">เต็มจำนวน</StyledBtnCardText>
              </WrapBtnCard>
            )
            // )
          }
        </CardContent>
      </StyledCard>
    </Fragment>
  );
};

export default CardActivity;
