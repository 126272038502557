import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
  Container,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useOrganization } from "../../../../quries/company";
import DailyDetail from "./DailyDetail";

const StyledDatePicker = styled(DatePicker)({
  backgroundColor: "#282828",
  "& .MuiOutlinedInput-root": {
    color: "#ffffff",
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "&:hover fieldset": {
      borderColor: "#ffffff",
    },
    "& .Mui-focused fieldset": {
      borderColor: "#ffffff",
    },
  },
  "& .MuiIconButton-root": {
    color: "#ffffff",
  },
  "& .MuiInputLabel-root": {
    color: "#ffffff",
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  backgroundColor: "#ffffff",
})

const StyledAutocomplete = styled(Autocomplete)({
  backgroundColor: '#282828',
  '& .MuiOutlinedInput-root': {
    color: '#ffffff',
  },
  'fieldset': {
    borderColor: '#ffffff',
  },
  '& .MuiFormLabel-root,& .MuiFormLabel-root.Mui-focused': {
    color: '#ffffff',
  },
})

const MoraleDailyDashboard = () => {
  const [companySelected, setCompanySelected] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState({ departmentName: 'All Department', idDepartment: 0 });
  const [sectionSelected, setSectionSelected] = useState({ sectionName: 'All Section', idSection: 0 });
  const [filterDate, setFilterDate] = useState(dayjs());
  
  const organization = useOrganization();

  useEffect(() => {
    if (organization.isSuccess) {
      setCompanySelected(organization.data[0])
    }
  }, [organization.isSuccess])

  if (organization.isPending) {
    return <CircularProgress />
  }
  
  return (
    <div className={`page dashboard-page`}>
      <Typography variant="h4" color={"#ffffff"}>สรุป Morale Daily</Typography>
      <StyledDivider />
        <Container>
          <Grid container spacing={2} marginBottom={4}>
            <Grid item xs={12} sm={6} md={3}>
              <StyledDatePicker
                value={filterDate}
                onChange={(newValue) => setFilterDate(newValue)}
                label={'เลือกเดือน'}
                views={['month']}
                minDate={dayjs().startOf('year')}
                maxDate={dayjs()}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledAutocomplete
                disableClearable
                value={companySelected}
                options={organization.data}
                onChange={(event, newValue) => {
                  setCompanySelected(newValue)
                  setDepartmentSelected({ departmentName: "All Department", idDepartment: 0 })
                  setSectionSelected({ sectionName: "All Section", idSection: 0 })
                }}
                getOptionLabel={(option) => option.companyName}
                isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                renderInput={(params) => <TextField {...params} label="Company" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledAutocomplete
                disableClearable
                value={departmentSelected}
                options={
                  companySelected
                    ? [
                        { departmentName: "All Department", idDepartment: 0 },
                        ...companySelected.department,
                      ]
                    : []
                }
                onChange={(event, newValue) => setDepartmentSelected(newValue)}
                getOptionLabel={(option) => option.departmentName}
                isOptionEqualToValue={(option, value) => option.idDepartment === value.idDepartment}
                renderInput={(params) => <TextField {...params} label="Department" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledAutocomplete
                disableClearable
                value={sectionSelected}
                options={
                  companySelected
                    ? [
                        { sectionName: "All Section", idSection: 0 },
                        ...companySelected.section,
                      ]
                    : []
                }
                onChange={(event, newValue) => setSectionSelected(newValue)}
                getOptionLabel={(option) => option.sectionName}
                isOptionEqualToValue={(option, value) => option.idSection === value.idSection}
                renderInput={(params) => <TextField {...params} label="Section" />}
              />
            </Grid>
          </Grid>
          {!companySelected?.idCompany && (
            <Typography variant="h5" color={"#ffffff"}>{"เลือกบริษัท"}</Typography>
          )}
          {companySelected?.idCompany && (
            <DailyDetail
              companySelected={companySelected}
              idDepartment={departmentSelected?.idDepartment}
              idSection={sectionSelected?.idSection}
              date={filterDate}
            />
          )}
        </Container>
    </div>
  );
};

export default MoraleDailyDashboard;
