import React, { useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Chip, CircularProgress, Divider, Grid, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import FlightIcon from '@mui/icons-material/Flight';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PetsIcon from '@mui/icons-material/Pets';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';

import CardReward from "../../shared/cardReward";
import HeaderPage from "./../../shared/header/headerPage";
import GiftIconHeader from "./assets/gift.png";
import CoinFilter from "../../shared/coinFilter";
import { useRewardsActive } from "../../../../quries/reward";

const StyledRoot = styled('div')({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
})

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
})

const StyledChip = styled(Chip)({
  padding: 8,
  height: 36,
  borderRadius: 18,
})

const WrapFilter = styled('div')({
  display: "flex",
  justifyContent: "flex-end",
  margin: "16px 0",
  "& .MuiFormControl-root": {
    // width: "100%",
    marginRight: 8,
  },
})

const WrapCoin = styled('div', {
  shouldForwardProp: (prop) => prop !== "showAmount"
})(({ showAmount }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "30%",
  // justifyContent: "center",
  opacity: showAmount,
}))

const rewardTypeList = [
  { idRewardType: 0, rewardType: 'all', icon: <DensitySmallIcon /> },
  { idRewardType: 1, rewardType: 'food', icon: <FastfoodIcon /> },
  { idRewardType: 2, rewardType: 'shopping', icon: <ShoppingCartIcon /> },
  { idRewardType: 3, rewardType: 'entertainment', icon: <QueueMusicIcon /> },
  { idRewardType: 4, rewardType: 'lifestyle', icon: <ColorLensIcon /> },
  { idRewardType: 5, rewardType: 'travel', icon: <FlightIcon /> },
  { idRewardType: 6, rewardType: 'health', icon: <LocalHospitalIcon /> },
  { idRewardType: 7, rewardType: 'pet', icon: <PetsIcon /> },
  { idRewardType: 8, rewardType: 'baby', icon: <ChildFriendlyIcon /> },
]

export default function RedeemReward() {
  const [openFilter, setOpenFilter] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const [selectRewardType, setSelectRewardType] = useState(0)
  const [filterCoin, setFilterCoin] = useState([]);

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { isPending, data: rewards } = useRewardsActive({ idCompany: userProfile?.idCompany })

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenFilter(!openFilter);
  };

  const handleChangeSearch = (event) => {
    setValueSearch(event.target.value);
  };

  const handleClickSearch = () => {
    return rewards.filter((value) => value.name.toLowerCase().includes(valueSearch.toLowerCase()))
  };

  const preventDefault = (event) => event.preventDefault();

  if (isPending) {
    return <CircularProgress color="secondary" />
  }

  return (
    <StyledRoot className={'page'}>
      <HeaderPage textLabel={"แลกของรางวัล"} icon={GiftIconHeader} />
      <StyledDivider />
      <Stack 
        direction={{ xs: 'column', xl: 'row' }}
        alignItems={"flex-end"}
        marginTop={2}
        marginBottom={2}
        spacing={2}
      >
        <Stack 
          direction={"row"} 
          width={"100%"} 
          spacing={3}
          style={{ overflowX: "auto", scrollbarWidth: "none" }}
        >
          {rewardTypeList.map((item) => {
            return (
              <StyledChip 
                clickable 
                variant="outlined" 
                key={item.idRewardType}
                label={item.rewardType}
                icon={item.icon}
                color={selectRewardType === item.idRewardType ? "warning" : "default"}
                onClick={() => {
                  setSelectRewardType(item.idRewardType)
                }}
              />
            )
          })}
        </Stack>
        <WrapFilter>
          <FormControl variant="outlined">
            <OutlinedInput
              id="outlined-adornment-search-reward"
              value={valueSearch}
              onChange={handleChangeSearch}
              placeholder="Search Reward..."
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickSearch}
                    onMouseDown={preventDefault}
                    edge="start"
                    size="large">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              />
          </FormControl>
          <IconButton aria-label="filter" onClick={toggleDrawer} size="large">
            <FilterListIcon fontSize="large" />
          </IconButton>
        </WrapFilter>
      </Stack>
      <Drawer anchor={"right"} open={openFilter} onClose={toggleDrawer}>
        <CoinFilter setFilter={setFilterCoin} />
      </Drawer>
      {/* <div style={{ display: "flex", flexWrap: "wrap" }}> */}
      <Grid container spacing={3}>
        {rewards &&
          rewards
            .filter((value) => {
              return value.name.toLowerCase().includes(valueSearch.toLowerCase())
            })
            .filter(item => selectRewardType === 0 || item.idRewardType === selectRewardType)
            .filter(item => filterCoin.length === 0 || item.coins.some(coin => filterCoin.includes(coin.idCoinType)))
            .map((value) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CardReward key={value.idReward} data={value} />
                </Grid>
              );
            })}
        </Grid>
      {/* </div> */}
    </StyledRoot>
  );
}
