import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { Typography, Grow, Fade, Stack } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ConfettiRedeem from "./confettiRedeem";

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    backgroundColor: "#000000",
    justifyContent: "center",
  },
});

const DialogRedeemSuccess = ({ open, handleClose, name, imageURL }) => {
  return (
    <StyledDialog fullScreen open={open} onClose={handleClose}>
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={6}
      >
        <div>
          <Slide
            timeout={3000}
            direction="down"
            in={open}
            mountOnEnter
            unmountOnExit
          >
            <Typography variant="h4" color="common.white" textAlign="center">
              {"แลกของรางวัลแล้ว"}
            </Typography>
          </Slide>
        </div>
        <div>
          <Fade in={open} timeout={3000}>
            <Box>
              <Box
                component="img"
                sx={{
                  width: 300,
                  height: 300,
                  marginBottom: 4,
                }}
                alt="reward"
                src={imageURL}
              />
              <Typography variant="h5" color="common.white" textAlign="center">
                {name}
              </Typography>
            </Box>
          </Fade>
        </div>
        <div>
          <Fade in={open} timeout={3000}>
            <IconButton aria-label="delete" onClick={handleClose}>
              <HighlightOffIcon fontSize="large" style={{ color: "#ffffff" }} />
            </IconButton>
          </Fade>
        </div>
      </Stack>
      <ConfettiRedeem />
    </StyledDialog>
  );
};

export default DialogRedeemSuccess;
