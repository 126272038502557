import React, { useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateOrganization } from "../../../../actions/company";
import { useOrganization } from "../../../../quries/company";
import LoadingIcon from "./loadingIcon";

const StyledAutoComplete = styled(Autocomplete)({
  "& .MuiFilledInput-root": {
    padding: "13.5px 14px",
    paddingRight: "32px",
    "& input": {
      padding: 0,
    },
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&:after": {
      border: "none",
    },
    "&:before": {
      border: "none",
      borderBottomStyle: "none !important",
    },
  },
});

const SelectAffiliateCompany = ({ value, handleChange, multiple }) => {
  const organization = useOrganization();

  useEffect(() => {
    if (organization.isSuccess) {
      handleChange(organization.data[0])
    }
  }, [organization.isSuccess])

  if (organization.isPending) {
    return <LoadingIcon />
  }

  return (
    <StyledAutoComplete
      fullWidth
      multiple={multiple}
      options={organization.data || []}
      getOptionLabel={(option) => option.companyName || ''}
      isOptionEqualToValue={(option, value) =>
        option.idCompany === value.idCompany
      }
      renderInput={(params) => (
        <StyledTextField
          {...params}
          InputProps={{ ...params.InputProps, endAdornment: null }}
          // variant="filled"
          // placeholder="เลือกบริษัท"
          label={"Company"}
        />
      )}
      value={value}
      onChange={(event, newValue) => handleChange(newValue)}
      noOptionsText="ไม่พบข้อมูล"
      disableClearable
    />
  );
};

export default SelectAffiliateCompany;
