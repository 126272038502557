import {
  COACH_RECORD_FETCHING,
  COACH_RECORD_FAILED,
  COACH_RECORD_SUCCESS,
} from "./types";

import coachRecordService from "../services/coach-record.service";

export const getCoachRecord = (idCoach) => async (dispatch) => {
  try {
    dispatch({
      type: COACH_RECORD_FETCHING,
    });
    const res = await coachRecordService.getCoachRecord(idCoach);
    if (res) {
      dispatch({
        type: COACH_RECORD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: COACH_RECORD_FAILED,
    });
    console.log(err);
  }
};

export const addCoachRecord = (formData) => async (dispatch) => {
  try {
    const res = await coachRecordService.addCoachRecord(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: COACH_RECORD_FAILED,
    });
    console.log(err);
  }
};

export const updateCoachRecord = (formData) => async (dispatch) => {
  try {
    const res = await coachRecordService.updateCoachRecord(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: COACH_RECORD_FAILED,
    });
    console.log(err);
  }
};
