import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  linearProgressClasses,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PeopleIcon from "@mui/icons-material/People";
import InfoIcon from '@mui/icons-material/Info';
import Gauge from "./Gauge";
import DoughnutChart from "./DoughnutChart";
import pfdGenerater from "./pfdGen";
import pptxGenerater from "./pptxGen";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMoraleKPIYear,
  getMoraleKPIYear,
} from "../../../../actions/moraleKPI";
import MenuDate from "../../shared/moraleKpi/menuDate";
import Filter from "../../shared/moraleKpi/filter";
import dayjs from "dayjs";
import SelectAffiliateCompany from "../../shared/general/selectAffiliateCompany";
import { useEmployeeLevel, useEmploymentType } from "../../../../quries/company";
import LoadingIcon from "../../shared/general/loadingIcon";
import MultiChart from "./MultiChart";

const DivRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  margin: "0px auto",
  padding: 16,
  marginTop: 60,
  width: "100%",
  minHeight: "calc(100vh - 60px)",
  backgroundColor: "#eeeeee",
});

const WrapHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: "linear-gradient(to right, #696eff, #f8acff)",
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  padding: 24,
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
});

const StyledCard = styled(Card)({
  borderRadius: 8,
  height: "100%",
  width: "100%"
});

const StyledCardContent = styled(CardContent)({
  // display: "flex",
  // flexDirection: "column",
  // alignItems: "center",
});

const StyledCardRange = styled(Card)(({ theme }) => ({
  height: "50%",
  width: "100%",
  backgroundColor: theme.palette.grey[100],
}));

const WrapRangeOuter = styled(Box)({
  display: "flex",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
});

const WrapRangeInner = styled(Box, {
  shouldForwardProp: (prop) => prop !== "value",
})(({ value }) => ({
  width: value,
  ["@media only screen and (max-width:600px)"]: {
    width: "100%",
  },
}));

const StyledColorTab = styled(Box, {
  shouldForwardProp: (prop) => prop !== "bgColor",
})(({ bgColor, value }) => ({
  width: "100%",
  height: 30,
  backgroundColor: bgColor,
  ["@media only screen and (max-width:600px)"]: {
    width: value,
  },
}));

const bgColorList = ["#FA95B9", "#73eadf", "#af8fdb"];

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
  backgroundColor: "#e2e2e2",
});

const StyledButton = styled(Button)({
  backgroundColor: "rgb(105, 110, 255)",
  ":hover": {
    backgroundColor: "rgb(71, 86, 226)",
  }
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 30,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    background: "linear-gradient(to right, #696eff, #f8acff)",
  },
}));

export const sortByAverage = (morale, order) => {
  const temp = morale.reduce((acc, topic) => {
    return [...acc, ...topic.moraleQuestionAvgAndDistributionAcross];
  }, []);
  if (order === "descending") {
    return [...temp].sort((a, b) => b.average - a.average);
  } else {
    return [...temp].sort((a, b) => a.average - b.average);
  }
};

export const sortByPositivePerception = (morale, order) => {
  const temp = morale.reduce((acc, topic) => {
    return [...acc, ...topic.moraleQuestionAvgAndDistributionAcross];
  }, []);
  if (order === "descending") {
    return [...temp].sort(
      (a, b) => b.positivePerception - a.positivePerception
    );
  } else {
    return [...temp].sort(
      (a, b) => a.positivePerception - b.positivePerception
    );
  }
};

const RenderSelectedFilter = ({ selectedFilter, handleToggle }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {Object.keys(selectedFilter)
        .filter(
          (element) => selectedFilter[element].itemList?.some((item) => item.checked)
        )
        .map((element) => {
          return (
            <div style={{ display: "flex", gap: "8px" }}>
              <Chip 
                icon={<FilterAltIcon />} 
                sx={{ 
                  backgroundColor: "rgb(105, 110, 255)",
                  color: "#ffffff",
                  '.MuiSvgIcon-root': { color: "#ffffff" },
                }}
                label={element}
              />
              {selectedFilter[element].itemList
                .filter((item) => item.checked)
                .map((item) => (
                  <Chip
                    variant="outlined"
                    label={item.text}
                    // onDelete={() => handleToggle(element, item.text)}
                  />
                ))}
            </div>
          );
        })}
    </div>
  );
};

const Report = () => {
  const dispatch = useDispatch();
  const pdfRef = useRef(null);
  const gaugeRef = useRef(null);
  const multiChartRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [doughnutChart, setDoughnutChart] = useState([]);
  const [moraleSelected, setMoraleSelected] = useState("");
  const [companySelected, setCompanySelected] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const { result: moraleKpiYear } = useSelector((state) => state.moraleKpiYear);
  const { result: moraleKpiYearItem, isFetching } = useSelector(
    (state) => state.moraleKpiYearItem
  );

  const employmentType = useEmploymentType({ idCompany: companySelected?.idCompany });
  const employmentLevel = useEmployeeLevel({ idCompany: companySelected?.idCompany });

  useEffect(() => {
    if (companySelected) {
      dispatch(getAllMoraleKPIYear(companySelected.idCompany));
    }
  }, [companySelected]);

  useEffect(() => {
    if (moraleKpiYear?.length > 0) {
      setMoraleSelected(moraleKpiYear[0].idMorale)
    }
  }, [moraleKpiYear]);

  useEffect(() => {
    if (moraleSelected) {
      dispatch(
        getMoraleKPIYear({
          idMorale: moraleSelected,
          gender: selectedFilter["gender"].itemList
            ?.filter((item) => item.checked)
            .map((item) => item.value),
          generation: selectedFilter["generation"].itemList
            ?.filter((item) => item.checked)
            .map((item) => item.value),
          jobLevel: selectedFilter["jobLevel"].itemList
            ?.filter((item) => item.checked)
            .map((item) => item.value),
          jobType: selectedFilter["jobType"].itemList
            ?.filter((item) => item.checked)
            .map((item) => item.value),
          // departmentSelected,
        })
      );
    }
  }, [moraleSelected, moraleKpiYear])

  useEffect(() => {
    const filterData = {
      gender: {
        label: "Gender",
        itemList: [
          { value: "Female", text: "Female", checked: false },
          { value: "Male", text: "Male", checked: false },
        ],
      },
      generation: {
        label: "Generation",
        itemList: [
          {
            value: "babyBoomer",
            text: "Baby Boomer",
            checked: false,
          },
          {
            value: "genX",
            text: "Generation X",
            checked: false,
          },
          {
            value: "genY",
            text: "Generation Y",
            checked: false,
          },
          {
            value: "genZ",
            text: "Generation Z",
            checked: false,
          },
          {
            value: "alpha",
            text: "Alpha",
            checked: false,
          },
        ],
      },
      jobLevel: {
        label: "Job Level",
        itemList: employmentLevel?.data?.map((item) => ({
          value: item.idLevel,
          text: item.levelName,
          checked: false,
        })),
      },
      jobType: {
        label: "Job Type",
        itemList: employmentType?.data?.map(item => ({
          value: item.idEmploymentType,
          text: item.employmentTypeName,
          checked: false,
        }))
      },
    }

    setSelectedFilter({ ...filterData })
  }, [employmentType.data, employmentLevel.data]);

  const handleChangeMoraleSelected = (value) => {
    setMoraleSelected(value);
  };

  const handleChangeCompanySelected = (value) => {
    setCompanySelected(value);
    setMoraleSelected(null);
  };

  const handleChangeDepartmentSelected = (event) => {
    setDepartmentSelected(event.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleToggle = (filterTab, item) => {
    var tempSelectedFilter = [...selectedFilter];
    const indexMenu = tempSelectedFilter.findIndex(
      (element) => element.key === filterTab.key
    );
    const indexChecked = tempSelectedFilter[indexMenu].itemList.findIndex(
      (element) => element.text === item
    );
    tempSelectedFilter[indexMenu].itemList[indexChecked].checked =
      !tempSelectedFilter[indexMenu].itemList[indexChecked].checked;
    setSelectedFilter(tempSelectedFilter);
  };

  const handleClickApply = async () => {
    if (moraleSelected) {
      const formData = {
        idMorale: moraleSelected,
        gender: selectedFilter["gender"].itemList
          ?.filter((item) => item.checked)
          .map((item) => item.value),
        generation: selectedFilter["generation"].itemList
          ?.filter((item) => item.checked)
          .map((item) => item.value),
        jobLevel: selectedFilter["jobLevel"].itemList
          ?.filter((item) => item.checked)
          .map((item) => item.value),
        jobType: selectedFilter["jobType"].itemList
          ?.filter((item) => item.checked)
          .map((item) => item.value),
        // departmentSelected,
      };
      dispatch(getMoraleKPIYear(formData));
    } else {
      setOpenSnackbar(true);
    }
  };

  const handleClearFilter = () => {
    setSelectedFilter({
      gender: {
        label: "Gender",
        itemList: [
          { value: "Female", text: "Female", checked: false },
          { value: "Male", text: "Male", checked: false },
        ],
      },
      generation: {
        label: "Generation",
        itemList: [
          {
            value: "babyBoomer",
            text: "Baby Boomer",
            checked: false,
          },
          {
            value: "genX",
            text: "Generation X",
            checked: false,
          },
          {
            value: "genY",
            text: "Generation Y",
            checked: false,
          },
          {
            value: "genZ",
            text: "Generation Z",
            checked: false,
          },
          {
            value: "alpha",
            text: "Alpha",
            checked: false,
          },
        ],
      },
      jobLevel: {
        label: "Job Level",
        itemList: employmentLevel?.data?.map((item) => ({
          value: item.idLevel,
          text: item.levelName,
          checked: false,
        })),
      },
      jobType: {
        label: "Job Type",
        itemList: employmentType?.data?.map(item => ({
          value: item.idEmploymentType,
          text: item.employmentTypeName,
          checked: false,
        }))
      },
    });
  };

  const data = [
    {
      title: "Bottom Quartile",
      range: "0 - 54%",
      value: 54,
    },
    {
      title: "Moderate",
      range: "54 - 76%",
      value: 22,
    },
    {
      title: "Top Quartile",
      range: "76 - 100%",
      value: 24,
    },
  ];

  const handleDownloadPDF = () => {
    const gaugeImage = gaugeRef.current.chartInstance.toBase64Image(
      "image/jpeg",
      1
    );
    const multiChartImage = multiChartRef.current.chartInstance.toBase64Image(
      "image/jpeg",
      1
    );
    pfdGenerater(
      moraleKpiYearItem,
      gaugeImage,
      multiChartImage,
      doughnutChart
    );
  };

  const handleDownloadPPTX = () => {
    const gaugeImage = gaugeRef.current.chartInstance.toBase64Image(
      "image/jpeg",
      1
    );
    const multiChartImage = multiChartRef.current.chartInstance.toBase64Image(
      "image/jpeg",
      1
    );
    pptxGenerater(moraleKpiYearItem, gaugeImage, multiChartImage);
  };

  return (
    <DivRoot>
      <Container maxWidth="lg">
        <div ref={pdfRef}>
          <Paper style={{ marginBottom: 40, borderRadius: 8 }}>
            <WrapHeader>
              <Typography variant="h4" color={"#ffffff"}>Report</Typography>
            </WrapHeader>
            <div style={{ padding: "16px"}}>
              <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "16px" }}>
                <SelectAffiliateCompany
                  value={companySelected}
                  handleChange={handleChangeCompanySelected}
                />
                {moraleKpiYear && (
                  <Select
                    fullWidth
                    value={moraleSelected}
                    handleChange={handleChangeMoraleSelected}
                    disabled={moraleKpiYear.length === 0}
                  >
                    {moraleKpiYear.map(item => (
                      <MenuItem value={item.idMorale}>{item.moraleName}</MenuItem>
                    ))}
                  </Select>
                )}
                {/* <Autocomplete
                  disableClearable
                  multiple
                  fullWidth
                  options={companySelected ? companySelected.department : []}
                  value={departmentSelected}
                  onChange={(event, newValue) => {
                    setDepartmentSelected(newValue);
                  }}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.departmentName}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.departmentName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Department" />
                  )}
                /> */}
              </div>
              {moraleSelected && moraleKpiYearItem && (
                <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "24px" }}>
                  <Filter
                    filter={selectedFilter}
                    handleToggle={handleToggle}
                    handleClickApply={handleClickApply}
                    handleClearFilter={handleClearFilter}
                    CustomButton={StyledButton}
                  />
                  <StyledButton
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    onClick={handleClick}
                    sx={{ backgroundColor: "rgb(105, 110, 255)" }}
                  >
                    DOWNLOAD
                  </StyledButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem variant="contained" onClick={handleDownloadPDF}>
                      PDF
                    </MenuItem>
                    <MenuItem variant="contained" onClick={handleDownloadPPTX}>
                      PPTX
                    </MenuItem>
                  </Menu>
                </div>
              )}
              {selectedFilter && (
                <RenderSelectedFilter
                  selectedFilter={selectedFilter}
                  handleToggle={handleToggle}
                />
              )}
            </div>
          </Paper>
        </div>
        {isFetching ? (
          <LoadingIcon />
        ) : moraleSelected && moraleKpiYearItem ? (
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <StyledCard>
                    <StyledCardContent>
                      <Stack spacing={2}>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={2}
                        >
                          <Avatar
                            style={{
                              backgroundColor: "#696eff",
                              color: "#ffffff",
                              height: 48,
                              width: 48,
                            }}
                            variant="rounded"
                          >
                            <InfoIcon fontSize={"large"} />
                          </Avatar>
                          <Typography variant="h6" color="#696eff">
                            Detail
                          </Typography>
                        </Stack>
                        <Typography variant="subtitle1">
                          {`Name: ${moraleKpiYearItem.moraleName}`}
                        </Typography>
                        <Typography variant="subtitle1">
                          {`Start: ${dayjs(moraleKpiYearItem.startDate).format(
                            "DD/MM/YYYY"
                          )}`}
                        </Typography>
                        <Typography variant="subtitle1">
                          {`End: ${dayjs(moraleKpiYearItem.endDate).format(
                            "DD/MM/YYYY"
                          )}`}
                        </Typography>
                      </Stack>
                    </StyledCardContent>
                  </StyledCard>
                </Grid>
                <Grid item xs={12}>
                  <StyledCard>
                    <StyledCardContent>
                      <Stack spacing={5} justifyContent={"space-evenly"}>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={2}
                        >
                          <Avatar
                            style={{
                              backgroundColor: "#696eff",
                              color: "#ffffff",
                              height: 48,
                              width: 48,
                            }}
                            variant="rounded"
                          >
                            <PeopleIcon fontSize={"large"} />
                          </Avatar>
                          <div>
                            <Typography variant="h6" color="#696eff">
                              Participant Rate
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="text.secondary"
                            >
                              {`${moraleKpiYearItem.moraleResponse.response}/${moraleKpiYearItem.moraleResponse.participant}`}
                            </Typography>
                          </div>
                        </Stack>
                        <Grid container spacing={1}>
                          <Grid item xs={10}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={
                                moraleKpiYearItem.moraleResponse.participant
                                  ? (moraleKpiYearItem.moraleResponse.response /
                                      moraleKpiYearItem.moraleResponse
                                        .participant) *
                                    100
                                  : 0
                              }
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Typography variant="h6">
                              {moraleKpiYearItem.moraleResponse.participant
                                ? `${Math.round(
                                    (moraleKpiYearItem.moraleResponse.response /
                                      moraleKpiYearItem.moraleResponse
                                        .participant) *
                                      100
                                  )}%`
                                : 0}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                    </StyledCardContent>
                  </StyledCard>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <StyledCard>
                <CardContent>
                  <Grid container alignItems={"center"} spacing={4}>
                    <Grid item xs={12}>
                      <Gauge ref={gaugeRef} morale={moraleKpiYearItem} />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledCardRange>
                        <CardContent>
                          <Typography variant="h5" gutterBottom>
                            Global
                          </Typography>
                          <WrapRangeOuter>
                            {data.map((item, index) => {
                              return (
                                <WrapRangeInner value={`${item.value}%`}>
                                  <Stack spacing={1}>
                                    <Typography>{item.title}</Typography>
                                    <StyledColorTab
                                      bgColor={bgColorList[index]}
                                      value={`${item.value}%`}
                                    />
                                    <Typography>{item.range}</Typography>
                                  </Stack>
                                </WrapRangeInner>
                              );
                            })}
                          </WrapRangeOuter>
                        </CardContent>
                      </StyledCardRange>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12}>
              <StyledCard>
                <StyledCardContent>
                  <Typography variant="h5">
                    How are we scoring on key indices
                  </Typography>
                  <StyledDivider />
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 10 }}
                  >
                    {moraleKpiYearItem.moraleTopicAvgAndBest.map(
                      (item, index) => (
                        <Grid item xs={4} sm={4} md={2} key={index}>
                          <Stack spacing={1} alignItems={"center"}>
                            <DoughnutChart
                              value={Math.ceil(item.average)}
                              id={index + 1}
                              doughnutChart={doughnutChart}
                              setDoughnutChart={setDoughnutChart}
                            />
                            <Typography>{item.questionTopic}</Typography>
                          </Stack>
                        </Grid>
                      )
                    )}
                  </Grid>
                </StyledCardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12}>
              <StyledCard>
                <StyledCardContent>
                  <Typography variant="h5">
                    What Is The Distribution Across Engagement Profiles?
                  </Typography>
                  <StyledDivider />
                  <MultiChart ref={multiChartRef} morale={moraleKpiYearItem} />
                </StyledCardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12}>
              <StyledCard>
                <StyledCardContent>
                  <Typography variant="h5">
                    What Are My Top Scoring Questions?
                  </Typography>
                  <StyledDivider />
                  <TableContainer sx={{ borderRadius: "8px" }}>
                    <Table
                      sx={{
                        minWidth: 700,
                        border: "1px solid rgba(224, 224, 224, 1)",
                      }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow
                          sx={{
                            background:
                              "linear-gradient(to right, #84ffc9, #eca0ff)",
                          }}
                        >
                          <TableCell>
                            <Typography fontWeight={500}>Rank</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontWeight={500}>Question</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontWeight={500}>Dimension</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontWeight={500}>Average</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortByAverage(
                          moraleKpiYearItem.moraleTopicAvgAndBest,
                          "descending"
                        )
                          .slice(0, 10)
                          .map((value, index) => (
                            <TableRow>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{value.question}</TableCell>
                              <TableCell>{value.questionTopic}</TableCell>
                              <TableCell>{`${value.average ? value.average.toFixed(2) : 0}%`}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </StyledCardContent>
              </StyledCard>
            </Grid>
          </Grid>
        ) : (
          <Typography variant="h5" textAlign={"center"}>ไม่พบข้อมูล</Typography>
        )}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="error"
            sx={{ width: "100%" }}
          >
            โปรดเลือกชุดคำถาม
          </Alert>
        </Snackbar>
      </Container>
    </DivRoot>
  );
};

export default Report;
