import React, { useState } from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import { SpecialIcon } from "../../../../shared/typeCoin";
import DialogConfig from "./dialogConfig";
import utils from "../../../../../../utils";
import { useCompanyRewardCost, useUniRewardCost } from "../../../../../../quries/reward";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { httpClient } from "../../../../../../services/httpClient";
import { toast } from "sonner";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function RowConfig({ idCompanyReward, idUniReward }) {
  const { data: companyRewardCost } = useCompanyRewardCost({ idCompanyReward });
  const { data: uniRewardCost } = useUniRewardCost({ idUniReward });

  const { state } = useLocation()
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationFn: async (formData) =>
      await httpClient.patch(`reward/${formData.idReward}/status`, formData),
    onSuccess: () => {
      if (state?.value?.idCompanyReward) {
        queryClient.invalidateQueries({
          queryKey: ["rewards", "company-rewards", state?.value?.idCompanyReward, "cost"],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ["rewards", "uni-rewards", state?.value?.idUniReward, "cost"],
        });
      }
      toast.success("สำเร็จ")
    },
    onError: () => {
      toast.error("เกิดข้อผิดพลาด")
    },
  });

  const [open, setOpen] = useState(false);
  const [selectReward, setSelectReward] = useState(null);

  const handleClickOpen = (data) => {
    setSelectReward(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell align={"center"}>วันที่ซื้อ</StyledTableCell>
              <StyledTableCell align={"center"}>SKU</StyledTableCell>
              <StyledTableCell align={"center"}>ตัวเลือก</StyledTableCell>
              <StyledTableCell align={"center"}>ราคา</StyledTableCell>
              <StyledTableCell align={"center"}>จำนวนคงเหลือ</StyledTableCell>
              <StyledTableCell align={"center"}>เหรียญ</StyledTableCell>
              <StyledTableCell align={"center"}>สิ้นสุดการแลก</StyledTableCell>
              <StyledTableCell align={"center"}>สถานะ</StyledTableCell>
              <StyledTableCell align={"center"}>ตั้งค่า</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(uniRewardCost || companyRewardCost)?.map((data) => (
              <TableRow
                key={data.idReward}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell align="center">
                  {dayjs(data.createAt).format("DD/MM/YYYY")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {data.sku}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {data.options.length > 0
                    ? data?.options.map((option) => option.value).join(", ")
                    : "ไม่มี"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {utils.numberWithCommas(data.price)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {`${utils.numberWithCommas(data.quantity)} / ${utils.numberWithCommas(data.initialQuantity)}`}
                </StyledTableCell>
                <StyledTableCell align="center" marginBottom={1}>
                  {data?.coins?.length > 0 ? (
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      marginBottom={1}
                      spacing={2}
                      divider={<Divider orientation="vertical" flexItem />}
                    >
                      {data?.coins?.map((coin) => (
                        <div>
                          <SpecialIcon idCoinType={coin.idCoinType} width={20} />
                          <Typography>{coin.amount}</Typography>
                        </div>
                      ))}
                    </Stack>
                  ) : (
                    <Typography>ไม่ได้กำหนด</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography>
                    {data.endDate ? dayjs(data.endDate).format("DD/MM/YYYY") : "ไม่ได้กำหนด"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Switch
                    checked={data.isActive}
                    onChange={() => mutate({ idReward: data.idReward, isActive: !data.isActive })}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton onClick={() => handleClickOpen(data)}>
                    <SettingsIcon />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Card variant="outlined">
        <CardContent>
          <Grid container rowGap={2}>
            <Grid container item xs={12}>
              <Grid item xs={1} align={"center"}>วันที่ซื้อ</Grid>
              <Grid item xs={2} align={"center"}>SKU</Grid>
              <Grid item xs={1.5} align={"center"}>ตัวเลือก</Grid>
              <Grid item xs={1} align={"center"}>ราคา</Grid>
              <Grid item xs={1.5} align={"center"}>จำนวนคงเหลือ</Grid>
              <Grid item xs={2} align={"center"}>เหรียญ</Grid>
              <Grid item xs={1.5} align={"center"}>สิ้นสุดการแลก</Grid>
              <Grid item xs={1} align={"center"}>สถานะ</Grid>
              <Grid item xs={0.5} align={"center"}>ตั้งค่า</Grid>
              <StyledDivider style={{ marginBottom: 0 }}/>
            </Grid>
            {(uniRewardCost || companyRewardCost)?.map((data) => (
              <Grid key={data.sku} container>
                <Grid item xs={1} align="center">
                  {dayjs(data.createAt).format("DD/MM/YYYY")}
                </Grid>
                <Grid item xs={2} align="center">
                  {data.sku}
                </Grid>
                <Grid item xs={1.5} align="center">
                  {data.options.length > 0
                    ? data?.options.map((option) => option.value).join(", ")
                    : "ไม่มี"}
                </Grid>
                <Grid item xs={1} align="center">
                  {utils.numberWithCommas(data.price)}
                </Grid>
                <Grid item xs={1.5} align="center" paddingLeft={2}>
                  {`${utils.numberWithCommas(data.quantity)} / ${utils.numberWithCommas(data.initialQuantity)}`}
                </Grid>
                <Grid item xs={2} align="center" marginBottom={1}>
                  {data?.coins?.length > 0 ? (
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      marginBottom={1}
                      spacing={2}
                      divider={<Divider orientation="vertical" flexItem />}
                    >
                      {data?.coins?.map((coin) => (
                        <div>
                          <SpecialIcon idCoinType={coin.idCoinType} width={20} />
                          <Typography>{coin.amount}</Typography>
                        </div>
                      ))}
                    </Stack>
                  ) : (
                    <Typography>ไม่ได้กำหนด</Typography>
                  )}
                </Grid>
                <Grid item xs={1.5} align="center">
                  {dayjs(data.endDate).format("DD/MM/YYYY")}
                </Grid>
                <Grid item xs={1} align="center">
                  <Switch
                    checked={data.isActive}
                    onChange={() => mutate({ idReward: data.idReward, isActive: !data.isActive })}
                  />
                </Grid>
                <Grid item xs={0.5} align="center">
                  <IconButton onClick={() => handleClickOpen(data)}>
                    <SettingsIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card> */}
      {open && (
        <DialogConfig
          open={open}
          handleClose={handleClose}
          selectReward={selectReward}
        />
      )}
    </>
  );
}

export default RowConfig;
