import { httpClient } from "./httpClient";

const getAllCoach = () => {
  return httpClient.get("coach");
};

const getCoach = (idCoach) => {
  return httpClient.get("coach/" + idCoach);
};

const getCoachPopular = () => {
  return httpClient.get("coach-popular");
};

const addCoach = (formData) => {
  return httpClient.post("coach", formData);
};

const updateCoach = (formData) => {
  return httpClient.patch("coach", formData);
};

const getCoacheeByIdCoach = (idCoach) => {
  return httpClient.get(`coach/${idCoach}/coachee-list`);
};

export default {
  getAllCoach,
  getCoach,
  getCoachPopular,
  addCoach,
  updateCoach,
  getCoacheeByIdCoach,
};
