import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  FormControlLabel,
  Typography,
  TextField,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Stack,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

import HeaderPage from "../../shared/header/headerPage";
import NotificationIcon from "./assets/notification.svg";
import H1 from "./assets/h1.png";
import H2 from "./assets/h2.png";
import H3 from "./assets/h3.png";
import H4 from "./assets/h4.png";
import H5 from "./assets/h5.png";
import H6 from "./assets/h6.png";
import H7 from "./assets/h7.png";
import H8 from "./assets/h8.png";
import H9 from "./assets/h9.png";
import H10 from "./assets/h10.png";

import { useDispatch, useSelector } from "react-redux";
import { addMoraleDailyAnswer, getMoraleDailyByUserId } from "../../../../actions/moraleDaily";
import * as dayjs from 'dayjs'

const WrapHeader = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
    "& .wrap-button-add": {
      marginTop: 8,
    },
  },
});

const StyledCoinChoice = styled(Avatar)({
  width: 24,
  height: 24,
  fontSize: 14,
  backgroundColor: "#f5cb00",
  color: "#dc8100",
  marginLeft: 10,
  position: "absolute",
  right: 10,
});

const WrapSection3 = styled("div")({
  marginTop: 30,
  width: "100%",
});

const StyledCard = styled(Card)({
  width: 350,
  margin: 5,
  borderRadius: 8,
});

const WrapCardHeaderInsight = styled("div")({
  display: "flex",
  padding: 5,
  width: "70%",
  borderTopRightRadius: 50,
  borderBottomRightRadius: 50,
  justifyContent: "space-between",
  alignItems: "center",
});

const WrapCardHeaderInsight2 = styled("div")({
  display: "flex",
  padding: 5,
  width: "28%",
  borderTopLeftRadius: 50,
  borderBottomLeftRadius: 50,
  alignItems: "center",
  backgroundColor: "#EFEFEF",
  marginLeft: "auto",
});

const WrapCardHeaderInsightImage = styled("div")({
  backgroundColor: "white",
  borderRadius: "50%",
  width: 35,
  height: 35,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
const StyledCardHeaderInsightIcon = styled(Avatar)({
  width: 35,
  height: 35,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFD923",
  color: "#EA8900",
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const StyledDialogContent = styled(DialogContent)({
  width: 340,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .MuiTypography-h6": {
    fontSize: 24,
  },
})

const WrapAnsChoice = styled('div')({
  display: "flex",
  flexDirection: "column",
})

const StyledButtonAns = styled(Button)({
  width: 182,
  margin: 4,
  border: "none",
  backgroundColor: "#FFFFFF7A",
})

const StyledRoot = styled('div')({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 320,
})

const bgCard = [
  "#ff1744",
  "#d500f9",
  "#651fff",
  "#2979ff",
  "#14a37f",
  "#ffc400",
  "#ff9100",
  "#357a38",
  "#f73378",
  "#6D4C41",
];

const getBgCard = (id) => {
  if (id === 0) {
    return "#BFBFBF"
  };
  const idModTen = id % 10
  return bgCard[idModTen]
}

const BtnAnsInsight = ({ 
  handleClickAnswer, 
  idMoraleDaily, 
  insightText, 
  reward 
}) => {
  return (
    <StyledButtonAns
      variant={"contained"}
      onClick={() => handleClickAnswer(idMoraleDaily, insightText)}
    >
      <Typography variant="button">{insightText}</Typography>
      <StyledCoinChoice>{reward}</StyledCoinChoice>
    </StyledButtonAns>
  );
};

const MyMoraleDaily = () => {
  const [dataInsight, setDataInsight] = useState(null);
  const [answerText, setAnswerText] = useState();
  const [openDailyInsight, setOpenDailyInsight] = useState(false);
  const [checkAnonymous, setCheckAnonymous] = React.useState(false);

  const colorBGCardHeader = [
    "#F1C232",
    "#6AA84F",
    "#CC0000",
    "#3C78D8",
    "#D64E91",
  ];

  const handleChangeAnonymous = (event) => {
    setCheckAnonymous(event.target.checked);
  };

  const handleOpenDailyInsight = (value) => {
    setDataInsight(value)
    setOpenDailyInsight(true);
  };

  const handleCloseDailyInsight = () => {
    setOpenDailyInsight(false);
  };

  const handleChangeAnswerText = (event) => {
    setAnswerText(event.target.value);
  };

  const [insightAns, setInsightAns] = React.useState([
    {
      text: "Very Happy",
      status: false,
    },
    {
      text: "Happy",
      status: false,
    },
    {
      text: "Little Happy",
      status: false,
    },
    {
      text: "Little Bad",
      status: false,
    },
    {
      text: "Bad",
      status: false,
    },
    {
      text: "Very Bad",
      status: false,
    },
  ]);

  const handleChangeBgInsight = () => {
    switch (dataInsight.idQuestionTopic) {
      case 1:
        return "#F1C232";
      case 2:
        return "#6AA84F";
      case 3:
        return "#CC0000";   
      case 4:
        return "#3C78D8";
      case 5:
        return "#D64E91";
      default:
        return "#FFF";
    }
  };

  const dispatch = useDispatch();
  const { result: moraleDailyUser } = useSelector((state) => state.moraleDailyUser);
  
  const handleClickAnswer = async (idMoraleDaily, answer) => {
    const res = await dispatch(
      addMoraleDailyAnswer({ idMoraleDaily, answer })
    );
    if(res) {
      dispatch(getMoraleDailyByUserId());
      handleCloseDailyInsight()
    }
  }

  useEffect(() => {
    dispatch(getMoraleDailyByUserId());
  }, []);

  return (
    <StyledRoot className={'page'}>
      <WrapHeader>
        <HeaderPage textLabel={"คำถามสุขภาพใจรายวัน"} icon={NotificationIcon} />
      </WrapHeader>
      <StyledDivider />
      <WrapSection3>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {moraleDailyUser &&
            moraleDailyUser
              .map((data) => {
                return (
                  <StyledCard>
                    <CardContent style={{ padding: 0, paddingTop: 10 }}>
                      <div style={{ display: "flex" }}>
                        <WrapCardHeaderInsight
                          style={{ backgroundColor: getBgCard(data.idQuestionTopic) }}
                        >
                          <Typography style={{ color: "#FFF" }}>
                            {data.questionTopic}
                          </Typography>
                          <WrapCardHeaderInsightImage>
                            <img
                              alt="questionImg"
                              width={24}
                              height={24}
                              src={`${process.env.REACT_APP_API_URL}image/insight/h${data.idQuestionTopic}.png`}
                            />
                          </WrapCardHeaderInsightImage>
                        </WrapCardHeaderInsight>
                        <WrapCardHeaderInsight2>
                          <StyledCardHeaderInsightIcon>
                            {data.reward}
                          </StyledCardHeaderInsightIcon>
                          <Typography style={{ paddingLeft: 5 }}>COIN</Typography>
                        </WrapCardHeaderInsight2>
                      </div>
                      <div style={{ padding: 20 }}>
                        <Typography
                          style={{ textAlign: "center", height: 64 }}
                          variant="h5"
                        >
                          {data.question}
                        </Typography>
                        <Typography
                          style={{ textAlign: "center" }}
                          color="textSecondary"
                          variant="subtitle1"
                        >
                          ประเภทคำตอบ :{" "}
                          {data.questionType === "text"
                            ? "ตัวหนังสือ"
                            : "ตัวเลือก"}
                        </Typography>
                        <Typography
                          style={{ textAlign: "center" }}
                          variant="subtitle1"
                        >
                          {`start: ${dayjs(data.startDate).format("YYYY-MM-DD HH:mm")}`}
                        </Typography>
                        <Typography
                          style={{ textAlign: "center" }}
                          variant="subtitle1"
                        >
                          {`end: ${dayjs(data.endDate).format("YYYY-MM-DD HH:mm")}`}
                        </Typography>
                        <Stack direction={"row"} justifyContent={"center"} marginTop={2}>
                          <Button 
                            variant="contained" 
                            onClick={() => handleOpenDailyInsight(data)} 
                            disabled={data.answer}
                          >
                            {`ตอบคำถาม`}
                          </Button>
                        </Stack>   
                      </div>
                      <Divider />
                    </CardContent>
                  </StyledCard>
                )
              })
          }
        </div>
      </WrapSection3>
        {dataInsight && (
        <Dialog
          open={openDailyInsight}
          onClose={handleCloseDailyInsight}
          aria-labelledby="alert-dialog-title-insight"
          aria-describedby="alert-dialog-description-insight"
        >
          <StyledDialogContent
            style={{ backgroundColor: handleChangeBgInsight() }}
          >
            <Typography variant="h6" align="center" color="#ffffff" gutterBottom>
              {dataInsight.question}
            </Typography>
            {dataInsight.questionType === "choice" ? (
              <WrapAnsChoice>
                {insightAns &&
                  insightAns.map((item, index) => {
                    return (
                      <BtnAnsInsight
                        btnIndex={index}
                        insightText={item.text}
                        insightStatus={item.status}
                        reward={dataInsight.reward}
                        idMoraleDaily={dataInsight.idMoraleDaily}
                        handleClickAnswer={handleClickAnswer}
                      />
                    );
                  })}
                {/* <Link
                  component="button"
                  variant="body2"
                  style={{ color: "#ffffff", marginTop: 16 }}
                  onClick={handleCloseDailyInsight}
                >
                  {"Skip >>"}
                </Link> */}
              </WrapAnsChoice>
            ) : (
              <div>
                <div>
                  <TextField
                    style={{ width: "100%", backgroundColor: "#FFF" }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    value={answerText}
                    onChange={handleChangeAnswerText}
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 100 }}
                  />
                  <FormControlLabel
                    style={{ width: "100%" }}
                    control={
                      <Checkbox
                        checked={checkAnonymous}
                        onChange={handleChangeAnonymous}
                        name="checkedB"
                        color="default"
                      />
                    }
                    label="ส่งโดยไม่ระบุชื่อ"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <StyledButtonAns
                    onClick={() => handleClickAnswer(dataInsight.idMoraleDaily, answerText)}
                  >
                    <div style={{ display: "flex", width: 100 }}>
                      <Typography variant="button">ส่งคำตอบ</Typography>
                      <StyledCoinChoice>
                        {dataInsight.reward}
                      </StyledCoinChoice>
                    </div>
                  </StyledButtonAns>
                  {/* <Link
                    component="button"
                    variant="body2"
                    style={{ color: "#ffffff", marginTop: 16 }}
                    onClick={handleCloseDailyInsight}
                  >
                    {"Skip >>"}
                  </Link> */}
                </div>
              </div>
            )}
          </StyledDialogContent>
        </Dialog>
      )}
    </StyledRoot>
  );
};

export default MyMoraleDaily;
