import {
  HEARTTRANSFER_FETCHING,
  HEARTTRANSFER_FAILED,
  HEARTTRANSFER_SUCCESS,
  HEART_RECEIVE_YEAR_FETCHING,
  HEART_RECEIVE_YEAR_FAILED,
  HEART_RECEIVE_YEAR_SUCCESS,
} from "./types";

import HeartTransferService from "../services/heartTransfer.service";

export const getAllHeartTransfer = () => async (dispatch) => {
  try {
    dispatch({
      type: HEARTTRANSFER_FETCHING,
    });
    const res = await HeartTransferService.getAllHeartTransfer();
    if (res) {
      dispatch({
        type: HEARTTRANSFER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: HEARTTRANSFER_FAILED,
    });
    console.log(err);
  }
};

export const getHeartTransfer = () => async (dispatch) => {
  try {
    dispatch({
      type: HEARTTRANSFER_FETCHING,
    });
    const res = await HeartTransferService.getHeartTransfer();
    if (res) {
      dispatch({
        type: HEARTTRANSFER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: HEARTTRANSFER_FAILED,
    });
    console.log(err);
  }
};

export const addHeartTransfer = (formData) => async (dispatch) => {
  try {
    const res = await HeartTransferService.addHeartTransfer(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: HEARTTRANSFER_FAILED,
    });
    console.log(err);
  }
};

export const updateHeartTransfer = (formData) => async (dispatch) => {
  try {
    const res = await HeartTransferService.updateHeartTransfer(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: HEARTTRANSFER_FAILED,
    });
    console.log(err);
  }
};

export const getHeartReceiveYear = () => async (dispatch) => {
  try {
    dispatch({
      type: HEART_RECEIVE_YEAR_FETCHING,
    });
    const res = await HeartTransferService.getHeartReceiveYear();
    if (res) {
      dispatch({
        type: HEART_RECEIVE_YEAR_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: HEART_RECEIVE_YEAR_FAILED,
    });
  }
};
