import React from "react";
import { styled } from "@mui/material/styles";
import Chip from '@mui/material/Chip';
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import InfoIcon from "@mui/icons-material/Info";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";


const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: "left",
  "& .icon": {
    color: "inherit",
  },
  "&.book": {
    color: theme.palette.info.main,
    border: `1px solid ${theme.palette.info.main}`,
  },
  "&.approve": {
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
  },
  "&.reject": {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
  "&.success": {
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
  },
}));

const CoachingStatus = (props) => {
  const { status } = props;
  let icon = null;

  if (status === "book") {
    icon = <InfoIcon className={"icon"} />;
  } else if (status === "approve") {
    icon = <DoneIcon className={"icon"} />;
  } else if (status === "reject") {
    icon = <ReportProblemIcon className={"icon"} />;
  } else if (status === "success") {
    icon = <DoneAllIcon className={"icon"} />;
  }

  return (
    <StyledChip
      className={status}
      icon={icon}
      label={status}
      variant={"outlined"}
    />
  );
};
export default CoachingStatus;
