import React from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  Alert,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import UpdateRewardImage from "../../../shared/uploadReward/updateRewardImage";
import InputField from "../../../shared/inputForm/inputField";

function NestedVariationField({ nestIndex, control, errors, items }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `variation[${nestIndex}].option`,
  });

  const variationOption = useWatch({
    control,
    name: `variation[${nestIndex}].option`,
  });

  const checkVariationOption = (option) => {
    return option.hasOwnProperty("idVariationOption");
  };

  const findImageOption = (index) => {
    const option = items.find((item) =>
      item.options.find(
        (option) => option.value === variationOption[index]?.value
      )
    );

    return { image: option ? option.image : null };
  };

  return (
    <>
      {fields.map((item, index) => (
        <Grid key={item.id} item xs={12}>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            spacing={1}
            marginBottom={1}
          >
            <InputField
              name={`variation[${nestIndex}].option[${index}].value`}
              control={control}
              type={"text"}
              placeholder={"ใส่ข้อมูลของตัวเลือก"}
              InputProps={{
                readOnly: checkVariationOption(item),
              }}
            />
            {nestIndex === 0 && (
              <Controller
                name={`variation[${nestIndex}].option[${index}].image`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <UpdateRewardImage
                    {...field}
                    value={field.value ?? findImageOption(index)}
                    acceptFileType={"image/jpeg, image/png, image/webp"}
                    error={error}
                    indexImage={index}
                    title={variationOption[index]?.name}
                  />
                )}
              />
            )}
            <IconButton
              color="error"
              disabled={fields.length === 1 || checkVariationOption(item)}
              onClick={() => remove(index)}
            >
              <BackspaceIcon />
            </IconButton>
          </Stack>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="outlined"
          disabled={fields.length === 20}
          onClick={() => append({ value: "", image: null })}
        >
          {`เพิ่มตัวเลือก (${fields.length}/20)`}
        </Button>
        {errors?.variation?.[nestIndex]?.option?.message && (
          <Alert severity="error">
            {errors.variation[nestIndex].option.message}
          </Alert>
        )}
      </Grid>

      {/* {nestIndex === 0 && (
        <SwitchInput
          name="addOptionImage"
          control={control}
          label={"เพิ่มรูปภาพ"}
        />
      )} */}
      {/* {nestIndex === 0 && addOptionImage && (
        <Grid container spacing={2}>
          {variation.map((item, index) => {
            return (
              <Grid item key={item.index}>
                <Controller
                  name={`variation[${nestIndex}].option[${index}].image`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <UploadFile
                      {...field}
                      // acceptFileType={"image/jpeg, image/png"}
                      error={error}
                      indexImage={index}
                      title={item.name}
                    />
                  )}
                />
              </Grid>
            );
          })}
        </Grid>
      )} */}
    </>
  );
}

export default NestedVariationField;
