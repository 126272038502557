import React, { useState, useEffect, Fragment, useMemo } from "react";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Grid,
  Typography,
  Paper,
  LinearProgress,
  AccordionSummary,
  AccordionDetails,
  Chip,
  linearProgressClasses,
  Stack,
  Snackbar,
  Alert,
  Button,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";

import HowToRegIcon from "@mui/icons-material/HowToReg";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import { useDispatch, useSelector } from "react-redux";
import { getAllMoraleKPI, getMoraleKPI } from "../../../../actions/moraleKPI";

import clsx from "clsx";
import { Bar } from "react-chartjs-2";
import MenuDate from "./menuDate";
import Filter from "./filter";
import FeelingIcon from "./../feelingIcon";
import unicornCry from "./assets/Unicorn-Mascot-Cry.png";
import unicornHeart from "./assets/Unicorn-Mascot-Heart.png";
import unicornIndifferently from "./assets/Unicorn-Mascot-Indifferently.png";
import { useEmployeeLevel, useEmploymentType, useOrganization } from "../../../../quries/company";
import { useMoraleCompany, useMoraleKPI } from "../../../../quries/morale";
import MoraleDetail from "./detail";

const BorderLinearProgress = styled(LinearProgress)({
  [`&.${linearProgressClasses.root}`]: {
    height: 10,
    borderRadius: 5,
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#333333",
  },
  [`&.${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
});

const BorderLinearProgressDetail = styled(LinearProgress)({
  [`&.${linearProgressClasses.root}`]: {
    height: 5,
    borderRadius: 5,
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#333333",
  },
  [`&.${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#f66817",
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`&.${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const colorSet = [
  "#ff5100",
  "#ff9000",
  "#ebc400",
  "#9fc914",
  "#12b09b",
  "#127eb0",
  "#5053a3",
  "#6939a3",
  "#a3399e",
  "#a3395e",
];

const colorSelect = (score) => {
  if (score === 100) {
    return "#178867";
  } else if (score >= 80) {
    return "#649c18";
  } else if (score >= 60) {
    return "#caa401";
  } else if (score >= 40) {
    return "#cb6600";
  } else if (score >= 20) {
    return "#bc293a";
  } else {
    return "#cc3426";
  }
};

const optionsTopic = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        ticks: {
          fontColor: "#CCC", // this here
        },
        gridLines: {
          display: true,
          color: "rgb(40,40,40)",
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontColor: "#CCC", // this here
          suggestedMin: 0,
          suggestedMax: 100,
        },
        gridLines: {
          display: true,
          color: "rgb(40,40,40)",
        },
      },
    ],
  },
};

const StyledResultIcon = styled("div")({
  display: "flex",
  justifyContent: "center",
  // margin: "32px 0",
});

const WrapSummaryAnswerDepartment = styled(Paper)({
  backgroundColor: "#424242",
  padding: "12px 16px",
  minHeight: 48,
  "& .label": {
    display: "flex",
    justifyContent: "space-between",
    "& .label-left": {
      display: "flex",
      "& .MuiSvgIcon-root, .MuiTypography-root": {
        color: "#FFFFFF",
      },
    },
  },
});

const StyledAccordion = styled(Accordion)({
  backgroundColor: "#424242",
  "& .label": {
    display: "flex",
    justifyContent: "space-between",
    "& .label-left": {
      display: "flex",
      "& .MuiSvgIcon-root, .MuiTypography-root": {
        color: "#FFFFFF",
      },
    },
  },
});

const WrapSummaryTopic = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  "& .cardTopicSummary": {
    opacity: 0.5,
    width: 256,
    padding: "8px 16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    "& .summary": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontSize: 45,
      },
      // "& .veryhappy": {
      //   color: "#447c20",
      // },
      // "& .happy": {
      //   color: "#6c9125",
      // },
      // "& .littlehappy": {
      //   color: "#6c9125",
      // },
      // "& .littlebad": {
      //   color: "#cc7f2e",
      // },
      // "& .bad": {
      //   color: "#cc7f2e",
      // },
      // "& .verybad": {
      //   color: "#a72523",
      // },
    },
    ["@media only screen and (max-width:600px)"]: {
      width: "100%",
    },
  },
  "& .active": {
    opacity: 1,
  },
  "& .veryhappy": {
    backgroundColor: "#178867",
  },
  "& .happy": {
    backgroundColor: "#649c18",
  },
  "& .littlehappy": {
    backgroundColor: "#caa401",
  },
  "& .littlebad": {
    backgroundColor: "#cb6600",
  },
  "& .bad": {
    backgroundColor: "#bc293a",
  },
  "& .verybad": {
    backgroundColor: "#cc3426",
  },
});

const StyledChipLabel = styled(Chip)({
  marginTop: 16,
  marginRight: 8,
  backgroundColor: "#FFFFFF",
});

const StyledAutocomplete = styled(Autocomplete)({
  backgroundColor: 'rgba(255,255,255,0.08)',
  '& .MuiOutlinedInput-root': {
    color: '#ffffff',
  },
  'fieldset': {
    borderColor: '#ffffff',
  },
  '& .MuiFormLabel-root,& .MuiFormLabel-root.Mui-focused': {
    color: '#ffffff',
  },
})

const RenderSelectedFilter = ({ selectedFilter, handleToggle }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {Object.keys(selectedFilter)
        .filter(
          (element) => selectedFilter[element].itemList?.some((item) => item.checked)
        )
        .map((element) => {
          return (
            <div style={{ display: "flex", gap: "8px" }}>
              <Chip 
                icon={<FilterAltIcon />} 
                label={element}
                color="primary"
              />
              {selectedFilter[element].itemList
                .filter((item) => item.checked)
                .map((item) => (
                  <Chip
                    variant="outlined"
                    label={item.text}
                    sx={{ color: "#ffffff" }}
                    // onDelete={() => handleToggle(element, item.text)}
                  />
                ))}
            </div>
          );
        })}
    </div>
  );
};

const Monthly = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [moraleSelected, setMoraleSelected] = useState(null);
  const [companySelected, setCompanySelected] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState({ departmentName: 'All Department', idDepartment: 0 });
  const [sectionSelected, setSectionSelected] = useState({ sectionName: 'All Section', idSection: 0 });
  const [filterSelected, setFilterSelected] = useState(null);

  const organization = useOrganization();
  const moraleCompany = useMoraleCompany({ idCompany: companySelected?.idCompany });
  const employmentType = useEmploymentType({ idCompany: companySelected?.idCompany });
  const employmentLevel = useEmployeeLevel({ idCompany: companySelected?.idCompany });

  useEffect(() => {
    if (moraleCompany?.data?.length > 0) {
      setMoraleSelected(moraleCompany.data[0])
    }
  }, [moraleCompany.data])

  useEffect(() => {
    const filterData = {
      gender: {
        label: "Gender",
        itemList: [
          { value: "Female", text: "Female", checked: false },
          { value: "Male", text: "Male", checked: false },
        ],
      },
      generation: {
        label: "Generation",
        itemList: [
          {
            value: "babyBoomer",
            text: "Baby Boomer",
            checked: false,
          },
          {
            value: "genX",
            text: "Generation X",
            checked: false,
          },
          {
            value: "genY",
            text: "Generation Y",
            checked: false,
          },
          {
            value: "genZ",
            text: "Generation Z",
            checked: false,
          },
          {
            value: "alpha",
            text: "Alpha",
            checked: false,
          },
        ],
      },
      jobLevel: {
        label: "Job Level",
        itemList: employmentLevel?.data?.map((item) => ({
          value: item.idLevel,
          text: item.levelName,
          checked: false,
        })),
      },
      jobType: {
        label: "Job Type",
        itemList: employmentType?.data?.map(item => ({
          value: item.idEmploymentType,
          text: item.employmentTypeName,
          checked: false,
        }))
      },
    }

    setFilterSelected({ ...filterData })
  }, [employmentType.data, employmentLevel.data]);

  // const moraleKPI = useMoraleKPI({ idMorale: moraleCompany?.data?.[0]?.idMorale, query: {} });
  
  // const [selectedFilter, setSelectedFilter] = useState([
  //   {
  //     key: "gender",
  //     label: "Gender",
  //     itemList: [
  //       { text: "Female", checked: false, value: "gender = 'F'" },
  //       { text: "Male", checked: false, value: "gender = 'M'" },
  //     ],
  //   },
  //   {
  //     key: "generation",
  //     label: "Generation",
  //     itemList: [
  //       {
  //         text: "Baby Boomer",
  //         checked: false,
  //         value: "YEAR(birthDate) BETWEEN 1946 AND 1964",
  //       },
  //       {
  //         text: "Generation X",
  //         checked: false,
  //         value: "YEAR(birthDate) BETWEEN 1965 AND 1980",
  //       },
  //       {
  //         text: "Generation Y",
  //         checked: false,
  //         value: "YEAR(birthDate) BETWEEN 1981 AND 1996",
  //       },
  //       {
  //         text: "Generation Z",
  //         checked: false,
  //         value: "YEAR(birthDate) BETWEEN 1997 AND 2012",
  //       },
  //       {
  //         text: "Alpha",
  //         checked: false,
  //         value: "YEAR(birthDate) BETWEEN 2013 AND 2025",
  //       },
  //     ],
  //   },
  //   {
  //     key: "jobLevel",
  //     label: "Job Level",
  //     itemList: [
  //       { text: "O0", checked: false, value: "plESOP = 'O0'" },
  //       { text: "O3", checked: false, value: "plESOP = 'O3'" },
  //       { text: "O4", checked: false, value: "plESOP = 'O4'" },
  //       { text: "O5", checked: false, value: "plESOP = 'O5'" },
  //       { text: "S1", checked: false, value: "plESOP = 'S1'" },
  //       { text: "S2", checked: false, value: "plESOP = 'S2'" },
  //       { text: "S3", checked: false, value: "plESOP = 'S3'" },
  //       { text: "S4", checked: false, value: "plESOP = 'S4'" },
  //       { text: "M1", checked: false, value: "plESOP = 'M1'" },
  //       { text: "M2", checked: false, value: "plESOP = 'M2'" },
  //       { text: "M3", checked: false, value: "plESOP = 'M3'" },
  //     ],
  //   },
  //   {
  //     key: "jobType",
  //     label: "Job Type",
  //     itemList: [
  //       {
  //         text: "Permanent Employee",
  //         checked: false,
  //         value: "employeeGroupCode = 'B'",
  //       },
  //       {
  //         text: "Contract Employee",
  //         checked: false,
  //         value: "employeeGroupCode = 'C'",
  //       },
  //     ],
  //   },
  // ]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleClickApply = (filterData) => {
    setFilterSelected({ ...filterData });
  };

  const handleClearFilter = () => {
    setFilterSelected({
      gender: {
        label: "Gender",
        itemList: [
          { value: "Female", text: "Female", checked: false },
          { value: "Male", text: "Male", checked: false },
        ],
      },
      generation: {
        label: "Generation",
        itemList: [
          {
            value: "babyBoomer",
            text: "Baby Boomer",
            checked: false,
          },
          {
            value: "genX",
            text: "Generation X",
            checked: false,
          },
          {
            value: "genY",
            text: "Generation Y",
            checked: false,
          },
          {
            value: "genZ",
            text: "Generation Z",
            checked: false,
          },
          {
            value: "alpha",
            text: "Alpha",
            checked: false,
          },
        ],
      },
      jobLevel: {
        label: "Job Level",
        itemList: employmentLevel?.data?.map((item) => ({
          value: item.idLevel,
          text: item.levelName,
          checked: false,
        })),
      },
      jobType: {
        label: "Job Type",
        itemList: employmentType?.data?.map(item => ({
          value: item.idEmploymentType,
          text: item.employmentTypeName,
          checked: false,
        }))
      },
    });
  };

  useEffect(() => {
    if (organization.isSuccess) {
      setCompanySelected(organization.data[0])
    }
  }, [organization.isSuccess])

  if (organization.isPending) {
    return <CircularProgress />
  }

  return (
    <Fragment>
      <Typography variant="h6" gutterBottom style={{ color: "#e2e2e2" }}>
        สรุปผลการประเมิน
      </Typography>
        <Fragment>
          {userProfile?.roles?.includes("ROLE_ADMIN") && employmentType.data && employmentLevel.data && (
            <>
              <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "24px" }}>
                <Filter
                  filter={filterSelected}
                  handleClickApply={handleClickApply}
                  handleClearFilter={handleClearFilter}
                  CustomButton={Button}
                />
              </div>
              <div style={{ marginBottom: "24px" }}>
                {filterSelected && (
                  <RenderSelectedFilter
                    selectedFilter={filterSelected}
                    // handleToggle={handleToggle}
                  />
                )}
              </div>
            </>
          )}
          <Grid container spacing={2} marginBottom={4}>
            <Grid item xs={12} sm={6} md={3}>
              <StyledAutocomplete
                disableClearable
                value={companySelected}
                options={organization.data}
                onChange={(event, newValue) => {
                  setCompanySelected(newValue)
                  setMoraleSelected(null)
                  setDepartmentSelected(null)
                  setSectionSelected(null)
                }}
                getOptionLabel={(option) => option.companyName}
                isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                renderInput={(params) => <TextField {...params} label="Company" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledAutocomplete
                disableClearable
                value={moraleSelected}
                options={moraleCompany.data}
                loading={moraleCompany.isPending}
                onChange={(event, newValue) => setMoraleSelected(newValue)}
                getOptionLabel={(option) => option.moraleName}
                isOptionEqualToValue={(option, value) => option.idMorale === value.idMorale}
                renderInput={(params) => <TextField {...params} label="Morale" />}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={3}>
              <StyledAutocomplete
                disableClearable
                value={departmentSelected}
                options={
                  companySelected
                    ? [
                        { departmentName: "All Department", idDepartment: 0 },
                        ...companySelected.department,
                        // ...department.filter((dep) =>
                        //   departmenOfMorale.includes(dep.idDepartment)
                        // ),
                      ]
                    : []
                }
                onChange={(event, newValue) => setDepartmentSelected(newValue)}
                getOptionLabel={(option) => option.departmentName}
                isOptionEqualToValue={(option, value) => option.idDepartment === value.idDepartment}
                renderInput={(params) => <TextField {...params} label="Department" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledAutocomplete
                disableClearable
                value={sectionSelected}
                options={
                  companySelected
                    ? [
                        { sectionName: "All Section", idSection: 0 },
                        ...companySelected.section,
                      ]
                    : []
                }
                onChange={(event, newValue) => setSectionSelected(newValue)}
                getOptionLabel={(option) => option.sectionName}
                isOptionEqualToValue={(option, value) => option.idSection === value.idSection}
                renderInput={(params) => <TextField {...params} label="Section" />}
              />
            </Grid> */}
          </Grid>
          {moraleSelected && (
            <MoraleDetail
              idMorale={moraleSelected.idMorale}
              filterSelected={filterSelected}
            />
          )}
          {/* <Typography variant="h4" textAlign={"center"} color="#e2e2e2">
            {`${
              moraleKPI.data?.moraleResponse.length > 0
                ? `${moraleKPI.data.moraleName}`
                : "ไม่พบข้อมูล"
            }`}
          </Typography>
          <Typography variant="h5" textAlign={"center"} color="#ffffff">
            {`Total score: ${avgScore}%`}
          </Typography>
          <StyledResultIcon>
            <img
              src={unicornImage(avgScore)}
              alt="unicorn"
              width={720}
              height={405}
            />
          </StyledResultIcon> */}
          {/* {} */}
        </Fragment>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          โปรดเลือกชุดคำถาม
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default Monthly;
