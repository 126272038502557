import React, { Fragment, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Box,
  Popper,
  IconButton,
  Chip,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import EditIcon from "@mui/icons-material/Edit";
import StyledCard from "../../../shared/general/Card";
import { getAnswerByUserId } from "../../../../../actions/assessment";
import DialogSelectSample from "./DialogSelectSample";
import { getAllAppraisee, getAppraisee } from "../../../../../actions/appraisee";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF",
  "& .wrapHeader": {
    display: "flex",
    alignItems: "center",
    marginBottom: 36,
    ["@media only screen and (max-width:600px)"]: {
      flexDirection: "column",
    },
  },
  "& .wrap-questions": {
    marginTop: 24,
    "& .MuiList-root": {
      maxWidth: "100%",
    },
  },
});

const StyledWrapAssignAndSampleSize = styled("div")({
  padding: 24,
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  "& .item": {
    minWidth: 300,
  },
});

const StyledWrapListQuestion = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledBoxSearch = styled(Box)({
  width: 350,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      margin: "0 8px",
      borderRadius: 8,
      paddingLeft: 8,
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledWrapListSearch = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .MuiAvatar-root": {
    marginRight: 16,
  },
});

const StyledDivider = styled(Divider)({
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
});

const StyledChip = styled(Chip)(({ mode }) => ({
  width: 120,
  marginRight: 16,
  ...(mode === "boss" && {
    borderColor: "#ff3034",
    color: "#ffffff",
    backgroundColor: "#ff3034",
  }),
  ...(mode === "peer" && {
    borderColor: "#ffc700",
    color: "#ffffff",
    backgroundColor: "#ffc700",
  }),
  ...(mode === "project" && {
    borderColor: "#008dd1",
    color: "#ffffff",
    backgroundColor: "#008dd1",
  }),
  ...(mode === "subordinate" && {
    borderColor: "#00bdb1",
    color: "#ffffff",
    backgroundColor: "#00bdb1",
  }),
  ...(mode === "cross function" && {
    borderColor: "#7d55c2",
    color: "#ffffff",
    backgroundColor: "#7d55c2",
  }),
}));

const AnswerAssessment360 = () => {
  const navigate = useNavigate();
  const { state: { idAppraisee } } = useLocation();

  const dispatch = useDispatch();
  const { result: appraisee } = useSelector((state) => state.appraiseeItem);
  const [open, setOpen] = React.useState(false);
  const [selectSampleSize, setSelectSampleSize] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    const findSampleSize = appraisee.sampleSize.find(
      (item) => item.idAppraisee_SampleSize === value
    );
    setSelectSampleSize(findSampleSize)
    setOpen(false);
  };

  useEffect(() => {
    if (idAppraisee) {
      dispatch(getAppraisee(idAppraisee));
    } else {
      navigate('/admin/assessment360', { replace: true })
    }
  }, []);

  useEffect(() => {
    if (appraisee) {
      setSelectSampleSize(appraisee.sampleSize[0])
    }
  }, [appraisee]);

  return (
    <StyledRoot className={`page`}>
      {appraisee && selectSampleSize && (
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom>
            Answer Assessment 360°
          </Typography>
          <StyledCard>
            <StyledWrapAssignAndSampleSize>
              <Fragment>
                <div className="item">
                  <Typography variant="h5" align="right">
                    {`${appraisee.firstname_TH} ${appraisee.lastname_TH}`}
                  </Typography>
                  <Typography color="text.secondary" align="right">
                    {appraisee.positionName}
                  </Typography>
                  {appraisee && (
                    <Typography
                      color="text.secondary"
                      style={{ fontWeight: 500 }}
                      align="right"
                    >
                      {appraisee.departmentName}
                    </Typography>
                  )}
                </div>
                <div>
                  <Typography variant="h3">VS</Typography>
                </div>
                <div className="item">
                  <div style={{ display: "flex" }}>
                    <Typography variant="h5" align="left">
                      {`${selectSampleSize.firstname_TH} ${selectSampleSize.lastname_TH}`}
                    </Typography>
                    <IconButton
                      onClick={handleClickOpen}
                      aria-label="edit"
                      size="small"
                      style={{ marginLeft: 8 }}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </div>

                  <Typography color="text.secondary" align="left">
                    {`${selectSampleSize.positionName}`}
                  </Typography>
                  {selectSampleSize.departmentName && (
                    <Typography
                      color="text.secondary"
                      style={{ fontWeight: 500 }}
                      align="left"
                      gutterBottom
                    >
                      {selectSampleSize.departmentName}
                    </Typography>
                  )}
                  <StyledChip
                    label={selectSampleSize.sampleSizeName}
                    mode={selectSampleSize.sampleSizeName}
                  />
                  {/* {assessmentAnswer && (
                  <StyledBoxSearch>
                    <StyledAutocomplete
                      value={search}
                      disableClearable
                      options={assessmentAnswer.sampleSize}
                      onChange={(event, newValue) => {
                        setSearch(newValue);
                      }}
                      popupIcon={<i class="fa-light fa-chevron-down"></i>}
                      getOptionLabel={(option) =>
                        `${option.sampleFirstName} ${option.sampleLastName}`
                      }
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          <StyledWrapListSearch>
                            <Avatar></Avatar>
                            <Typography>
                              {option.sampleFirstName} {option.sampleLastName}
                            </Typography>
                          </StyledWrapListSearch>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder={"Sample List"}
                        />
                      )}
                      PopperComponent={StyledPopper}
                      noOptionsText={"ไม่พบข้อมูล"}
                    />
                  </StyledBoxSearch>
                )} */}
                </div>
              </Fragment>
            </StyledWrapAssignAndSampleSize>
          </StyledCard>
          <div className="wrap-questions">
            <List
              style={{
                width: "100%",
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Questions list
                </ListSubheader>
              }
            >
              {appraisee &&
                appraisee &&
                // assessmentAnswer
                appraisee.questionList.map((item, index) => (
                  <Fragment key={index}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>{index + 1}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <StyledWrapListQuestion>
                            <Typography>{item.question}</Typography>
                            {/* <Typography variant="h4">{rand()}</Typography> */}
                            {selectSampleSize.answerList.length > 0 && (
                              <Typography
                                variant={
                                  appraisee.assessmentType === "close"
                                    ? "h4"
                                    : "body1"
                                }
                              >
                                {
                                  selectSampleSize.answerList.find(
                                    (value) =>
                                      value.idAssessment_Question ===
                                      item.idAssessment_Question
                                  ).answer
                                }
                              </Typography>
                            )}
                          </StyledWrapListQuestion>
                        }
                      />
                    </ListItem>
                    <StyledDivider variant="inset" />
                  </Fragment>
                ))}
            </List>
          </div>
        </Container>
      )}
      {open && (
        <DialogSelectSample
          open={open}
          onClose={handleClose}
          appraiseeList={appraisee.sampleSize}
          selectSampleSize={selectSampleSize}
        />
      )}
    </StyledRoot>
  );
};

export default AnswerAssessment360;
