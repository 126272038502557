import { 
  MORALEQUESTIONS_FETCHING, 
  MORALEQUESTIONS_FAILED, 
  MORALEQUESTIONS_SUCCESS,
  MORALEQUESTION_ITEM_FETCHING, 
  MORALEQUESTION_ITEM_FAILED,
  MORALEQUESTION_ITEM_SUCCESS,
  MORALEQUESTION_USER_FETCHING,
  MORALEQUESTION_USER_FAILED,
  MORALEQUESTION_USER_SUCCESS,
} from "./types";
  
import MoraleQuestionsService from "../services/morale-question.service";

export const getAllMorale = () => async (dispatch) => {
  try {
    dispatch({
      type: MORALEQUESTIONS_FETCHING,
    });
    const res = await MoraleQuestionsService.getAllMorale();
    if (res) {
      dispatch({
        type: MORALEQUESTIONS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTIONS_FAILED,
    });
    console.log(err);
  }
};

//Morale
export const getMorale = (idMorale) => async (dispatch) => {
  try {
    dispatch({
      type: MORALEQUESTION_ITEM_FETCHING,
    });
    const res = await MoraleQuestionsService.getMorale(idMorale);
    if (res) {
      dispatch({
        type: MORALEQUESTION_ITEM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTION_ITEM_FAILED,
    });
    console.log(err);
  }
};

export const addMorale = (formData) => async (dispatch) => {
  try {
    const res = await MoraleQuestionsService.addMorale(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTIONS_FAILED,
    });
    console.log(err);
  }
};

export const updateMorale = (formData) => async (dispatch) => {
  try {
    const res = await MoraleQuestionsService.updateMorale(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTIONS_FAILED,
    });
    console.log(err);
  }
};

export const deleteMorale = (idMorale) => async (dispatch) => {
  try {
    const res = await MoraleQuestionsService.deleteMorale(idMorale);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTIONS_FAILED,
    });
    console.log(err);
  }
};

//Question
export const addMoraleQuestion = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: MORALEQUESTIONS_FETCHING,
    });
    const res = await MoraleQuestionsService.addMoraleQuestion(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTIONS_FAILED,
    });
  }
};

export const updateMoraleQuestion = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: MORALEQUESTIONS_FETCHING,
    });
    const res = await MoraleQuestionsService.updateMoraleQuestion(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTIONS_FAILED,
    });
  }
};

export const deleteMoraleQuestion = (idQuestion) => async (dispatch) => {
  try {
    const res = await MoraleQuestionsService.deleteMoraleQuestion(idQuestion);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTIONS_FAILED,
    });
  }
};

export const addMoraleParticipant = (formData) => async (dispatch) => {
  try {
    const res = await MoraleQuestionsService.addMoraleParticipant(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTIONS_FAILED,
    });
  }
};

export const addMoraleAnswer = (formData) => async (dispatch) => {
  try {
    const res = await MoraleQuestionsService.addMoraleAnswer(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTIONS_FAILED,
    });
  }
};

export const addMoraleQuestionFeedback = (formData) => async (dispatch) => {
  try {
    const res = await MoraleQuestionsService.addMoraleQuestionFeedback(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTIONS_FAILED,
    });
  }
};

export const getMoraleUser = () => async (dispatch) => {
  try {
    dispatch({
      type: MORALEQUESTION_USER_FETCHING,
    });
    const res = await MoraleQuestionsService.getMoraleUser();
    if (res) {
      dispatch({
        type: MORALEQUESTION_USER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTION_USER_FAILED,
    });
  }
};
