import React, { useRef, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import "chartjs-plugin-doughnutlabel";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import {
  Typography,
  FormControl,
  MenuItem,
  Grid,
  Divider,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Select from "@mui/material/Select";
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import ToggleButton, { toggleButtonClasses } from '@mui/material/ToggleButton';
import ToggleButtonGroup, { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import GridOnIcon from "@mui/icons-material/GridOn";
import dayjs from "dayjs";

import { getDepartment } from "./../../../../actions/department";
import { getMoraleCompare } from "../../../../actions/moraleKPI";
import { getAllQuestionTopic } from "../../../../actions/questionTopic";

const StyledFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    color: "#fff",
  },
})

const StyledFormControl = styled(FormControl)({
  width: 400,
  marginRight: 16,
  "& .MuiInputBase-root": {
    color: "#FFFFFF",
    "& .MuiSelect-root": {
      backgroundColor: "transparent",
    },
    "& .MuiSvgIcon-root": {
      color: "#FFFFFF",
    },
  },
})

const WrapAvgTopic = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: 8,
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
    marginTop: 16
  },
})

const CustomSelect = styled(InputBase)(({ theme }) => ({
  [`& .${inputBaseClasses.root}`]: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  [`& .${inputBaseClasses.input}`]: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    color: "#000000",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
    },
  }
}))

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`${toggleButtonGroupClasses.grouped}`] : {
    margin: theme.spacing(0.5),
    height: 34,
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  }
}))

const backgroundColorList = [
  'rgb(255, 99, 132)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  'rgb(153, 102, 255)',
  'rgb(255, 99, 132)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  'rgb(153, 102, 255)',
  'rgb(201, 203, 207)',
]

const borderColor = [
  'rgba(255, 99, 132, 0.5)',
  'rgba(255, 159, 64, 0.5)',
  'rgba(255, 205, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)',
  'rgba(54, 162, 235, 0.5)',
  'rgba(153, 102, 255, 0.5)',
  'rgba(255, 99, 132, 0.5)',
  'rgba(255, 159, 64, 0.5)',
  'rgba(255, 205, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)',
  'rgba(54, 162, 235, 0.5)',
  'rgba(153, 102, 255, 0.5)',
  'rgba(201, 203, 207, 0.5)',
]

const backgroundColorQuestion =  [
  "rgb(53,185,233)",
  "rgb(155,85,229)",
  "rgb(255, 99, 132)",
  "rgb(252,193,3)",
  "rgb(67,217,191)",
]

export default function Compare({ role }) {
  const dispatch = useDispatch();
  const { result: department } = useSelector((state) => state.department);
  const { result: questionTopic } = useSelector((state) => state.questionTopic);
  const { result: moraleCompare } = useSelector((state) => state.moraleCompare);
  
  const [filterDep, setFilterDep] = React.useState(0);
  const [selectedTopic, setSelectedTopic] = React.useState(0);
  const [filterYear, setFilterYear] = React.useState({
    firstYear: true,
    secondYear: true,
    thirdYear: true,
    fourthYear: true,
    fifthYear: true,
  });

  // Onload
  useEffect(() => {
    dispatch(getMoraleCompare())
    dispatch(getDepartment());
    dispatch(getAllQuestionTopic())
  }, []);

  const handleChangeFilterYear = (event) => {
    setFilterYear({ ...filterYear, [event.target.name]: event.target.checked });
  };

  const handleChangeFilterDepartment = (event) => {
    setFilterDep(event.target.value);
  };

  const handleChangeSelectedTopic = (event) => {
    setSelectedTopic(event.target.value);
  };

  const dataDepartment = (data, id) => {
    const arr = []  
    for(const element of data) {
      const department = element.moraleDepAvgAndBest.find(dep => dep.idDepartment === id)
      if(department){
        arr.push(Math.ceil(department.average))
      } else {
        arr.push(0)
      }
    }
    return arr
  }
  
  const dataTopic = (data) => {
    const arr = []
    for(const element of data) {
      const topic = element.moraleTopicAvgAndBest.find(item => item.idQuestionTopic === selectedTopic)
      if(topic){
        arr.push(Math.ceil(topic.average))
      } else {
        arr.push(0)
      }
    }
    return arr
  }

  const dataQuestion = () => {
    const mySet = new Set()
    const myArr = []
    for(const morale of moraleCompare) {
      const moraleTopic = morale.moraleTopicAvgAndBest
      for(const topic of moraleTopic) {
        if(topic.idQuestionTopic === selectedTopic) {
          const moralequestion = topic.moraleQuestion
          for(const question of moralequestion) {
            if(mySet.has(question.idQuestion)) {
              break;
            } else {
              mySet.add(question.idQuestion)
              myArr.push(question)
            }
          }
        }
      }
    }
    if(myArr.length === 0) return []

    const temp = []
    for(const morale of moraleCompare) {
      const moraleTopic = morale.moraleTopicAvgAndBest
      for(const topic of moraleTopic) {
        if(topic.idQuestionTopic === selectedTopic) {
          const moralequestion = topic.moraleQuestion
          for(const question of moralequestion) {
            if(question.questionType === 'choice') {
              temp.push({ 
                idMorale: morale.idMorale,
                moraleName: morale.moraleName,
                idQuestion: question.idQuestion,
                question: question.question,
                score: question.score.average ? question.score.average : 0,
              })
            }
          }
        }
      }
    }

    for(const question of myArr) {
      const a = []
      for(const x of temp) {
        if(x.idQuestion === question.idQuestion) {
          a.push({ compare: x })
        }
      }
      question.compare = [...a]
    }
    console.log(myArr)
    return myArr
  }

  return (
    <Fragment>
      <Typography variant="h6" style={{ color: "#e2e2e2" }}>
        เปรียบเทียบข้อมูลย้อนหลัง 5 ครั้ง
      </Typography>
      {moraleCompare &&
        moraleCompare.length > 0 && (
        <Fragment>
          <div
            style={{
              marginTop: 32,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {role === "admin" && department && (
              <StyledFormControl variant="outlined">
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={filterDep}
                  onChange={handleChangeFilterDepartment}
                  input={<CustomSelect />}
                >
                  <MenuItem value={0}>All Department</MenuItem>
                  {department.map((dep, index) => {
                    return (
                      <MenuItem value={index + 1}>
                        {dep.departmentName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </StyledFormControl>
            )}

            {/* <Button
              variant="outlined"
              size="medium"
              style={{ borderColor: "#c7c7c7", color: "#c7c7c7" }}
              startIcon={<GridOnIcon />}
            >
              Export
            </Button> */}
          </div>
          <div style={{ marginTop: 16 }}>
            <div style={{ height: 350 }}>
              {filterDep === 0 ? (
                <Line
                  data={{
                    labels: [...moraleCompare.map(morale => morale.moraleName)],
                    datasets: department.map((value, index) => {
                      return {
                        label: value.departmentShortName,
                        data: dataDepartment(moraleCompare, value.idDepartment),
                        fill: false,
                        backgroundColor: backgroundColorList[index],
                        borderColor: borderColor[index],
                      }
                    })
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                      display: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontColor: "#CCC", // this here
                          },
                          gridLines: {
                            display: true,
                            color: "rgb(40,40,40)",
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            fontColor: "#CCC", // this here
                            suggestedMin: 0,
                            suggestedMax: 100,
                            stepSize: 10,
                          },
                          gridLines: {
                            display: true,
                            color: "rgb(40,40,40)",
                          },
                        },
                      ],
                    },
                    tooltips: {
                      enabled: true,
                      // mode: "index",
                      intersect: false,
                    },
                  }}
                />
              ) : (
                <Line
                  data={{
                    labels: [...moraleCompare.map(morale => morale.moraleName)],
                    datasets: [
                      {
                        label: department[filterDep - 1].departmentShortName,
                        data: dataDepartment(moraleCompare, filterDep),
                        fill: false,
                        backgroundColor: "rgb(53,185,233, 0.5)",
                        borderColor: "rgba(53,185,233, 0.5)",
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                      display: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontColor: "#CCC", // this here
                          },
                          gridLines: {
                            display: true,
                            color: "rgb(40,40,40)",
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            fontColor: "#CCC", // this here
                            suggestedMin: 0,
                            suggestedMax: 100,
                            stepSize: 20,
                          },
                          gridLines: {
                            display: true,
                            color: "rgb(40,40,40)",
                          },
                        },
                      ],
                    },
                    tooltips: {
                      enabled: true,
                      // mode: "index",
                      intersect: false,
                    },
                  }}
                />
              )}
            </div>
            <WrapAvgTopic>
              <Typography variant="h6" style={{ color: "#c7c7c7" }}>
                ข้อมูลเฉลี่ยรวมตามหัวข้อ
              </Typography>
              <div style={{ display: "flex" }}>
                <StyledFormControl variant="outlined">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    inputProps={{ "aria-label": "Without label" }}
                    value={selectedTopic}
                    onChange={handleChangeSelectedTopic}
                    input={<CustomSelect />}
           
                  >
                    <MenuItem value={0} disabled>{"Select Topic"}</MenuItem>
                    {questionTopic.slice(1).map(item => {
                      return (
                        <MenuItem value={item.idQuestionTopic}>{item.questionTopic}</MenuItem>
                      )
                    })}
                  </Select>
                </StyledFormControl>
              </div>
            </WrapAvgTopic>
            <div style={{ height: 350 }}>
              <Bar
                data={{
                  labels: [...moraleCompare.map(morale => morale.moraleName)],
                  datasets: [
                    {
                      type: "bar",
                      label: "Score",
                      backgroundColor: [
                        "rgba(53, 185, 233, 1)",
                        "rgba(155, 85, 229, 1)",
                        "rgba(255, 99, 132, 1)",
                        "rgba(252, 193, 3, 1)",
                        "rgba(67, 217, 191, 1)",
                      ],
                      borderColor: [
                        "rgba(53, 185, 233, 0.5)",
                        "rgba(155, 85, 229, 0.5)",
                        "rgba(255, 99, 132, 0.5)",
                        "rgba(252, 193, 3, 0.5)",
                        "rgba(67, 217, 191, 0.5)",
                      ],
                      data: dataTopic(moraleCompare),
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  legend: {
                    display: false,
                  },
                  scales: {
                    xAxes: [
                      {
                        ticks: {
                          fontColor: "#CCC", // this here
                        },
                        gridLines: {
                          display: true,
                          color: "rgb(40,40,40)",
                        },
                      },
                    ],
                    yAxes: [
                      {
                        ticks: {
                          fontColor: "#CCC", // this here
                          min: 0,
                          max: 100,
                        },
                        gridLines: {
                          display: true,
                          color: "rgb(40,40,40)",
                        },
                      },
                    ],
                  },
                  tooltips: {
                    enabled: true,
                    mode: "index",
                    intersect: false,
                  },
                }}
              />
            </div>
            <div style={{ marginTop: 16 }}>
              <Typography
                variant="h6"
                style={{ color: "#c7c7c7", marginBottom: 10 }}
              >
                ข้อมูลเฉลี่ยตามคำถาม
              </Typography>
            </div>
            <div>
              {
                dataQuestion().map((item, index) => {
                return (
                  <Fragment>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <Typography style={{ color: "#f3f3f3" }}>
                          {item.idQuestion}
                          {". "}
                          {item.question}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          {item.compare.map((value, index) => {
                            return (
                              <div style={{ height: 180, width: 180 }}>
                                <Doughnut
                                  data={{
                                    labels: ["score"],
                                    datasets: [
                                      {
                                        data: [
                                          value.compare.score,
                                          100 - value.compare.score,

                                        ],
                                        backgroundColor: [backgroundColorQuestion[index], "rgb(196, 196, 196)"],
                                        hoverBackgroundColor: [backgroundColorQuestion[index], "rgb(196, 196, 196)"],
                                      },
                                    ],
                                  }}
                                  options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    legend: {
                                      display: false,
                                    },
                                    tooltips: {
                                      enabled: false,
                                      mode: "dataset"
                                    },
                                    plugins: {
                                      doughnutlabel: {
                                        labels: [
                                          {
                                            text: value.compare.score,
                                            color: "#737373",
                                            font: {
                                              size: 16,
                                              weight: "bold",
                                            },
                                          },
                                        ],
                                      },
                                    },
                                    title: {
                                      display: true,
                                      fontSize: 16,
                                      text: value.compare.moraleName
                                    }
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </Grid>
                    </Grid>
                    <Divider
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        backgroundColor: "#ffffff2e",
                      }}
                    />
                  </Fragment>
                );
              })}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
