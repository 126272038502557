import React from "react";
import ListMenu from "../../../pages/shared/listMenu";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';

const UniTeamMenu = () => {
  const dataListMenu = [
    {
      listItemIcon: <ShoppingCartIcon />,
      listItemText: "ของรางวัล",
      listLink: "/uni-team/reward",
      listKey:"uni-team-reward",
    },
    {
      listItemIcon: <SettingsIcon />,
      listItemText: "จัดการของรางวัล",
      listLink: "/uni-team/reward/list",
      listKey:"uni-team-reward",
    },
  ];

  return (
    <ListMenu dataListMenu={dataListMenu} menuRole={"Uni Team"} />
  );
};

export default UniTeamMenu;
