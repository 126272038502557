import { httpClient } from "./httpClient";

const getUniRewards = () => {
  return httpClient.get("uni-reward");
};

const getUniReward = (id) => {
  return httpClient.get(`uni-reward/${id}`);
};

const addUniReward = (formData) => {
  return httpClient.post("uni-reward", formData);
};

const updateUniReward = (formData) => {
  return httpClient.put("uni-reward", formData);
};

const getUniRewardCart = () => {
  return httpClient.get("uni-reward-cart");
};

const addUniRewardCart = (formData) => {
  return httpClient.post("uni-reward-cart", formData);
};

const deleteUniRewardCart = (idCartItem) => {
  return httpClient.delete(`uni-reward-cart/${idCartItem}`);
};

const updateUniRewardCart = (formData) => {
  return httpClient.put("uni-reward-cart", formData);
};

const getUniRewardOrder = (id) => {
  return httpClient.get(`uni-reward-order/${id}`);
};

const addUniRewardOrder = (formData) => {
  return httpClient.post("uni-reward-order", formData);
};

export default {
  getUniRewards,
  getUniReward,
  addUniReward,
  updateUniReward,
  getUniRewardCart,
  addUniRewardCart,
  deleteUniRewardCart,
  updateUniRewardCart,
  getUniRewardOrder,
  addUniRewardOrder,
};