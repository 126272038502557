import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { useCompanyBudget } from "../../../../quries/company";
import utils from "../../../../utils";
import { useSelector } from "react-redux";

const CompanyBudget = () => {
  const { result: userProfile } = useSelector(state => state.userProfile);
  const { data, isPending } = useCompanyBudget({ idCompany: userProfile?.idCompany });
  if (isPending) {
    return <CircularProgress color="secondary" />;
  }

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderRadius={2}
      bgcolor={"#8d86c9"}
      padding={2}
      gap={2}
    >
      <CreditCardIcon sx={{ color: "#ffffff" }} />
      <Typography variant="h6" color={"#ffffff"}>
        {`เครดิตปัจจุบัน ${utils.numberWithCommas(data.uniCultureCredit)}`}
      </Typography>
    </Box>
  );
};

export default CompanyBudget;
