import React, { useCallback } from "react";
import {
  CircularProgress,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import dayjs from "dayjs";
import { useMoraleDailyKPI } from "../../../../quries/morale";
import { BarChart } from '@mui/x-charts/BarChart';
import FeelingIcon from "../../shared/feelingIcon";
import unicornCry from "./assets/Unicorn-Mascot-Cry-square.png";
import unicornHeart from "./assets/Unicorn-Mascot-Heart-square.png";
import unicornIndifferently from "./assets/Unicorn-Mascot-Indifferently-square.png";
import TableMoraleDaily from "./TableDaily";
import { utils, writeFileXLSX } from "xlsx";

const WrapFeeling = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 16,
  borderRadius: 8,
  backgroundColor: "#282828",
});

const feelings = [
  { feeling: "veryhappy" },
  { feeling: "happy" },
  { feeling: "littlehappy" },
  { feeling: "littlebad" },
  { feeling: "bad" },
  { feeling: "verybad" },
];

const feelingCapital = {
  veryhappy: "Very happy",
  happy: "Happy",
  littlehappy: "Little happy",
  littlebad: "Little bad",
  bad: "Bad",
  verybad: "Very bad",
};

const feelingScore = (score) => {
  if (score === 100) {
    return "veryhappy";
  } else if (score >= 80) {
    return "happy";
  } else if (score >= 60) {
    return "littlehappy";
  } else if (score >= 40) {
    return "littlebad";
  } else if (score >= 20) {
    return "bad";
  } else {
    return "verybad";
  }
};

const unicornImage = (x) => {
  if (x >= 80) {
    return unicornHeart;
  } else if (x >= 50) {
    return unicornIndifferently;
  } else {
    return unicornCry;
  }
};

const DailyDetail = ({ companySelected, idDepartment, idSection, date }) => {
  const { data, isPending } = useMoraleDailyKPI({
    query: {
      idCompany: companySelected.idCompany,
      year: dayjs(date).year(),
      month: dayjs(date).month() + 1,
    },
  });
  
  const filterData = data
    ?.filter((item) => {
      return idDepartment === 0 ? true : item.idDepartment === idDepartment;
    })
    ?.filter((item) => {
      return idSection === 0 ? true : item.idSection === idSection;
    }) || [];

  const exportFile = useCallback(() => {
    const data = filterData?.reduce((acc, cur) => {
      const employee = {
        employeeID: cur.employeeID, 
        firstname_TH: cur.firstname_TH, 
        lastname_TH: cur.lastname_TH,
        positionName: cur.positionName,
        sectionName: cur.sectionName,
        departmentName: cur.departmentName,
        divisionName: cur.divisionName,
        averageScore: cur.averageScore
      }

      const temp = Object.assign(employee, ...cur.questionTopics.map(item => ({ [item.questionTopic]: item.averageScore })))
      acc.push(temp)
      return acc 
    }, [])

    const ws = utils.json_to_sheet(data);
    utils.sheet_add_aoa(ws, [[
      "รหัสพนักงาน",
      "ชื่อ",
      "นามสกุล",
      "ตำแหน่ง",
      "ฝ่าย",
      "ส่วน",
      "แผนก",
      "average",
      ...filterData[0].questionTopics.map((item) => item.questionTopic)
    ]], { origin: "A1" });

    ws["!cols"] = [
      { wch: 10 },
      { wch: 25 }, 
      { wch: 25 }, 
      { wch: 30 }, 
      { wch: 30 }, 
      { wch: 30 }, 
      { wch: 30 }, 
      { wch: 10 },
      ...filterData[0].questionTopics.map((item) => ({ wch: 20 }))
    ];

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws);
    writeFileXLSX(wb, `KPI Daily ${dayjs(date).format("MMMM YYYY")} ${companySelected.companyName}.xlsx`);
  }, [filterData]);

  const scoreData = filterData?.reduce(
    (acc, cur) => {
      if (cur.averageScore) {
        if (cur.averageScore === 100) {
          acc.veryhappy++;
        } else if (cur.averageScore >= 80) {
          acc.happy++;
        } else if (cur.averageScore >= 60) {
          acc.littlehappy++;
        } else if (cur.averageScore >= 40) {
          acc.littlebad++;
        } else if (cur.averageScore >= 20) {
          acc.bad++;
        } else {
          acc.verybad++;
        }

        acc.totalScore += Number(cur.averageScore);
        acc.dataLength++;
      }

      return acc;
    },
    {
      dataLength: 0,
      totalScore: 0,
      veryhappy: 0,
      happy: 0,
      littlehappy: 0,
      littlebad: 0,
      bad: 0,
      verybad: 0,
    }
  );

  const scoreTopic = filterData?.reduce(
    (acc, cur) => {
      for (const x of cur.questionTopics) {
        if (!acc[x.idQuestionTopic]) {
          acc[x.idQuestionTopic] = { sumScore: 0, dataLength: 0 };
        }
        if (x.averageScore) {
          acc[x.idQuestionTopic].sumScore += Number(x.averageScore);
          acc[x.idQuestionTopic].dataLength++;
        }
      }
      return acc;
    },
    {}
  );

  const totalAverageScore =
    scoreData?.dataLength > 0
      ? Math.round(scoreData.totalScore / scoreData.dataLength)
      : 0;
  
  const averageScoreTopic = Object.keys(scoreTopic).map((topic) => {
    return scoreTopic[topic].dataLength > 0
      ? scoreTopic[topic].sumScore / scoreTopic[topic].dataLength
      : 0;
  });

  const maxLengthTopic = filterData?.[0]?.questionTopics.reduce((acc, cur) => (
    cur.questionTopic.length > acc ? cur.questionTopic.length : acc
  ), 0)

  if (isPending) {
    return <CircularProgress />;
  }

  return (
    <div>
      {filterData.length === 0 ? (
        <Typography variant="h6" color={"#ffffff"}>
          {"ไม่พบข้อมูล"}
        </Typography>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 6 }}>
            <WrapFeeling>
              <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <div style={{ width: 80, height: 80 }}>
                  <FeelingIcon feeling={feelingScore(scoreData.totalScore)} />
                </div>
                <Typography variant="h4" color={"#ffffff"}>
                  {`Total score: ${totalAverageScore}%`}
                </Typography>
              </div>
            </WrapFeeling>
            <img
              src={unicornImage(totalAverageScore)}
              alt="unicorn"
              width={480}
              height={480}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", marginBottom: "1rem" }}>
            <DashboardIcon sx={{ color: "#ffffff" }} />
            <Typography variant="h5" color={"#ffffff"}>
              {"สัดส่วนพนักงาน"}
            </Typography>
          </div>
          <Grid container spacing={2} marginBottom={6}>
            {feelings.map((item) => (
              <Grid item key={item.feeling} xs={12} sm={6} md={4}>
                <WrapFeeling>
                  <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                    <div style={{ width: 60, height: 60 }}>
                      <FeelingIcon feeling={item.feeling} />
                    </div>
                    <Typography variant="h5" color={"#ffffff"}>
                      {feelingCapital[item.feeling]}
                    </Typography>
                  </div>
                  <Typography variant="h5" color={"#ffffff"}>
                    {`${Math.round(
                      (scoreData[item.feeling] / scoreData.dataLength) * 100
                    )}%`}
                  </Typography>
                </WrapFeeling>
              </Grid>
            ))}
          </Grid>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <LeaderboardIcon sx={{ color: "#ffffff" }} />
            <Typography variant="h5" color={"#ffffff"}>
              {"หัวข้อคำถาม"}
            </Typography>
          </div>
          <div style={{ padding: "0px 16px", marginBottom: "24px" }}>
            <BarChart
              height={Math.round(filterData[0].questionTopics.length * 40)}
              margin={{ left: maxLengthTopic * 12 }}
              yAxis={[{
                scaleType: 'band', 
                data: filterData[0].questionTopics.map((item) => item.questionTopic),
                tickSize: 8,
                tickPlacement: "middle",
              }]}
              xAxis={[{
                tickInterval: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
              }]}
              series={[{ 
                data: averageScoreTopic, valueFormatter: (value) => `${value}%`, 
                color: "#ff9da7",
              }]}
              borderRadius={8}
              layout="horizontal"
              slotProps={{
                axisTickLabel: {
                  style: {
                    fill: "#ffffff",
                    fontSize: 16,
                  },
                },
                axisTick: {
                  style: {
                    stroke: "#ffffff",
                  },
                },
                axisLine: {
                  style: {
                    stroke: "#ffffff",
                  },
                },
              }}
              grid={{ vertical: true }}
            />
          </div>
          <TableMoraleDaily filterData={filterData} exportFile={exportFile} />
        </>
      )}
    </div>
  );
};

export default DailyDetail;
