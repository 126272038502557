import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Typography,
  Card,
  CardContent,
  Divider,
  Chip,
  LinearProgress,
  Stack,
  Button,
  Container,
  Grid,
} from "@mui/material";

import NotificationIcon from "./assets/notification.svg";
import HeaderPage from "../../../shared/header/headerPage";
import * as dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllMoraleDaily } from "../../../../../actions/moraleDaily";
import { getAllQuestionTopic } from "../../../../../actions/questionTopic";

const WrapHeader = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
    "& .wrap-button-add": {
      marginTop: 8,
    },
  },
});

const WrapSection2 = styled("div")({
  width: "100%",
});

const WrapSection3 = styled("div")({
  marginTop: 30,
  width: "100%",
});

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const StyledCard = styled(Card)({
  height: "100%",
  borderRadius: 8,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const WrapCardHeaderInsight = styled("div")({
  display: "flex",
  padding: 5,
  width: "70%",
  borderTopRightRadius: 50,
  borderBottomRightRadius: 50,
  justifyContent: "space-between",
  alignItems: "center",
});

const WrapCardHeaderInsight2 = styled("div")({
  display: "flex",
  padding: 5,
  width: "28%",
  borderTopLeftRadius: 50,
  borderBottomLeftRadius: 50,
  alignItems: "center",
  backgroundColor: "#EFEFEF",
  marginLeft: "auto",
});

const WrapCardHeaderInsightImage = styled("div")({
  backgroundColor: "white",
  borderRadius: "50%",
  width: 35,
  height: 35,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledCardHeaderInsightIcon = styled(Avatar)({
  width: 35,
  height: 35,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFD923",
  color: "#EA8900",
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 320,
});

const colorBGCardHeader = [
  "#ff1744",
  "#d500f9",
  "#651fff",
  "#2979ff",
  "#14a37f",
  "#ffc400",
  "#ff9100",
  "#357a38",
  "#f73378",
  "#6D4C41",
];

const getBgCard = (id) => {
  const idModTen = id % 10
  return colorBGCardHeader[idModTen]
}

const MoraleDaily = (props) => {
  const [insightList, setInsightList] = React.useState([]);

  const handleSelectInsight = (id) => {
    if (id === 0) {
      setInsightList([]);
    } else {
      if (insightList.some((value) => value === id)) {
        setInsightList(insightList.filter((value) => value !== id));
      } else {
        setInsightList([...insightList, id]);
      }
    }
  };

  const dispatch = useDispatch();
  const { result: moraleDaily } = useSelector((state) => state.moraleDaily);
  const { result: questionTopic } = useSelector((state) => state.questionTopic);

  useEffect(() => {
    dispatch(getAllMoraleDaily());
    dispatch(getAllQuestionTopic());
  }, []);

  return (
    <StyledRoot className={'page'}>
      <WrapHeader>
        <HeaderPage textLabel={"Morale Daily"} icon={NotificationIcon} />
        <div className={`wrap-button-add`}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/admin/manage/notification`}
          >
            ส่งแจ้งเตือนคำถาม
          </Button>
        </div>
      </WrapHeader>
      <StyledDivider />
      <Container>
        {questionTopic && (
          <div style={{ marginBottom: "24px" }}>
            <Typography gutterBottom variant="h6">
              หัวข้อคำถาม
            </Typography>
            <StyledChip
              clickable
              label={"ALL"}
              color={insightList.length === 0 ? "primary" : "default"}
              onClick={() => handleSelectInsight(0)}
            />
            {questionTopic.slice(1).map((data, index) => (
              <StyledChip
                key={index}
                clickable
                label={data.questionTopic}
                color={insightList.includes(data.idQuestionTopic) ? "primary" : "default"}
                onClick={() => handleSelectInsight(data.idQuestionTopic)}
              />
            ))}
          </div>
        )}
        <Grid container spacing={2}>
          {moraleDaily &&
            moraleDaily
              .filter((data) => {
                return insightList.length > 0
                  ? insightList.includes(data.idQuestionTopic)
                  : data;
              })
              .map((data) => {
                const numberOfAnswer = data.participantList.filter(
                  (item) => item.answer !== null
                ).length;
                return (
                  <Grid item xs={12} md={6} lg={4}>
                    <StyledCard elevation={2} key={data.idMoraleDaily}>
                      <div style={{ display: "flex" }}>
                        <WrapCardHeaderInsight
                          style={{ backgroundColor: getBgCard(data.idQuestionTopic) }}
                        >
                          <Typography style={{ color: "#FFF" }}>
                            {data.questionTopic}
                          </Typography>
                          <WrapCardHeaderInsightImage>
                            <img
                              alt="questionImg"
                              width={24}
                              height={24}
                              src={`${process.env.REACT_APP_API_URL}image/insight/h${data.idQuestionTopic}.png`}
                            />
                          </WrapCardHeaderInsightImage>
                        </WrapCardHeaderInsight>
                        <WrapCardHeaderInsight2>
                          <StyledCardHeaderInsightIcon>
                            {data.reward}
                          </StyledCardHeaderInsightIcon>
                          <Typography style={{ paddingLeft: 5 }}>
                            COIN
                          </Typography>
                        </WrapCardHeaderInsight2>
                      </div>
                      <div style={{ padding: "16px" }}>
                        <Typography style={{ textAlign: "center" }} variant="h5" gutterBottom>
                          {data.question}
                        </Typography>
                        <Typography style={{ textAlign: "center" }} color="textSecondary" variant="subtitle1">
                          ประเภทคำตอบ :{" "}
                          {data.questionType === "text" ? "ข้อความ" : "ตัวเลือก"}
                        </Typography>
                        <Typography style={{ textAlign: "center" }} variant="subtitle1">
                          {`start: ${dayjs(data.startDate).format("YYYY-MM-DD HH:mm")}`}
                        </Typography>
                        <Typography style={{ textAlign: "center" }} variant="subtitle1">
                          {`end: ${dayjs(data.endDate).format("YYYY-MM-DD HH:mm")}`}
                        </Typography>
                      </div>
                      <div style={{ padding: "16px" }}>
                        <LinearProgress
                          variant="determinate"
                          value={(numberOfAnswer * 100) / data.participantList.length}
                        />
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Typography variant="body2" color={"#3C3C3C"}>
                            {`ประเมินแล้ว ${numberOfAnswer}/${data.participantList.length} คน`}
                          </Typography>
                          <Button
                            component={Link}
                            to={`/admin/manage/moraleDaily/${data.idMoraleDaily}`}
                          >
                            ดูคำตอบ
                          </Button>
                        </Stack>
                      </div>
                    </StyledCard>
                  </Grid>
                );
              })}
        </Grid>
      </Container>
    </StyledRoot>
  );
};

export default MoraleDaily;
