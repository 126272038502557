import React from "react";
import {
  Button,
  Container,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import HeaderPage from "../../../shared/header/headerPage";
import { StyledDividerHeader } from "../../../shared/dividerHeader";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const rows = [
  {
    sku: 1,
    name: "A",
    price: 100,
  },
  {
    sku: 2,
    name: "B",
    price: 200,
  },
  {
    sku: 3,
    name: "C",
    price: 300,
  },
  {
    sku: 4,
    name: "D",
    price: 400,
  },
];

const RewardList = () => {
  return (
    <StyledRoot className={"page"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <HeaderPage textLabel={"จัดการสินค้า"} />
        <Button variant="contained" component={Link} to="/uni-team/reward/new">
          เพิ่มสินค้า
        </Button>
      </Stack>
      <StyledDividerHeader />
      <Container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{"รหัสสินค้า"}</TableCell>
                <TableCell>ชื่อรายการ</TableCell>
                <TableCell align="right">ราคาขาย/หน่วย</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.sku}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    {row.sku}
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="right">{row.price}</TableCell>
                  <TableCell align="right">
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      {/* <Grid container spacing={3}>
        {data
          .filter((value) => {
            return value.name.toLowerCase().includes(filterName.toLowerCase());
          })
          .filter(
            (item) => rewardType === 0 || item.idRewardType === rewardType
          )
          .map((value) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CardReward {...value} />
              </Grid>
            );
          })}
      </Grid> */}
    </StyledRoot>
  );
};

export default RewardList;
