import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import {
  Typography,
  Divider,
  DialogTitle,
  Alert,
  AlertTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { SpecialIcon } from "../typeCoin";
import Utils from "../../../../utils";
import TiptapDetail from "../reward/tiptapDetail";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../actions/user";
import { useAddRedeemTransaction } from "../../../../quries/redeemTransaction";
import { toast } from "sonner";

const StyledDivider = styled(Divider)({
  margin: "8px 0",
  borderStyle: "dashed",
  borderWidth: "0px 0px thin",
  borderColor: "rgba(145, 158, 171, 0.24)",
  backgroundColor: "transparent",
});

const WrapPrice = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 8,
  marginTop: 8,
  marginBottom: 8,
  "& .MuiSvgIcon-root": {
    color: "rgba(0, 0, 0, 0.2)",
  },
});

const WrapQuantity = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  "& .label": {
    fontSize: 16,
    marginTop: 4,
  },
});

const WrapQuantityItem = styled("div")({
  "& .MuiPaper-root": {
    padding: "4px 6px",
    display: "flex",
    "& p": {
      width: 40,
    },
  },
});

const WrapTotal = styled("div")({
  marginTop: 8,
  display: "flex",
  justifyContent: "space-between",
  "& .inner-total": {
    minWidth: 115,
  },
  "& .label": {
    fontSize: 16,
    marginTop: 4,
  },
});

const ButtonRainbow = styled(Button)({
  backgroundImage: "linear-gradient(to right, #e55d87 0%, #5fc3e4 51%, #e55d87 100%)",
  transition: "0.5s",
  backgroundSize: "200% auto",
  color: "#ffffff",
  boxShadow: "0 0 20px #eee",
  ":hover": {
    backgroundPosition: "right center",
  }
})

const DialogDescriptionReward = ({ data, open, handleClose, handleOpenRedeemSuccess }) => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [item, setItem] = useState(1);
  const [openConfirm, setOpenConfirm] = useState(false);
  const redeemTransaction = useAddRedeemTransaction();

  const handleAddReward = () => {
    if (item <= data.quantity) {
      setItem(item + 1);
    }
  };

  const handleRemoveReward = () => {
    if (item > 1) {
      setItem(item - 1);
    }
  };

  const handleCloseAccept = () => {
    setOpenConfirm(false);
  };

  const disableRedeem = () => {
    for (let coinReward of data.coins) {
      const coinWallet = userProfile?.wallet?.find(e => e.idCoinType === coinReward.idCoinType).amount
      if (coinReward.amount * item > coinWallet) {
        return true
      }
    }
    return false
  };

  const handleRedeemReward = () => {
    const formData = {
      quantity: item,
      idReward: data.idReward,
      coins: data.coins,
    };

    redeemTransaction.mutate(formData, {
      onSuccess: () => handleOpenRedeemSuccess(),
      onError: () => toast.error("เกิดข้อผิดพลาด"),
      onSettled: () => {
        dispatch(getUserProfile())
        handleCloseAccept()
        handleClose()
      },
    })
  };

  return (
    <div>
      {userProfile && (
        <>
          <Dialog
            fullWidth
            maxWidth={"md"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              {data && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5">{data.name}</Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <img src={data.imageURL} style={{ width: "100%" }} alt="reward" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TiptapDetail detail={data.detail} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <WrapPrice>
                      {data.coins.length > 0 && 
                        data.coins.map((coin, index) => (
                          <>
                            <SpecialIcon
                              idCoinType={coin.idCoinType}
                              width={40}
                            />
                            <Typography
                              variant="h6"
                              style={{ fontSize: 24 }}
                            >
                              {Utils.numberWithCommas(coin.amount)}
                            </Typography>
                            {data.coins.length > 1 &&
                              data.coins.length - 1 !== index && (
                                <CloseIcon color="textSecondary" />
                              )}
                          </>
                        ))
                      }
                    </WrapPrice>
                    {disableRedeem() && (
                      <Alert severity="error">
                        <AlertTitle>ข้อผิดพลาด</AlertTitle>
                        <strong>เหรียญคงเหลือไม่เพียงพอ!</strong>
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <WrapQuantity>
                      <Typography className={`label`}>Quantity</Typography>
                      <WrapQuantityItem>
                        <Paper 
                          variant="outlined" 
                          sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
                        >
                          <IconButton
                            aria-label="remove"
                            size="small"
                            onClick={handleRemoveReward}
                            disabled={item <= 1}
                          >
                            <RemoveIcon fontSize="inherit" />
                          </IconButton>
                          <Typography variant="body2" align="center">
                            {item}
                          </Typography>
                          <IconButton
                            aria-label="add"
                            size="small"
                            onClick={handleAddReward}
                            disabled={item >= data.quantity}
                          >
                            <AddIcon fontSize="inherit" />
                          </IconButton>
                        </Paper>
                        <Typography
                          color="textSecondary"
                          align="right"
                          fontSize={14}
                        >
                          Available: {data.quantity}
                        </Typography>
                      </WrapQuantityItem>
                    </WrapQuantity>
                    <StyledDivider />
                    <WrapTotal>
                      <Typography className={`label`}>Total</Typography>
                      <div className={`inner-total`}>
                        {data.coins.map(coin => (
                          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8px"}}>
                            <SpecialIcon
                              idCoinType={coin.idCoinType}
                              width={40}
                            />
                            <Typography
                              variant="h6"
                              style={{ marginLeft: 8, fontSize: 24 }}
                            >
                              {Utils.numberWithCommas(coin.amount * item)}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    </WrapTotal>
                  </Grid>
                  <StyledDivider />
              </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="error" onClick={handleClose}>
                ยกเลิก
              </Button>
              <ButtonRainbow
                onClick={() => setOpenConfirm(true)}
                disabled={disableRedeem()}
              >
                แลกของรางวัล
              </ButtonRainbow>
            </DialogActions>
          </Dialog>
          {openConfirm && data && (
            <Dialog
              open={openConfirm}
              fullWidth
              onClose={handleCloseAccept}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"ยืนยันแลกของรางวัล"}
              </DialogTitle>
              <DialogContent>
                <Typography fontSize={20}>{data.name}</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseAccept}
                  variant="outlined"
                  color="error"
                >
                  ยกเลิก
                </Button>
                <ButtonRainbow onClick={handleRedeemReward}>
                  แลกของรางวัล
                </ButtonRainbow>
              </DialogActions>
            </Dialog>
          )}
        </>
      )}
    </div>
  );
};

export default DialogDescriptionReward;
