import React, { useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SettingsIcon from "@mui/icons-material/Settings";
import DialogEditQuota from "./dialogEdit";
import {
  getAllCoinQuota,
  updateMissionCoinQuota,
} from "../../../../../actions/user";

const AssignMissionQuota = () => {
  const dispatch = useDispatch();
  const { result: userCoinQuota } = useSelector((state) => state.userCoinQuota);
  const [open, setOpen] = useState(false);

  const columns = [
    {
      field: "fullname",
      headerName: "ชื่อ-นามสกุล",
      type: "string",
      flex: 2,
      sortable: false,
    },
    { field: "positionName", headerName: "ตำแหน่ง", flex: 1, sortable: false },
    { field: "divisionName", headerName: "ฝ่าย", flex: 1, sortable: false },
    { field: "departmentName", headerName: "ส่วน", flex: 1, sortable: false },
    { field: "sectionName", headerName: "แผนก", flex: 1, sortable: false },
    {
      field: "assignMissionQuota",
      headerName: "โควต้า",
      type: "number",
      flex: 1,
    },
    {
      field: "assignMissionBalance",
      headerName: "คงเหลือ",
      type: "number",
      flex: 1,
    },
  ];

  const rows = userCoinQuota.map((item) => ({
    id: item.idEmployees,
    fullname: `${item.firstname_TH} ${item.lastname_TH}`,
    positionName: item.positionName,
    divisionName: item.divisionName,
    departmentName: item.departmentName,
    sectionName: item.sectionName,
    assignMissionQuota: item.assignMissionQuota,
    assignMissionBalance: item.assignMissionBalance,
  }));

  const handleEditQuota = async (data) => {
    const res = await dispatch(
      updateMissionCoinQuota(data.type, {
        value: data.value,
        selectedPerson: data.selectedPerson,
      })
    );
    if (res) {
      dispatch(getAllCoinQuota());
    }
  };

  const handleOpenEdit = () => {
    setOpen(true);
  };

  const handleCloseEdit = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        startIcon={<SettingsIcon />}
        onClick={handleOpenEdit}
        sx={{ marginBottom: 1 }}
      >
        ตั้งค่า
      </Button>
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            }
          }}
        />
      </div>
      {open && (
        <DialogEditQuota
          open={open}
          handleClose={handleCloseEdit}
          handleEdit={handleEditQuota}
          title={"ตั้งค่าโควต้าภารกิจ"}
        />
      )}
    </div>
  );
};

export default AssignMissionQuota;
