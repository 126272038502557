import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import {
  Container,
  Tooltip,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import HeaderPage from "../../shared/header/headerPage";
import iconHeader from "./assets/360-degrees-color.svg";
import tagClose from "./assets/tagClose.png";
import tagOpen from "./assets/tagOpen.png";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AssessmentList from "./AssessmentList";
import DialogResultUpload from "./DialogResultUpload";

import { getAllEmployees } from "../../../../actions/user";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF",
  "& .wrapHeader": {
    display: "flex",
    alignItems: "center",
    marginBottom: 36,
    ["@media only screen and (max-width:600px)"]: {
      flexDirection: "column",
    },
  },
});

export default function Assessment360() {
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [excelData, setExcelData] = useState(null);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickCreateAssessment = () => {
    navigate("/admin/manage/createassessment360");
  };

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (!file) {
        reject();
      }
      fileReader.readAsArrayBuffer(file);

      fileReader.onabort = () => {};

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsQuestions = wb.Sheets["Questionaire"];
        const wsNamelist = wb.Sheets["Namelist"];

        const dataQuestions = XLSX.utils.sheet_to_json(wsQuestions);
        const dataNamelist = XLSX.utils.sheet_to_json(wsNamelist);

        const data = {
          questions: dataQuestions,
          nameList: dataNamelist
        }
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      console.log(data);
      var questions = [];
      const sampleSizeRatio = [
        {
          idSampleSizeType: 1,
          name: "Boss",
          value: 0,
        },
        {
          idSampleSizeType: 2,
          name: "Peer",
          value: 0,
        },
        {
          idSampleSizeType: 3,
          name: "Project",
          value: 0,
        },
        {
          idSampleSizeType: 4,
          name: "Subordinate",
          value: 0,
        },
        {
          idSampleSizeType: 5,
          name: "Cross Function",
          value: 0,
        },
      ];

      const nameListData = data.nameList.map((row, index) => {
        return {
          personId: row["personId"],
          idSampleSizeType: sampleSizeRatio.find(o => o.name === row['Sample Type']).idSampleSizeType,
          sampleId: row["sampleId"],
        };
      });
      const fixedData = data.questions.map((row, index) => {
        return {
          nameQuestionaire: row["Name Questionaire"],
          description: row["Description"],
          launchDate: dayjs(
            new Date(Date.UTC(0, 0, row["Launch Date"] - 1))
          ).format("YYYY-MM-DD"),
          endDate: dayjs(new Date(Date.UTC(0, 0, row["End Date"] - 1))).format(
            "YYYY-MM-DD"
          ),
          question: row["Question"],
          sampleSize: row["sampleSize"],
          sampleRatio: row["sampleRatio"],
        };
      });

      const dataShow = {
        nameQuestionaire: fixedData[0].nameQuestionaire,
        description: fixedData[0].description,
        launchDate: fixedData[0].launchDate,
        endDate: fixedData[0].endDate,
      };

      for (let index = 0; index < fixedData.length; index++) {
        const element = fixedData[index];
        questions.push(element.question);

        const _index = sampleSizeRatio.findIndex((object) => {
          return object.name === element.sampleSize;
        });
        if (_index >= 0) {
          sampleSizeRatio[_index].value = element.sampleRatio;
        }
      }

      dataShow.questions = questions;
      dataShow.sampleSizeRatio = sampleSizeRatio;
      dataShow.sampleSize = nameListData;

      console.log(dataShow)

      setExcelData(dataShow);
      handleClickOpen()
    });
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div className={`wrapHeader`}>
          <HeaderPage textLabel={"ประเมิน 360 องศา"} icon={iconHeader} />
          <div style={{ marginLeft: "auto" }}>
            <Tooltip title="Upload">
              <label htmlFor="icon-button-file">
                <input
                  hidden
                  accept=".xlsx, .xls, .csv"
                  id="icon-button-file"
                  type="file"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    readExcel(file);
                  }}
                />
                <ButtonBlue
                  aria-label="upload file"
                  component="span"
                  startIcon={<UploadFileIcon />}
                  variant="contained"
                >
                  Upload
                </ButtonBlue>
              </label>
            </Tooltip>
            <ButtonBlue
              variant="contained"
              color="primary"
              onClick={handleClickCreateAssessment}
              style={{ marginLeft: 16 }}
            >
              สร้างชุดคำถามใหม่
            </ButtonBlue>
          </div>
        </div>
        <div>
          <AssessmentList />
        </div>
        <DialogResultUpload handleClose={handleClose} open={open} data={excelData}/>
      </Container>
    </StyledRoot>
  );
}
