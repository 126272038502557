import React, { useEffect, useState, useRef, useMemo, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { DataGrid, GridActionsCellItem, GridCsvExportMenuItem, GridPrintExportMenuItem, GridToolbarContainer, GridToolbarExport, GridToolbarExportContainer } from '@mui/x-data-grid';
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import "./index.css";
import { getDepartment } from "../../../../../actions/department";
import { CSVLink } from "react-csv";

import {
  Typography,
  Button,
  Divider,
  Grid,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  IconButton,
  Menu,
  List,
  ListItem,
  Avatar,
  TablePagination,
  Chip,
  Stack,
  FormControlLabel,
  Switch,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Container,
} from "@mui/material";
import ActivityRegister from "./activityRegister";
import { 
  updateActivityParticipantStatus, 
  getActivityParticipant, 
  updateRewardActivityRegister, 
  getActivity
} from "../../../../../actions/activity";
import dayjs from "dayjs";
import { toast } from "sonner";

// const CustomExportButton = (props) => (
//   <GridToolbarExportContainer {...props}>
//     <GridCsvExportMenuItem options={csvOptions} />
//     <GridPrintExportMenuItem options={printOptions} />
//   </GridToolbarExportContainer>
// );
const printOptions = { 
  fields: ['employeeId', 'firstname_TH', 'lastname_TH', 'mobileNumber'],
  hideToolbar: true,
  hideFooter: true,
}

const csvOptions = { 
  fields: ['employeeId', 'firstname_TH', 'lastname_TH', 'mobileNumber'],
  fileName: 'activity-register.csv', 
}

const CustomToolbar = (props) => (
  <GridToolbarContainer {...props}>
    <GridToolbarExport />
  </GridToolbarContainer>
);

const StyledTableCell = styled(TableCell)({
  color: "#637381",
  backgroundColor: "#f4f6f8",
})

const StatusMenu = ({ idEmployees, handleChangeStatus }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClickMenu}
        >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`actions-menu-${idEmployees}`}
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        elevation={1}
      >
        <MenuItem onClick={() => handleChangeStatus({ idEmployees, status: 1 })}>
          {'เข้าร่วม'}
        </MenuItem>
        <MenuItem onClick={() => handleChangeStatus({ idEmployees, status: 0 })}>
          {'ไม่เข้าร่วม'}
        </MenuItem>
      </Menu>
    </>
  );
}

const EmployeeList = ({ 
  activity,
  activityParticipant,
  idActivity,
  filterEmployee,
  companySelected
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [registerStatus, setRegisterStatus] = useState(true);

  const handleChangeRegister = (event) => {
    setRegisterStatus(event.target.checked);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowsFilter = () => {
    const participantList = activityParticipant.filter((person) =>
      registerStatus ? person.registerStatus : !person.registerStatus
    );
    
    if (
      filterEmployee.employee === "" &&
      companySelected === null &&
      filterEmployee.status === "all"
    ) {
      return participantList;
    } else {
      let resultFilter = [...participantList]

      if (filterEmployee) {
        resultFilter = resultFilter.filter(
          (item) =>
            item.firstname_TH
              .toLowerCase()
              .includes(filterEmployee.toLowerCase()) ||
            item.lastname_TH
              .toLowerCase()
              .includes(filterEmployee.toLowerCase())
        );
      }

      if (companySelected !== null) {
        resultFilter = resultFilter.filter(
          (item) => item.idCompany === companySelected.idCompany
        );
      }

      return resultFilter;
    }
  };

  // const data = useMemo(() => {
  //   const arr = []
  //   arr.push(['รหัสพนักงาน', 'ชื่อ', 'นามสกุล', 'เบอร์มือถือ'])
  //   for(let part of activityParticipant) {
  //     for(let emp of employeeList) {
  //       if(part.idEmployees === emp.id) {
  //         arr.push([
  //           emp.employeeID.toString(), 
  //           emp.firstname_TH.toString(), 
  //           emp.lastname_TH.toString(), 
  //           emp.telephoneMobile.toString()
  //           emp.participantStatus.toString()
  //         ])
  //       }
  //     }
  //   }
  //   return arr
  // }, [activityParticipant, employeeList])

  const handleChangeStatus = async ({ idEmployees, status }) => {
    const res = await dispatch(
      updateActivityParticipantStatus({
        idActivity,
        idEmployees,
        participantStatus: status,
      })
    )
    if(res) {
      dispatch(getActivityParticipant(idActivity))
      setAnchorEl(null);
    }
  };

  const handleGiveReward = async () => {
    const res = await dispatch(updateRewardActivityRegister(idActivity));
    if (res && res.status === 200) {
      toast.success("สำเร็จ")
      dispatch(getActivityParticipant(idActivity));
      dispatch(getActivity(idActivity));
    } else {
      toast.error("เกิดข้อผิดพลาด")
    }
  };

  useEffect(() => {
    dispatch(getDepartment());
  }, []);

  return (
    <>
      <Divider sx={{ marginBottom: "8px" }} />
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {activity.status === "rewarded" && (
            <Chip color="warning" label={"ให้รางวัลแล้ว"} />
          )}
          <Typography variant="body2" color="textSecondary">
            {`รายการทั้งหมด (${rowsFilter().length})`}
          </Typography>
          <FormControlLabel
            control={
              <Switch checked={registerStatus} onChange={handleChangeRegister} inputProps={{ 'aria-label': 'controlled' }} />
            }
            label="Register"
          />
        </div>
        <Button
          variant="contained"
          onClick={handleGiveReward}
        >
          {"ให้รางวัล"}
        </Button>
      </div>
      <TableContainer component={Paper} elevation={2}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="center">Position</StyledTableCell>
              <StyledTableCell align="center">Section</StyledTableCell>
              <StyledTableCell align="center">Department</StyledTableCell>
              <StyledTableCell align="center">Division</StyledTableCell>
              <StyledTableCell align="center">Mobile</StyledTableCell>
              <StyledTableCell align="center">Email</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {rowsFilter()
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((emp) => (
              <TableRow
                key={emp.idEmployees}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <Avatar alt={"employee"} src={emp.imageURL} />
                    <Typography>
                      {`${emp.firstname_TH} ${emp.lastname_TH}`}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="center">{emp.sectionName}</TableCell>
                <TableCell align="center">{emp.departmentName}</TableCell>
                <TableCell align="center">{emp.divisionName}</TableCell>
                <TableCell align="center">{emp.telephoneMobile}</TableCell>
                <TableCell align="center">{emp.divisionName}</TableCell>
                <TableCell align="center">{emp.email}</TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Chip
                      label={emp.participantStatus ? "เข้าร่วม" : "ไม่เข้าร่วม"}
                      color={emp.participantStatus ? "success" : "error"}
                    />
                    <StatusMenu
                      idEmployees={emp.idEmployees}
                      handleChangeStatus={handleChangeStatus}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {rowsFilter()
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((emp, index) => (
          <div key={emp.id}>
            <List>
              <ListItem>
                <Grid container spacing={2} alignItems="center">
                  <TableCell>
                    <div style={{ display: "flex", gap: "8px" }}>
                      <Avatar alt={"employee"} src={emp.imageURL} />
                      <StyledTextName variant="body1">
                        {`${emp.firstname_TH} ${emp.lastname_TH}`}
                      </StyledTextName>
                      <StyledTextOverFlow
                        variant="body2"
                        color="textSecondary"
                      >
                        {emp.positionName}
                      </StyledTextOverFlow>
                    </div>
                  </TableCell>
                  <TableCell>{emp.sectionName}</TableCell>
                  <TableCell>{emp.departmentName}</TableCell>
                  <TableCell>{emp.divisionName}</TableCell>
                  <Grid item xs={12} md={8}>
                    <Grid container>
                      <Grid item xs={12} sm={4} md={4} lg={2}>
                        <StyledLabelAbout
                          variant="body2"
                          color="textSecondary"
                        >
                          เบอร์มือถือ{" "}
                        </StyledLabelAbout>
                        <Typography variant="body1">
                          {emp.telephoneMobile}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={3}>
                        <StyledLabelAbout
                          variant="body2"
                          color="textSecondary"
                        >
                          Email{" "}
                        </StyledLabelAbout>
                        <Typography variant="body1">
                          {emp.emailAddressBusiness}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={6} lg={2}>
                        <StyledLabelAbout
                          variant="body2"
                          color="textSecondary"
                        >
                          สถานะการเข้าร่วม{" "}
                        </StyledLabelAbout>
                        <div style={{ display: "flex" }}>
                          <Chip
                            label={emp.participantStatus ? "เข้าร่วม" : "ไม่เข้าร่วม"}
                            color={emp.participantStatus ? "success" : "error"}
                          />
                          <StatusMenu
                            idActivity={emp.idActivity}
                            idEmployees={emp.idEmployees}
                            handleChangeStatus={handleChangeStatus}
                          />
                        </div>
                        <div style={{ display: "flex" }}>
                          {dayjs().isBefore(dayjs(activity.endDate)) ? ( 
                            <Chip 
                              fullWidth
                              label={'in progress'}
                              color={"warning"} 
                            />
                          ) : (
                            {emp.registerStatus ? (
                              <>
                                <Chip
                                  label={emp.participantStatus ? "เข้าร่วม" : "ไม่เข้าร่วม"}
                                  color={emp.participantStatus ? "success" : "error"}
                                />
                                <StatusMenu
                                  idActivity={emp.idActivity}
                                  idEmployees={emp.idEmployees}
                                  handleChangeStatus={handleChangeStatus}
                                />
                              </>
                            ) : (
                              <Chip label={"unregistered"} color={"error"} />
                            )}
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
            <Divider />
          </div>
        ))} */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowsFilter().length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default EmployeeList;
