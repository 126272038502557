import React from "react";
import { styled } from "@mui/material/styles";
import { Card } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const StyledCard = styled(Card)({
  borderRadius: 8,
  height: "100%",
});

const StyledCardMedia = styled(CardMedia)({
  height: 0,
  paddingTop: "56.25%", // 16:9
  backgroundSize: "contain",
});

const StyledCardContent = styled(CardContent)({
  padding: "16px !important",
  "& .rewardName": {
    display: "flex",
    alignItems: "end",
    "& img": {
      marginRight: 4,
    },
  },
  "& .price": {
    display: "flex",
    alignItems: "center",
    marginTop: 16,
    marginBottom: 8,
    justifyContent: "flex-end",
  },
  "& .price .MuiSvgIcon-root": {
    color: "rgba(0, 0, 0, 0.2)",
  },
});

function CardReward({ idUniReward, name, image, totalQuantity }) {
  return (
    <StyledCard elevation={3}>
      <CardActionArea
        style={{ height: "100%" }}
        component={Link}
        to={`detail/${idUniReward}`}
      >
        <StyledCardMedia image={image} title={name} />
        <StyledCardContent>
          <div>
            <div className={`rewardName`}>
              <Typography
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {name}
              </Typography>
            </div>
            <Typography variant="body2" color="textSecondary">
              available: {totalQuantity}
            </Typography>
          </div>
        </StyledCardContent>
      </CardActionArea>
    </StyledCard>
  );
}

export default CardReward;
