import { useMutation, useQuery } from "@tanstack/react-query";
import { httpClient } from "../services/httpClient";

export const useRewardType = () => {
  return useQuery({
    queryKey: ["rewardType"],
    queryFn: async () => (await httpClient.get(`reward-type`)).data,
    refetchOnWindowFocus: false,
  });
};

export const useAddReward = () => {
  return useMutation({
    mutationFn: async (formData) => await httpClient.post("reward", formData),
  });
};

export const useUpdateRewardCost = ({ idReward }) => {
  return useMutation({
    mutationFn: async (formData) =>
      await httpClient.patch(`reward/${idReward}/cost`, formData),
  });
};

export const useUpdateRewardStatus = ({ idReward }) => {
  return useMutation({
    mutationFn: async (formData) =>
      await httpClient.patch(`reward/${idReward}/status`, formData),
  });
};

export const useCompanyRewardCost = ({ idCompanyReward }) => {
  return useQuery({
    queryKey: ["rewards", "company-rewards", idCompanyReward, "cost"],
    queryFn: async () => (await httpClient.get(`/rewards/company-rewards/${idCompanyReward}/cost`)).data,
    enabled: Boolean(idCompanyReward),
  });
};

export const useUniRewardCost = ({ idUniReward }) => {
  return useQuery({
    queryKey: ["rewards", "uni-rewards", idUniReward, "cost"],
    queryFn: async () => (await httpClient.get(`/rewards/uni-rewards/${idUniReward}/cost`)).data,
    enabled: Boolean(idUniReward),
  });
};

export const useRewardsActive = ({ idCompany, select }) => {
  return useQuery({
    queryKey: ["rewards", "active"],
    queryFn: async () => (await httpClient.get(`reward-active/${idCompany}`)).data,
    enabled: Boolean(idCompany),
    select,
  });
};
  