import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import "date-fns";
import {
  Divider,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import ActivityIcon from "./assets/calendar.png";
import HeaderPage from "../../../shared/header/headerPage";
import ActivityListCard from "./activityCards";
import ActivityListTable from "./activityTable";

import { useDispatch, useSelector } from "react-redux";
import { getAllActivity } from "../../../../../actions/activity";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween"
import FilterActivityName from "./filterName";
import LoadingIcon from "../../../shared/general/loadingIcon";
dayjs.extend(isBetween)

const StyledRoot = styled('div')({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
})

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
})

const Activity = () => {
  const dispatch = useDispatch();
  const { result: activity, isFetching } = useSelector((state) => state.activity)

  const [alignment, setAlignment] = React.useState("card");
  const [filterData, setFilterData] = React.useState([]);
  const [filterStatus, setFilterStatus] = React.useState("all");

  const handleChangeFilterStatus = (event) => {
    const value = event.target.value;
    let newData = null
    if (value === "soon") {
      newData = activity.filter((item) => dayjs(item.startDate).isBetween(dayjs(), dayjs().add(30, "day")))
    } else if (value === "end") {
      newData = activity.filter(item => dayjs().isAfter(item.endDate))
    } else if (value === "interesting") {
      newData = activity.filter(item => item.isInteresting)
    } else {
      newData = [...activity]
    }

    setFilterData(newData)
    setFilterStatus(value);
  }

  const handleSetFilter = (value) => {
    const newData = activity.filter(item => 
      item.name.toLowerCase().includes((value).toLowerCase())
    )
    setFilterData(newData)
  }

  useEffect(() => {
    dispatch(getAllActivity());
  }, [])

  useEffect(() => {
    if (activity) {
      setFilterData(activity)
      setFilterStatus("all")
    }
  }, [activity])
  
  return (
    <StyledRoot className={'page'}>
      <HeaderPage textLabel={"จัดการกิจกรรม"} icon={ActivityIcon} />
      <StyledDivider />
      <div 
        style={{ 
          display: "flex", 
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px" 
        }}
      >
        <div style={{ display: "flex", gap: "8px" }}>
          <FilterActivityName handleSetFilter={handleSetFilter} />
          <FormControl sx={{ minWidth: "160px" }}>
            <InputLabel>ตัวเลือกค้นหา</InputLabel>
            <Select
              label="ตัวเลือกค้นหา"
              value={filterStatus}
              onChange={handleChangeFilterStatus}
            >
              <MenuItem value={"all"}>ทั้งหมด</MenuItem>
              <MenuItem value={"soon"}>กำลังมาถึง</MenuItem>
              <MenuItem value={"end"}>ผ่านไปแล้ว</MenuItem>
              <MenuItem value={"interesting"}>กิจกรรมน่าสนใจ</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          component={Link}
          to={"/admin/manage/create-activity-alt"}
        >
          เพิ่มกิจกรรม
        </Button>
      </div>
      {isFetching ? <LoadingIcon /> : <ActivityListCard data={filterData} />}
    </StyledRoot>
  );
};

export default Activity;
