import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Grid,
  Avatar,
  Typography,
  Divider,
  TextField,
  Rating,
  Stack,
  IconButton,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";

import { useSelector, useDispatch } from "react-redux";
import {
  addCoachRecord,
  getCoachRecord,
  updateCoachRecord,
} from "../../../../actions/coachRecord";

import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import {
  getCoachReviewByIdBooking,
  addCoachReview,
  deleteCoachReview,
} from "../../../../actions/coachReview";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(15),
  border: "0.7px solid",
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const StyledBtnEditGroup = styled(IconButton)({
  border: "1px solid #ffa726",
  backgroundColor: "#ffffff",
  color: "#ffa726",
  "&:hover": {
    color: "#ffffff",
    backgroundColor: "#ffa726",
  },
});

const validationSchema = yup.object().shape({
  profile: yup.string().max(200),
  issue: yup.string().max(200),
  goal: yup.string().max(200),
  reality: yup.string().max(200),
  opportunity: yup.string().max(200),
  wrapUp: yup.string().max(200),
  note: yup.string().max(200),
  homework: yup.string().max(200),
});

const SaveCoaching = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: coachRecord } = useSelector((state) => state.coachRecord);
  const { result: coachReviewItem } = useSelector(
    (state) => state.coachReviewItem
  );

  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [editMode, setEditMode] = useState(false);

  const { handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const onSubmit = async (data) => {
    const formData = { ...data, idBooking: id };
    let res = null;
    if (coachRecord[0].idBookingStatus === 2) {
      res = await dispatch(addCoachRecord(formData));
    } else {
      res = await dispatch(updateCoachRecord(formData));
    }

    if (res) {
      setEditMode(!editMode);
      dispatch(getCoachRecord(id));
    }
  };

  const handleChangeReview = (event) => {
    setReview(event.target.value);
  };

  const handleReset = () => {
    setReview("");
    setRating(0);
    dispatch(getCoachRecord(id));
    dispatch(getCoachReviewByIdBooking(id));
  };

  const handleAddReview = async () => {
    const res = await dispatch(
      addCoachReview({ idBooking: coachRecord[0].idBooking, rating, review })
    );
    if (res) {
      handleReset();
    }
  };

  const handleDeleteReview = async () => {
    const res = await dispatch(deleteCoachReview(id));
    if (res) {
      handleReset();
    }
  };

  useEffect(() => {
    dispatch(getCoachRecord(id));
    dispatch(getCoachReviewByIdBooking(id));
  }, []);

  return (
    <>
      {coachRecord && (
        <div className={`page`}>
          {!(
            coachRecord[0]?.idEmployees === currentUser.id ||
            coachRecord[0]?.idCoach === currentUser.id
          ) ? (
            <Navigate to="/coaching/coachee-history" />
          ) : (
            <Container maxWidth="lg">
              <Paper style={{ padding: 20 }}>
                <Grid container>
                  <Grid item xs={6}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <StyledAvatar
                        src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <StyledAvatar
                        src={`${process.env.REACT_APP_API_URL}image/profile/2.jpg`}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: 10 }}>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h5">
                        {`${coachRecord[0].coach_firstName} ${coachRecord[0].coach_lastName}`}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{
                          fontWeight: 400,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {coachRecord[0].coach_position}
                      </Typography>
                      <Typography variant="subtitle1" color={"primary"}>
                        Coach
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: 10 }}>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h5">
                        {`${coachRecord[0].coachee_firstName} ${coachRecord[0].coachee_lastName}`}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{
                          fontWeight: 400,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {coachRecord[0].coachee_position}
                      </Typography>
                      <Typography variant="subtitle1" color={"primary"}>
                        Coachee
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
              <Paper style={{ padding: 20, marginTop: 20 }}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography variant="h5" gutterBottom>
                    Save Meeting
                  </Typography>
                  <StyledBtnEditGroup onClick={() => handleEditMode()}>
                    <EditIcon />
                  </StyledBtnEditGroup>
                </Stack>
                <Typography>
                  {`Coach Date: ${dayjs(coachRecord[0].startTime).format(
                    "DD/MM/YYYY HH:mm"
                  )} - ${dayjs(coachRecord[0].endTime).format("HH:mm")}`}
                </Typography>
                {coachRecord[0].recordDate && (
                  <Typography>
                    {`Update Date: ${dayjs(coachRecord[0].recordDate).format(
                      "DD/MM/YYYY HH:mm"
                    )}`}
                  </Typography>
                )}
                <StyledDivider />
                {editMode ? (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Controller
                          name="issue"
                          control={control}
                          defaultValue={coachRecord[0].issue || ""}
                          render={({ field, formState }) => (
                            <TextField
                              {...field}
                              fullWidth
                              multiline
                              rows={3}
                              label="Interference / Issue / Gap"
                              error={!!formState.errors?.issue}
                              helperText={errors.issue?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledDivider />
                        <Controller
                          name="goal"
                          control={control}
                          defaultValue={coachRecord[0].goal || ""}
                          render={({ field, formState }) => (
                            <TextField
                              {...field}
                              fullWidth
                              multiline
                              rows={3}
                              label="Goal"
                              error={!!formState.errors?.goal}
                              helperText={errors.goal?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledDivider />
                        <Controller
                          name="reality"
                          control={control}
                          defaultValue={coachRecord[0].reality || ""}
                          render={({ field, formState }) => (
                            <TextField
                              {...field}
                              fullWidth
                              multiline
                              rows={3}
                              label="Reality"
                              error={!!errors?.reality}
                              helperText={errors.reality?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledDivider />
                        <Controller
                          name="opportunity"
                          control={control}
                          defaultValue={coachRecord[0].opportunity || ""}
                          render={({ field, formState }) => (
                            <TextField
                              {...field}
                              fullWidth
                              multiline
                              rows={3}
                              label="Opportunity"
                              error={!!formState.errors?.opportunity}
                              helperText={errors.opportunity?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledDivider />
                        <Controller
                          name="wrapUp"
                          control={control}
                          defaultValue={coachRecord[0].wrapUp || ""}
                          render={({ field, formState }) => (
                            <TextField
                              {...field}
                              fullWidth
                              multiline
                              rows={3}
                              label="Wrap up"
                              error={!!formState.errors?.wrapUp}
                              helperText={errors.wrapUp?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledDivider />
                        <Controller
                          name="note"
                          control={control}
                          defaultValue={coachRecord[0].note || ""}
                          render={({ field, formState }) => (
                            <TextField
                              {...field}
                              fullWidth
                              multiline
                              rows={3}
                              label="Note"
                              error={!!formState.errors?.note}
                              helperText={errors.note?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledDivider />
                        <Controller
                          name="homework"
                          control={control}
                          defaultValue={coachRecord[0].homework || ""}
                          render={({ field, formState }) => (
                            <TextField
                              {...field}
                              fullWidth
                              multiline
                              rows={3}
                              label="Homework"
                              error={!!formState.errors?.homework}
                              helperText={errors.homework?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledDivider />
                        <Stack
                          direction={"row"}
                          justifyContent={"flex-end"}
                          spacing={2}
                        >
                          <Button
                            type="reset"
                            variant="outlined"
                            color="error"
                            onClick={handleEditMode}
                          >
                            Cancel
                          </Button>
                          <Button type="submit" variant="contained">
                            Save
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Interference / Issue / Gap
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        paragraph
                        style={{ textIndent: "2em" }}
                      >
                        {coachRecord[0].issue}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledDivider />
                      <Typography variant="h6">Goal</Typography>
                      <Typography
                        variant="subtitle1"
                        paragraph
                        style={{ textIndent: "2em" }}
                      >
                        {coachRecord[0].goal}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledDivider />
                      <Typography variant="h6">Reality</Typography>
                      <Typography
                        variant="subtitle1"
                        paragraph
                        style={{ textIndent: "2em" }}
                      >
                        {coachRecord[0].reality}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledDivider />
                      <Typography variant="h6">Opportunity</Typography>
                      <Typography
                        variant="subtitle1"
                        paragraph
                        style={{ textIndent: "2em" }}
                      >
                        {coachRecord[0].opportunity}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledDivider />
                      <Typography variant="h6">Wrap up</Typography>
                      <Typography
                        variant="subtitle1"
                        paragraph
                        style={{ textIndent: "2em" }}
                      >
                        {coachRecord[0].wrapUp}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledDivider />
                      <Typography variant="h6">Note</Typography>
                      <Typography
                        variant="subtitle1"
                        paragraph
                        style={{ textIndent: "2em" }}
                      >
                        {coachRecord[0].note}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledDivider />
                      <Typography variant="h6">What Next / Homework</Typography>
                      <Typography
                        variant="subtitle1"
                        paragraph
                        style={{ textIndent: "2em" }}
                      >
                        {coachRecord[0].homework}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Paper>
              {coachRecord[0].idEmployees === currentUser.id && (
                <Paper style={{ padding: 20, marginTop: 30 }}>
                  {coachReviewItem && coachReviewItem.length > 0 ? (
                    <Stack direction={"row"} spacing={5}>
                      <div>
                        <Typography component="legend">Rating</Typography>
                        <Rating
                          readOnly
                          name="simple-controlled"
                          value={coachReviewItem[0].rating}
                        />
                      </div>
                      <TextField
                        InputProps={{ readOnly: true }}
                        label="Review"
                        fullWidth
                        multiline
                        rows={2}
                        value={coachReviewItem[0].review}
                      />
                      <IconButton
                        size="large"
                        color="error"
                        onClick={handleDeleteReview}
                      >
                        <HighlightOffIcon fontSize="inherit" />
                      </IconButton>
                    </Stack>
                  ) : (
                    <Stack direction={"row"} spacing={5}>
                      <div>
                        <Typography component="legend">Rating</Typography>
                        <Rating
                          name="simple-controlled"
                          value={rating}
                          onChange={(event, newValue) => {
                            setRating(newValue);
                          }}
                        />
                      </div>
                      <TextField
                        label="Review"
                        fullWidth
                        multiline
                        rows={2}
                        value={review}
                        onChange={handleChangeReview}
                      />
                      <IconButton
                        size="large"
                        color="success"
                        onClick={handleAddReview}
                      >
                        <AddCircleIcon fontSize="inherit" />
                      </IconButton>
                    </Stack>
                  )}
                </Paper>
              )}
            </Container>
          )}
        </div>
      )}
    </>
  );
};

export default SaveCoaching;
