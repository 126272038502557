import {
  UNI_REWARD_FETCHING,
  UNI_REWARD_FAILED,
  UNI_REWARD_SUCCESS,
  UNI_REWARD_ITEM_FETCHING,
  UNI_REWARD_ITEM_FAILED,
  UNI_REWARD_ITEM_SUCCESS,
} from "./types";
import uniRewardService from "../services/uniReward.service";

export const getUniRewards = () => async (dispatch) => {
  try {
    dispatch({
      type: UNI_REWARD_FETCHING,
    });
    const res = await uniRewardService.getUniRewards();
    if (res) {
      dispatch({
        type: UNI_REWARD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: UNI_REWARD_FAILED,
    });
    console.log(error);
  }
};

export const getUniReward = (id) => async (dispatch) => {
  try {
    dispatch({
      type: UNI_REWARD_ITEM_FETCHING,
    });
    const res = await uniRewardService.getUniReward(id);
    if (res) {
      dispatch({
        type: UNI_REWARD_ITEM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: UNI_REWARD_ITEM_FAILED,
    });
    console.log(error);
  }
};

export const addUniReward = (formData) => async (dispatch) => {
  try {
    const res = await uniRewardService.addUniReward(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: UNI_REWARD_FAILED,
    });
    console.log(error);
  }
};

export const updateUniReward = (formData) => async (dispatch) => {
  try {
    const res = await uniRewardService.updateUniReward(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: UNI_REWARD_FAILED,
    });
    console.log(error);
  }
};