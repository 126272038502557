import { httpClient } from "./httpClient";

const getAllQuestionTopic = (idCompany) => {
  return httpClient.get("question-topic", { params: { idCompany }});
};

const addQuestionTopic = (formData) => {
  return httpClient.post("question-topic/", formData);
};

const updateQuestionTopic = (formData) => {
  return httpClient.patch(`question-topic/${formData.idQuestionTopic}`, formData);
};

const deleteQuestionTopic = (id) => {
  return httpClient.delete("question-topic/" + id);
};

const getAllQuestion = () => {
  return httpClient.get("question");
};

const addQuestion = (formData) => {
  return httpClient.post("question", formData);
};

export default {
  getAllQuestionTopic,
  addQuestionTopic,
  updateQuestionTopic,
  deleteQuestionTopic,
  getAllQuestion,
  addQuestion,
};
