import {
  REWARDS_FETCHING,
  REWARDS_FAILED,
  REWARDS_SUCCESS,
  REWARD_ITEM_FETCHING,
  REWARD_ITEM_FAILED,
  REWARD_ITEM_SUCCESS,
  REWARD_BESTSELLER_FETCHING,
  REWARD_BESTSELLER_FAILED,
  REWARD_BESTSELLER_SUCCESS,
} from "./types";
import RewardService from "../services/reward.service";

export const getRewards = () => async (dispatch) => {
  try {
    dispatch({
      type: REWARDS_FETCHING,
    });
    const res = await RewardService.getRewards();
    if (res) {
      dispatch({
        type: REWARDS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REWARDS_FAILED,
    });
    console.log(err);
  }
};

export const getRewardsActive = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: REWARDS_FETCHING,
    });
    const res = await RewardService.getRewardsActive(idCompany);
      dispatch({
        type: REWARDS_SUCCESS,
        payload: res.data,
      });
  } catch (err) {
    dispatch({
      type: REWARDS_FAILED,
    });
    console.log(err);
  }
};

export const getUniRewardOfCompany = (idCompany, idUniReward) => async (dispatch) => {
  try {
    dispatch({
      type: REWARD_ITEM_FETCHING,
    });
    const { data } = await RewardService.getRewards(idCompany);
    const selectedReward = data.find((item) => item.idUniReward === idUniReward);
    if (selectedReward) {
      dispatch({
        type: REWARD_ITEM_SUCCESS,
        payload: selectedReward,
      });
    }
  } catch (err) {
    dispatch({
      type: REWARD_ITEM_FAILED,
    });
    console.log(err);
  }
};

export const getCompanyRewardOfCompany = (idCompany, idCompanyReward) => async (dispatch) => {
  try {
    dispatch({
      type: REWARD_ITEM_FETCHING,
    });
    const { data } = await RewardService.getRewards(idCompany);
    const selectedReward = data.find((item) => item.idCompanyReward === idCompanyReward);
    if (selectedReward) {
      dispatch({
        type: REWARD_ITEM_SUCCESS,
        payload: selectedReward,
      });
    }
  } catch (err) {
    dispatch({
      type: REWARD_ITEM_FAILED,
    });
    console.log(err);
  }
};

export const getActiveUniReward = (idCompany, idUniReward) => async (dispatch) => {
  try {
    dispatch({
      type: REWARD_ITEM_FETCHING,
    });
    const { data } = await RewardService.getRewardsActive(idCompany);
    const selectedReward = data.find((item) => item.idUniReward === idUniReward);

    if (selectedReward) {
      dispatch({
        type: REWARD_ITEM_SUCCESS,
        payload: selectedReward,
      });
    }
  } catch (err) {
    dispatch({
      type: REWARD_ITEM_FAILED,
    });
    console.log(err);
  }
};

export const getActiveCompanyReward = (idCompany, idCompanyReward) => async (dispatch) => {
  try {
    dispatch({
      type: REWARD_ITEM_FETCHING,
    });
    const { data } = await RewardService.getRewardsActive(idCompany);
    const selectedReward = data.find((item) => item.idCompanyReward === idCompanyReward)
    if (selectedReward) {
      dispatch({
        type: REWARD_ITEM_SUCCESS,
        payload: selectedReward,
      });
    }
  } catch (err) {
    dispatch({
      type: REWARD_ITEM_FAILED,
    });
    console.log(err);
  }
};

export const updateReward = (formData) => async (dispatch) => {
  try {
    const res = await RewardService.updateReward(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: REWARD_ITEM_FAILED,
    });
    console.log(error);
  }
};

// export const getRewardsBestSeller = (idCompany) => async (dispatch) => {
//   try {
//     dispatch({
//       type: REWARD_BESTSELLER_FETCHING,
//     });
//     const res = await RewardService.getRewardsBestSeller(idCompany);
//     if (res) {
//       dispatch({
//         type: REWARD_BESTSELLER_SUCCESS,
//         payload: res.data,
//       });
//     }
//   } catch (err) {
//     dispatch({
//       type: REWARD_BESTSELLER_FAILED,
//     });
//     console.log(err);
//   }
// };
