import React from "react";
import { useFieldArray } from "react-hook-form";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NestedVariationField from "./nestedVariationField";
import TableField from "./tableFiled";

export default function VariationField({ control, register, errors, items }) {
  const { fields, append } = useFieldArray({
    control,
    name: "variation",
  });

  return (
    <>
      <List>
        {fields.map((item, index) => {
          return (
            <ListItem key={item.id} disableGutters>
              <Card sx={{ bgcolor: "aliceblue", margin: "auto", width: "100%", maxWidth: 800 }}>
                <CardHeader subheader={`ตัวเลือกสินค้า ${index + 1}`} />
                <CardContent>
                  <Grid container rowGap={2}>
                    <Grid item container alignItems={"center"}>
                      <Grid item xs={2}>
                        <Typography>ชื่อ</Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography>{item.name}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item xs={2}>
                        <Typography>ตัวเลือก</Typography>
                      </Grid>
                      <Grid container item xs={10}>
                        <NestedVariationField
                          nestIndex={index}
                          {...{ control, register, errors, items }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </ListItem>
          );
        })}
      </List>
      {fields.length < 2 && (
        <Button
          sx={{ marginBottom: 2 }}
          startIcon={<AddCircleOutlineIcon />}
          variant="outlined"
          type="button"
          onClick={() =>
            append({
              name: "",
              option: [{ name: "" }],
            })
          }
        >
          เพิ่ม
        </Button>
      )}
      <TableField control={control} />
    </>
  );
}
