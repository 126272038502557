import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Typography, Avatar, Chip, Container } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { getBookingByIdCoachee } from "../../../../actions/book";
import { useNavigate } from "react-router-dom";
import CoachingStatus from "../../shared/coachingStatus/CoachingStatus";

const StyledRoot = styled("div")({
  paddingTop: 100,
  height: "100%",
});

const WrapDataGrid = styled("div")({
  height: 640,
  width: "100%",
});

const StyledDataGrid = styled(DataGrid)({
  "&.MuiDataGrid-root": {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      border: "none",
    },
    "& .MuiDataGrid-cell": {
      border: "none",
    },
  },
  "& .super-app-theme--header": {
    backgroundColor: "#f4f6f8",
    color: "#637381",
  },
});

const StyledChipTime = styled(Chip)(({ theme }) => ({
  justifyContent: "left",
  color: "#ffffff",
  backgroundColor: theme.palette.warning.main,
  // border: `1px solid ${theme.palette.info.main}`
}));

const BookingList = () => {
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [valueSelected, setValueSelected] = React.useState(null);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: bookCoachee } = useSelector((state) => state.bookCoachee);

  useEffect(() => {
    dispatch(getBookingByIdCoachee(currentUser.id));
  }, []);

  const rowsData = useMemo(() => {
    if (bookCoachee) {
      return bookCoachee.map((item) => ({
        id: item.idBooking,
        idCoach: item.idCoach,
        name: `${item.firstname_TH} ${item.lastname_TH}`,
        bookDate: item.bookDate,
        date: item.startTime,
        startTime: item.startTime,
        endTime: item.endTime,
        status: item.bookingStatus,
        image: "1.jpg",
      }));
    }
  }, [bookCoachee]);

  const columns = [
    {
      field: "name",
      headerName: "ชื่อนาม-สกุล",
      flex: 4,
      minWidth: 280,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={`${process.env.REACT_APP_API_URL}image/profile/${params.row.image}`}
            />
            <Typography style={{ marginLeft: 8 }}>{params.row.name}</Typography>
          </div>
        );
      },
    },
    {
      field: "bookDate",
      headerName: "วันที่จอง",
      type: "date",
      flex: 2,
      minWidth: 160,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => new Date(params.row.bookDate),
      renderCell: (params) => {
        return (
          <div>
            <Typography style={{ fontWeight: 600, fontSize: 14 }}>
              {dayjs(params.row.bookDate).format("DD MMM YYYY ")}
            </Typography>
            <Typography style={{ fontSize: 14 }} color="text.third">
              {dayjs(params.row.bookDate).format("HH:mm")}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "วันที่เรียน",
      type: "date",
      flex: 2,
      minWidth: 160,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => new Date(params.row.date),
      renderCell: (params) => {
        return (
          <Typography>
            {dayjs(params.row.date).format("DD MMM YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "time",
      headerName: "เวลาเรียน",
      type: "date",
      flex: 2,
      minWidth: 160,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => new Date(params.row.startTime),
      renderCell: (params) => {
        return (
          <StyledChipTime
            label={`${dayjs(params.row.startTime).format("HH:mm")} - ${dayjs(
              params.row.endTime
            ).format("HH:mm")} `}
          />
        );
      },
      filterable: false,
    },
    {
      field: "status",
      headerName: "สถานะ",
      flex: 1,
      minWidth: 160,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return <CoachingStatus status={params.value} />;
      },
      type: "singleSelect",
      valueOptions: ["book", "approve", "reject"],
      sortable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "ตัวเลือก",
      flex: 1,
      minWidth: 80,
      headerClassName: "super-app-theme--header",
      cellClassName: "actions",
      getActions: (params) => {
        const isBookStatus = params.row.status === "book";
        if (isBookStatus) {
          return [
            <GridActionsCellItem
              showInMenu
              label="โปรไฟล์ผู้สอน"
              icon={<AccountCircleIcon />}
              className="textPrimary"
              onClick={() =>
                navigate(`/coaching/profile/${params.row.idCoach}`)
              }
            />,
            <GridActionsCellItem
              showInMenu
              icon={<CancelIcon color="error" />}
              label="ยกเลิกการจอง"
              className="textPrimary"
              style={{ color: "#d32f2f" }}
              onClick={() => console.log(params.row.id)}
            />,
          ];
        } else {
          return [
            <GridActionsCellItem
              showInMenu
              label="โปรไฟล์ผู้สอน"
              icon={<AccountCircleIcon />}
              className="textPrimary"
              onClick={() =>
                navigate(`/coaching/profile/${params.row.idCoach}`)
              }
            />,
          ];
        }
      },
    },
  ];

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <Typography variant="h4" marginBottom={2}>
          ตารางการจองโค้ช
        </Typography>
        {bookCoachee && (
          <WrapDataGrid>
            <StyledDataGrid
              rows={rowsData}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 100]}
              // filterModel={filterModel}
              // onFilterModelChange={(model) => setFilterModel(model)}
            />
          </WrapDataGrid>
        )}
      </Container>
    </StyledRoot>
  );
};

export default BookingList;
